import React, { Component } from "react";
import ImageButton from "../2/Controls/ImageButton";
import Icon from "../2/Controls/Icon";
import "./CustomerSearch.css";
import { getCustomerMLoyalLoyaltyPoints } from "../../services/api/MLoyal";
import { getCustomerEasyRewardzLoyaltyPoints } from "../../services/api/EasyRewardz";

var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;

class CustomerSearch extends Component {
  constructor() {
    super();
    this.state = {
      isCustomerTypeAheadLoading: false,
      customerTypeAheadSelected: [],
      customerTypeAheadOptions: [],
      LoyaltyType: "",
      LoyaltyPoints: "",
      // customer:null
    };
    this.onCustomerTypeAheadSearch = this.onCustomerTypeAheadSearch.bind(this);
    this.onCustomerSelected = this.onCustomerSelected.bind(this);
    this.getCustomerDetails = this.getCustomerDetails.bind(this);
    this.onRemoveCustomer = this.onRemoveCustomer.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  onCustomerTypeAheadSearch(query) {
    if (this.props.config.ConnectionStatus.Status !== "connected") {
      this.setState({ query: query, isCustomerTypeAheadLoading: false });
      return;
    }
    this.setState({ isCustomerTypeAheadLoading: true, query: query });
    this.props
      .searchCustomer(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        query
      )
      .then((result) => {
        // console.log('result.searchResult ', result.searchResult )//
        this.setState({ customerTypeAheadOptions: result.searchResult });
        this.setState({ isCustomerTypeAheadLoading: false });
      });
  }

  onRemoveCustomer() {
    this.props.onCustomerChange(null);
  }

  async getCustomerDetails(customer) {
    if (
      this.props.registration.registration.hasOwnProperty("LoyaltyEnabled") &&
      this.props.registration.registration.LoyaltyEnabled
    ) {
      let customerLoyalty = null;
      customerLoyalty = await this.props.getCustomerLoyaltyPoints(customer.key);
      if (customerLoyalty) {
        this.setState({
          LoyaltyType: customerLoyalty ? customerLoyalty.LoyaltyType : "",
          LoyaltyPoints: customerLoyalty ? customerLoyalty.LoyaltyPoints : "",
        });
      }
    } else if (
      this.props.registration.registration.hasOwnProperty("EnableMLoyal") &&
      this.props.registration.registration.EnableMLoyal
    ) {
      // let response = await
      getCustomerMLoyalLoyaltyPoints(
        this.props.user.store.RegistrationID,
        this.props.user.store.key,
        customer.key,
        customer.PhoneNo
      ).then((response) => {
        console.log("response", response);
        // console.log('response.CUSTOMER_DETAILS.Slab',response.CUSTOMER_DETAILS[0].Slab);
        // console.log('response.CUSTOMER_DETAILS.LoyalityPoints',response.CUSTOMER_DETAILS[0].LoyalityPoints)
        if (
          response &&
          response.CUSTOMER_DETAILS &&
          response.CUSTOMER_DETAILS.length
        ) {
          this.setState({
            LoyaltyType:
              response.CUSTOMER_DETAILS[0].Slab +
              " (Pt: " +
              response.CUSTOMER_DETAILS[0].LoyalityPoints +
              ", Vl: " +
              response.CUSTOMER_DETAILS[0].LoyalityPointsValue +
              ")",
            LoyaltyPoints: response.CUSTOMER_DETAILS[0].LoyalityPoints,
          });
        }
      });
      // console.log('result',result)
    } else if (
      this.props.registration.registration.hasOwnProperty(
        "EnableEasyRewardz"
      ) &&
      this.props.registration.registration.EnableEasyRewardz
    ) {
      getCustomerEasyRewardzLoyaltyPoints(
        this.props.user.store.RegistrationID,
        this.props.user.store.key,
        this.props.user.store.StoreCode,
        customer.key,
        customer.PhoneNo
      ).then((response) => {
        console.log("response", response);
        // console.log('response.CUSTOMER_DETAILS.Slab',response.CUSTOMER_DETAILS[0].Slab);
        // console.log('response.CUSTOMER_DETAILS.LoyalityPoints',response.CUSTOMER_DETAILS[0].LoyalityPoints)
        if (response && response.Success) {
          this.setState({
            LoyaltyType: "Loyalty Points : " + response.Data.AvailablePoints,
            LoyaltyPoints: response.Data.AvailablePoints,
          });
        }
      });
    }
    let membership = {};
    // console.log('membership', customer.Membership)

    if (
      customer.Membership &&
      customer.Membership.hasOwnProperty("ValidityDate") &&
      customer.Membership.ValidityDate
    ) {
      // console.log('new Date(customer.Membership.ValidityDate.seconds*1000)', new Date(customer.Membership.ValidityDate.seconds*1000))
      // console.log('new Date()', new Date())
      // console.log('Date(customer.Membership.ValidityDate)', Date(customer.Membership.ValidityDate))
      if (new Date(customer.Membership.ValidityDate) < new Date()) {
        // console.log('membership no longer valid')
      } else {
        membership = customer.Membership;
      }
    } else {
      membership = customer.Membership;
    }
    let selectedCustomer = {
      key: customer.key,
      CustomerCode: customer.CustomerCode,
      FirstName: customer.FirstName,
      LastName: customer.LastName,
      EmailID: customer.EmailID,
      PhoneNo: customer.PhoneNo,
      Membership: membership,
      Note: customer.hasOwnProperty("Note") ? customer.Note : "",
      CustomField1: customer.CustomField1,
      CustomField2: customer.CustomField2,
      CustomField3: customer.CustomField3,
      CustomField4: customer.CustomField4,
      CustomField5: customer.CustomField5,
      CustomField6: customer.CustomField6,
      WalletBalance: this.roundUpTo2(customer.WalletBalance),
      Addresses: customer.Addresses,
      PetDetails: customer.hasOwnProperty("PetDetails")
        ? customer.PetDetails
        : [],
      SaleToPurchaseStoreID: customer.hasOwnProperty("SaleToPurchaseStoreID")
        ? customer.SaleToPurchaseStoreID
        : "",
      OpeningOutStandingAmount: customer.hasOwnProperty(
        "OpeningOutStandingAmount"
      )
        ? customer.OpeningOutStandingAmount
        : 0,
      OpeningOutStandingAmountCurrent: customer.hasOwnProperty(
        "OpeningOutStandingAmountCurrent"
      )
        ? customer.OpeningOutStandingAmountCurrent
        : 0,
      OpeningOutStandingAmountReceived: customer.hasOwnProperty(
        "OpeningOutStandingAmountReceived"
      )
        ? customer.OpeningOutStandingAmountReceived
        : 0,
      OutStandingAmount: customer.hasOwnProperty("OutStandingAmount")
        ? customer.OutStandingAmount
        : 0,
      // LoyaltyType: customerLoyalty ? customerLoyalty.LoyaltyType:'',
      // LoyaltyPoints:customerLoyalty ? customerLoyalty.LoyaltyPoints: '',
      LastTransactionNo: customer.hasOwnProperty("LastTransactionNo")
        ? customer.LastTransactionNo
        : "",
      LastTransactionKey: customer.hasOwnProperty("LastTransactionKey")
        ? customer.LastTransactionKey
        : "",
      LastVisitedOn: customer.hasOwnProperty("LastVisitedOn")
        ? new Date(customer.LastVisitedOn).toISOString().split("T")[0]
        : "",
    };
    // console.log('selectedCustomer', selectedCustomer)
    return selectedCustomer;
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      // alert('enter presses')
      // console.log('this.state.customerTypeAheadOptions.length', this.state.customerTypeAheadOptions.length)
      if (this.state.customerTypeAheadOptions.length === 0) {
        let query = this.state.query;
        let customer = {};
        customer.EmailID = "";
        customer.PhoneNo = "";
        customer.FirstName = "";
        if (query.includes("@")) {
          customer.EmailID = query;
        }
        if (Number(query)) {
          customer.PhoneNo = query;
        }
        if (!query.includes("@") && !Number(query)) {
          customer.FirstName = query;
        }
        // console.log(customer)
        this.props.onAddCustomer(customer);
      }
    }
  }

  async onCustomerSelected(selected) {
    // console.log("CustomerSearch onCustomerSelected", selected);
    let customer = await this.getCustomerDetails(selected);
    // console.log('onCustomerSelected',customer )
    this.props.onCustomerChange(customer);
  }

  render() {
    return (
      <div
        className={
          this.props.showAddOption
            ? "customer-search"
            : "customer-search-rounded"
        }
      >
        {!this.props.customer && (
          <div
            className={
              this.props.error ? "form-group has-error" : "form-group "
            }
            style={{ marginBottom: "0px" }}
          >
            {!this.props.user.store.DisableCustomerCreationAndEdit && (
              <React.Fragment>
                <div className="input-group" style={{ width: "100%" }}>
                  <AsyncTypeahead
                    ref="refSearchCustomerInput"
                    // ref="asyncCustomerSearch"
                    id="my-typeahead-id"
                    // autoFocus={true}
                    allowNew={false}
                    isLoading={this.state.isCustomerTypeAheadLoading}
                    multiple={false}
                    options={this.state.customerTypeAheadOptions}
                    labelKey="FirstName"
                    minLength={3}
                    onSearch={this.onCustomerTypeAheadSearch}
                    onKeyDown={this.handleKeyPress}
                    useCache={false} // Disable caching
                    placeholder={
                      this.props.placeholder
                        ? this.props.placeholder
                        : "Search customer by name, contact no, emailid or customer code"
                    }
                    // :"(F3) Search customer by name, contact no, emailid or customer code"
                    // filterBy={[
                    //   "FirstName",
                    //   "LastName",
                    //   "PhoneNo",
                    //   "EmailID",
                    //   "CustomerCode",
                    // ]}
                    filterBy={() => true}
                    bsSize={"large"}
                    dropup={false}
                    highlightOnlyResult={true}
                    selected={this.state.customerTypeAheadSelected}
                    // onKeyPress={}
                    onChange={(selected) => {
                      this.onCustomerSelected(selected[0]);
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <div className="customer-search-lineitem">
                        <div className="customer-search-lineitem-maintext">
                          <b>
                            {option.FirstName} {option.LastName}
                          </b>
                        </div>
                        <div className="customer-search-lineitem-subtext">
                          {option.PhoneNo && option.PhoneNo !== "" && (
                            <React.Fragment>
                              {" "}
                              <i className="fa fa-phone"></i>&nbsp;
                              {option.PhoneNo}{" "}
                            </React.Fragment>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;
                          {option.EmailID && option.EmailID !== "" && (
                            <React.Fragment>
                              <i className="fa fa-envelope"></i>
                              {option.EmailID}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="customer-search-lineitem-subtext">
                          {option.CustomerCode}
                        </div>
                      </div>
                    )}
                  />

                  {this.props.showAddOption && (
                    <div className="input-group-btn">
                      <button
                        type="button"
                        onClick={() => this.props.onAddCustomer(null)}
                        className="btn button-lg button-border button-right-rounded"
                      >
                        <Icon type="addlg" />
                        {/* <i className="fa fa-plus"></i> */}
                      </button>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            {this.props.user.store.DisableCustomerCreationAndEdit && (
              <input
                type="text"
                className="rbt-input-main form-control rbt-input  input-lg form-control-lg"
                placeholder="Please provide customer details"
                value={this.state.customerInput}
                onChange={(e) => {
                  this.setState({ customerInput: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // alert("enter")
                    this.props
                      .searchCustomer(
                        this.props.user.user.RegistrationID,
                        this.props.user.store.key,
                        this.state.customerInput
                      )
                      .then((result) => {
                        // console.log('result.searchResult ', result.searchResult )
                        for (let i = 0; i < result.searchResult.length; i++) {
                          if (
                            result.searchResult[i].PhoneNo ===
                            this.state.customerInput
                          ) {
                            // console.log("result.searchResult[i]",result.searchResult[i])
                            this.setState({ customerInput: "" });
                            this.onCustomerSelected(result.searchResult[i]);
                            return;
                          }
                        }
                        alert("Customer not found");
                      });
                  }
                }}
              />
            )}
          </div>
        )}
        {this.props.customer && (
          <React.Fragment>
            <div className="customer-search-selected-customer">
              <div
                className="customer-search-selected-customer-left"
                onClick={() => {
                  // if(!this.props.mobile)
                  // {
                  if (!this.props.user.store.DisableCustomerCreationAndEdit) {
                    this.props.onCustomerEdit();
                  }

                  // }
                }}
              >
                <div>
                  {this.props.customer.FirstName} {this.props.customer.LastName}
                </div>
                {this.props.customer.PhoneNo !== "" &&
                  !this.props.user.store.DisableCustomerCreationAndEdit && (
                    <div className="customer-search-selected-customer-phone">
                      <i className="fa fa-phone"></i>{" "}
                      {this.props.customer.PhoneNo}
                    </div>
                  )}
              </div>
              <div className="customer-search-selected-customer-right">
                {!this.props.user.store.DisableCustomerCreationAndEdit && (
                  <ImageButton
                    type="customer"
                    onClick={this.props.onShowCustomerDashBoard}
                  />
                )}
                <ImageButton
                  type="remove"
                  onClick={() => this.onRemoveCustomer()}
                />
              </div>
            </div>
            {this.props.customer &&
              (this.props.customer.OutStandingAmount > 0 ||
                this.props.customer.WalletBalance > 0 ||
                (this.props.customer.Membership &&
                  this.props.customer.Membership.hasOwnProperty(
                    "MembershipID"
                  )) ||
                this.state.LoyaltyType.trim() !== "") && (
                <div className="customer-search-selected-status pull-right">
                  {this.props.customer &&
                    this.props.customer.OutStandingAmount > 0 && (
                      <span className="label label-danger ">
                        O/S: {this.props.customer.OutStandingAmount}
                      </span>
                    )}
                  {this.props.customer &&
                    this.props.customer.WalletBalance > 0 && (
                      <span className="label label-success ">
                        Wallet: {this.props.customer.WalletBalance}
                      </span>
                    )}
                  {this.props.customer &&
                    this.props.customer.Membership &&
                    this.props.customer.Membership.hasOwnProperty(
                      "MembershipID"
                    ) &&
                    this.props.customer.Membership.MembershipID != "" && (
                      <span className="label label-warning">
                        {this.props.customer.Membership.Name}
                      </span>
                    )}
                  {this.state.LoyaltyType.trim() !== "" && (
                    <span className="label label-info">
                      {this.state.LoyaltyType}
                    </span>
                  )}
                </div>
              )}
          </React.Fragment>
        )}

        {/* {this.props.customer && <div className="input-group">
            style={{marginTop:"5px",marginBottom:"0px",fontSize:"14px"}}
                <button type="button" onClick={() => 
                {if(!this.props.mobile)
                    {
                        this.props.onCustomerEdit()
                    }
                }}
                    className="btn-left-rounded" style={{padding:"0px",height:"46px"}}>
                    <div style={{fontSize:"15px", marginLeft:"5px"}} className="pull-left">{this.props.customer.FirstName} {this.props.customer.LastName}  {this.props.customer.CustomerCode !== "" ? " (" + this.props.customer.CustomerCode + ")" : ""}</div><br></br>
                    {this.props.customer.PhoneNo!=="" && <div style={{fontSize:"12px", marginLeft:"5px"}} className="pull-left"><i className="fa fa-phone"></i> {this.props.customer.PhoneNo}</div>}
                    {this.props.customer.EmailID!=="" && <div style={{fontSize:"12px", marginRight:"5px"}} className="pull-right"><i className="fa fa-envelope"></i> {this.props.customer.EmailID}</div>}
                </button>
                <div className="input-group-btn">
                    {!this.props.mobile && <button type="button" className="btn-flat" 
                        onClick={this.props.onShowCustomerDashBoard}>
                        <Icon type="customer"/>
                    </button> }                                           
                    <button type="button" className="btn-right-rounded"
                        onClick={() => this.onRemoveCustomer()}>
                        <Icon type="remove"/>
                        </button>
                </div>
            </div>} */}

        {/* {(this.props.customer  && this.props.customer.WalletBalance > 0 )&& <span className="label label-success pull-right" style={{margin:"5px",marginBottom:"0px",fontSize:"14px"}}>Wallet: {this.props.customer.WalletBalance}</span> }
                {(this.props.customer && this.props.customer.Membership && this.props.customer.Membership.hasOwnProperty('MembershipID') ) && <React.Fragment> {(this.props.customer.Membership !== null) && <React.Fragment> {this.props.customer.Membership.hasOwnProperty() } <span className="label label-warning pull-right" style={{margin:"5px",marginBottom:"0px",fontSize:"14px"}}>Membership: {this.props.customer.Membership.Name}</span></React.Fragment>} </React.Fragment>} */}
      </div>
    );
  }
}
export default CustomerSearch;
