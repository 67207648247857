import firebase, { database } from "../firebase";
import * as constants from "../Constatnts";

export function addNewVendor(vendor) {
  return (dispatch, getState) => {
    vendor.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    vendor.ActionBy = getState().user.user.Name;
    vendor.ActionByUID = getState().user.user.UID;
    vendor.ActionByEmailID = getState().user.user.EmailID;

    vendor.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("Vendors")
      .add({
        ...vendor,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding vendors: ", error);
      });
  };
}

export function getVendors(
  registrationID,
  from,
  size,
  option,
  searchText,
  searchCity,
  searchState,
  searchCountry,
  searchPinCode,
  searchCustomField1,
  searchCustomField2,
  searchCustomField3,
  searchCustomField4,
  searchCustomField5,
  searchCustomField6
) {
  let searchParameters = {
    from: from,
    size: 1000,
    track_total_hits: true,
    query: {
      bool: {
        must: [{ term: { "RegistrationID.keyword": registrationID } }],
        must_not: [],
        should: [],
        minimum_should_match: 1,
        filter: {
          bool: {
            must: [],
          },
        },
      },
    },
  };
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ match: { IsDeleted: "true" } });
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({
      match: { IsDeleted: "false" },
    });
  }
  searchParameters.query.bool.should.push({
    match_phrase: { VendorFirstName: searchText.trim().toLowerCase() },
  });
  searchParameters.query.bool.should.push({
    match_phrase: { VendorLastName: searchText.trim().toLowerCase() },
  });
  searchParameters.query.bool.should.push({
    regexp: { VendorFirstName: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { VendorLastName: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { VendorCode: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" },
  });
  searchParameters.query.bool.should.push({
    regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" },
  });

  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { City: "@" + searchCity.trim().toLowerCase() + ".*" },
    });
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { State: "@" + searchState.trim().toLowerCase() + ".*" },
    });
  }
  if (searchPinCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { PostalCode: "@" + searchPinCode.trim().toLowerCase() + ".*" },
    });
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: { Country: "@" + searchCountry.trim().toLowerCase() + ".*" },
    });
  }
  if (searchCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField1: "@" + searchCustomField1.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField2: "@" + searchCustomField2.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField3: "@" + searchCustomField3.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField4: "@" + searchCustomField4.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField5: "@" + searchCustomField5.trim().toLowerCase() + ".*",
      },
    });
  }
  if (searchCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      regexp: {
        CustomField6: "@" + searchCustomField6.trim().toLowerCase() + ".*",
      },
    });
  }

  console.log("Query", JSON.stringify(searchParameters));
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable("getVendors");

    return addMessage({ text: searchParameters }).then(function(result) {
      const vendors = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let party = data._source;
        // console.log(party)
        if (getState().user.user.Role === "Power User") {
          vendors.push({ key: data._id, ...party });
        } else if (
          party.StoreID.trim() !== "" &&
          party.StoreID === getState().user.store.key
        ) {
          vendors.push({ key: data._id, ...party });
        } else if (
          party.hasOwnProperty("AssociatedStores") &&
          party.AssociatedStores.filter(
            (f) => f.StoreID === getState().user.store.key
          ).length > 0
        ) {
          vendors.push({ key: data._id, ...party });
        }
      });
      return { totalItemsCount: vendors.length, searchResult: vendors };
    });
  };
}

export function deleteVendor(key) {
  return (dispatch, getState) => {
    return database
      .collection("Vendors")
      .doc(key)
      .update({
        IsDeleted: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,

        Action: constants.AUDIT_TRAIL_DELETE,
      })
      .then(() => {
        return true;
      });
  };
}

export function getVendorDetails(key) {
  return (dispatch) => {
    return database
      .collection("Vendors")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        } else {
          console.log("No such vendor found!");
          return null;
        }
      });
  };
}

export function updateVendor(vendor, key) {
  return (dispatch, getState) => {
    vendor.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    vendor.ActionBy = getState().user.user.Name;
    vendor.ActionByUID = getState().user.user.UID;
    vendor.ActionByEmailID = getState().user.user.EmailID;

    vendor.Action = constants.AUDIT_TRAIL_EDIT;
    return database
      .collection("Vendors")
      .doc(key)
      .update({
        ...vendor,
      });
  };
}

export function checkIfVendorCodeIsUnique(vendorCode, registrationID) {
  return (dispatch) => {
    let isUnique = true;
    return database
      .collection("Vendors")
      .where("RegistrationID", "==", registrationID)
      .where("VendorCode", "==", vendorCode)
      .where("IsDeleted", "==", false)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          isUnique = false;
        });
        return isUnique;
      });
  };
}

export function searchVendor(RegistrationID, StoreID, searchText) {
  return (dispatch) => {
    //   let searchParameters = {
    //     "from": 0,
    //     "size": 50,
    //     "track_total_hits": true,
    //     "query": {
    //       "bool": {
    //         "must": [
    //           { "match": { "RegistrationID.keyword": RegistrationID } },
    //           { "match": { "IsDeleted": "false" } }
    //         ],
    //         "should": [

    //         ],"minimum_should_match": 1
    //       }
    //     }
    //   }
    //   searchParameters.query.bool.should.push({ "regexp": { "VendorFirstName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
    // searchParameters.query.bool.should.push({ "regexp": { "VendorLastName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
    // searchParameters.query.bool.should.push({ "regexp": { "VendorCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
    // searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
    // searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@"+ searchText.trim().toLowerCase() +  ".*" }})

    let searchParameters = {
      from: 0,
      size: 50,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            { match: { "RegistrationID.keyword": RegistrationID } },
            { match: { IsDeleted: "false" } },
          ],
          should: [],
          minimum_should_match: 1,
        },
      },
    };

    // Split search text into individual words
    const searchTerms = searchText
      .trim()
      .toLowerCase()
      .split(/\s+/);

    // Create regex patterns for each field
    const createRegexConditions = (fieldName) => {
      return searchTerms.map((term) => ({
        regexp: {
          [fieldName]: `.*${term}.*`,
        },
      }));
    };

    // Add conditions for each field
    searchParameters.query.bool.should.push(
      ...createRegexConditions("VendorFirstName"),
      ...createRegexConditions("VendorLastName"),
      { regexp: { VendorCode: "@" + searchText.trim().toLowerCase() + ".*" } },
      { regexp: { PhoneNo: "@" + searchText.trim().toLowerCase() + ".*" } },
      { regexp: { EmailID: "@" + searchText.trim().toLowerCase() + ".*" } }
    );
    console.log("searchVendor", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("getVendors");
    return addMessage({ text: searchParameters }).then(function(result) {
      const parties = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        let party = data._source;
        console.log("party", party);
        if (party.StoreID.trim() !== "" && party.StoreID === StoreID) {
          parties.push({ key: data._id, ...party });
        } else if (
          party.hasOwnProperty("AssociatedStores") &&
          party.AssociatedStores.filter((f) => f.StoreID === StoreID).length > 0
        ) {
          parties.push({ key: data._id, ...party });
        }
      });
      // console.log(JSON.stringify(parties))
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: parties,
      };
    });
  };
}
