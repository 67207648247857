import React, { Component } from "react";
var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;

class VendorSearch extends Component {
  constructor() {
    super();
    this.state = {
      isTypeAheadLoading: false,
      typeAheadSelected: [],
      typeAheadOptions: [],
    };
    this.onTypeAheadSearch = this.onTypeAheadSearch.bind(this);
    this.onSelected = this.onSelected.bind(this);
    this.onRemove = this.onRemove.bind(this);
  }

  onTypeAheadSearch(query) {
    this.setState({ isTypeAheadLoading: true });
    // console.log('query')//
    this.props
      .searchVendor(
        this.props.user.user.RegistrationID,
        this.props.user.store.key,
        query
      )
      .then((result) => {
        console.log("result", result);
        this.setState({
          typeAheadOptions: result.searchResult,
          isTypeAheadLoading: false,
        });
        // this.setState({ isTypeAheadLoading: false });
      });
  }

  onRemove() {
    this.props.onChange(null);
  }

  onSelected(selected) {
    this.props.onChange(selected[0]);
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.vendor && (
          <div
            className={
              this.props.error ? "form-group has-error" : "form-group "
            }
            style={{ marginBottom: "0px" }}
          >
            <div className="input-group">
              <AsyncTypeahead
                id="vendorSearch"
                allowNew={false}
                isLoading={this.state.isTypeAheadLoading}
                multiple={false}
                options={this.state.typeAheadOptions}
                labelKey="VendorFirstName"
                minLength={3}
                onSearch={this.onTypeAheadSearch}
                placeholder="Search vendor"
                useCache={false} // Disable caching
                filterBy={() => true}
                // filterBy={[
                //   "VendorFirstName",
                //   "VendorLastName",
                //   "PhoneNo",
                //   "EmailID",
                //   "VendorCode",
                // ]}
                dropup={false}
                highlightOnlyResult={true}
                selected={this.state.typeAheadSelected}
                onChange={(selected) => {
                  this.onSelected(selected);
                }}
                renderMenuItemChildren={(option, props) => (
                  <div>
                    <div>
                      <b>
                        {option.VendorFirstName} {option.VendorLastName}
                      </b>
                    </div>
                    <div>{option.PhoneNo}</div>
                    <div>{option.EmailID}</div>
                    <div>{option.VendorCode}</div>
                  </div>
                )}
              />
              <div className="input-group-btn">
                <button
                  type="button"
                  onClick={() => this.props.onAddVendor(null)}
                  className="btn btn-default btn-md btn-flat"
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        {this.props.vendor && (
          <div className="input-group">
            <button
              type="button"
              onClick={() => this.props.onEditVendor()}
              className="btn btn-default btn-block btn-flat right-flat"
              // style={{ padding: "0px", height: "34px" }}
            >
              <div
                //  style={{ fontSize: "15px", marginLeft: "5px" }}
                className="pull-left"
              >
                {this.props.vendor.VendorFirstName}{" "}
                {this.props.vendor.VendorLastName}{" "}
                {this.props.vendor.VendorCode !== ""
                  ? " (" + this.props.vendor.VendorCode + ")"
                  : ""}
              </div>
              <br></br>
              {/* {this.props.vendor.PhoneNo !== "" && <div style={{ fontSize: "12px", marginLeft: "5px" }} className="pull-left"><i className="fa fa-phone"></i> {this.props.vendor.PhoneNo}</div>}
                    {this.props.vendor.EmailID !== "" && <div style={{ fontSize: "12px", marginRight: "5px" }} className="pull-right"><i className="fa fa-envelope"></i> {this.props.vendor.EmailID}</div>} */}
            </button>
            {!this.props.hideRemoveOption && (
              <div className="input-group-btn">
                <button
                  type="button"
                  className="btn btn-default btn-md btn-flat"
                  onClick={() => this.onRemove()}
                >
                  <i className="fa fa-remove"></i>{" "}
                </button>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default VendorSearch;
