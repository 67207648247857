import firebase, { database } from "../firebase";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";

export function getProductBrands(registrationID) {
  return (dispatch) => {
    let searchQuery = {
      from: 0,
      size: 10000,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            { match: { IsDeleted: "false" } },
          ],
        },
      },
    };

    let addMessage = firebase.functions().httpsCallable("getProductBrands");

    return addMessage({ text: searchQuery }).then(function(result) {
      const productBrands = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        productBrands.push({ key: data._id, ...data._source });
      });
      dispatch({ type: actionTypes.PRODUCT_BRANDS_GET, productBrands });
    });
  };
}

export function deleteProductBrand(key) {
  return (dispatch, getState) => {
    return database
      .collection("ProductBrands")
      .doc(key)
      .update({
        IsDeleted: true,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByUID: getState().user.user.UID,
        ActionByEmailID: getState().user.user.EmailID,

        Action: constants.AUDIT_TRAIL_DELETE,
      })
      .then(() => {
        return true;
      });
  };
}

export function saveProductBrand(productBrand, key) {
  return (dispatch, getState) => {
    productBrand.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    productBrand.ActionBy = getState().user.user.Name;
    productBrand.ActionByUID = getState().user.user.UID;
    productBrand.ActionByEmailID = getState().user.user.EmailID;

    if (key.trim() !== "") {
      productBrand.Action = constants.AUDIT_TRAIL_EDIT;
      return database
        .collection("ProductBrands")
        .doc(key)
        .update({
          ...productBrand,
        })
        .then(() => {
          return key;
        })
        .catch(function(error) {
          console.error("Error updating product brand: ", error);
        });
    } else {
      productBrand.Action = constants.AUDIT_TRAIL_NEW;

      return database
        .collection("ProductBrands")
        .add({
          ...productBrand,
        })
        .then(function(docRef) {
          return docRef.id;
        })
        .catch(function(error) {
          console.error("Error adding product brand: ", error);
        });
    }
  };
}

export function checkIfProductBrandIsUnique(brand, brandID, registrationID) {
  return (dispatch) => {
    let searchParameters = {
      from: 0,
      size: 1,
      query: {
        bool: {
          must: [
            { match: { RegistrationID: registrationID } },
            { match: { IsDeleted: "false" } },
            { match_phrase: { Brand: brand } },
          ],
          must_not: [],
        },
      },
    };
    console.log("brandID", brandID);

    if (brandID !== "") {
      searchParameters.query.bool.must_not.push({ match: { _id: brandID } });
    }
    console.log("brand searchParameters", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getProductBrands");
    return addMessage({ text: searchParameters }).then(function(result) {
      let sanitizedMessage = result.data.text;
      let isUnique = true;
      if (sanitizedMessage.hits.total.value > 0) {
        isUnique = false;
      }
      return isUnique;
    });
  };
}

export function searchProductBrands(
  registrationID,
  from,
  size,
  option,
  searchText
) {
  return (dispatch) => {
    let searchParameters = {
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [{ match: { RegistrationID: registrationID } }],
          must_not: [],
        },
      },
      sort: [{ ActionOn: { order: "desc" } }],
    };
    if (option === "active") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "true" },
      });
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({
        match: { IsDeleted: "false" },
      });
    }
    searchParameters.query.bool.must.push({
      regexp: { Brand: "@" + searchText.trim().toLowerCase() + ".*" },
    });

    console.log("Brand Details", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getProductBrands");
    return addMessage({ text: searchParameters }).then(function(result) {
      const productBrands = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        productBrands.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: productBrands,
      };
    });
  };
}
