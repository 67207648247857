import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import React, { Component } from "react";
import { HotKeys } from "react-hotkeys";
// import Modal from "react-responsive-modal";
import "./Style.css";
import * as actionTypes from "../../actionTypes";
import { withRouter } from "react-router-dom";
import Customer from "../CRM/Customer";
import Product from "../Catalogue/Product";
import Payment from "./Payment";
import CustomerSearch from "./CustomerSearch";
import CustomerAddress from "./CustomerAddress";
import CustomerPets from "./CustomerPets";
import ImageButton from "../2/Controls/ImageButton";
import Icon from "../2/Controls/Icon";
import Delivery from "./Delivery";
import CustomerDashBoard from "../Reports/CustomerDashboard";
import Switch from "react-switch";
import SalesModifiers from "./SalesModifiers";
import UniqueNoSelection from "../Inventory/UniqueNoSelection";
import UniqueNoInput from "../Inventory/UniqueNoInput";
import CustomFieldEntry from "../Common/CustomFieldEntry";
import MobileCustomer from "./MobileCustomer";
import { debounce } from "lodash";
import BatchSelection from "../Inventory/BatchSelection";
// import HeaderNew from "../HeaderNew";
import Avatar from "../Common/Avatar";
import ListSearch from "../Common/ListSearch";
import Spinner from "../Common/Spinner";
import ProductVariants from "./ProductVariants";
import Button from "../2/Controls/Button";
import Input from "../2/Controls/Input";
import Window from "../2/Controls/Window";
import Page from "../2/Controls/Page";
import AllStoreInStockQty from "./AllStoreInStockQty";
import { getUOMs } from "../../CommonFunctions";
import "./Sales.css";
import "../../index.css";
import * as constants from "../../Constatnts";
import { isMobileApp } from "../../actions/Util";
import SalesOptions from "./SalesOptions";
import SalesNotes from "./SalesNotes";
import Menu from "../2/Menu";
import ProductSelection from "../Common/ProductSelection";
import ProductPincode from "../Catalogue/ProductPincode";
import CustomAsyncTypeahead from "./CustomAsyncTypeahead";

// import { getSalesChannel } from "../../services/api/SalesChannelAPI";
var AsyncTypeahead = require("react-bootstrap-typeahead").AsyncTypeahead;
var Typeahead = require("react-bootstrap-typeahead").Typeahead;

const keyMap = {
  productSearch: "f1",
  productScan: "f2",
  searchCustomer: "f3",
  newCustomer: "f4",
  printKOT: "f10",
  printBill: "f11",
  pay: "f12",
  discount: "ctrl+d",
  hold: "ctrl+h",
};

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductSelection: false,
      showProductSelectionList: [],
      tableOrder: false,
      printKOT: false,
      printBill: false,
      enableTCS: false,
      TCSPercentage: 0,
      TCSAmount: 0,
      tmpTCSAmount: 0,
      TCSAmountAppliedOn: 0,
      tmpTCSAmountAppliedOn: 0,
      showAdditionalCharges: false,
      showVariants: false,
      selectedVariantProduct: null,
      favouriteSelected: false,
      uoms: [],
      title: "",
      productSearchText: "",
      editSaleLineItems: [],
      editTransactionNo: "",
      editTransactionDate: null,
      editStore: null,
      sale: null,
      transactionType: null,
      type: "",
      key: "",
      parentTransactionKey: "",
      parentTransactionNo: "",
      parentTransactionModule: "",
      selectedCustomer: null,
      selectedCategory: "",
      selectedCategoryID: "",
      selectedSubCategory: "",
      selectedSubCategoryID: "",
      lineItems: [],
      billQty: 0,
      billSubTotal: 0,
      billDiscount: 0,
      billTaxAmount: 0,
      billAmount: 0,
      notes: "",
      tempNotes: "",
      showLineItemModal: false,
      selectedLineItem: null,
      selectedLineItemIndex: -1,
      selectedProductID: "",
      showParkedSalesModal: false,
      showPayment: false,
      balanceAmount: 0,
      paidAmount: 0,
      changeDue: 0,
      cashRegisters: [],
      productsTypeAheadOptions: [],
      isProductTypeAheadLoading: false,
      productsTypeAheadSelected: [],
      // customerTypeAheadOptions: [],
      // isCustomerTypeAheadLoading: false,
      showAddCustomer: false,
      showAddProduct: false,
      showInvoice: true,
      overallDiscountPercentage: 0,
      overallDiscountAmount: 0,
      overallDiscountClearExistingDiscount: false,
      employeeList: [],
      transactionLevelEmployeeSelected: null,
      lineItemEmployeeSelected: null,
      memberships: [],
      applicableMembership: null,
      selectedCashRegister: null,
      showCashRegisterSelection: false,
      negativeQty: true,
      customerError: "",
      employeeMandatoryAtTransactionError: "",
      employeeMandatoryAtLineItemError: "",
      showError: false,
      selectedItemEmployeeSelectionError: "",
      taxSummaryList: [],
      selectedCustomerAddress: null,
      selectedCustomerPet: null,
      rightHeight: "100px",
      placeOfSupply: "",
      KOTType: "Dine In",
      deliveryCustomer: null,
      deliveryCustomerAddress: null,
      deliveryDate: null,
      deliveryTime: { HH: "", MM: "", AMPM: "" },
      deliveryNotes: "",
      showDelivery: false,
      fromDelivery: false,
      tmpDeliveryCustomer: null,
      showDiscountModal: false,
      showNotesModal: false,
      cashRegisterClosing: null,
      cashRegisterOpeningBalance: 0,
      roundOff: 0,
      showCustomerDashBoard: false,
      newCustomer: null,
      offers: [],
      offersApplied: [],
      offersToApply: [],
      offersPopupTabSelected: "applied",
      applyMembershipDiscount: false,
      discountOptionSelected: "transaction",
      showDiscountMembership: false,
      showDiscountTransaction: false,
      showDiscountOffers: false,
      showDiscountCoupon: false,
      showDiscountOptions: false,
      showModifiers: false,
      toggleSearch: false,
      showUniqueNoSelection: false,
      selectedProduct: null,
      showUniqueNoInput: false,
      queryText: "",
      showTransactionTypes: false,
      applicableModifiers: [],
      applicableModifierGroups: [],
      selectedModifierGroupID: "",
      selectedModifiers: [],
      modifierQty: 0,
      modifierAmount: 0,
      receivedPaymentAmount: 0,
      receivedPaymentTransactions: [],
      isConvert: false,
      convertedFromTransactionBalanceAmount: 0,
      customField1Name: "",
      customField2Name: "",
      customField3Name: "",
      customField4Name: "",
      customField5Name: "",
      customField6Name: "",
      customField7Name: "",
      customField8Name: "",
      customField9Name: "",
      customField10Name: "",
      customField11Name: "",
      customField12Name: "",
      customField13Name: "",
      customField14Name: "",
      customField15Name: "",
      customField16Name: "",
      customField17Name: "",
      customField18Name: "",
      customField19Name: "",
      customField20Name: "",
      customField1: "",
      customField2: "",
      customField3: "",
      customField4: "",
      customField5: "",
      customField6: "",
      customField7: "",
      customField8: "",
      customField9: "",
      customField10: "",
      customField11: "",
      customField12: "",
      customField13: "",
      customField14: "",
      customField15: "",
      customField16: "",
      customField17: "",
      customField18: "",
      customField19: "",
      customField20: "",
      showAdditionalInfo: false,
      inStockQtyNegativeError: "",
      lineItemExceedError: "",
      noLineItemExistError: "",
      inStockQtyNegativeErrors: [],
      showNumberPad: false,
      numberPadQty: 0,
      numberPadLineItemIndex: -1,
      couponCode: "",
      couponCodeError: "",
      couponErrorMsg: "",
      couponSuccessMsg: "",
      coupon: null,
      mobileOptionSelected: 2,
      categorySelected: false,
      mobileLineItemSelected: false,
      mobile: false,
      mobileShowMoreOptions: false,
      mobileShowCustomer: false,
      mobileShowInvoice: true,
      mobileShowProduct: false,
      productSearchText: "",
      productCustomField1DisplayName: "",
      productCustomField2DisplayName: "",
      productCustomField3DisplayName: "",
      productCustomField4DisplayName: "",
      productCustomField5DisplayName: "",
      productCustomField6DisplayName: "",
      drafts: [],
      showEWayBill: false,
      eWayBill: null,
      tmpGenerateEWayBill: false,
      tmpEWayBillTransporterName: "",
      tmpEWayBillModeOfTransportCode: "",
      tmpEWayBillModeOfTransportName: "",
      tmpEWayBillVehicleNo: "",
      tmpEWayBillDistanceOfTransport: "",
      tmpEWayBillVehicleTypeCode: "",
      tmpEWayBillVehicleTypeName: "",
      modeOfTransportList: [],
      vehicleTypelist: [],
      storeError: "",
      fromGSTINError: "",
      toGSTINError: "",
      showBatchsForSelection: false,
      favouriteExists: false,
      desktopOption: 1,
      transactionTypes: [],
      negativeProductQtyNotAllowedForBilling: false,
      billingHeight: "100px",
      dektop1ScrollHeight: "100px",
      page: "1",
      screen: "1",
      searchList: [],
      payments: [],
      salesChannels: [],
      selectedSalesChannel: "",
      showSalesChannelSelection: false,
      salesChannelSearchText: "",
      Products: [],
      SaleProductCategories: [],
      SaleProductSubCategories: [],
      selectedSalesChannel: "",
      selectedSalesChannelName: "Sales Channel",
    };
    this.onProductSelected = this.onProductSelected.bind(this);
    this.productSelected = this.productSelected.bind(this);
    this.calculateBillTotal = this.calculateBillTotal.bind(this);
    this.onRemoveLineItem = this.onRemoveLineItem.bind(this);
    this.onPaymentClick = this.onPaymentClick.bind(this);
    this.onDiscardClicked = this.onDiscardClicked.bind(this);
    this.onParkSaleClicked = this.onParkSaleClicked.bind(this);
    this.onLineItemQtyChange = this.onLineItemQtyChange.bind(this);
    this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this);
    this.onLineItemSubTotalChange = this.onLineItemSubTotalChange.bind(this);
    this.onLineItemDiscountChange = this.onLineItemDiscountChange.bind(this);
    this.onLineItemSelected = this.onLineItemSelected.bind(this);
    this.onChargeTaxOnProductChange = this.onChargeTaxOnProductChange.bind(
      this
    );
    this.onTaxIncluxiveChange = this.onTaxIncluxiveChange.bind(this);
    this.onLineItemDiscountAmountChange = this.onLineItemDiscountAmountChange.bind(
      this
    );
    this.onTaxGroupChange = this.onTaxGroupChange.bind(this);
    this.onLineItemNotesChange = this.onLineItemNotesChange.bind(this);
    this.onLineItemChangesDone = this.onLineItemChangesDone.bind(this);
    this.onRetriveParkedSales = this.onRetriveParkedSales.bind(this);
    this.onNotesOKClicked = this.onNotesOKClicked.bind(this);
    this.onProuctTypeAheadSearch = this.onProuctTypeAheadSearch.bind(this);
    this.onProductTypeAheadSearchOnline = this.onProductTypeAheadSearchOnline.bind(
      this
    );

    this.handleProductSearchOnlineThrottle = debounce(
      this.onProductTypeAheadSearchOnline,
      300
    );
    this.handleSearchOnlineInputChange = this.handleSearchOnlineInputChange.bind(
      this
    );
    this.onOverAllDiscount = this.onOverAllDiscount.bind(this);
    this.onCustomerSelected = this.onCustomerSelected.bind(this);
    this.onRemoveCustomer = this.onRemoveCustomer.bind(this);
    this.onCustomerChange = this.onCustomerChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.shortcutProductSearch = this.shortcutProductSearch.bind(this);
    this.shortcutProductScan = this.shortcutProductScan.bind(this);
    this.shortcutSearchCustomer = this.shortcutSearchCustomer.bind(this);
    this.shortcutNewCustomer = this.shortcutNewCustomer.bind(this);
    this.shortcutPay = this.shortcutPay.bind(this);
    this.onDiscountClicked = this.onDiscountClicked.bind(this);
    this.roundUpTo2 = this.roundUpTo2.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onSelectedCustomerAddressChange = this.onSelectedCustomerAddressChange.bind(
      this
    );
    this.onDeliveryClicked = this.onDeliveryClicked.bind(this);
    // this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this);
    // this.onEnteringCashRegisterOpeningBalance = this.onEnteringCashRegisterOpeningBalance.bind(this);
    this.onLineItemWalletTopUpChange = this.onLineItemWalletTopUpChange.bind(
      this
    );
    this.applyOffer = this.applyOffer.bind(this);
    this.applySelectedOffer = this.applySelectedOffer.bind(this);
    this.removeSelectedOffer = this.removeSelectedOffer.bind(this);
    this.onApplyRemoveMembershipDiscount = this.onApplyRemoveMembershipDiscount.bind(
      this
    );
    this.onShowDiscountOptions = this.onShowDiscountOptions.bind(this);
    this.applyRemoveMembershipDiscountAtLineItem = this.applyRemoveMembershipDiscountAtLineItem.bind(
      this
    );
    this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this);
    this.onTransactionTypeSelected = this.onTransactionTypeSelected.bind(this);
    this.checkIfModifierDefinedForSelectedProduct = this.checkIfModifierDefinedForSelectedProduct.bind(
      this
    );
    this.onModifierSelectionCompletion = this.onModifierSelectionCompletion.bind(
      this
    );
    this.getApplicableModifierGroups = this.getApplicableModifierGroups.bind(
      this
    );
    this.onModifierSelectionClose = this.onModifierSelectionClose.bind(this);
    this.checkIfBillingAllowed = this.checkIfBillingAllowed.bind(this);
    this.applyInvoiceDiscountOffer = this.applyInvoiceDiscountOffer.bind(this);
    this.onShowLineItemModal = this.onShowLineItemModal.bind(this);
    this.applyCoupon = this.applyCoupon.bind(this);
    this.removeSelectedCoupon = this.removeSelectedCoupon.bind(this);
    this.removeSelectedOfferCoupon = this.removeSelectedOfferCoupon.bind(this);
    this.applySelectedCoupon = this.applySelectedCoupon.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleProductSearchThrottle = debounce(
      this.onProuctTypeAheadSearch,
      100
    );

    this.productBarCodeSearch = this.productBarCodeSearch.bind(this);
    this.showParkedSales = this.showParkedSales.bind(this);
    this.getParkedSales = this.getParkedSales.bind(this);
    this.onEWayBillClicked = this.onEWayBillClicked.bind(this);
    this.onEWayBillCancelClicked = this.onEWayBillCancelClicked.bind(this);
    this.onEWayBillOKClicked = this.onEWayBillOKClicked.bind(this);
    this.renderMobileHeaderOptions = this.renderMobileHeaderOptions.bind(this);
    this.renderMobileCategories = this.renderMobileCategories.bind(this);
    this.renderMobileSubCategoriesAndProducts = this.renderMobileSubCategoriesAndProducts.bind(
      this
    );
    this.renderMobileFavItems = this.renderMobileFavItems.bind(this);
    this.renderMobileLineItems = this.renderMobileLineItems.bind(this);
    this.renderMobileSearchCatalog = this.renderMobileSearchCatalog.bind(this);
    this.renderMobileFooterOptions = this.renderMobileFooterOptions.bind(this);
    this.onHeaderBackClick = this.onHeaderBackClick.bind(this);
    this.renderAdditionalCharges = this.renderAdditionalCharges.bind(this);
    this.sendMessageToCustomerDisplay = this.sendMessageToCustomerDisplay.bind(
      this
    );
    this.onPrintKOT = this.onPrintKOT.bind(this);
    this.onPrintBill = this.onPrintBill.bind(this);
    this.renderQtyControl = this.renderQtyControl.bind(this);
    this.readQty = this.readQty.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
    this.renderBilling = this.renderBilling.bind(this);
    this.renderProductSearchBar = this.renderProductSearchBar.bind(this);
    this.onProductSearch = this.onProductSearch.bind(this);
    this.onProductSearchSelected = this.onProductSearchSelected.bind(this);
    this.renderDummyButtons = this.renderDummyButtons.bind(this);
    this.onAddnChargesClick = this.onAddnChargesClick.bind(this);
    this.onScanClicked = this.onScanClicked.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.renderProductThumbnail = this.renderProductThumbnail.bind(this);
    this.renderDesktopOption3 = this.renderDesktopOption3.bind(this);
    this.renderTotals = this.renderTotals.bind(this);
    this.renderLineItemBatchSelection = this.renderLineItemBatchSelection.bind(
      this
    );
    this.renderSalesChannelSelectionWindow = this.renderSalesChannelSelectionWindow.bind(
      this
    );
    this.onSalesChannelSelected = this.onSalesChannelSelected.bind(this);
    this.onProductScan = this.onProductScan.bind(this);
  }

  async componentDidMount() {
    //populate employees
    let uoms = getUOMs();
    this.setState({ uoms: uoms });
    this.loadTransactionTypes();
    let type = "";
    let key = "";
    let callCalculateBillTotal = false;
    let membershipList = [];
    let employeeList = [];
    let selectedCategory = "";
    let selectedCategoryID = "";
    let selectedSubCategory = "";
    let selectedSubCategoryID = "";
    let parentTransactionKey = "";
    let parentTransactionNo = "";
    let parentTransactionModule = "";
    let lineItems = [];
    let applicableMembership = null;
    let selectedCustomer = null;
    let billQty = 0;
    let billSubTotal = 0;
    let billDiscount = 0;
    let billTaxAmount = 0;
    let billAmount = 0;
    let roundOff = 0;
    let notes = "";
    let balanceAmount = 0;
    let paidAmount = 0;
    let changeDue = 0;
    let transactionType = null;
    let sale = null;
    let transactionLevelEmployeeSelected = null;
    let taxSummaryList = [];
    let deliveryCustomer = null;
    let deliveryCustomerAddress = null;
    let deliveryDate = null;
    let deliveryTime = { HH: "", MM: "", AMPM: "" };
    let deliveryNotes = "";
    let placeOfSupply = "";
    let receivedPaymentAmount = 0; //sales order to sales invoice scenario
    let receivedPaymentTransactions = [];
    let isConvert = false;
    let convertedFromTransactionBalanceAmount = 0;
    let customField1 = "";
    let customField2 = "";
    let customField3 = "";
    let customField4 = "";
    let customField5 = "";
    let customField6 = "";
    let customField7 = "";
    let customField8 = "";
    let customField9 = "";
    let customField10 = "";
    let customField11 = "";
    let customField12 = "";
    let customField13 = "";
    let customField14 = "";
    let customField15 = "";
    let customField16 = "";
    let customField17 = "";
    let customField18 = "";
    let customField19 = "";
    let customField20 = "";
    let eWayBill = null;
    this.getParkedSales();
    let modeOfTransportList = await this.props.getModeOfTransport();
    let vehicleTypelist = await this.props.getVehicleType();
    let tableOrder = false;
    let table = null;
    let KOTType = "Dine In";
    let desktopOption = 1;
    let module = "";
    let payments = [];

    let negativeProductQtyNotAllowedForBilling = false;
    if (
      this.props.user.store.hasOwnProperty(
        "NegativeProductQtyNotAllowedForBilling"
      )
    ) {
      negativeProductQtyNotAllowedForBilling = this.props.user.store
        .NegativeProductQtyNotAllowedForBilling;
      // console.log('store NegativeProductQtyNotAllowedForBilling', negativeProductQtyNotAllowedForBilling)
    } else {
      negativeProductQtyNotAllowedForBilling = this.props.registration
        .registration.NegativeProductQtyNotAllowedForBilling;
      // console.log('registration NegativeProductQtyNotAllowedForBilling')
    }
    this.setState({
      negativeProductQtyNotAllowedForBilling: negativeProductQtyNotAllowedForBilling,
    });
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.hasOwnProperty("module")
    ) {
      module = this.props.location.state.module;
      if (this.props.location.state.module === "TableOrder") {
        tableOrder = true;
        // console.log('TableOrder', tableOrder)
        if (this.props.location.state.hasOwnProperty("table")) {
          table = this.props.location.state.table;
        }
      }
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.hasOwnProperty("KOTType") &&
      this.props.location.state.KOTType !== ""
    ) {
      KOTType = this.props.location.state.KOTType;
    }
    this.setState({
      modeOfTransportList: modeOfTransportList,
      vehicleTypelist: vehicleTypelist,
    });

    if (
      this.props.registration.registration.hasOwnProperty("EnableTCS") &&
      this.props.registration.registration.EnableTCS
    ) {
      this.setState({
        enableTCS: this.props.registration.registration.EnableTCS,
        TCSPercentage: this.props.registration.registration.hasOwnProperty(
          "TCSPercentage"
        )
          ? this.props.registration.registration.TCSPercentage
          : 0,
      });
    }
    //scan mode or search mode
    if (this.props.user.store.hasOwnProperty("SalesProductSearch")) {
      if (this.props.user.store.SalesProductSearch === "2") {
        this.setState({ toggleSearch: true });
      } else {
        this.setState({ toggleSearch: false });
      }
    }

    if (this.props.user.store.hasOwnProperty("DesktopOption")) {
      if (this.props.user.store.DesktopOption !== "") {
        desktopOption = Number(this.props.user.store.DesktopOption);
      }
    }
    if (this.props.registration.registration.OnlineBilling) {
      desktopOption = 3;
    }
    this.setState({
      desktopOption: desktopOption,
    });
    //favourite exists
    if (
      this.props.config.Products.filter(
        (e) => e.hasOwnProperty("Favourite") && e.Favourite
      ).length > 0
    ) {
      this.setState({ favouriteExists: true });
    }

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Sales") {
        // console.log('customField1',customField.CustomField1Name)
        this.setState({
          customField1Name: customField.CustomField1Name.trim(),
          customField2Name: customField.CustomField2Name.trim(),
          customField3Name: customField.CustomField3Name.trim(),
          customField4Name: customField.CustomField4Name.trim(),
          customField5Name: customField.CustomField5Name.trim(),
          customField6Name: customField.CustomField6Name.trim(),
          customField7Name: customField.hasOwnProperty("CustomField7Name")
            ? customField.CustomField7Name.trim()
            : "",
          customField8Name: customField.hasOwnProperty("CustomField8Name")
            ? customField.CustomField8Name.trim()
            : "",
          customField9Name: customField.hasOwnProperty("CustomField9Name")
            ? customField.CustomField9Name.trim()
            : "",
          customField10Name: customField.hasOwnProperty("CustomField10Name")
            ? customField.CustomField10Name.trim()
            : "",
          customField11Name: customField.hasOwnProperty("CustomField11Name")
            ? customField.CustomField11Name.trim()
            : "",
          customField12Name: customField.hasOwnProperty("CustomField12Name")
            ? customField.CustomField12Name.trim()
            : "",
          customField13Name: customField.hasOwnProperty("CustomField13Name")
            ? customField.CustomField13Name.trim()
            : "",
          customField14Name: customField.hasOwnProperty("CustomField14Name")
            ? customField.CustomField14Name.trim()
            : "",
          customField15Name: customField.hasOwnProperty("CustomField15Name")
            ? customField.CustomField15Name.trim()
            : "",
          customField16Name: customField.hasOwnProperty("CustomField16Name")
            ? customField.CustomField16Name.trim()
            : "",
          customField17Name: customField.hasOwnProperty("CustomField17Name")
            ? customField.CustomField17Name.trim()
            : "",
          customField18Name: customField.hasOwnProperty("CustomField18Name")
            ? customField.CustomField18Name.trim()
            : "",
          customField19Name: customField.hasOwnProperty("CustomField19Name")
            ? customField.CustomField19Name.trim()
            : "",
          customField20Name: customField.hasOwnProperty("CustomField20Name")
            ? customField.CustomField20Name.trim()
            : "",
        });
      } else if (customField.ModuleName === "Product") {
        let customField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "Custom Field 1"
            : customField.CustomField1Name.trim();
        let customField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "Custom Field 2"
            : customField.CustomField2Name.trim();
        let customField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "Custom Field 3"
            : customField.CustomField3Name.trim();
        let customField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "Custom Field 4"
            : customField.CustomField4Name.trim();
        let customField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "Custom Field 5"
            : customField.CustomField5Name.trim();
        let customField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "Custom Field 6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: customField1DisplayName,
          productCustomField2DisplayName: customField2DisplayName,
          productCustomField3DisplayName: customField3DisplayName,
          productCustomField4DisplayName: customField4DisplayName,
          productCustomField5DisplayName: customField5DisplayName,
          productCustomField6DisplayName: customField6DisplayName,
        });
      }
    });

    if (this.props.user.cashRegister === null) {
      alert("No cash register selected. Please try again");
      this.props.history.push("/home");
      return;
    }
    let offers = [];
    this.props.config.ApplicableOffers.map((m) => {
      if (m.hasOwnProperty("Type") && m.Type === "Coupon") {
        //coupon
      } else {
        offers.push(m);
      }
    });
    this.props.config.ActiveEmployees.map(
      (e) => (e.EmployeeName = e.EmployeeFirstName + " " + e.EmployeeLastName)
    );
    this.setState({
      offers: offers,
      employeeList: this.props.config.ActiveEmployees.filter(
        (f) => f.ShowInSales
      ),
      memberships: this.props.config.ActiveMemberships,
    });

    // check if selected category has items directly under it
    // console.log('desktopOption',desktopOption)
    if (desktopOption === 1 && !this.props.isMobileApp()) {
      let categories = this.props.config.SaleProductCategories;
      if (categories.length > 0) {
        selectedCategory = categories[0].Name;
        selectedCategoryID = categories[0].key;
        let categoryItems = this.props.config.Products.filter(
          (e) => e.CategoryID === selectedCategoryID && e.SubCategoryID === ""
        );
        if (categoryItems.length === 0) {
          let subCategories = this.props.config.SaleProductSubCategories.filter(
            (e) => e.ParentID === selectedCategoryID
          );
          if (subCategories.length > 0) {
            selectedSubCategoryID = subCategories[0].key;
            selectedSubCategory = subCategories[0].Name;
          }
        }
      }
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type
    ) {
      // alert('this.props.location.state.type = ' + this.props.location.state.type)
      type = this.props.location.state.type;
    } else if (this.props.menuURLData && this.props.menuURLData.type) {
      // alert('this.props.menuURLData.type =' + this.props.menuURLData.type)
      type = this.props.menuURLData.type;
    }
    if (type === actionTypes.SALE_NEW) {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.transactionType
      ) {
        transactionType = this.props.location.state.transactionType;
      } else if (this.props.menuURLData.transactionType) {
        transactionType = this.props.menuURLData.transactionType;
      }
      if (transactionType.TransactionType.toLowerCase() === "sales return") {
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.sale
        ) {
          sale = this.props.location.state.sale;
          selectedCustomer = sale.Customer;
          if (selectedCustomer) {
            if (selectedCustomer.Membership) {
              membershipList
                .filter(
                  (e) =>
                    e.key === selectedCustomer.Membership.MembershipID &&
                    !e.IsDeleted
                )
                .map((e) => {
                  applicableMembership = e;
                });
            }
          }

          // console.log('this.props.location.state.sale', this.props.location.state.sale)
          this.props.location.state.sale.LineItems.map((l) => {
            let lineItem = l;
            lineItem.Qty = l.Qty * -1;
            lineItem.ProductConsumption = null;
            lineItem.Discounts = [];
            lineItem = this.onLineItemChange(
              -2,
              lineItem,
              sale.hasOwnProperty("PlaceOfSupply") ? sale.PlaceOfSupply : ""
            );
            lineItems.push(lineItem);
          });
          // parentTransactionKey = this.props.location.state.key;
          parentTransactionKey = sale.hasOwnProperty("ParentTransactionKey")
            ? sale.ParentTransactionKey
            : this.props.location.state.hasOwnProperty("key")
            ? this.props.location.state.key
            : "";
          parentTransactionNo = this.props.location.state.sale.TransactionNo;
          parentTransactionModule = sale.hasOwnProperty(
            "ParentTransactionModule"
          )
            ? sale.ParentTransactionModule
            : "";
          billQty = sale.BillQty * -1;
          billSubTotal = sale.BillSubTotal * -1;
          billDiscount = sale.BillDiscount * -1;
          billTaxAmount = sale.BillTaxAmount * -1;
          billAmount = sale.BillAmount * -1;
          notes = "";
          balanceAmount = 0;
          paidAmount = 0;
          changeDue = 0;
        }
      } else if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.sale &&
        transactionType.TransactionType.toLowerCase() !== "sales return"
      ) {
        sale = this.props.location.state.sale;
        customField1 = sale.hasOwnProperty("CustomField1")
          ? sale.CustomField1
          : "";
        customField2 = sale.hasOwnProperty("CustomField2")
          ? sale.CustomField2
          : "";
        customField3 = sale.hasOwnProperty("CustomField3")
          ? sale.CustomField3
          : "";
        customField4 = sale.hasOwnProperty("CustomField4")
          ? sale.CustomField4
          : "";
        customField5 = sale.hasOwnProperty("CustomField5")
          ? sale.CustomField5
          : "";
        customField6 = sale.hasOwnProperty("CustomField6")
          ? sale.CustomField6
          : "";
        customField7 = sale.hasOwnProperty("CustomField7")
          ? sale.CustomField7
          : "";
        customField8 = sale.hasOwnProperty("CustomField8")
          ? sale.CustomField8
          : "";
        customField9 = sale.hasOwnProperty("CustomField9")
          ? sale.CustomField9
          : "";
        customField10 = sale.hasOwnProperty("CustomField10")
          ? sale.CustomField10
          : "";
        customField11 = sale.hasOwnProperty("CustomField11")
          ? sale.CustomField11
          : "";
        customField12 = sale.hasOwnProperty("CustomField12")
          ? sale.CustomField12
          : "";
        customField13 = sale.hasOwnProperty("CustomField13")
          ? sale.CustomField13
          : "";
        customField14 = sale.hasOwnProperty("CustomField14")
          ? sale.CustomField14
          : "";
        customField15 = sale.hasOwnProperty("CustomField15")
          ? sale.CustomField15
          : "";
        customField16 = sale.hasOwnProperty("CustomField16")
          ? sale.CustomField16
          : "";
        customField17 = sale.hasOwnProperty("CustomField17")
          ? sale.CustomField17
          : "";
        customField18 = sale.hasOwnProperty("CustomField18")
          ? sale.CustomField18
          : "";
        customField19 = sale.hasOwnProperty("CustomField19")
          ? sale.CustomField19
          : "";
        customField20 = sale.hasOwnProperty("CustomField20")
          ? sale.CustomField20
          : "";
        lineItems = sale.LineItems;
        selectedCustomer = sale.Customer;
        //parentTransactionKey = ""
        //parentTransactionNo = ""
        parentTransactionKey = sale.hasOwnProperty("ParentTransactionKey")
          ? sale.ParentTransactionKey
          : "";
        parentTransactionNo = sale.hasOwnProperty("ParentTransactionNo")
          ? sale.ParentTransactionNo
          : "";
        parentTransactionModule = sale.hasOwnProperty("ParentTransactionModule")
          ? sale.ParentTransactionModule
          : "";
        notes = "";
        balanceAmount = 0;
        paidAmount = 0;
        changeDue = 0;
        callCalculateBillTotal = true;
        receivedPaymentAmount = sale.hasOwnProperty("ReceivedPaymentAmount")
          ? sale.ReceivedPaymentAmount
          : 0;
        receivedPaymentTransactions = sale.hasOwnProperty(
          "ReceivedPaymentTransactions"
        )
          ? sale.ReceivedPaymentTransactions
          : [];
        isConvert = sale.hasOwnProperty("IsConvert") ? sale.IsConvert : false;
        convertedFromTransactionBalanceAmount = sale.hasOwnProperty(
          "ConvertedFromTransactionBalanceAmount"
        )
          ? sale.ConvertedFromTransactionBalanceAmount
          : 0;
        // console.log('convertedFromTransactionBalanceAmount',convertedFromTransactionBalanceAmount)
        // this.setState({lineItems:lineItems,selectedCustomer:selectedCustomer})
      }
    }
    // let rightHeight = this.state.rightHeight;
    if (type === actionTypes.SALE_EDIT) {
      //edit invoice
      let sale = this.props.location.state.sale;
      payments = sale.Payments ? sale.Payments : [];
      // console.log('sale.TransactionDate', sale.TransactionDate)
      let editSaleLineItems = [];
      sale.LineItems.map((m) => {
        editSaleLineItems.push({ ...m });
      });
      this.setState({
        editSaleLineItems: editSaleLineItems,
        editTransactionNo: sale.TransactionNo,
        editStore: sale.Store,
        editTransactionDate: new Date(
          sale.TransactionDate.seconds
            ? sale.TransactionDate.seconds * 1000
            : sale.TransactionDate
        ),
      });

      ////console.log('component did mount sale', sale)
      if (sale.Customer) {
        if (sale.Customer.Membership) {
          membershipList
            .filter(
              (e) =>
                e.key === sale.Customer.Membership.MembershipID && !e.IsDeleted
            )
            .map((e) => {
              applicableMembership = e;
            });
        }
      }
      key = this.props.location.state.key;
      // console.log("key", key);
      lineItems = sale.LineItems;
      // console.log('lineItems', lineItems)
      billQty = sale.BillQty;
      billSubTotal = sale.BillSubTotal;
      billDiscount = sale.BillDiscount;
      billTaxAmount = sale.BillTaxAmount;
      billAmount = sale.BillAmount;
      notes = sale.Notes;
      balanceAmount = 0;
      paidAmount = 0;
      changeDue = 0;
      tableOrder = sale.hasOwnProperty("TableOrder") ? sale.TableOrder : false;
      table = sale.hasOwnProperty("Table") ? sale.Table : null;
      if (
        this.props.config.TransactionTypes.filter(
          (f) => f.key === sale.TransactionType.key
        ).length > 0
      ) {
        transactionType = this.props.config.TransactionTypes.filter(
          (f) => f.key === sale.TransactionType.key
        )[0];
      } else {
        transactionType = sale.TransactionType;
      }
      selectedCustomer = sale.Customer;
      transactionLevelEmployeeSelected = sale.Employee ? sale.Employee : null;
      taxSummaryList = sale.TaxSummaryList ? sale.TaxSummaryList : [];
      deliveryCustomer = sale.hasOwnProperty("DeliveryCustomer")
        ? sale.DeliveryCustomer
        : null;
      deliveryCustomerAddress = sale.hasOwnProperty("DeliveryCustomerAddress")
        ? sale.DeliveryCustomerAddress
        : deliveryCustomerAddress;
      deliveryDate = sale.hasOwnProperty("DeliveryDate")
        ? sale.DeliveryDate
        : deliveryDate;
      deliveryTime = sale.hasOwnProperty("DeliveryTime")
        ? sale.DeliveryTime
        : deliveryTime;
      deliveryNotes = sale.hasOwnProperty("DeliveryNotes")
        ? sale.DeliveryNotes
        : deliveryNotes;
      placeOfSupply = sale.hasOwnProperty("PlaceOfSupply")
        ? sale.PlaceOfSupply
        : placeOfSupply;
      roundOff = sale.hasOwnProperty("RoundOff") ? sale.RoundOff : 0;
      customField1 = sale.hasOwnProperty("CustomField1")
        ? sale.CustomField1
        : "";
      customField2 = sale.hasOwnProperty("CustomField2")
        ? sale.CustomField2
        : "";
      customField3 = sale.hasOwnProperty("CustomField3")
        ? sale.CustomField3
        : "";
      customField4 = sale.hasOwnProperty("CustomField4")
        ? sale.CustomField4
        : "";
      customField5 = sale.hasOwnProperty("CustomField5")
        ? sale.CustomField5
        : "";
      customField6 = sale.hasOwnProperty("CustomField6")
        ? sale.CustomField6
        : "";
      customField7 = sale.hasOwnProperty("CustomField7")
        ? sale.CustomField7
        : "";
      customField8 = sale.hasOwnProperty("CustomField8")
        ? sale.CustomField8
        : "";
      customField9 = sale.hasOwnProperty("CustomField9")
        ? sale.CustomField9
        : "";
      customField10 = sale.hasOwnProperty("CustomField10")
        ? sale.CustomField10
        : "";
      customField11 = sale.hasOwnProperty("CustomField10")
        ? sale.CustomField11
        : "";
      customField12 = sale.hasOwnProperty("CustomField12")
        ? sale.CustomField12
        : "";
      customField13 = sale.hasOwnProperty("CustomField13")
        ? sale.CustomField13
        : "";
      customField14 = sale.hasOwnProperty("CustomField14")
        ? sale.CustomField14
        : "";
      customField15 = sale.hasOwnProperty("CustomField15")
        ? sale.CustomField15
        : "";
      customField16 = sale.hasOwnProperty("CustomField16")
        ? sale.CustomField16
        : "";
      customField17 = sale.hasOwnProperty("CustomField17")
        ? sale.CustomField17
        : "";
      customField18 = sale.hasOwnProperty("CustomField18")
        ? sale.CustomField18
        : "";
      customField19 = sale.hasOwnProperty("CustomField19")
        ? sale.CustomField19
        : "";
      customField20 = sale.hasOwnProperty("CustomField20")
        ? sale.CustomField20
        : "";
      eWayBill = sale.hasOwnProperty("eWayBill") ? sale.eWayBill : null;
      // if (
      //     sale.Customer &&
      //     sale.Customer.Addresses &&
      //     sale.Customer.Addresses.length >= 1
      // ) {
      //     rightHeight = rightHeight - 10;
      // }
    }
    if (type === actionTypes.SALE_DRAFT) {
      //draft invoice
      // console.log("componentDidMount - Draft Sale");
      this.onRetriveParkedSales(
        this.props.location.state.draft.Transaction,
        this.props.location.state.key
      );
      return;
    }

    if (isMobileApp()) {
      selectedCategory = "";
      selectedCategoryID = "";
    }
    this.setState({
      title: transactionType.TransactionType,
      // rightHeight: rightHeight,
      module: module,
      key: key,
      type: type, //this.props.location.state.type,
      selectedCategory: selectedCategory,
      selectedCategoryID: selectedCategoryID,
      selectedSubCategory: selectedSubCategory,
      selectedSubCategoryID: selectedSubCategoryID,
      lineItems: lineItems,
      billQty: billQty,
      billSubTotal: billSubTotal,
      billDiscount: billDiscount,
      billTaxAmount: billTaxAmount,
      billAmount: billAmount,
      notes: notes,
      balanceAmount: balanceAmount,
      paidAmount: paidAmount,
      changeDue: changeDue,
      transactionType: transactionType,
      selectedCustomer: selectedCustomer,
      applicableMembership: applicableMembership,
      transactionLevelEmployeeSelected: transactionLevelEmployeeSelected,
      parentTransactionKey: parentTransactionKey,
      parentTransactionNo: parentTransactionNo,
      parentTransactionModule: parentTransactionModule,
      taxSummaryList: taxSummaryList,
      deliveryCustomer: deliveryCustomer,
      deliveryCustomerAddress: deliveryCustomerAddress,
      deliveryDate: deliveryDate,
      deliveryTime: deliveryTime,
      deliveryNotes: deliveryNotes,
      placeOfSupply: placeOfSupply,
      roundOff: roundOff,
      receivedPaymentAmount: receivedPaymentAmount,
      receivedPaymentTransactions: receivedPaymentTransactions,
      isConvert: isConvert,
      convertedFromTransactionBalanceAmount: convertedFromTransactionBalanceAmount,
      customField1: customField1,
      customField2: customField2,
      customField3: customField3,
      customField4: customField4,
      customField5: customField5,
      customField6: customField6,
      customField7: customField7,
      customField8: customField8,
      customField9: customField9,
      customField10: customField10,
      customField11: customField11,
      customField12: customField12,
      customField13: customField13,
      customField14: customField14,
      customField15: customField15,
      customField16: customField16,
      customField17: customField17,
      customField18: customField18,
      customField19: customField19,
      customField20: customField20,
      eWayBill: eWayBill,
      tableOrder: tableOrder,
      table: table,
      KOTType: KOTType,
      payments: payments,
    });
    if (callCalculateBillTotal) {
      this.calculateBillTotal(lineItems, receivedPaymentAmount);
    }
    this.setRightSectionHeight(selectedCustomer, transactionType);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    let Products = this.props.config.Products;
    let SaleProductCategories = this.props.config.SaleProductCategories;
    let SaleProductSubCategories = this.props.config.SaleProductSubCategories;
    this.setState({
      Products: Products,
      SaleProductCategories: SaleProductCategories,
      SaleProductSubCategories: SaleProductSubCategories,
    });
    // console.log("Products", Products);

    if (this.props.config.WhiteLabel.Code === "WRX") {
      // let salesChannels = await getSalesChannel(
      //   this.props.registration.registration.key
      // );
      let salesChannels = this.props.config.SalesChannel;
      // console.log("salesChannels", salesChannels);
      if (salesChannels && salesChannels.length > 0) {
        let selectedSalesChannel = this.props.config.SelectedSalesChannel;
        this.onSalesChannelSelected(
          selectedSalesChannel,
          salesChannels,
          selectedSalesChannel === "" ? true : false
        );
        // this.setState({
        // salesChannels: salesChannels,
        // selectedSalesChannel: "",
        // selectedSalesChannelName: "Sales Channel",
        // showSalesChannelSelection: true,
        // });
      }
    }

    let printConfig = this.props.config.PrintConfigs.filter(
      (e) => !e.IsDeleted && e.TransactionTypeID === transactionType.key
    );
    if (printConfig.length > 0) {
      console.log("printConfig", printConfig);
      // selectedPringConfigID = printConfig[0].key;
      // selectedPrintConfigPrintDisplayName = printConfig[0].PrintDisplayName;
      // printChecked = true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevState.productsTypeAheadOptions !== this.state.productsTypeAheadOptions
    // ) {
    //   console.log(
    //     "Products updated State:",
    //     this.state.productsTypeAheadOptions
    //   );
    //   // this.setState({ productsTypeAheadOptions: this.state.productsTypeAheadOptions });
    // }
  }

  onSalesChannelSelected(selectedSalesChannel, salesChannels, showWindow) {
    // console.log("selectedSalesChannel", selectedSalesChannel);
    // console.log("salesChannels", salesChannels);
    this.props.setSelectedSalesChannel(selectedSalesChannel);
    // console.log("selectedSalesChannel", selectedSalesChannel);
    // console.log("this.state.salesChannels", this.state.salesChannels);
    let Products = this.props.config.Products;
    let SaleProductCategories = this.props.config.SaleProductCategories;
    let SaleProductSubCategories = this.props.config.SaleProductSubCategories;
    let selectedSalesChannelName = "Sales Channel";
    if (
      selectedSalesChannel !== "" &&
      salesChannels.filter((f) => f.key === selectedSalesChannel).length > 0
    ) {
      let salesChannel = salesChannels.filter(
        (f) => f.key === selectedSalesChannel
      )[0];
      selectedSalesChannelName = salesChannel.Name;

      //right now we are handling only product specific sales channels
      if (
        salesChannel.hasOwnProperty("Products") &&
        salesChannel.Products.length > 0
      ) {
        let finalProducts = [];
        let finalCategories = [];
        let finalSubCategories = [];
        let products = salesChannel.Products;
        for (let i = 0; i < products.length; i++) {
          // console.log("ctr", i);
          if (
            this.props.config.Products.filter(
              (f) => f.key === products[i].ProductID
            ).length > 0
          ) {
            let product = this.props.config.Products.filter(
              (f) => f.key === products[i].ProductID
            )[0];
            // console.log("product", product);
            if (
              product.hasOwnProperty("ParentProductID") &&
              product.ParentProductID !== ""
            ) {
              //now add child to list of products
              if (
                finalProducts.filter((f) => f.key === product.ProductID)
                  .length === 0
              ) {
                // console.log("child aded", product);
                product.RetailPrice = products[i].RetailPrice;
                finalProducts.push(product);
              }
              let parentProductID = product.ParentProductID;
              //find parent and update parent details
              if (
                this.props.config.Products.filter(
                  (f) => f.key === parentProductID
                ).length > 0
              ) {
                let parentProduct = this.props.config.Products.filter(
                  (f) => f.key === parentProductID
                )[0];
                if (parentProduct.hasOwnProperty("Variants")) {
                  parentProduct.Variants.map((v) => {
                    if (v.ProductID === product.key) {
                      v.RetailPrice = products[i].RetailPrice;
                    }
                  });
                }
                //if parent product is infinalproducts then update
                let finalProductExists = false;
                finalProducts.map((f) => {
                  if (f.key === parentProductID) {
                    finalProductExists = true;
                    f = parentProduct;
                  }
                });
                //else
                if (!finalProductExists) {
                  finalProducts.push(parentProduct);
                }
              }
            } else {
              if (
                finalProducts.filter((f) => f.key === product.key).length === 0
              ) {
                product.RetailPrice = products[i].RetailPrice;
                finalProducts.push(product);
              } else {
                finalProducts.filter(
                  (f) => f.key === product.key
                )[0].RetailPrice = products[i].RetailPrice;
              }
            }
            //now add category
            if (
              finalCategories.filter((f) => f.key === product.CategoryID)
                .length === 0
            ) {
              if (
                SaleProductCategories.filter(
                  (f) => f.key === product.CategoryID
                ).length > 0
              ) {
                let category = SaleProductCategories.filter(
                  (f) => f.key === product.CategoryID
                )[0];
                finalCategories.push(category);
              }
            }
            //now add subcateogry
            if (
              finalSubCategories.filter((f) => f.key === product.SubCategoryID)
                .length === 0
            ) {
              if (
                SaleProductSubCategories.filter(
                  (f) => f.key === product.SubCategoryID
                ).length > 0
              ) {
                let subcategory = SaleProductSubCategories.filter(
                  (f) => f.key === product.SubCategoryID
                )[0];
                finalSubCategories.push(subcategory);
              }
            }
          }
        }
        finalCategories.sort((a, b) => a.Sequence - b.Sequence);
        finalSubCategories.sort((a, b) => a.Sequence - b.Sequence);

        Products = finalProducts;
        SaleProductCategories = finalCategories;
        SaleProductSubCategories = finalSubCategories;
        // console.log("finalProducts", finalProducts);
        // console.log("finalCategories", finalCategories);
        // console.log("finalSubCategories", finalSubCategories);
      }
    }

    this.setState({
      selectedSalesChannel: selectedSalesChannel,
      selectedSalesChannelName: selectedSalesChannelName,
      Products: Products,
      SaleProductCategories: SaleProductCategories,
      SaleProductSubCategories: SaleProductSubCategories,
      salesChannels: salesChannels,
      showSalesChannelSelection: showWindow,
    });
  }

  renderSalesChannelSelectionWindow() {
    return (
      <React.Fragment>
        {this.state.showSalesChannelSelection && (
          <Window
            header={"Sales channel"}
            // modal={true}
            onClose={() => this.setState({ showSalesChannelSelection: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showSalesChannelSelection: false });
            }}
            buttons={[]}
          >
            <Input
              type="text"
              placeholder="Please enter search text"
              value={this.state.salesChannelSearchText}
              onChange={(value) =>
                this.setState({ salesChannelSearchText: value })
              }
            />
            <div className="">
              <div
                className="list-row seperator"
                onClick={() => {
                  // this.setState({ selectedSalesChannel: "" });
                  this.onSalesChannelSelected(
                    "",
                    this.state.salesChannels,
                    false
                  );
                }}
              >
                <div>
                  <div className="row-title">None</div>
                </div>
              </div>
              {this.state.salesChannels
                .filter((f) => {
                  if (this.state.salesChannelSearchText.trim() === "") {
                    return true;
                  } else {
                    return new RegExp(
                      this.state.salesChannelSearchText,
                      "i"
                    ).test(f.Name);
                  }
                })
                .map((s) => (
                  <div
                    className="list-row seperator"
                    onClick={() => {
                      // this.setState({ selectedSalesChannel: s.key });
                      this.onSalesChannelSelected(
                        s.key,
                        this.state.salesChannels,
                        false
                      );
                    }}
                  >
                    <div>
                      <div className="row-title">{s.Name}</div>
                    </div>
                  </div>
                ))}
            </div>
          </Window>
        )}
      </React.Fragment>
    );
  }
  onScanClicked() {
    // alert('scan clicked')
    if (!window.cordova) {
      return;
    }
    if (this.props.config.Device === constants.PINELABS_DEVICE) {
      let payLoad = {
        Header: {
          ApplicationId: this.props.config.PineLabsApplicationID,
          UserId: this.props.config.AndroidPackageName,
          MethodId: "1007",
          VersionNo: "1.0",
        },
      };
      window.cordova.plugins.pinelabs.scan(
        { payLoad: payLoad },
        (result1) => {
          // alert('scan result');
          let result = JSON.parse(result1);
          // alert(result1);
          let barcode =
            result.Detail && result.Detail.ScannedData
              ? result.Detail.ScannedData
              : "";
          // alert('barcode:' + barcode);
          // if(result.Response && result.Response.ResponseMsg && result.Response.ResponseMsg.toLowerCase() ==="success"){
          if (barcode !== "") {
            // let barcode = result.Detail.ScannedData;

            let searchList = this.props.Products.filter(
              (f) =>
                f.BarCode.toLowerCase().indexOf(barcode.toLowerCase()) != -1 ||
                f.SKU.toLowerCase().indexOf(barcode.toLowerCase()) != -1
            );
            if (searchList.length > 1) {
              this.setState({
                screen: "3",
                searchText: barcode,
                searchList: searchList,
              });
            } else if (searchList.length === 1) {
              this.onProductSelected(searchList[0]);
            } else {
              alert("Product not found");
            }
          } else {
            alert("Scanning failed");
          }
        },
        (error) => {
          alert(error);
        }
      );
    } else {
      window.cloudSky.zBar.scan(
        {
          text_title: "Scan Barcode", // Android only
          text_instructions: "Please point your camera at the Barcode.", // Android only
        },
        (barcode) => {
          let searchList = this.props.Products.filter(
            (f) =>
              f.BarCode.toLowerCase().indexOf(barcode.toLowerCase()) != -1 ||
              f.SKU.toLowerCase().indexOf(barcode.toLowerCase()) != -1
          );
          if (searchList.length > 1) {
            this.setState({
              screen: "3",
              searchText: barcode,
              searchList: searchList,
            });
          } else {
            this.onProductSelected(searchList[0]);
          }
        },
        (error) => {
          alert("Scanning failed: " + error);
        }
      );
    }
  }

  onAddnChargesClick() {
    let tmpTCSAmountAppliedOn = this.state.TCSAmountAppliedOn;
    this.state.lineItems.map(
      (m) =>
        (tmpTCSAmountAppliedOn =
          Number(tmpTCSAmountAppliedOn) + Number(m.Amount))
    );
    this.setState({
      showAdditionalCharges: true,
      tmpTCSAmountAppliedOn: tmpTCSAmountAppliedOn,
      tmpTCSAmount: this.roundUpTo2(
        (tmpTCSAmountAppliedOn * this.state.TCSPercentage) / 100
      ),
    });
  }

  renderProductSearchBar() {
    return (
      <div className="sales-page-search">
        <input
          type="text"
          className="form-control"
          placeholder="Search product..."
          value={this.state.searchText}
          onClick={this.onProductSearchSelected}
          onChange={(e) => this.onProductSearch(e.target.value)}
        />
        <div className="sales-page-search-btn-grp">
          <ImageButton type="scan" text="scan" onClick={this.onScanClicked} />
        </div>
      </div>
    );
  }

  onProductSearch(searchText) {
    let searchList = this.props.config.Products.filter(
      (f) =>
        f.Name.toLowerCase().indexOf(searchText.toLowerCase()) != -1 ||
        f.SKU.toLowerCase().indexOf(searchText.toLowerCase()) != -1
    );
    console.log("searchList", searchList);
    this.setState({ searchText: searchText, searchList: searchList });
  }

  onProductSearchSelected() {
    this.setState({
      screen: "3",
      searchText: "",
      searchList: this.props.config.Products,
    });
  }

  renderDummyButtons() {
    return (
      <React.Fragment>
        <div className="dummyBtns"></div>
        <div className="dummyBtns"></div>
        <div className="dummyBtns"></div>
        <div className="dummyBtns"></div>
      </React.Fragment>
    );
  }

  renderCategory(category) {
    return (
      <React.Fragment>
        {!category.Image && (
          <div className="category-name">{category.Name}</div>
        )}
        {category.Image && (
          <React.Fragment>
            <div className="category-image">
              <img src={category.Image.Preview} />
            </div>
            <div className="category-image-name">{category.Name}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderProduct(product) {
    return (
      <React.Fragment>
        {product.Images.length === 0 && (
          <React.Fragment>
            <div className="product-name">{product.Name}</div>
            <div className="product-price">₹{product.RetailPrice}</div>
          </React.Fragment>
        )}
        {product.Images.length > 0 && (
          <React.Fragment>
            <div className="product-image">
              <img src={product.Images[0].ImagePath} />
            </div>
            <div className="product-image-name">{product.Name}</div>
            <div className="product-image-price">₹{product.RetailPrice}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderProductThumbnail(product, lineItem, index) {
    return (
      <React.Fragment>
        {product.Images.length > 0 && (
          <img
            onClick={() => {
              if (lineItem) {
                this.onLineItemSelected(lineItem, index);
              }
            }}
            className="product-thumbnail"
            src={product.Images[0].ImagePath}
          />
        )}
        {product.Images.length === 0 && (
          <div
            onClick={() => {
              if (lineItem) {
                this.onLineItemSelected(lineItem, index);
              }
            }}
            className="product-thumbnail-text"
          >
            {product.Name.substring(0, 1)}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderBilling() {
    return (
      <React.Fragment>
        {this.state.screen !== "4" && (
          <React.Fragment>
            <div className="sales-header">
              <div className="sales-header-back">
                {this.state.screen === "1" && <Menu {...this.props} />}
                {this.state.screen !== "1" && (
                  <ImageButton
                    type="leftarrow"
                    onClick={() => {
                      if (this.state.screen === "1") {
                        if (this.state.lineItems.length > 0) {
                          //show alert
                          this.setState({
                            showAlert: true,
                            alertType: "back",
                            alertMsg:
                              "Products added will be lost. Would you like to park this transaction for later use?",
                          });
                        } else {
                          this.props.onClose();
                        }
                      }
                      if (this.state.screen === "2") {
                        this.setState({ screen: "1" });
                      }
                      if (this.state.screen === "3") {
                        this.setState({ screen: "1" });
                      }
                    }}
                  />
                )}
              </div>
              <div className="searchbar">{this.renderProductSearchBar()}</div>
            </div>
            <div
              className="sales-content"
              style={{ height: window.innerHeight - Number(134) + "px" }}
            >
              {this.state.screen === "1" && (
                <React.Fragment>
                  {this.state.selectedCategoryID === "" &&
                    !this.state.favouriteSelected && (
                      <div className="categories">
                        {this.state.favouriteExists && (
                          <div
                            key={"fav"}
                            className="category"
                            onClick={() =>
                              this.setState({
                                favouriteSelected: true,
                                selectedCategoryID: "",
                                selectedCategory: "",
                                selectedSubCategoryID: "",
                                selectedSubCategory: "",
                                mobileOptionSelected: 3,
                              })
                            }
                          >
                            <div className="category-name">Favourite</div>
                          </div>
                        )}
                        {this.state.SaleProductCategories.map((m, index) => (
                          <div
                            key={index}
                            className="category"
                            onClick={() => {
                              this.setState({ selectedCategoryID: m.key });
                            }}
                          >
                            {this.renderCategory(m)}
                          </div>
                        ))}
                        {this.renderDummyButtons()}
                      </div>
                    )}

                  {this.state.selectedCategoryID !== "" &&
                    this.state.selectedSubCategoryID === "" &&
                    !this.state.favouriteSelected && (
                      <div className="categories">
                        <div
                          className="back"
                          onClick={() => {
                            this.setState({ selectedCategoryID: "" });
                          }}
                        >
                          <Icon type="leftarrow" />
                        </div>
                        {this.state.SaleProductSubCategories.filter(
                          (f) => f.ParentID === this.state.selectedCategoryID
                        ).map((m, index) => (
                          <div
                            key={index}
                            className="category"
                            onClick={() => {
                              this.setState({ selectedSubCategoryID: m.key });
                            }}
                          >
                            {this.renderCategory(m)}
                          </div>
                        ))}
                        {this.state.Products.filter(
                          (e) => {
                            let show = true;
                            if (e.hasOwnProperty("ParentProductID")) {
                              show = false;
                            }
                            if (
                              e.hasOwnProperty("SearchMode") &&
                              e.SearchMode.toLowerCase() === "scanonly"
                            ) {
                              // console.log(e.SearchMode)
                              show = false;
                            }
                            if (
                              show &&
                              e.ProductTypeID !== 2 &&
                              e.CategoryID === this.state.selectedCategoryID &&
                              e.SubCategoryID === ""
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          // f.ProductTypeID !== 2   && f.CategoryID === this.state.selectedCategoryID && f.SubCategoryID ===""
                        ).map((m, index) => (
                          <div
                            key={index}
                            className="product"
                            onClick={() => {
                              this.onProductSelected(m);
                            }}
                          >
                            {this.renderProduct(m)}
                          </div>
                        ))}
                        {this.renderDummyButtons()}
                      </div>
                    )}

                  {this.state.selectedCategoryID !== "" &&
                    this.state.selectedSubCategoryID !== "" &&
                    !this.state.favouriteSelected && (
                      <div className="products">
                        <div
                          className="back"
                          onClick={() => {
                            this.setState({ selectedSubCategoryID: "" });
                          }}
                        >
                          <Icon type="leftarrow" />
                        </div>
                        {this.state.Products.filter(
                          (e) => {
                            let show = true;
                            if (e.hasOwnProperty("ParentProductID")) {
                              show = false;
                            }
                            if (
                              e.hasOwnProperty("SearchMode") &&
                              e.SearchMode.toLowerCase() === "scanonly"
                            ) {
                              // console.log(e.SearchMode)
                              show = false;
                            }
                            if (
                              show &&
                              e.ProductTypeID !== 2 &&
                              e.CategoryID === this.state.selectedCategoryID &&
                              e.SubCategoryID ===
                                this.state.selectedSubCategoryID
                            ) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                          // f.SubCategoryID === this.state.selectedSubCategoryID
                        ).map((m, index) => (
                          <div
                            key={index}
                            className="product"
                            onClick={() => {
                              this.onProductSelected(m);
                            }}
                          >
                            {/* <div className="product-name">{m.Name}</div>
                                <div className="product-price">₹{m.RetailPrice}</div> */}
                            {this.renderProduct(m)}
                          </div>
                        ))}
                        {this.renderDummyButtons()}
                      </div>
                    )}

                  {this.state.favouriteSelected && (
                    <div className="products">
                      <div
                        className="back"
                        onClick={() => {
                          this.setState({ favouriteSelected: false });
                        }}
                      >
                        <Icon type="leftarrow" />
                      </div>
                      {this.state.Products.filter(
                        (e) =>
                          e.hasOwnProperty("Favourite") &&
                          e.Favourite &&
                          e.ProductTypeID !== 2 &&
                          !e.hasOwnProperty("ParentProductID")
                      ).map((m, index) => (
                        <div
                          key={index}
                          className="product"
                          onClick={() => {
                            this.onProductSelected(m);
                          }}
                        >
                          {/* <div className="product-name">{m.Name}</div>
                                <div className="product-price">₹{m.RetailPrice}</div> */}
                          {this.renderProduct(m)}
                        </div>
                      ))}
                      {this.renderDummyButtons()}
                    </div>
                  )}
                </React.Fragment>
              )}

              {this.state.screen === "2" && (
                <React.Fragment>
                  <div className="header-new-background"></div>
                  <CustomerSearch
                    {...this.props}
                    ref="refSearchCustomer"
                    mobile={false}
                    showAddOption={true}
                    customer={this.state.selectedCustomer}
                    error={this.state.customerError}
                    onCustomerChange={this.onCustomerChange}
                    onAddCustomer={(newCustomer) => {
                      this.setState({
                        showAddCustomer: true,
                        showInvoice: false,
                        newCustomer: newCustomer,
                      });
                    }}
                    onCustomerEdit={() =>
                      this.props.config.ConnectionStatus.Status === "connected"
                        ? this.setState({
                            showAddCustomer: true,
                            showInvoice: false,
                          })
                        : false
                    }
                    onShowCustomerDashBoard={() => {
                      this.setState({
                        showCustomerDashBoard: true,
                        showInvoice: false,
                      });
                    }}
                  />
                  {this.state.lineItems.map((m, index) => (
                    <div key={"lineitem" + index} className="lineitem">
                      {this.renderProductThumbnail(m.Product, m, index)}
                      <div className="lineitem-content">
                        <div
                          className="lineitem-product-name"
                          onClick={() => {
                            this.onLineItemSelected(m, index);
                            // selectedLineItem:{...m}, selectedLineItemIndex:index,this.setState({screen:'4'})
                          }}
                        >
                          {m.Product.Name} (SKU:{m.Product.SKU})
                        </div>
                        <div className="lineitem-row">
                          <div>₹{m.RetailPrice}</div>
                          <div>{this.renderQtyControl(m, index)}</div>
                          <div>₹{m.Amount}</div>
                        </div>
                      </div>
                      <div className="lineitem-delete">
                        <ImageButton
                          type="delete"
                          onClick={() => this.onRemoveLineItem(index)}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="dummyLineItem"></div>
                  {this.state.lineItems.length === 0 && (
                    <div className="no-lineitem">No products added</div>
                  )}
                </React.Fragment>
              )}
              {this.state.screen === "3" && (
                <React.Fragment>
                  <div className="header-new-background"></div>
                  {/* <div className="productlist-remove">
                            <ImageButton type="remove" text="clear" onClick={()=>{this.setState({screen:'1', searchText:'', searchList:[]})}}/>
                        </div> */}
                  <div className="productlist">
                    {this.state.searchList.map((p, index) => (
                      <div
                        key={"productlist" + index}
                        onClick={() => {
                          this.onProductSelected(p);
                        }}
                        className="productlist-row"
                      >
                        {this.renderProductThumbnail(p)}
                        <div>
                          <div className="productlist-name">{p.Name}</div>
                          <div className="productlist-sku">SKU:{p.SKU}</div>
                          <div className="productlist-price">
                            ₹{p.RetailPrice}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
            </div>
            {this.state.type === actionTypes.SALE_RETURN && (
              <div className="salesreturn-negative-qty">
                <Input
                  type="switch"
                  label="Negative qty"
                  value={this.state.negativeQty}
                  onChange={(value) => this.setState({ negativeQty: value })}
                />
              </div>
            )}
            <div className="sales-new-mobile-footer">
              <div className="w15">
                <ImageButton
                  type="option"
                  onClick={() => {
                    this.setState({ showOptions: true });
                  }}
                />
              </div>
              <div
                className="total-qty w30"
                onClick={() => {
                  this.setState({
                    screen: this.state.screen === "2" ? "1" : "2",
                  });
                }}
              >
                {this.state.billQty} items
              </div>
              <div
                className={
                  this.state.type === actionTypes.SALE_NEW
                    ? "pay-btn1 newsale w50"
                    : this.state.type === actionTypes.SALE_EDIT
                    ? "pay-btn editsale w50"
                    : "pay-btn salereturn w50"
                }
                onClick={() => {
                  if (this.state.lineItems.length > 0) {
                    this.onPaymentClick();
                  } else {
                    alert("No products added.");
                  }
                }}
              >
                <div>
                  {this.state.transactionType.PaymentModeChecked
                    ? " Pay"
                    : " Save"}
                </div>
                <div>₹{this.state.billAmount}</div>
              </div>

              {/* {this.state.transactionType.PaymentModeChecked ? " Pay": " Save"} */}
            </div>
          </React.Fragment>
        )}

        {/* {this.state.screen === '4' && <SalesLineItem 
                    selectedLineItem={this.state.selectedLineItem} 
                    transactionType={this.state.transactionType}
                    onLineItemChangesDone={(selectedLineItem)=>{this.onLineItemChangesDone(selectedLineItem)}}
                    onClose={()=>this.setState({selectedLineItem:null, selectedLineItemIndex:-1,screen:'2'})}
            />} */}

        {this.state.showOptions && (
          <SalesOptions
            {...this.props}
            showDiscountOption={this.state.transactionType.DiscountChecked}
            showParkSaleOption={this.state.lineItems.length > 0}
            showRetrieveSaleOption={this.state.key.trim() === ""}
            showDiscardSaleOption={
              this.state.key.trim() === "" && this.state.lineItems.length > 0
            }
            showCustomFields={
              this.props.config.CustomFields.filter(
                (f) => f.ModuleName.toLowerCase() === "sales"
              ).length > 0
            }
            showDeliveryOption={this.props.registration.registration.Delivery}
            showAddnCharges={this.state.enableTCS}
            onButtonClick={(type) => {
              if (type === "notes") {
                this.setState({ showNotes: true, showOptions: false });
              } else if (type === "discardsale") {
                this.onDiscardClicked();
              } else if (type === "parksale") {
                this.onParkSaleClicked();
              } else if (type === "discount") {
                this.setState({ showDiscountOptions: true });
              } else if (type === "retrievesales") {
                this.showParkedSales();
              } else if (type === "additionalinfo") {
                this.setState({
                  showAdditionalInfo: true,
                  additionalInfoMode: "edit",
                });
              } else if (type === "delivery") {
                this.onDeliveryClicked();
              } else if (type === "addncharges") {
                this.onAddnChargesClick();
              }
              this.setState({ showOptions: false });
            }}
            onClose={() => {
              this.setState({ showOptions: false });
            }}
          />
        )}

        {this.state.showNotes && (
          <SalesNotes
            notes={this.state.notes}
            onOK={(notes) => {
              this.setState({ notes: notes, showNotes: false });
            }}
            onClose={() => this.setState({ showNotes: false })}
          />
        )}
      </React.Fragment>
    );
  }

  async readQty() {
    const ipcRenderer = window.require("electron").ipcRenderer;
    let a = ipcRenderer.sendSync("readFile");
    // console.log("output", a);
    this.onLineItemQtyChange(a);
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      if (this.state.showLineItemModal) {
        this.onLineItemChangesDone();
      }
      if (this.state.showDiscountModal) {
        // this.onOverAllDiscount()
        this.onOverAllDiscount(
          this.state.overallDiscountPercentage,
          this.state.overallDiscountAmount,
          this.state.overallDiscountClearExistingDiscount,
          "",
          "",
          ""
        );
      }
    }
  }

  handleScan(e) {
    e.preventDefault();
    if (!window.cordova) {
      return;
    }
    window.cloudSky.zBar.scan(
      {
        text_title: "Scan Barcode", // Android only
        text_instructions: "Please point your camera at the Barcode.", // Android only
      },
      (barcode) => {
        this.productBarCodeSearch(barcode);
      },
      (error) => {
        alert("Scanning failed: " + error);
      }
    );
  }

  loadTransactionTypes() {
    let transactionTypes = [];

    this.props.config.TransactionTypes.filter((e) => {
      if (
        this.props.user.store.hasOwnProperty("EnableTableOrder") &&
        this.props.user.store.EnableTableOrder
      ) {
        return false;
      }
      let accessibleToSpecificStores = false;
      if (
        e.hasOwnProperty("AccessibleToSpecificStores") &&
        e.AccessibleToSpecificStores &&
        e.AccessibleToSpecificStoresList.filter(
          (f) => f.StoreID === this.props.user.store.key
        ).length > 0
      ) {
        accessibleToSpecificStores = true;
      } else if (
        e.hasOwnProperty("AccessibleToSpecificStores") &&
        !e.AccessibleToSpecificStores
      ) {
        accessibleToSpecificStores = true;
      } else if (!e.hasOwnProperty("AccessibleToSpecificStores")) {
        accessibleToSpecificStores = true;
      }
      if (
        e.Module === "sales" &&
        !e.IsDeleted &&
        accessibleToSpecificStores &&
        e.TransactionType.toLowerCase() !== "receive payment"
      ) {
        return true;
      } else {
        return false;
      }
    }).map((m, index) => {
      transactionTypes.push(m);
    });

    this.setState({ transactionTypes: transactionTypes });
  }

  resize() {
    // this.setState({hideNav: window.innerWidth <= 760});
    // console.log('window.innerHeight',window.innerHeight)
    let screenHeight = window.innerHeight;
    let billingHeight = Number(screenHeight) - Number(76) - 90 + "px";
    let desktop1ScrollHeight = Number(screenHeight) - (132 + 100) + "px";
    this.setState({
      billingHeight: billingHeight,
      desktop1ScrollHeight: desktop1ScrollHeight,
    });
    this.setRightSectionHeight(
      this.state.selectedCustomer,
      this.state.transactionType
    );
  }

  setRightSectionHeight(customer, transactionType) {
    let screenHeight = window.innerHeight;
    let customerLabel = 0;
    let customerAddress = 0;
    let employeeTagging = 0;
    // let customer= this.state.selectedCustomer;
    // let transactionType = this.state.transactionType;
    if (customer) {
      if (
        customer.OutStandingAmount > 0 ||
        customer.WalletBalance > 0 ||
        (customer.Membership &&
          customer.Membership.hasOwnProperty("MembershipID")) ||
        (customer.hasOwnProperty("LoyaltyType") &&
          customer.LoyaltyType.trim() !== "")
      ) {
        customerLabel = 30;
      }
      if (customer.Addresses && customer.Addresses.length > 0) {
        customerAddress = 56;
      }
    }
    if (
      transactionType &&
      transactionType.EmployeeManadatoryTransactionORLineItemLevel ===
        "transaction"
    ) {
      employeeTagging = 56;
    }
    let height =
      Number(screenHeight) -
      (Number(76) +
        Number(66) +
        Number(customerLabel) +
        Number(customerAddress) +
        Number(employeeTagging) +
        90 +
        10);
    height = height + "px";
    this.setState({ rightHeight: height });
  }

  onPrintKOT() {
    this.setState({ printKOT: true });
    this.onPaymentClick();
  }

  onPrintBill() {
    this.setState({ printBill: true, printKOT: false });
    this.onPaymentClick();
  }

  checkIfModifierDefinedForSelectedProduct(selectedProduct) {
    let showModifiers = false;
    if (this.props.config.Modifiers.length > 0) {
      for (let i = 0; i < this.props.config.Modifiers.length; i++) {
        if (
          this.props.config.Modifiers[i].AllProducts ||
          this.props.config.Modifiers[i].AssociatedProducts.filter(
            (f) => f.ProductID === selectedProduct.key
          ).length > 0
        ) {
          showModifiers = true;
          break;
        }
      }
    }
    // console.log('showModifiers',showModifiers)
    return showModifiers;
  }

  roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100;
  }

  // async onProductScan(code) {
  //   let result = await this.props.getProductsByBarCode(
  //     this.props.registration.registration.key,
  //     0,
  //     50,
  //     code
  //   );
  //   // console.log("result", result);
  //   let finalList = [];
  //   result.searchResult.map((m) => {
  //     if (m.SKU === code || m.BarCode === code) {
  //       finalList.push(m);
  //     }
  //   });
  //   if (finalList.length === 1) {
  //     let e = finalList[0];
  //     if (e.hasOwnProperty("StoreSpecificPricing")) {
  //       if (e.StoreSpecificPricing) {
  //         let storePrice = e.StoreSpecificPrices.filter(
  //           (f) => f.StoreID === this.props.user.store.key
  //         );

  //         if (storePrice.length > 0) {
  //           e.RetailPrice = storePrice[0].RetailPrice;
  //           e.SupplyPrice = storePrice[0].SupplyPrice;
  //           e.Markup = storePrice[0].Markup;
  //           e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
  //             "WalletTopUpSameAsRetailPrice"
  //           )
  //             ? storePrice[0].WalletTopUpSameAsRetailPrice
  //             : false;
  //           e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
  //             "WalletTopUpAmount"
  //           )
  //             ? storePrice[0].WalletTopUpAmount
  //             : 0;
  //           e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
  //             "EditWalletTopUpAmount"
  //           )
  //             ? storePrice[0].EditWalletTopUpAmount
  //             : false;
  //           e.MRP = storePrice[0].MRP;
  //         }
  //       }
  //     }
  //     this.onProductSelected(e);
  //   } else if (finalList.length > 1) {
  //     for (let i = 0; i < finalList.length; i++) {
  //       let e = finalList[i];
  //       if (e.hasOwnProperty("StoreSpecificPricing")) {
  //         if (e.StoreSpecificPricing) {
  //           let storePrice = e.StoreSpecificPrices.filter(
  //             (f) => f.StoreID === this.props.user.store.key
  //           );

  //           if (storePrice.length > 0) {
  //             e.RetailPrice = storePrice[0].RetailPrice;
  //             e.SupplyPrice = storePrice[0].SupplyPrice;
  //             e.Markup = storePrice[0].Markup;
  //             e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
  //               "WalletTopUpSameAsRetailPrice"
  //             )
  //               ? storePrice[0].WalletTopUpSameAsRetailPrice
  //               : false;
  //             e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
  //               "WalletTopUpAmount"
  //             )
  //               ? storePrice[0].WalletTopUpAmount
  //               : 0;
  //             e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
  //               "EditWalletTopUpAmount"
  //             )
  //               ? storePrice[0].EditWalletTopUpAmount
  //               : false;
  //             e.MRP = storePrice[0].MRP;
  //           }
  //         }
  //       }
  //       finalList[i] = e;
  //     }
  //     this.setState({
  //       showProductSelection: true,
  //       showProductSelectionList: finalList,
  //       queryText: "",
  //     });
  //     // return;
  //   } else {
  //     // alert("No product found");
  //     this.setState({
  //       showAddProduct: true,
  //       showInvoice: false,
  //       scanedCode: code,
  //     });
  //   }
  //   this.setState({ queryText: "" });
  // }

  async onProductScan(code) {
    let result = await this.props.getProductsByBarCode(
      this.props.registration.registration.key,
      0,
      50,
      code
    );
    // console.log("result", result);
    let finalList = [];
    result.searchResult.map((m) => {
      if (m.SKU === code || m.BarCode === code) {
        finalList.push(m);
      }
    });
    if (finalList.length === 1) {
      let e = finalList[0];
      if (e.hasOwnProperty("StoreSpecificPricing")) {
        if (e.StoreSpecificPricing) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            e.MRP = storePrice[0].MRP;
          }
        }
      }

      this.onProductSelected(e);
      this.setState({ productsTypeAheadSelected: [] });
      this.setState({ scannedInput: "" });
    } else if (finalList.length > 1) {
      for (let i = 0; i < finalList.length; i++) {
        let e = finalList[i];
        if (e.hasOwnProperty("StoreSpecificPricing")) {
          if (e.StoreSpecificPricing) {
            let storePrice = e.StoreSpecificPrices.filter(
              (f) => f.StoreID === this.props.user.store.key
            );

            if (storePrice.length > 0) {
              e.RetailPrice = storePrice[0].RetailPrice;
              e.SupplyPrice = storePrice[0].SupplyPrice;
              e.Markup = storePrice[0].Markup;
              e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                "WalletTopUpSameAsRetailPrice"
              )
                ? storePrice[0].WalletTopUpSameAsRetailPrice
                : false;
              e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                "WalletTopUpAmount"
              )
                ? storePrice[0].WalletTopUpAmount
                : 0;
              e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                "EditWalletTopUpAmount"
              )
                ? storePrice[0].EditWalletTopUpAmount
                : false;
              e.MRP = storePrice[0].MRP;
            }
          }
        }
        finalList[i] = e;
      }

      this.setState({
        showProductSelection: true,
        showProductSelectionList: finalList,
        queryText: "",
      });
      // return;
    } else {
      // alert("No product found");

      this.setState({
        showAddProduct: true,
        showInvoice: false,
        scanedCode: code,
      });
    }

    this.setState({ queryText: "" });
  }

  handleSearchOnlineInputChange(input) {
    console.log("handleSearchOnlineInputChange", input);
    // Check if the input ends with an "Enter" key (barcode scan)
    if (input.endsWith("\n")) {
      const barcode = input.trim(); // Remove the "Enter" key
      this.onProductScan(barcode);
      console.log("scan", barcode);
    } else {
      // Handle normal typeahead search with debouncing
      this.handleProductSearchOnlineThrottle(input);
    }
  }

  async onProductTypeAheadSearchOnline(query) {
    console.log("onProductTypeAheadSearchOnline", query);
    if (query.length < 3) {
      this.setState({ productsTypeAheadOptions: [] });
      return;
    }
    this.setState({ isProductTypeAheadLoading: true });
    let result = await this.props.getProductsSearch(
      this.props.user.user.RegistrationID,
      query
    );
    // console.log("onProductTypeAheadSearchOnline", result);
    let productSearchResult = [];
    let rowCount = 0;
    for (let i = 0; i < result.searchResult.length; i++) {
      // if (rowCount > 20) {
      //   break;
      // }
      let e = result.searchResult[i];

      if (e.hasOwnProperty("StoreSpecificPricing")) {
        if (e.StoreSpecificPricing) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            e.MRP = storePrice[0].MRP;
          }
        }
      }
      rowCount = rowCount + 1;
      productSearchResult.push(e);
    }
    // console.log(productSearchResult);
    // this.setState({ productsTypeAheadOptions: productSearchResult });
    // console.log("API result:", result);
    // console.log("Processed products:", productSearchResult);
    // console.log("Before state update:", this.state.productsTypeAheadOptions);
    this.setState({ productsTypeAheadOptions: productSearchResult });
    this.setState({ isProductTypeAheadLoading: false });
    // this.setState({ productsTypeAheadOptions: productSearchResult }, () => {
    //   console.log("After state update:", this.state.productsTypeAheadOptions);
    // });
    // setTimeout(() => {
    //   this.setState({ productsTypeAheadOptions: productSearchResult });
    //   this.setState({ isProductTypeAheadLoading: false });
    // }, 100);
  }

  onProuctTypeAheadSearch = async (query) => {
    this.setState({ isProductTypeAheadLoading: true });
    let searchedProducts = [];
    if (query === "") {
      searchedProducts = this.props.config.Products;
    } else {
      searchedProducts = this.props.config.Products.filter(
        (p) =>
          p.ProductTypeID !== 2 &&
          ((p.SKU && p.SKU.toLowerCase().includes(query.toLowerCase())) ||
            (p.Name && p.Name.toLowerCase().includes(query.toLowerCase())) ||
            (p.BarCode &&
              p.BarCode.toLowerCase().includes(query.toLowerCase())))
      );
    }

    let productSearchResult = [];
    let rowCount = 0;
    for (let i = 0; i < searchedProducts.length; i++) {
      // if (rowCount > 10) {
      //     break
      // }
      let e = searchedProducts[i];
      if (
        e.hasOwnProperty("SearchMode") &&
        e.SearchMode.toLowerCase() === "scanonly"
      ) {
        // console.log(e.SearchMode);
        continue;
      }
      let checkForStoreSpecificPriceOptions = true;
      if (e.hasOwnProperty("ParentProductID") && e.ParentProductID !== "") {
        //this is variant product so selecte what rate is specified in
        checkForStoreSpecificPriceOptions = false;
      }
      // console.log(
      //   "checkForStoreSpecificPriceOptions",
      //   checkForStoreSpecificPriceOptions
      // );
      if (checkForStoreSpecificPriceOptions) {
        if (e.hasOwnProperty("AccessibleToSelectedStores")) {
          if (e.AccessibleToSelectedStores) {
            let storePrice = e.StoreSpecificPrices.filter(
              (f) => f.StoreID === this.props.user.store.key
            );
            if (storePrice.length > 0) {
              e.RetailPrice = storePrice[0].RetailPrice;
              e.SupplyPrice = storePrice[0].SupplyPrice;
              e.Markup = storePrice[0].Markup;
              e.MRP = storePrice[0].MRP;
              e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
                ? storePrice[0].DiscountAmount
                : 0;
              e.DiscountPercentage = storePrice[0].hasOwnProperty(
                "DiscountPercentage"
              )
                ? storePrice[0].DiscountPercentage
                : 0;
              e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                "WalletTopUpSameAsRetailPrice"
              )
                ? storePrice[0].WalletTopUpSameAsRetailPrice
                : false;
              e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                "WalletTopUpAmount"
              )
                ? storePrice[0].WalletTopUpAmount
                : 0;
              e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                "EditWalletTopUpAmount"
              )
                ? storePrice[0].EditWalletTopUpAmount
                : false;
              productSearchResult.push(e);
              rowCount = rowCount + 1;
            }
            continue;
          }
        }
        if (e.hasOwnProperty("StoreSpecificPricing")) {
          if (e.StoreSpecificPricing) {
            let storePrice = e.StoreSpecificPrices.filter(
              (f) => f.StoreID === this.props.user.store.key
            );

            if (storePrice.length > 0) {
              e.RetailPrice = storePrice[0].RetailPrice;
              e.SupplyPrice = storePrice[0].SupplyPrice;
              e.Markup = storePrice[0].Markup;
              e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
                ? storePrice[0].DiscountAmount
                : 0;
              e.DiscountPercentage = storePrice[0].hasOwnProperty(
                "DiscountPercentage"
              )
                ? storePrice[0].DiscountPercentage
                : 0;
              e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                "WalletTopUpSameAsRetailPrice"
              )
                ? storePrice[0].WalletTopUpSameAsRetailPrice
                : false;
              e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                "WalletTopUpAmount"
              )
                ? storePrice[0].WalletTopUpAmount
                : 0;
              e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                "EditWalletTopUpAmount"
              )
                ? storePrice[0].EditWalletTopUpAmount
                : false;
              e.MRP = storePrice[0].MRP;
            }
          }
        }
      }
      rowCount = rowCount + 1;
      productSearchResult.push(e);
    }
    // console.log("productSearchResult", productSearchResult);
    this.setState({ productsTypeAheadOptions: productSearchResult });
    this.setState({ isProductTypeAheadLoading: false });
  };

  onCustomerChange(customer) {
    // console.log("onCustomerChange", customer);
    if (customer) {
      this.onCustomerSelected(customer);
      if (!isMobileApp()) {
        if (this.state.toggleSearch && !this.state.mobileShowCustomer) {
          this.refs.refProductScan.focus(); //13feb
        } else if (!this.state.toggleSearch && !this.state.mobileShowCustomer) {
          this.refs.refProductSearch.getInstance().focus(); //13feb
        }
      }
    } else {
      this.onRemoveCustomer();
    }

    // else if (!this.state.mobileShowCustomer) {
    //     // this.refs.refProductSearch.getInstance().focus()
    // }
  }

  onRemoveCustomer() {
    //console.log('onRemoveCustomer', this.state.placeOfSupply)
    // let rightHeight = this.state.rightHeight;
    if (
      this.state.applicableMembership ||
      this.state.placeOfSupply.trim() !== ""
    ) {
      //change line item price to normal
      // rightHeight = rightHeight + 5;
      let lineItems = [];
      this.state.lineItems.map((l) => {
        let lineItem = l;
        lineItem.RetailPrice = this.state.applicableMembership
          ? l.Product.RetailPrice
          : lineItem.RetailPrice;
        lineItem.DiscountPer = 0;
        lineItem.DiscountAmount = 0;
        lineItem = this.onLineItemChange(-2, lineItem, "");
        lineItems.push(lineItem);
      });
      this.setState({ lineItems: lineItems });
      this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    }
    // if (this.state.selectedAddress) {
    //     rightHeight = rightHeight + 10;
    // }
    this.setState({
      selectedCustomer: null,
      applicableMembership: null,
      selectedCustomerAddress: null,
      selectedCustomerPet: null,
      // rightHeight: rightHeight,
      showDiscountMembership: false,
      showDiscountTransaction: true,
      showDiscountOffers: true,
      showDiscountCoupon: true,
      offersApplied: [],
      discountOptionSelected: "transaction",
      placeOfSupply: "",
      customerError: "",
    });
    this.setRightSectionHeight(null, this.state.transactionType);
    // this.shortcutSearchCustomer()
  }

  onCustomerSelected(selectedCustomer) {
    // console.log("selectedCustomer", selectedCustomer);
    let applicableMembership = null;
    let applyMembershipDiscount = false;
    let rightHeight = this.state.rightHeight;
    if (selectedCustomer.Membership) {
      this.state.memberships
        .filter(
          (e) =>
            e.key === selectedCustomer.Membership.MembershipID && !e.IsDeleted
        )
        .map((e) => {
          applicableMembership = e;
        });
      if (applicableMembership) {
        applyMembershipDiscount = true;
        // rightHeight = rightHeight - 5;
        //recalculate item price based on customer mebership
        this.onApplyRemoveMembershipDiscount(
          applyMembershipDiscount,
          applicableMembership
        );
      }
    }
    if (selectedCustomer.Addresses && selectedCustomer.Addresses.length >= 1) {
      rightHeight = rightHeight - 10;
    }
    this.setRightSectionHeight(selectedCustomer, this.state.transactionType);
    let selectedCustomerAddress = null;
    if (
      selectedCustomer &&
      selectedCustomer.Addresses &&
      selectedCustomer.Addresses.length > 0
    ) {
      selectedCustomerAddress = selectedCustomer.Addresses[0];
      this.onSelectedCustomerAddressChange(selectedCustomerAddress.State);
    }
    // console.log("check 1");
    // console.log("selectedCustomerAddress", selectedCustomerAddress);
    this.setState({
      customerError: "",
      rightHeight: rightHeight,
      placeOfSupply: "",
      selectedCustomer: selectedCustomer,
      applicableMembership: applicableMembership,
      applyMembershipDiscount: applyMembershipDiscount,
      selectedCustomerAddress: selectedCustomerAddress,
    });
  }

  onSelectedCustomerAddressChange(placeOfSupply) {
    // console.log("onSelectedCustomerAddressChange", placeOfSupply);
    //pawan14april22
    // if (placeOfSupply.trim() === "") {
    //     return;
    // }
    let lineItems = [];
    this.state.lineItems.map((l) => {
      let lineItem = l;
      lineItem = this.onLineItemChange(-2, lineItem, placeOfSupply);
      lineItems.push(lineItem);
    });
    this.setState({ lineItems: lineItems });
    this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
  }

  onDiscardClicked() {
    this.setState({
      selectedCategory: "",
      selectedCategoryID: "",
      selectedSubCategory: "",
      selectedSubCategoryID: "",
      selectedCustomer: null,
      applicableMembership: null,
      lineItems: [],
      billQty: 0,
      billSubTotal: 0,
      billDiscount: 0,
      billTaxAmount: 0,
      billAmount: 0,
      parentTransactionNo: "",
      parentTransactionKey: "",
      parentTransactionModule: "",
      transactionLevelEmployeeSelected: null,
      notes: "",
      taxSummaryList: null,
      selectedCustomerAddress: null,
      selectedCustomerPet: null,
      placeOfSupply: "",
    });
  }

  onOverAllDiscount(
    overallDiscountPercentage,
    overallDiscountAmount,
    overallDiscountClearExistingDiscount,
    discountType,
    discountID,
    discountInfo
  ) {
    let discountPercentage = this.roundUpTo2(overallDiscountPercentage);
    let discountAmount = this.roundUpTo2(overallDiscountAmount);
    let overRide = overallDiscountClearExistingDiscount;

    if (discountAmount > 0) {
      let billSubTotal = 0;
      this.state.lineItems.map((m) => {
        let allowDiscount = true;
        if (m.Product.hasOwnProperty("AllowDiscount")) {
          allowDiscount = m.Product.AllowDiscount;
        }
        if (allowDiscount) {
          billSubTotal = billSubTotal + Number(m.SubTotal);
        }
      });
      discountPercentage = Number(
        ((discountAmount * 100) / Number(billSubTotal)).toFixed(3)
      );
    }
    let updatedLineItems = [];
    this.state.lineItems.map((lineItem) => {
      lineItem.DiscountPer = overRide
        ? Number(discountPercentage)
        : Number(lineItem.DiscountPer) + Number(discountPercentage);
      lineItem.DiscountAmount = 0;
      lineItem = this.onLineItemChange(
        -2,
        lineItem,
        this.state.placeOfSupply,
        discountType,
        discountID,
        discountInfo,
        true
      );
      updatedLineItems.push(lineItem);
    });
    // console.log("onOverAllDiscount updatedLineItems", updatedLineItems);

    this.setState({
      lineItems: updatedLineItems,
      showDiscountOptions: false,
      overallDiscountAmount: 0,
      overallDiscountClearExistingDiscount: false,
      overallDiscountPercentage: 0,
    });
    this.calculateBillTotal(updatedLineItems, this.state.receivedPaymentAmount);
  }

  async onProductSelected(product, scaleWeight = 0) {
    console.log("product", product);
    if (
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "unique no"
    ) {
      this.setState({
        showUniqueNoSelection: true,
        selectedProduct: product,
        selectedLineItem: null,
      });
      return;
    }
    if (
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      //show batch list
      // console.log('show batches for selection')
      this.setState({
        showBatchsForSelection: true,
        selectedProduct: product,
        selectedLineItem: null,
        showAllBatches: false,
      });
      return;
    }
    if (
      this.props.config.Products.filter(
        (f) => f.ParentProductID === product.key
      ).length > 0
    ) {
      this.setState({
        showVariants: true,
        selectedVariantProduct: product,
        selectedLineItem: null,
      });
      return;
    }
    this.productSelected(product, null, null, scaleWeight);
  }

  async checkIfBillingAllowed(product, lineItem, lineItemAdded = true) {
    // console.log('product', product)
    if (!this.state.transactionType.UpdateStockChecked) {
      return true;
    }
    if (product.ProductTypeID !== 1) {
      return true;
    }

    if (
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "uniqueno"
    ) {
      return true;
    }

    if (!this.state.negativeProductQtyNotAllowedForBilling) {
      return true;
    }
    //now check if instock qty exists for this item
    let qty = lineItem.Qty;
    if (
      lineItem.hasOwnProperty("UOM") &&
      lineItem.UOM.trim() !== "" &&
      lineItem.Product.hasOwnProperty("UOM") &&
      lineItem.Product.UOM.trim() !== "" &&
      lineItem.Product.hasOwnProperty("SecondaryUOMs") &&
      lineItem.Product.SecondaryUOMs.length > 0 &&
      lineItem.UOM.trim() !== lineItem.Product.UOM.trim()
    ) {
      let conversionValue = 1;
      for (let i = 0; i < lineItem.Product.SecondaryUOMs.length; i++) {
        if (
          lineItem.Product.SecondaryUOMs[i].SecondaryUOMValue.trim() ===
          lineItem.UOM.trim()
        ) {
          // console.log('3. Secondary UOM coversion value', product.SecondaryUOMs[i].Conversion)
          conversionValue = Number(
            lineItem.Product.SecondaryUOMs[i].Conversion
          );
        }
      }
      // console.log("conversionValue", conversionValue);
      qty = qty * conversionValue;
      // console.log("qty", qty);
    }
    let inStockQty = 0; //lineItem.InStockQty;
    if (
      product.hasOwnProperty("InventoryType") &&
      product.InventoryType.toLowerCase() === "batch tracking"
    ) {
      // console.log('batch tracking')
      inStockQty = lineItem.InStockQty;
    } else {
      // console.log('Normal')
      inStockQty = await this.props.getProductInStockQty(
        this.props.user.store.key,
        this.props.user.cashRegister.StoreLocationID,
        product.key
      );
      // Pawan 22 ar 2022
      // lineItem.ActualInStockQty = inStockQty;
      lineItem.InStockQty = inStockQty;
    }
    // console.log('inStockQty',inStockQty)
    //check if edit and the product exists previously then offset that qty
    if (this.state.type === actionTypes.SALE_EDIT) {
      if (this.state.editSaleLineItems.length > 0) {
        let result = this.state.editSaleLineItems.filter(
          (f) => f.Product.key === product.key
        );
        let qty = 0;
        for (let i = 0; i < result.length; i++) {
          qty = qty + Number(result[i].Qty);
        }
        // console.log('result',result)
        // console.log('qty', qty)
        inStockQty = inStockQty + qty;
      }
      // console.log('edit', inStockQty)
    }
    if (lineItemAdded) {
      if (inStockQty > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      // console.log("qty", qty);
      if (inStockQty - qty >= 0) {
        // console.log("true");
        return true;
      } else {
        return false;
      }
    }
    // } else
  }

  async productSelected(product, uniqueNoList, batch = null, scaleWeight = 0) {
    let lineItemIndex = -1;
    let lineItem = {};
    if (scaleWeight > 0 && lineItemIndex === -1) {
    } else if (
      (!this.state.transactionType.EmployeeMandatoryChecked &&
        lineItemIndex === -1) ||
      (this.state.transactionType.EmployeeMandatoryChecked &&
        this.state.transactionType
          .EmployeeManadatoryTransactionORLineItemLevel !== "lineitem" &&
        lineItemIndex === -1)
    ) {
      this.state.lineItems.map((l, index) => {
        if (l.Product.key === product.key) {
          //increase qty
          if (l.Modifiers && l.Modifiers.length > 0) {
            //new line item
          } else if (l.hasOwnProperty("KOTNo") && l.KOTNo !== "") {
            //new line item
          } else if (
            l.Product.hasOwnProperty("InventoryType") &&
            l.Product.InventoryType.toLowerCase() === "batch tracking"
          ) {
            if (l.hasOwnProperty("BatchID") && l.BatchID === batch.key) {
              if (
                this.state.transactionType.TransactionType.toLowerCase() ===
                  "sales return" &&
                this.state.negativeQty &&
                l.Qty < 0
              ) {
                lineItem = l;
                lineItemIndex = index;
              } else if (
                this.state.transactionType.TransactionType.toLowerCase() ===
                  "sales return" &&
                !this.state.negativeQty &&
                l.Qty > 0
              ) {
                lineItem = l;
                lineItemIndex = index;
              } else if (
                this.state.transactionType.TransactionType.toLowerCase() !==
                "sales return"
              ) {
                lineItem = l;
                lineItemIndex = index;
              }
            }
          } else {
            if (
              this.state.transactionType.TransactionType.toLowerCase() ===
                "sales return" &&
              this.state.negativeQty &&
              l.Qty < 0
            ) {
              lineItem = l;
              lineItemIndex = index;
            } else if (
              this.state.transactionType.TransactionType.toLowerCase() ===
                "sales return" &&
              !this.state.negativeQty &&
              l.Qty > 0
            ) {
              lineItem = l;
              lineItemIndex = index;
            } else if (
              this.state.transactionType.TransactionType.toLowerCase() !==
              "sales return"
            ) {
              lineItem = l;
              lineItemIndex = index;
            }
          }
        }
      });
    }
    //check if product is already added
    //if lineitem level employee mandatory then
    if (lineItemIndex === -1) {
      // console.log('product',product)
      lineItem.RetailPrice = product.RetailPrice;
      lineItem.UOM = product.hasOwnProperty("SalesUOM") ? product.SalesUOM : "";
      lineItem.DiscountPer = product.hasOwnProperty("DiscountPercentage")
        ? product.DiscountPercentage
        : 0;
      lineItem.DiscountAmount = 0;

      if (batch) {
        lineItem.BatchID = batch.key;
        lineItem.BatchNo = batch.BatchNo;
        lineItem.BatchMRP = batch.BatchMRP;
        lineItem.BatchRetailPrice = batch.BatchRetailPrice;
        lineItem.BatchMfgDate = batch.BatchMfgDate;
        lineItem.BatchExpDate = batch.BatchExpDate;
        lineItem.BatchMfgDateValue = batch.BatchMfgDateValue;
        lineItem.BatchExpDateValue = batch.BatchExpDateValue;
        lineItem.InStockQty = batch.Qty;
        if (product.BatchTrackingMRPRequired) {
          product.MRP = batch.BatchMRP;
          lineItem.RetailPrice =
            batch.BatchRetailPrice === 0
              ? lineItem.RetailPrice
              : batch.BatchRetailPrice;
        }
      }

      if (uniqueNoList && uniqueNoList.length > 0) {
        lineItem.UniqueNoList = uniqueNoList;
        lineItem.Qty =
          this.state.transactionType.TransactionType.toLowerCase() ===
            "sales return" && this.state.negativeQty
            ? -1 * uniqueNoList.length
            : uniqueNoList.length;
      } else {
        // lineItem.Qty = (this.state.transactionType.TransactionType.toLowerCase() === "sales return" && this.state.negativeQty) ? -1 : 1
        if (scaleWeight > 0) {
          lineItem.Qty =
            this.state.transactionType.TransactionType.toLowerCase() ===
              "sales return" && this.state.negativeQty
              ? -1 * scaleWeight
              : scaleWeight;
        } else {
          lineItem.Qty =
            this.state.transactionType.TransactionType.toLowerCase() ===
              "sales return" && this.state.negativeQty
              ? -1
              : 1;
        }
      }
      lineItem.SubTotal = 0;
      lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct;
      lineItem.PriceBasedTax = product.ChargeTaxOnProduct
        ? product.PriceBasedTax
        : false;
      lineItem.HSNSACCode = product.HSNSACCode;
      lineItem.TaxInclusive = product.ChargeTaxOnProduct
        ? product.TaxInclusive
        : false;
      lineItem.TaxGroup = product.TaxGroup;
      lineItem.TaxID = product.TaxID;
      lineItem.SubTotalWithDiscount = 0;
      lineItem.TaxableAmount = 0;
      lineItem.TotalTax = 0;
      lineItem.Amount = 0;
      lineItem.Product = product;
      lineItem.Employee = { key: "" };
      lineItem.Notes = "";
      let overallMembershipDiscount = false;
      // console.log("this.state.applicableMembership",this.state.applicableMembership);
      // console.log("this.state.applyMembershipDiscount",this.state.applyMembershipDiscount);
      if (this.state.applicableMembership) {
        if (this.state.applicableMembership.hasOwnProperty("DiscountApplied")) {
          overallMembershipDiscount = this.state.applicableMembership
            .DiscountApplied;
        }
        this.applyRemoveMembershipDiscountAtLineItem(
          lineItem,
          this.state.applyMembershipDiscount,
          overallMembershipDiscount,
          this.state.applicableMembership
        );
      }
    } else {
      if (uniqueNoList && uniqueNoList.length > 0) {
        // updated by pawan 17Nov 21
        // if (!this.state.selectedLineItem) {
        //     uniqueNoList.map((m) => lineItem.UniqueNoList.push(m));
        // } else {
        lineItem.UniqueNoList = uniqueNoList;
        // }

        lineItem.Qty =
          this.state.transactionType.TransactionType.toLowerCase() ===
            "sales return" && this.state.negativeQty
            ? -1 * lineItem.UniqueNoList.length
            : lineItem.UniqueNoList.length;
      } else {
        lineItem.Qty =
          this.state.transactionType.TransactionType.toLowerCase() ===
            "sales return" && this.state.negativeQty
            ? Number(lineItem.Qty) - 1
            : Number(lineItem.Qty) + 1;
      }
    }
    //now check negative billing qty - Start

    let allow = true;
    // Pawan 22 mar 2022
    // if (lineItem.Qty > 0) {
    //     await this.checkIfBillingAllowed(
    //         product,
    //         lineItem,
    //         lineItemIndex === -1 ? true : false
    //     );
    // }

    // console.log('allow', allow)
    if (!allow) {
      // console.log('allow showError:true');
      this.setState({
        showError: true,
        inStockQtyNegativeError:
          "Product can not be selected for billing as in stock quantity is less than zero.",
      });
      return;
    } else {
      // console.log('allow showError:false');
      this.setState({ showError: false, inStockQtyNegativeError: "" });
    }
    //now check negative billing qty - End

    if (
      this.props.registration.registration.key === "sgoJAOfvUrFfuQ0ICBm2" &&
      this.state.lineItems.length > 99
    ) {
      this.setState({
        showError: true,
        lineItemExceedError: "LineItems cannot be more than 100.",
      });
      return;
    }

    if (lineItemIndex === -1) {
      if (this.checkIfModifierDefinedForSelectedProduct(product)) {
        lineItem.ModifierApplicable = true;
      } else {
        lineItem.ModifierApplicable = false;
      }
    }
    lineItem = this.onLineItemChange(
      lineItemIndex,
      lineItem,
      this.state.placeOfSupply
    );
    let lineItems = this.state.lineItems;
    if (lineItemIndex >= 0) {
      // lineItems[lineItemIndex] = lineItem
      lineItems.splice(lineItemIndex, 1);
      lineItems.unshift(lineItem);
    } else {
      // lineItems.push(lineItem)
      lineItems.unshift(lineItem);
    }
    this.setState({ lineItems: lineItems });
    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    if (window.cordova) {
    }
    let options = {
      hideDuration: 300,
      timeOut: 300,
    };
    this.props.getToastr("Product added", options);
    this.getInstockQtyForProduct(lineItem);

    if (
      ((this.state.transactionType.EmployeeMandatoryChecked &&
        this.state.transactionType
          .EmployeeManadatoryTransactionORLineItemLevel === "lineitem") ||
        (lineItem.Product.hasOwnProperty("QtyPrompt") &&
          lineItem.Product.QtyPrompt)) &&
      lineItemIndex === -1
    ) {
      // this.onLineItemSelected(lineItem, lineItems.length -1)
      this.onLineItemSelected(lineItem, 0);
    } else {
      this.applyOffer();
      this.applySelectedCoupon(this.state.coupon);
    }
  }

  async getInstockQtyForProduct(lineItem) {
    let inStockQty = 0;
    let product = lineItem.Product;
    // console.log('lineItem', lineItem)
    if (
      (this.state.negativeProductQtyNotAllowedForBilling ||
        this.props.registration.registration.EnablePosBillingForPincode) &&
      product.ProductTypeID === 1
    ) {
      if (
        product.hasOwnProperty("InventoryType") &&
        product.InventoryType !== "" &&
        product.InventoryType.toLowerCase() === "batch tracking"
      ) {
        return true;
      }
      inStockQty = await this.props.getProductInStockQty(
        this.props.user.store.key,
        this.props.user.cashRegister.StoreLocationID,
        product.key
      );
      // console.log(' this.props.user.store.key,',  this.props.user.store.key,);
      // console.log('this.props.user.cashRegister.StoreLocationID,', this.props.user.cashRegister.StoreLocationID)
      // console.log('product.key', product.key)
      // console.log('inStockQty',inStockQty);
      let lineItems = this.state.lineItems;
      lineItems.map((m) => {
        if (m.Product.key === product.key) {
          m.InStockQty = inStockQty;
        }
      });
      this.setState({ lineItems: lineItems });
    }
  }

  getApplicableModifierGroups(productID) {
    let applicableModifiers = this.props.config.Modifiers.filter(
      (f) =>
        f.AllProducts ||
        f.AssociatedProducts.filter((l) => l.ProductID === productID).length > 0
    );
    let applicableModifierGroups = [];
    let selectedModifierGroupID = "";
    applicableModifiers.map((m) => {
      if (
        applicableModifierGroups.filter(
          (f) => f.ModifierGroup === m.ModifierGroup
        ).length === 0
      ) {
        if (selectedModifierGroupID === "") {
          selectedModifierGroupID = m.ModifierGroupID;
        }
        applicableModifierGroups.push({
          ModifierGroup: m.ModifierGroup,
          ModifierGroupID: m.ModifierGroupID,
        });
      }
    });
    // this.calculateTotal(this.props.selectedModifiers)
    this.setState({
      applicableModifiers: applicableModifiers,
      applicableModifierGroups: applicableModifierGroups,
      selectedModifierGroupID: selectedModifierGroupID,
    });
  }

  async onRemoveLineItem(index) {
    let lineItems = this.state.lineItems;
    lineItems.splice(index, 1);
    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    await this.applyOffer();
    await this.applySelectedCoupon(this.state.coupon);
    this.setState({
      selectedLineItem: null,
      selectedLineItemIndex: -1,
      selectedItemEmployeeSelectionError: "",
    });
  }

  ///line Item selected events -- START
  onLineItemSelected(lineItem, index) {
    this.onShowLineItemModal(lineItem, index);
  }

  onShowLineItemModal(lineItem, index) {
    let selectedLineItem = { ...lineItem };
    this.setState({
      selectedLineItem: selectedLineItem,
      selectedLineItemIndex: index,
      selectedItemEmployeeSelectionError: "",
      showLineItemModal: true,
    });
  }

  onLineItemSubTotalChange(subTotal) {
    let lineItem = this.state.selectedLineItem;
    lineItem.SubTotalWithDiscount = Number(subTotal);
    if (
      this.props.user.store.hasOwnProperty("ChangeQtyOnPriceUpdate") &&
      this.props.user.store.ChangeQtyOnPriceUpdate &&
      (lineItem.Product.InventoryType == "FIFO" ||
        lineItem.Product.ProductTypeID === 3)
    ) {
      let qty = subTotal / lineItem.Product.RetailPrice;
      qty = qty.toFixed(4);
      lineItem.Qty = Number(qty);
    }
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onLineItemPriceChange(price) {
    let lineItem = this.state.selectedLineItem;
    lineItem.RetailPrice = Number(price);

    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
    //console.log('onLineItemPriceChange', this.state.selectedLineItem)
  }

  onLineItemQtyChange(qty, objLineItem = null) {
    let lineItem = objLineItem ? objLineItem : this.state.selectedLineItem;
    lineItem.Qty = Number(qty);
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onLineItemDiscountChange(discountPer) {
    let lineItem = this.state.selectedLineItem;
    lineItem.DiscountPer = Number(discountPer);
    lineItem.DiscountAmount = 0;
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onLineItemDiscountAmountChange(discountAmount) {
    let lineItem = this.state.selectedLineItem;
    lineItem.DiscountPer = 0;
    lineItem.DiscountAmount = Number(discountAmount);
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onChargeTaxOnProductChange() {
    let lineItem = this.state.selectedLineItem;
    lineItem.ChargeTaxOnProduct = !lineItem.ChargeTaxOnProduct;
    if (!lineItem.ChargeTaxOnProduct) {
      lineItem.TaxInclusive = false;
      lineItem.TaxGroup = "";
    }
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onTaxIncluxiveChange() {
    let lineItem = this.state.selectedLineItem;
    lineItem.TaxInclusive = !lineItem.TaxInclusive;
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onTaxGroupChange(taxID, taxGroup) {
    let lineItem = this.state.selectedLineItem;
    lineItem.TaxID = taxID;
    lineItem.TaxGroup = taxGroup;
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    this.setState({ selectedLineItem: lineItem });
  }

  onLineItemNotesChange(notes) {
    let lineItem = this.state.selectedLineItem;
    lineItem.Notes = notes;
    //lineItem = {...this.state.selectedLineItem, Notes:notes}
    //console.log('noteschange', lineItem)
    this.setState({ selectedLineItem: lineItem });
    //this.setState({selectedLineItem: lineItem})
  }

  onLineItemWalletTopUpChange(amount) {
    // console.log(amount)
    // console.log('amount', amount)
    let lineItem = this.state.selectedLineItem;
    // console.log('lineItem.Product.WalletTopUpAmount', lineItem.Product.WalletTopUpAmount)
    lineItem.Product.WalletTopUpAmount = Number(amount);
    // console.log('onLineItemWalletTopUpChange - lineItem', lineItem)
    this.setState({ selectedLineItem: lineItem });
  }

  async onLineItemChangesDone() {
    // alert('onLineItemChangesDone')
    let mrpError = "";
    let mspError = "";
    let selectedItemEmployeeSelectionError = "";
    if (
      this.state.transactionType.EmployeeMandatoryChecked &&
      this.state.transactionType
        .EmployeeManadatoryTransactionORLineItemLevel === "lineitem"
    ) {
      if (this.state.selectedLineItem.Employee.key === "") {
        selectedItemEmployeeSelectionError = "Please select employee";
      }
    }

    if (
      this.props.registration.registration &&
      this.props.registration.registration.EnableRE === true
    ) {
      let product = this.state.selectedLineItem.Product;
      if (
        product.hasOwnProperty("MRP") &&
        Number(product.MRP) > 0 &&
        Number(this.state.selectedLineItem.RetailPrice) > Number(product.MRP)
      ) {
        mrpError =
          "Retail price can not be more than MRP (" + product.MRP + "/-)";
        alert(mrpError);
        return;
      }
      if (
        product.hasOwnProperty("MSP") &&
        Number(product.MSP) > 0 &&
        Number(this.state.selectedLineItem.RetailPrice) < Number(product.MSP)
      ) {
        mspError =
          "Retail price can not be less than MSP (" + product.MSP + "/-)";
        alert(mspError);
        return;
      }
    }

    if (
      mspError !== "" ||
      mrpError !== "" ||
      selectedItemEmployeeSelectionError !== ""
    ) {
      this.setState({
        selectedItemEmployeeSelectionError: selectedItemEmployeeSelectionError,
        mrpError: mrpError,
        mspError: mspError,
      });
      return;
    }
    let lineItems = this.state.lineItems;
    let selectedLineItem = this.state.selectedLineItem;
    if (
      lineItems[this.state.selectedLineItemIndex].hasOwnProperty("InStockQty")
    ) {
      selectedLineItem.InStockQty =
        lineItems[this.state.selectedLineItemIndex].InStockQty;
    }
    lineItems[this.state.selectedLineItemIndex] = selectedLineItem; //this.state.selectedLineItem;
    this.setState({
      lineItems: lineItems,
      showLineItemModal: false,
      screen: "2",
    }); //abc
    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    this.applyOffer();
    this.applySelectedCoupon(this.state.coupon);

    //pawan
    if (this.state.toggleSearch && !this.state.mobileShowCustomer) {
      this.refs.refProductScan.focus(); //13feb
    } else if (!this.state.toggleSearch && !this.state.mobileShowCustomer) {
      if (this.refs.refProductSearch)
        this.refs.refProductSearch.getInstance().focus(); //13feb
    }
  }

  async onModifierSelectionCompletion() {
    let lineItems = this.state.lineItems;
    let lineItem = lineItems[this.state.selectedLineItemIndex];
    lineItem.Modifiers = this.state.selectedModifiers;
    lineItem.ModifierAmount = this.state.modifierAmount;
    lineItem.ModifierQty = this.state.modifierQty;
    lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply);
    if (this.state.modifierAmount > 0) {
    }
    lineItems[this.state.selectedLineItemIndex] = lineItem;
    this.setState({ lineItems: lineItems });
    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    this.applyOffer();
    this.applySelectedCoupon(this.state.coupon);
    this.onModifierSelectionClose();
  }

  onModifierSelectionClose() {
    this.setState({
      showModifiers: false,
      selectedModifiers: [],
      selectedModifierGroupID: "",
      applicableModifierGroups: [],
      modifierQty: 0,
      modifierAmount: 0,
    });
  }

  async calculateBillTotal(lineItems, receivedPaymentAmount = 0) {
    let billQty = 0;
    let billAmount = 0;
    let billSubTotal = 0;
    let billTaxAmount = 0;
    let billDiscount = 0;
    let taxSummaryList = [];
    let roundOff = 0;
    // console.log('calculateBillTotal this.state.lineItems', this.state.lineItems)
    //this.state.
    lineItems.map((lineItem, index) => {
      if (lineItem.TaxID != "") {
        let indexFound = -1;
        taxSummaryList.map((t, index) => {
          if (
            t.TaxID === lineItem.TaxID &&
            t.HSNSACCode === lineItem.HSNSACCode
          ) {
            let sameTaxComponent = true;
            t.TaxComponentAmount.map((a) => {
              if (
                lineItem.TaxComponentAmount.filter(
                  (f) => f.TaxName === a.TaxName
                ).length === 0
              ) {
                sameTaxComponent = false;
                return;
              }
            });
            if (sameTaxComponent) {
              indexFound = index;
              return;
            }
          }
        });
        //console.log(indexFound)
        if (indexFound < 0) {
          let taxSummary = {
            TaxID: lineItem.TaxID,
            TaxGroup: lineItem.TaxGroup,
            HSNSACCode: lineItem.HSNSACCode,
            TaxableAmount: this.roundUpTo2(lineItem.TaxableAmount),
            TotalTax: this.roundUpTo2(lineItem.TotalTax),
            TotalQty: this.roundUpTo2(lineItem.Qty),
            TaxComponentAmount: [],
          };
          lineItem.TaxComponentAmount.map((a) => {
            taxSummary.TaxComponentAmount.push({ ...a });
          });
          taxSummaryList.push(taxSummary);
        } else {
          taxSummaryList[indexFound].TaxableAmount = this.roundUpTo2(
            Number(taxSummaryList[indexFound].TaxableAmount) +
              Number(lineItem.TaxableAmount)
          );
          taxSummaryList[indexFound].TotalTax = this.roundUpTo2(
            Number(taxSummaryList[indexFound].TotalTax) +
              Number(lineItem.TotalTax)
          );
          taxSummaryList[indexFound].TotalQty = this.roundUpTo2(
            Number(taxSummaryList[indexFound].TotalQty) + Number(lineItem.Qty)
          );
          taxSummaryList[indexFound].TaxComponentAmount.map((t, index) => {
            t.TaxAmount = this.roundUpTo2(
              Number(t.TaxAmount) +
                Number(lineItem.TaxComponentAmount[index].TaxAmount)
            );
          });
        }
      }
      //console.log('taxSummaryList', taxSummaryList)
      billQty = this.roundUpTo2(Number(billQty) + Number(lineItem.Qty));
      billSubTotal = this.roundUpTo2(
        Number(billSubTotal) + Number(lineItem.SubTotal)
      );
      billAmount = this.roundUpTo2(
        Number(billAmount) + Number(lineItem.Amount)
      );
      billTaxAmount = this.roundUpTo2(
        Number(billTaxAmount) + Number(lineItem.TotalTax)
      );
      billDiscount = this.roundUpTo2(
        Number(billDiscount) + Number(lineItem.DiscountAmount)
      );
    });
    roundOff = this.roundUpTo2(Math.round(billAmount) - billAmount);
    // console.log('roundOff', Math.round(roundOff))
    billAmount = Math.round(billAmount - Number(receivedPaymentAmount));
    this.setState({
      billQty: billQty,
      billSubTotal: billSubTotal,
      billAmount: billAmount,
      billTaxAmount: billTaxAmount,
      billDiscount: billDiscount,
      taxSummaryList: taxSummaryList,
      roundOff: roundOff,
    });

    if (
      this.props.user.store.EnableCustomerDisplay &&
      !this.props.isMobileApp()
    ) {
      window.sendMessageToCustomerDisplay({
        lineItems: lineItems,
        billQty: billQty,
        billSubTotal: billSubTotal,
        billAmount: billAmount,
        billTaxAmount: billTaxAmount,
        billDiscount: billDiscount,
        taxSummaryList: taxSummaryList,
        roundOff: roundOff,
        store: this.props.user.store,
        message: "",
        productCustomField1DisplayName: this.state
          .productCustomField1DisplayName,
        productCustomField2DisplayName: this.state
          .productCustomField2DisplayName,
        productCustomField3DisplayName: this.state
          .productCustomField3DisplayName,
        productCustomField4DisplayName: this.state
          .productCustomField4DisplayName,
        productCustomField5DisplayName: this.state
          .productCustomField5DisplayName,
        productCustomField6DisplayName: this.state
          .productCustomField6DisplayName,
      });
    }

    // this.sendMessageToCustomerDisplay({
    //     lineItems: lineItems,
    //     billQty: billQty,
    //     billSubTotal: billSubTotal,
    //     billAmount: billAmount,
    //     billTaxAmount: billTaxAmount,
    //     billDiscount: billDiscount,
    //     taxSummaryList: taxSummaryList,
    //     roundOff: roundOff,
    //     store: this.props.user.store,
    //     message: '',
    //     banners: this.props.config.Carousels ? this.props.config.Carousels.filter(f=> !f.IsDeleted) : []
    // })
  }

  sendMessageToCustomerDisplay(data) {
    if (
      this.props.user.store.EnableCustomerDisplay &&
      !this.props.isMobileApp()
    ) {
      window.sendMessageToCustomerDisplay({ ...data });
    }
  }

  async applyOffer() {
    // console.log('********** applyOffer **********')
    // return
    let offersTmp = this.state.offers;
    let offers = [];
    //check for time based offers
    for (let k = 0; k < offersTmp.length; k++) {
      let offer = offersTmp[k];
      if (
        offer.SpecificTime !== "" &&
        offer.SpecificTime.toLowerCase() === "yes"
      ) {
        // console.log('Time based offer:', offer.Name)
        var today = new Date();
        var currentTime = today.getHours() + "." + today.getMinutes();
        // console.log('currentTime', currentTime)
        // console.log('currentHrs', currentHrs)
        // console.log('currentMins', currentMins)
        var startTime =
          Number(offer.OfferStartTime.HH) +
          (offer.OfferStartTime.AMPM === "PM" ? 12 : 0) +
          "." +
          offer.OfferStartTime.MM;
        var endTime =
          Number(offer.OfferEndTime.HH) +
          (offer.OfferEndTime.AMPM === "PM" ? 12 : 0) +
          "." +
          offer.OfferEndTime.MM;
        // console.log('startTime', startTime)
        // console.log('endTime', endTime)
        if (
          Number(startTime) <= Number(currentTime) &&
          Number(currentTime) <= endTime
        ) {
          console.log("Time based offer applicable");
          offers.push(offer);
        }
      } else {
        offers.push(offer);
      }
    }
    // console.log('offers',offers)
    if (offers.length === 0) {
      return;
    }
    if (
      this.state.applyMembershipDiscount &&
      this.state.selectedCustomer &&
      this.state.selectedCustomer.Membership &&
      this.state.selectedCustomer.Membership.hasOwnProperty("MembershipID")
    ) {
      return;
    }
    let offersApplied = this.state.offersApplied;
    let beforeOffersToApply = this.state.offersToApply;
    let offersToApply = []; //this.state.offersToApply
    let showOffersToApply = false;
    let lineItems = this.state.lineItems;
    //1.first check if TRANSACTION levle offers that are alredy applied are still applicable if not then remove them
    let offersAppliedToBeRetained = [];

    //20Aug23
    // for (let i = 0; i < offersApplied.length; i++) {
    //     let offer = offersApplied[i];
    //     if (offer.OfferTypeID.toLowerCase() === "invoicediscount") {
    //         let invoiceAmount = Number(this.state.billAmount);
    //         if (invoiceAmount < offer.InvoiceAmount) {
    //             await this.removeSelectedOffer(i);
    //             continue;
    //         } else {
    //             //19th
    //             // console.log("offer.Discount", offer.Discount);
    //             if (offer.Discount !== "GetXFree") {
    //                 console.log(1)
    //                 await this.applyInvoiceDiscountOffer(offer);
    //                 offersAppliedToBeRetained.push(offer);
    //             } else {
    //                 //check if no of free qty still exists
    //             }
    //         }
    //     }
    // }

    //2. now remove line item level offers
    for (let i = 0; i < lineItems.length; i++) {
      if (
        lineItems[i].hasOwnProperty("Discounts") &&
        lineItems[i].Discounts.length > 0
      ) {
        //if line item level then remove discout

        //20Aug23
        // if (
        //     lineItems[i].Discounts[0].DiscountType.toLowerCase() === "offer"
        //     && !lineItems[i].Discounts[0].TransactionLevelDiscount
        // ) {
        lineItems[i].DiscountPer = 0;
        lineItems[i].DiscountAmount = 0;
        lineItems[i].Discounts = [];
        lineItems[i] = this.onLineItemChange(
          0,
          lineItems[i],
          this.state.placeOfSupply
        );
        await this.calculateBillTotal(
          lineItems,
          this.state.receivedPaymentAmount
        );
        // }
      }
    }
    console.log("lineItems after removing line item offers", lineItems);

    offers.sort(function(a, b) {
      return (
        (a.hasOwnProperty("Priority") ? a.Priority : 0) -
        (b.hasOwnProperty("Priority") ? b.Priority : 0)
      );
    });
    console.log("check offers", offers);
    //3. check which offers TRANSACTION level and LINE ITEM level are now applicable
    for (let i = 0; i < offers.length; i++) {
      let offer = offers[i];
      // console.log('offer',offer)
      // console.log('i',i)
      // console.log('offer.OfferTypeID',offer.OfferTypeID)
      if (offer.OfferTypeID.toLowerCase() === "invoicediscount") {
        let invoiceOfferApplicable = false;
        let invoiceAmount = Number(this.state.billAmount);
        //changed 19thAug23
        if (offer.Discount === "GetXFree") {
          let freeProductTags = this.props.config.ProductTags.filter(
            (productTag) => productTag.key === offer.FreeGroupID
          );
          // console.log('freeProductTags',freeProductTags)
          if (freeProductTags.length > 0) {
            let offerProducts = [];
            let freeProductTag = freeProductTags[0];
            let freeProductQty = 0;
            let freeAmount = 0;
            // console.log("GetXFree - lineItems", lineItems);
            for (let l = 0; l < lineItems.length; l++) {
              let m = lineItems[l];
              let products = freeProductTag.Products.filter(
                (f) => f.ProductID === m.Product.key
              );
              if (products.length > 0) {
                // freeProductQty = freeProductQty + m.Qty;
                // freeAmount = freeAmount + m.Amount;
                for (let q = 0; q < m.Qty; m++) {
                  offerProducts.push({
                    ProductID: m.Product.key,
                    RetailPrice: m.RetailPrice,
                    LineItemIndex: l,
                  });
                }
                // console.log("Free product",m.Product)
              }
            }
            offerProducts.sort(function(a, b) {
              return b.RetailPrice - a.RetailPrice;
            });
            for (let o = 0; o < offerProducts.length; o++) {
              if (freeProductQty < offer.FreeQty) {
                freeProductQty = freeProductQty + 1;
                let tmp = offerProducts[o].RetailPrice;
                //20Aug23
                if (
                  offer.hasOwnProperty("GetXFreeDiscountPercentage") &&
                  offer.GetXFreeDiscountPercentage !== ""
                ) {
                  //calculate free amount
                  tmp =
                    (Number(offerProducts[o].RetailPrice) *
                      Number(offer.GetXFreeDiscountPercentage)) /
                    100;
                }
                freeAmount = freeAmount + Number(tmp);
              }
            }
            // console.log('freeProductQty',freeProductQty);
            // console.log('offer.FreeQty',offer.FreeQty);
            // console.log('invoiceAmount - freeAmount', invoiceAmount - freeAmount);
            // console.log('offer.InvoiceAmount',offer.InvoiceAmount);
            if (
              freeProductQty >= offer.FreeQty &&
              invoiceAmount - freeAmount >= offer.InvoiceAmount
            ) {
              invoiceOfferApplicable = true;
            }
          }
        } else {
          if (invoiceAmount >= offer.InvoiceAmount) {
            invoiceOfferApplicable = true;
          }
        }
        //check if this offer is alread applied
        // console.log("invoiceOfferApplicable", invoiceOfferApplicable);
        if (!invoiceOfferApplicable) {
          continue;
        }
        if (offersToApply.filter((f) => f.key === offer.key).length > 0) {
          // console.log("already part of offers to apply");
          continue;
        } else {
          // console.log("Offer to apply");
          showOffersToApply = true;
          offersToApply.push(offer);
          // if(offersToApply.filter(f=> f.key=== offer.key).length===0)
          // {
          //     showOffersToApply= true
          //     offersToApply.push(offer)
          // }
        }
      } else if (offer.OfferTypeID.toLowerCase() === "buyxgety") {
        //look at items that
        // console.log('buyxgety',offer)
        let offerApplied = false;
        let offerProducts = [];
        let purchaseGroupProductsFound = false;
        let freeGroupProductsFound = false;
        let productTags = this.props.config.ProductTags.filter(
          (productTag) => productTag.key === offer.PurchaseGroupID
        );
        if (productTags.length > 0) {
          // console.log('productTags found', productTags[0])
          let productTag = productTags[0];
          lineItems.map((m, index) => {
            let products = productTag.Products.filter(
              (f) => f.ProductID === m.Product.key
            );
            if (products.length > 0) {
              if (
                !m.hasOwnProperty("Discounts") ||
                (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
              ) {
                purchaseGroupProductsFound = true;
                for (let j = 1; j <= m.Qty; j++) {
                  offerProducts.push({
                    ...m,
                    Index: index,
                    Counter: j,
                    PurchaseGroup: true,
                    FreeGroup: false,
                    IncludedIn: 0,
                  }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
                }
              }
            }
          });
          // console.log('Purcahse group - offerProducts',offerProducts)
          if (!purchaseGroupProductsFound) {
            //if no purchase group products found then proceed further
            continue;
          }
          //get free product group
          let freeProductTags = this.props.config.ProductTags.filter(
            (productTag) => productTag.key === offer.FreeGroupID
          );
          if (freeProductTags.length > 0) {
            let freeProductTag = freeProductTags[0];
            lineItems.map((m, index) => {
              let products = freeProductTag.Products.filter(
                (f) => f.ProductID === m.Product.key
              );
              if (products.length > 0) {
                if (
                  !m.hasOwnProperty("Discounts") ||
                  (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
                ) {
                  freeGroupProductsFound = true;
                  if (
                    offerProducts.filter((f) => f.Index === index).length > 0
                  ) {
                    //same line item product is part of purchase group also include it as free group
                    for (let j = 0; j < offerProducts.length; j++) {
                      if (offerProducts[j].Index === index) {
                        offerProducts[j].FreeGroup = true;
                      }
                    }
                  } else {
                    //add to offerProducts as free grouup product
                    for (let j = 1; j <= m.Qty; j++) {
                      offerProducts.push({
                        ...m,
                        Index: index,
                        Counter: j,
                        PurchaseGroup: false,
                        FreeGroup: true,
                        IncludedIn: 0,
                      }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
                    }
                  }
                }
              }
            });
            ///sort this data as per price
            if (!freeGroupProductsFound) {
              //if no purchase group products found then proceed further
              continue;
            }
            // console.log('offer',offer)
            // console.log('freeGroupProductsFound',freeGroupProductsFound)
            // console.log('freeGroupProductsFound  - offerProducts',offerProducts)
            //check purchase group qty is met
            let purchaseGroupQty = 0;
            // let purcahseCriteriaMeet = false
            offerProducts.sort(function(a, b) {
              return b.RetailPrice - a.RetailPrice;
            });
            // console.log('offerProducts',offerProducts)
            let offerProductsProcessing = [];
            for (let s = 0; s < offerProducts.length; s++) {
              if (
                offerProducts[s].IncludedIn === 0 &&
                offerProducts[s].PurchaseGroup
              ) {
                purchaseGroupQty = Number(purchaseGroupQty) + 1;
                offerProducts[s].IncludedIn = 1;
                offerProductsProcessing.push(offerProducts[s]);
                // console.log("Purchase group index", s);
                // console.log("Purchase group", offerProducts[s]);
              }
              // console.log('purchaseGroupQty',purchaseGroupQty)
              if (offer.PurchaseQty <= purchaseGroupQty) {
                purchaseGroupQty = 0;
                // purcahseCriteriaMeet= true
                // console.log('purcahseCriteriaMeet',true)
                let freeGroupQty = 0;
                for (let j = 0; j < offerProducts.length; j++) {
                  if (
                    offerProducts[offerProducts.length - (j + 1)].IncludedIn ===
                    0
                  ) {
                    freeGroupQty = freeGroupQty + 1;
                    offerProducts[
                      offerProducts.length - (j + 1)
                    ].IncludedIn = 2;
                    offerProductsProcessing.push(
                      offerProducts[offerProducts.length - (j + 1)]
                    );
                    // console.log("Free group index",offerProducts.length - (j + 1));
                    // console.log("Free group",offerProducts[offerProducts.length - (j + 1)]);
                    if (offer.FreeQty <= freeGroupQty) {
                      //apply offer
                      //if this is already part of offers that are applicable to be applied them keep it that way
                      if (
                        beforeOffersToApply.filter((f) => f.key === offer.key)
                          .length > 0
                      ) {
                        // console.log("break");
                        offersToApply.push(offer);
                        break;
                      }
                      if (
                        offer.hasOwnProperty("SpreadDiscount") &&
                        offer.SpreadDiscount
                      ) {
                        //Apply Discount Percentage Equally -- Start
                        // console.log("spread discount",offerProductsProcessing );
                        let freeAmount = 0;
                        let purchaseAmount = 0;
                        let discountPer = 0;
                        let index = -1;
                        // for(let k= 0 ; k<offerProductsProcessing.length; k++)
                        // {
                        //     let offerPoduct = offerProductsProcessing[k]
                        //     if(offerPoduct.IncludedIn=== 2 && index !==offerPoduct.Index){
                        //         freeAmount = freeAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                        //         index = offerPoduct.Index
                        //     }
                        // }
                        //1jun pawan
                        for (
                          let k = 0;
                          k < offerProductsProcessing.length;
                          k++
                        ) {
                          let offerPoduct = offerProductsProcessing[k];
                          if (offerPoduct.IncludedIn === 2) {
                            // console.log('freee offerPoduct.Index', offerPoduct.Index)
                            freeAmount =
                              Number(freeAmount) +
                              Number(lineItems[offerPoduct.Index].RetailPrice);
                            index = offerPoduct.Index;
                          }
                        }
                        index = -1;
                        // for(let k= 0 ; k<offerProductsProcessing.length; k++)
                        // {
                        //     let offerPoduct = offerProductsProcessing[k]
                        //     if(offerPoduct.IncludedIn=== 1 && index !==offerPoduct.Index){
                        //         purchaseAmount = purchaseAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                        //         index = offerPoduct.Index
                        //     }
                        // }
                        for (
                          let k = 0;
                          k < offerProductsProcessing.length;
                          k++
                        ) {
                          let offerPoduct = offerProductsProcessing[k];
                          if (offerPoduct.IncludedIn === 1) {
                            // console.log('purchase offerPoduct.Index', offerPoduct.Index)
                            purchaseAmount =
                              Number(purchaseAmount) +
                              Number(lineItems[offerPoduct.Index].RetailPrice);
                            index = offerPoduct.Index;
                          }
                        }
                        // console.log('freeAmount', freeAmount);
                        // console.log('purchaseAmount', purchaseAmount)
                        discountPer = this.roundUpTo2(
                          (freeAmount * 100) / (freeAmount + purchaseAmount)
                        );
                        // console.log('freeAmount', freeAmount)
                        // console.log('purchaseAmount', purchaseAmount)
                        // console.log('discountPer',discountPer)
                        index = -1;
                        for (
                          let k = 0;
                          k < offerProductsProcessing.length;
                          k++
                        ) {
                          let offerPoduct = offerProductsProcessing[k];
                          // console.log('offerPoduct.Index',offerPoduct)
                          if (index !== offerPoduct.Index) {
                            // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                            index = offerPoduct.Index;
                            // console.log('index', index)
                            lineItems[index].DiscountPer = discountPer; //percentage
                            lineItems[index].DiscountAmount = 0;
                            lineItems[index] = this.onLineItemChange(
                              0,
                              lineItems[index],
                              this.state.placeOfSupply,
                              "Offer",
                              offer.key,
                              offer.Name,
                              false
                            );
                            await this.calculateBillTotal(
                              lineItems,
                              this.state.receivedPaymentAmount
                            );
                            offerApplied = true;
                          }
                        }
                        //Apply Discount Percentage Equally -- End
                      } else {
                        //Free QTY -- Start
                        let index = -1;
                        for (
                          let k = 0;
                          k < offerProductsProcessing.length;
                          k++
                        ) {
                          // console.log('k',k)
                          // console.log('index', index)
                          let offerPoduct = offerProductsProcessing[k];
                          if (index !== -1 && index !== offerPoduct.Index) {
                            lineItems[index] = this.onLineItemChange(
                              0,
                              lineItems[index],
                              this.state.placeOfSupply,
                              "Offer",
                              offer.key,
                              offer.Name,
                              false
                            );
                            await this.calculateBillTotal(
                              lineItems,
                              this.state.receivedPaymentAmount
                            );
                            offerApplied = true;
                            // console.log('lineItems[index]', lineItems[index])
                          }
                          if (offerPoduct.IncludedIn === 2) {
                            index = offerPoduct.Index;
                            lineItems[offerPoduct.Index].DiscountPer = 0;
                            lineItems[offerPoduct.Index].DiscountAmount =
                              Number(
                                lineItems[offerPoduct.Index].DiscountAmount
                              ) + lineItems[offerPoduct.Index].RetailPrice;
                            // lineItems[offerPoduct.Index].IncludedInOffer = true
                            // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key
                            // console.log('part of free group')
                          } else {
                            index = offerPoduct.Index;
                            // lineItems[offerPoduct.Index].IncludedInOffer = true
                            // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key
                            // console.log('part ofprucase group')
                          }
                          // console.log('lineItems[offerPoduct.Index]', lineItems[offerPoduct.Index])
                        }
                        // console.log('index', index)
                        if (index !== -1) {
                          lineItems[index] = this.onLineItemChange(
                            0,
                            lineItems[index],
                            this.state.placeOfSupply,
                            "Offer",
                            offer.key,
                            offer.Name,
                            false
                          );
                          await this.calculateBillTotal(
                            lineItems,
                            this.state.receivedPaymentAmount
                          );
                          offerApplied = true;
                          // console.log('lineItems[index]', lineItems[index])
                        }
                        //Free QTY -- END
                      }

                      freeGroupQty = 0;
                      offerProductsProcessing = [];
                      // console.log('freeGroupQty', freeGroupQty)
                      // console.log('offer.FreeQty', offer.FreeQty)
                      break;
                    }
                  }
                }
              }
            }
          }
        }
        if (offerApplied) {
          if (
            offersAppliedToBeRetained.filter((f) => f.key === offer.key)
              .length === 0
          ) {
            offersAppliedToBeRetained.push(offer);
          }
        }
      } else if (
        offer.OfferTypeID.toLowerCase() === "buyxqtygetydiscountpercentage"
      ) {
        // console.log('offer name', offer.Name)
        let offerApplied = false;
        let offerProducts = [];
        let purchaseGroupProductsFound = false;
        let productTags = this.props.config.ProductTags.filter(
          (productTag) => productTag.key === offer.PurchaseGroupID
        );
        if (productTags.length > 0) {
          // console.log('productTags.length', productTags.length)
          let productTag = productTags[0];
          lineItems.map((m, index) => {
            let products = productTag.Products.filter(
              (f) => f.ProductID === m.Product.key
            );
            if (products.length > 0) {
              // console.log('products found',m.Product)
              if (
                !m.hasOwnProperty("Discounts") ||
                (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
              ) {
                purchaseGroupProductsFound = true;
                for (let j = 1; j <= m.Qty; j++) {
                  offerProducts.push({
                    ...m,
                    Index: index,
                    Counter: j,
                    PurchaseGroup: true,
                    FreeGroup: false,
                    IncludedIn: 0,
                  }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
                }
              }
            }
          });
          // console.log('Purcahse group - offerProducts',offerProducts)
          if (!purchaseGroupProductsFound) {
            //if no purchase group products found then proceed further
            continue;
          }
          // console.log('purchaseGroupProductsFound', purchaseGroupProductsFound)
          //check purchase group qty is met
          let purchaseGroupQty = 0;
          // let purcahseCriteriaMeet = false
          offerProducts.sort(function(a, b) {
            return b.RetailPrice - a.RetailPrice;
          });

          // console.log('offerProducts',offerProducts)
          let offerProductsProcessing = [];
          for (let s = 0; s < offerProducts.length; s++) {
            if (offerProducts[s].IncludedIn === 0) {
              purchaseGroupQty = Number(purchaseGroupQty) + 1;
              offerProducts[s].IncludedIn = 1;
              offerProductsProcessing.push(offerProducts[s]);
            }
            // console.log('purchaseGroupQty',purchaseGroupQty)
            if (offer.PurchaseQty <= purchaseGroupQty) {
              // console.log('Purchase qty criteria meet - offerProductsProcessing',offerProductsProcessing )

              //pawan
              if (
                beforeOffersToApply.filter((f) => f.key === offer.key).length >
                0
              ) {
                // console.log('break 1')
                offersToApply.push(offer);
                break;
              }
              let index = -1;
              for (let k = 0; k < offerProductsProcessing.length; k++) {
                let offerPoduct = offerProductsProcessing[k];
                // console.log('offerPoduct.Index',offerPoduct)
                if (index !== offerPoduct.Index) {
                  // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                  index = offerPoduct.Index;
                  // console.log('index', index)
                  lineItems[index].DiscountPer = offer.DiscountPercentage; //percentage
                  lineItems[index].DiscountAmount = 0;
                  lineItems[index] = this.onLineItemChange(
                    0,
                    lineItems[index],
                    this.state.placeOfSupply,
                    "Offer",
                    offer.key,
                    offer.Name,
                    false
                  );
                  await this.calculateBillTotal(
                    lineItems,
                    this.state.receivedPaymentAmount
                  );
                  offerApplied = true;
                }
              }
              offerProductsProcessing = [];
              purchaseGroupQty = 0;
            }
          }
        }
        if (offerApplied) {
          if (
            offersAppliedToBeRetained.filter((f) => f.key === offer.key)
              .length === 0
          ) {
            offersAppliedToBeRetained.push(offer);
          }
        }
      }
    }
    this.setState({
      lineItems: lineItems,
      offersApplied: offersAppliedToBeRetained,
    });
    let lineItemsWithouAnyOfferAlliedExists = false;
    lineItems.map((l) => {
      if (l.Discounts && l.Discounts.length === 0) {
        lineItemsWithouAnyOfferAlliedExists = true;
      }
    });
    if (showOffersToApply && lineItemsWithouAnyOfferAlliedExists) {
      this.setState({
        showDiscountOptions: true,
        showDiscountOffers: true,
        discountOptionSelected: "offer",
        offersPopupTabSelected: "applicable",
        offersToApply: offersToApply,
      });
    }
    // this.setState({offersToApply:offersToApply})
  }

  async removeSelectedCoupon() {
    let status = await this.removeSelectedOfferCoupon(this.state.coupon);
    if (status) {
      this.setState({ coupon: null });
    }
  }

  async removeSelectedOffer(offer) {
    // let offer = this.state.offersApplied[index];
    let offerRemoved = await this.removeSelectedOfferCoupon(offer);

    if (offerRemoved) {
      let offersToApply = this.state.offersToApply;
      if (offersToApply.filter((f) => f.key === offer.key).length === 0) {
        offersToApply.push(offer);
      }

      // let offersApplied = this.state.offersApplied;
      // offersApplied.splice(index, 1);

      this.setState({
        //updatedLineItems,
        offersToApply: offersToApply,
        // offersApplied: offersApplied,
      });
    }
  }

  async removeSelectedOfferCoupon(offer) {
    let lineItems = this.state.lineItems;
    let offerRemoved = false;
    for (let i = 0; i < lineItems.length; i++) {
      let lineItem = lineItems[i];
      if (lineItem.hasOwnProperty("Discounts")) {
        if (
          lineItem.Discounts.filter((f) => f.DiscountID === offer.key).length >
          0
        ) {
          offerRemoved = true;
          // console.log('dicount applicable')
          lineItem.DiscountPer = 0;
          lineItem.DiscountAmount = 0;
          lineItem.Discounts = [];
          lineItem = this.onLineItemChange(
            0,
            lineItem,
            this.state.placeOfSupply,
            "",
            "",
            ""
          );
        }
      }
    }
    if (offerRemoved) {
      this.setState({ lineItems: lineItems });
      this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    }
    return offerRemoved;
  }

  async applyInvoiceDiscountOffer(offer) {
    if (offer.Discount === "GetXFree") {
      let lineItems = this.state.lineItems;
      let offersApplied = this.state.offersApplied;

      let freeProductTags = this.props.config.ProductTags.filter(
        (productTag) => productTag.key === offer.FreeGroupID
      );
      if (freeProductTags.length > 0) {
        let freeProductTag = freeProductTags[0];
        let offerProducts = [];
        lineItems.map((m, index) => {
          let products = freeProductTag.Products.filter(
            (f) => f.ProductID === m.Product.key
          );
          if (products.length > 0) {
            if (
              !m.hasOwnProperty("Discounts") ||
              (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
            ) {
              for (let j = 1; j <= m.Qty; j++) {
                offerProducts.push({
                  ...m,
                  Index: index,
                  Counter: j,
                  PurchaseGroup: false,
                  FreeGroup: true,
                  IncludedIn: 0,
                }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
              }
            }
          }
        });
        // console.log('offerProducts',offerProducts);
        if (offerProducts.length > 0 && offerProducts.length >= offer.FreeQty) {
          offerProducts.sort(function(a, b) {
            return b.RetailPrice - a.RetailPrice;
          });
          let freeGroupQty = 0;
          let index = -1;
          let offerApplied = false;
          for (let j = 0; j < offerProducts.length; j++) {
            freeGroupQty = freeGroupQty + 1;
            let offerPoduct = offerProducts[j];

            if (freeGroupQty <= offer.FreeQty) {
              if (index !== -1 && index !== offerPoduct.Index) {
                lineItems[index] = this.onLineItemChange(
                  0,
                  lineItems[index],
                  this.state.placeOfSupply,
                  "Offer",
                  offer.key,
                  offer.Name,
                  false
                );
                await this.calculateBillTotal(
                  lineItems,
                  this.state.receivedPaymentAmount
                );
                offerApplied = true;
                // console.log('lineItems[index]', lineItems[index])
              }
              index = offerPoduct.Index;
              lineItems[offerPoduct.Index].DiscountPer = 0;
              let tmpValue = lineItems[offerPoduct.Index].RetailPrice;
              if (
                offer.hasOwnProperty("GetXFreeDiscountPercentage") &&
                offer.GetXFreeDiscountPercentage !== ""
              ) {
                //calculate free amount
                tmpValue =
                  (Number(lineItems[offerPoduct.Index].RetailPrice) *
                    Number(offer.GetXFreeDiscountPercentage)) /
                  100;
              }
              lineItems[offerPoduct.Index].DiscountAmount =
                Number(lineItems[offerPoduct.Index].DiscountAmount) + tmpValue;
            }
          }
          if (index !== -1) {
            //!offerApplied &&
            lineItems[index] = this.onLineItemChange(
              0,
              lineItems[index],
              this.state.placeOfSupply,
              "Offer",
              offer.key,
              offer.Name,
              false
            );
            await this.calculateBillTotal(
              lineItems,
              this.state.receivedPaymentAmount
            );
            offerApplied = true;
          }
          // if(offerApplied)
          // {
          //     if(offersAppliedToBeRetained.filter(f=>f.key === offer.key).length===0)
          //     {
          //         offersAppliedToBeRetained.push(offer)
          //     }

          // }
        }
      }
    } else {
      let discountAmount = 0;
      let discountPercentage = 0;
      if (offer.Discount.toLowerCase() === "amount") {
        discountAmount = Number(offer.DiscountValue);
        // console.log('amount', discountAmount)
      } else {
        discountPercentage = Number(offer.DiscountValue);
        // console.log('amount', discountPercentage)
      }
      this.onOverAllDiscount(
        discountPercentage,
        discountAmount,
        true,
        offer.hasOwnProperty("Type") ? offer.Type : "Offer",
        offer.key,
        offer.Name
      );
    }
  }

  async applyCoupon() {
    if (this.state.couponCode.trim() === "") {
      this.setState({ couponCodeError: "Please specify coupon" });
      return;
    }
    let couponCode = this.state.couponCode;
    let offersTmp = this.props.config.ApplicableOffers;
    let couponID = "";
    console.log("couponCode", couponCode);
    let coupon = await this.props.validateCoupon(
      this.props.user.user.RegistrationID,
      this.props.user.store.key,
      couponCode,
      this.state.selectedCustomer ? this.state.selectedCustomer.key : ""
    );
    console.log("coupon from server", coupon);
    if (!coupon) {
      this.setState({
        couponErrorMsg: "Invalid coupon",
        couponSuccessMsg: "",
        couponCode: "",
        coupon: coupon,
      });
      return;
    }
    if (coupon.hasOwnProperty("CouponID")) {
      couponID = coupon.CouponID;
    } else {
      couponID = coupon.key;
    }
    //check if coupon is still valid coupon
    if (offersTmp.filter((f) => f.key === couponID).length === 0) {
      // console.log('Coupon not available in applicable list')
      this.setState({
        couponErrorMsg: "Invalid coupon",
        couponSuccessMsg: "",
        couponCode: "",
        coupon: coupon,
      });
      return;
    }
    //check for time based offers
    if (
      coupon.SpecificTime !== "" &&
      coupon.SpecificTime.toLowerCase() === "yes"
    ) {
      console.log("Time based coupon:", coupon.Name);
      var today = new Date();
      var currentTime = today.getHours() + "." + today.getMinutes();
      // console.log('currentTime',currentTime)
      var startTime =
        Number(coupon.OfferStartTime.HH) +
        (coupon.OfferStartTime.AMPM === "PM" ? 12 : 0) +
        "." +
        coupon.OfferStartTime.MM;
      var endTime =
        Number(coupon.OfferEndTime.HH) +
        (coupon.OfferEndTime.AMPM === "PM" ? 12 : 0) +
        "." +
        coupon.OfferEndTime.MM;
      if (
        Number(startTime) <= Number(currentTime) &&
        Number(currentTime) <= endTime
      ) {
        // console.log('Time based offer applicable')
      } else {
        console.log("Time based coupon not applicable");
        this.setState({
          couponErrorMsg: "Invalid coupon",
          couponSuccessMsg: "",
          couponCode: "",
          coupon: coupon,
        });
        return;
      }
    }

    // for (let k = 0; k < offersTmp.length; k++) {
    //     let offer = offersTmp[k];
    //     // console.log('offer',offer)
    //     if (offer.hasOwnProperty("Type") && offer.Type === "Coupon" && offer.key === couponID)
    //     {
    //         if (
    //             offer.SpecificTime !== "" &&
    //             offer.SpecificTime.toLowerCase() === "yes"
    //         ) {
    //             console.log('Time based coupon:', offer.Name)
    //             var today = new Date();
    //             var currentTime =
    //                 today.getHours() + "." + today.getMinutes();
    //             // console.log('currentTime',currentTime)
    //             var startTime =
    //                 Number(offer.OfferStartTime.HH) +
    //                 (offer.OfferStartTime.AMPM === "PM" ? 12 : 0) +
    //                 "." +
    //                 offer.OfferStartTime.MM;
    //             var endTime =
    //                 Number(offer.OfferEndTime.HH) +
    //                 (offer.OfferEndTime.AMPM === "PM" ? 12 : 0) +
    //                 "." +
    //                 offer.OfferEndTime.MM;
    //             // console.log('startTime', startTime)
    //             // console.log('endTime', endTime)
    //             if (
    //                 Number(startTime) <= Number(currentTime) &&
    //                 Number(currentTime) <= endTime
    //             ) {
    //                 // console.log('Time based offer applicable')
    //                 coupon = offer;
    //                 break;
    //             }
    //         } else {
    //             coupon = offer;
    //             break;
    //         }
    //         console.log("coupon found", coupon);
    //     }
    // }
    // if (!coupon) {
    //     this.setState({
    //         couponErrorMsg: "Invalid coupon",
    //         couponSuccessMsg: "",
    //         couponCode: "",
    //         coupon: coupon,
    //     });
    //     return;
    // }
    await this.applySelectedCoupon(coupon);
  }

  async applySelectedCoupon(coupon) {
    if (!coupon) {
      return false;
    }

    await this.removeSelectedOfferCoupon(coupon);

    let status = await this.computeSelectedOfferCoupon(coupon);
    console.log("applySelectedCoupon - status", status);
    if (!status) {
      this.setState({
        couponErrorMsg: this.state.coupon ? "" : "Coupon criteria not met",
        couponSuccessMsg: "",
        couponCode: "",
        coupon: this.state.coupon ? null : coupon,
      });
      return;
    }
    this.setState({
      couponSuccessMsg: "Coupon applied",
      couponCode: "",
      coupon: coupon,
      couponErrorMsg: "",
    });
  }

  async applySelectedOffer(index) {
    let offer = this.state.offersToApply[index];
    let offersToApply = this.state.offersToApply;
    let offersApplied = this.state.offersApplied;
    let status = await this.computeSelectedOfferCoupon(offer);
    if (!status) {
      offersToApply.splice(index, 1);
      this.setState({ offersToApply: offersToApply });
      return;
    }
    offersApplied.push(offer);
    offersToApply.splice(index, 1);
    this.setState({
      offersApplied: offersApplied,
      offersToApply: offersToApply,
      showDiscountOptions: false,
    });
  }

  async computeSelectedOfferCoupon(offer) {
    // return
    //this is transaciton offer
    let lineItems = this.state.lineItems;
    // let discountPercentage = 0
    // let discountAmount = 0
    // 21June2019
    // let offersApplied = this.state.offersApplied
    // let offersToApply = this.state.offersToApply
    if (offer.OfferTypeID.toLowerCase() === "invoicediscount") {
      //check if offer is still valid
      let invoiceAmount = Number(this.state.billAmount);
      if (invoiceAmount < offer.InvoiceAmount) {
        // await this.removeSelectedOffer(index)
        // console.log('Offer no longer valid')

        //21June2019
        // offersToApply.splice(index, 1)
        // this.setState({offersToApply:offersToApply})
        return false;
      }
      //check if any discountable item exists
      let dicountableLineItemExists = false;
      // console.log("lineItems", lineItems);
      for (let l = 0; l < lineItems.length; l++) {
        let lineItem = lineItems[l];
        if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
          if (lineItem.Product.AllowDiscount) {
            dicountableLineItemExists = true;
            break;
          }
        } else {
          dicountableLineItemExists = true;
          break;
        }
      }
      // console.log('dicountableLineItemExists', dicountableLineItemExists)
      if (!dicountableLineItemExists) {
        // console.log('Non discontanble items')
        return false;
      }
      //added 19th May
      this.applyInvoiceDiscountOffer(offer);
    } else if (offer.OfferTypeID.toLowerCase() === "buyxgety") {
      //look at items that
      // console.log('buyxgety',offer)
      let offerApplied = false;
      let offerProducts = [];
      let purchaseGroupProductsFound = false;
      let freeGroupProductsFound = false;
      let productTags = this.props.config.ProductTags.filter(
        (productTag) => productTag.key === offer.PurchaseGroupID
      );
      if (productTags.length > 0) {
        // console.log('productTags found', productTags[0])
        let productTag = productTags[0];
        lineItems.map((m, index) => {
          let products = productTag.Products.filter(
            (f) => f.ProductID === m.Product.key
          );
          if (products.length > 0) {
            if (
              !m.hasOwnProperty("Discounts") ||
              (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
            ) {
              purchaseGroupProductsFound = true;
              for (let j = 1; j <= m.Qty; j++) {
                offerProducts.push({
                  ...m,
                  Index: index,
                  Counter: j,
                  PurchaseGroup: true,
                  FreeGroup: false,
                  IncludedIn: 0,
                }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
              }
            }
          }
        });
        // console.log('Purcahse group - offerProducts',offerProducts)
        if (!purchaseGroupProductsFound) {
          //if no purchase group products found then proceed further
          // console.log('Purchase group not found')
          // await this.removeSelectedOffer(index)

          //21June2019
          // offersToApply.splice(index, 1)
          // this.setState({offersToApply:offersToApply})
          return false;
        }
        //get free product group
        let freeProductTags = this.props.config.ProductTags.filter(
          (productTag) => productTag.key === offer.FreeGroupID
        );
        if (freeProductTags.length > 0) {
          let freeProductTag = freeProductTags[0];
          lineItems.map((m, index) => {
            let products = freeProductTag.Products.filter(
              (f) => f.ProductID === m.Product.key
            );
            if (products.length > 0) {
              if (
                !m.hasOwnProperty("Discounts") ||
                (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
              ) {
                freeGroupProductsFound = true;
                if (offerProducts.filter((f) => f.Index === index).length > 0) {
                  //same line item product is part of purchase group also include it as free group
                  for (let j = 0; j < offerProducts.length; j++) {
                    if (offerProducts[j].Index === index) {
                      offerProducts[j].FreeGroup = true;
                    }
                  }
                } else {
                  //add to offerProducts as free grouup product
                  for (let j = 1; j <= m.Qty; j++) {
                    offerProducts.push({
                      ...m,
                      Index: index,
                      Counter: j,
                      PurchaseGroup: false,
                      FreeGroup: true,
                      IncludedIn: 0,
                    }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
                  }
                }
              }
            }
          });
          ///sort this data as per price
          if (!freeGroupProductsFound) {
            //if no purchase group products found then proceed further
            // await this.removeSelectedOffer(index)
            // console.log()

            // 21June2019
            // offersToApply.splice(index, 1)
            // this.setState({offersToApply:offersToApply})
            return false;
          }
          // console.log('offer',offer)
          // console.log('freeGroupProductsFound',freeGroupProductsFound)
          // console.log('freeGroupProductsFound  - offerProducts',offerProducts)
          //check purchase group qty is met
          let purchaseGroupQty = 0;
          // let purcahseCriteriaMeet = false
          offerProducts.sort(function(a, b) {
            return b.RetailPrice - a.RetailPrice;
          });
          // console.log('offerProducts',offerProducts)
          let offerProductsProcessing = [];
          for (let s = 0; s < offerProducts.length; s++) {
            if (offerProducts[s].IncludedIn === 0) {
              purchaseGroupQty = Number(purchaseGroupQty) + 1;
              offerProducts[s].IncludedIn = 1;
              offerProductsProcessing.push(offerProducts[s]);
            }
            // console.log('purchaseGroupQty',purchaseGroupQty)
            if (offer.PurchaseQty <= purchaseGroupQty) {
              purchaseGroupQty = 0;
              // purcahseCriteriaMeet= true
              // console.log('purcahseCriteriaMeet',true)
              let freeGroupQty = 0;
              for (let j = 0; j < offerProducts.length; j++) {
                if (
                  offerProducts[offerProducts.length - (j + 1)].IncludedIn === 0
                ) {
                  freeGroupQty = freeGroupQty + 1;
                  offerProducts[offerProducts.length - (j + 1)].IncludedIn = 2;
                  offerProductsProcessing.push(
                    offerProducts[offerProducts.length - (j + 1)]
                  );
                  if (offer.FreeQty <= freeGroupQty) {
                    //apply offer
                    //if this is already part of offers that are applicable to be applied them keep it that way
                    // if(beforeOffersToApply.filter(f=>f.key === offer.key).length > 0)
                    // {
                    //     console.log('break')
                    //     offersToApply.push(offer)
                    //     break
                    // }
                    if (
                      offer.hasOwnProperty("SpreadDiscount") &&
                      offer.SpreadDiscount
                    ) {
                      //Apply Discount Percentage Equally -- Start
                      let freeAmount = 0;
                      let purchaseAmount = 0;
                      let discountPer = 0;
                      let index = -1;
                      for (let k = 0; k < offerProductsProcessing.length; k++) {
                        let offerPoduct = offerProductsProcessing[k];
                        if (
                          offerPoduct.IncludedIn === 2 &&
                          index !== offerPoduct.Index
                        ) {
                          freeAmount =
                            freeAmount +
                            lineItems[offerPoduct.Index].SubTotalWithDiscount;
                          index = offerPoduct.Index;
                        }
                      }
                      index = -1;
                      for (let k = 0; k < offerProductsProcessing.length; k++) {
                        let offerPoduct = offerProductsProcessing[k];
                        if (
                          offerPoduct.IncludedIn === 1 &&
                          index !== offerPoduct.Index
                        ) {
                          purchaseAmount =
                            purchaseAmount +
                            lineItems[offerPoduct.Index].SubTotalWithDiscount;
                          index = offerPoduct.Index;
                        }
                      }
                      discountPer = this.roundUpTo2(
                        (freeAmount * 100) / (freeAmount + purchaseAmount)
                      );
                      // console.log('freeAmount', freeAmount)
                      // console.log('purchaseAmount', purchaseAmount)
                      // console.log('discountPer',discountPer)
                      index = -1;
                      for (let k = 0; k < offerProductsProcessing.length; k++) {
                        let offerPoduct = offerProductsProcessing[k];
                        // console.log('offerPoduct.Index',offerPoduct)
                        if (index !== offerPoduct.Index) {
                          // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                          index = offerPoduct.Index;
                          // console.log('index', index)
                          lineItems[index].DiscountPer = discountPer; //percentage
                          lineItems[index].DiscountAmount = 0;
                          lineItems[index] = this.onLineItemChange(
                            0,
                            lineItems[index],
                            this.state.placeOfSupply,
                            offer.hasOwnProperty("Type") ? offer.Type : "Offer",
                            offer.key,
                            offer.Name,
                            false
                          );
                          await this.calculateBillTotal(
                            lineItems,
                            this.state.receivedPaymentAmount
                          );
                          offerApplied = true;
                        }
                      }
                      //Apply Discount Percentage Equally -- End
                    } else {
                      //Free QTY -- Start
                      let index = -1;
                      for (let k = 0; k < offerProductsProcessing.length; k++) {
                        // console.log('k',k)
                        // console.log('index', index)
                        let offerPoduct = offerProductsProcessing[k];
                        if (index !== -1 && index !== offerPoduct.Index) {
                          lineItems[index] = this.onLineItemChange(
                            0,
                            lineItems[index],
                            this.state.placeOfSupply,
                            offer.hasOwnProperty("Type") ? offer.Type : "Offer",
                            offer.key,
                            offer.Name,
                            false
                          );
                          await this.calculateBillTotal(
                            lineItems,
                            this.state.receivedPaymentAmount
                          );
                          offerApplied = true;
                          // console.log('lineItems[index]', lineItems[index])
                        }
                        if (offerPoduct.IncludedIn === 2) {
                          index = offerPoduct.Index;
                          lineItems[offerPoduct.Index].DiscountPer = 0;
                          lineItems[offerPoduct.Index].DiscountAmount =
                            Number(
                              lineItems[offerPoduct.Index].DiscountAmount
                            ) + lineItems[offerPoduct.Index].RetailPrice;
                          // lineItems[offerPoduct.Index].IncludedInOffer = true
                          // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key
                          // console.log('part of free group')
                        } else {
                          index = offerPoduct.Index;
                          // lineItems[offerPoduct.Index].IncludedInOffer = true
                          // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key
                          // console.log('part ofprucase group')
                        }
                        // console.log('lineItems[offerPoduct.Index]', lineItems[offerPoduct.Index])
                      }
                      // console.log('index', index)
                      if (index !== -1) {
                        lineItems[index] = this.onLineItemChange(
                          0,
                          lineItems[index],
                          this.state.placeOfSupply,
                          offer.hasOwnProperty("Type") ? offer.Type : "Offer",
                          offer.key,
                          offer.Name,
                          false
                        );
                        await this.calculateBillTotal(
                          lineItems,
                          this.state.receivedPaymentAmount
                        );
                        offerApplied = true;
                        // console.log('lineItems[index]', lineItems[index])
                      }
                      //Free QTY -- END
                    }

                    freeGroupQty = 0;
                    offerProductsProcessing = [];
                    // console.log('freeGroupQty', freeGroupQty)
                    // console.log('offer.FreeQty', offer.FreeQty)
                    break;
                  }
                }
              }
            }
          }
        }
      }
    } else if (
      offer.OfferTypeID.toLowerCase() === "buyxqtygetydiscountpercentage"
    ) {
      // console.log('offer name', offer.Name)
      let offerApplied = false;
      let offerProducts = [];
      let purchaseGroupProductsFound = false;
      let productTags = this.props.config.ProductTags.filter(
        (productTag) => productTag.key === offer.PurchaseGroupID
      );
      if (productTags.length > 0) {
        // console.log('productTags.length', productTags.length)
        let productTag = productTags[0];
        lineItems.map((m, index) => {
          let products = productTag.Products.filter(
            (f) => f.ProductID === m.Product.key
          );
          if (products.length > 0) {
            // console.log('products found',m.Product)
            if (
              !m.hasOwnProperty("Discounts") ||
              (m.hasOwnProperty("Discounts") && m.Discounts.length === 0)
            ) {
              purchaseGroupProductsFound = true;
              for (let j = 1; j <= m.Qty; j++) {
                offerProducts.push({
                  ...m,
                  Index: index,
                  Counter: j,
                  PurchaseGroup: true,
                  FreeGroup: false,
                  IncludedIn: 0,
                }); //IncludedIn:1 = purchase group, IncludedIn:2 free group
              }
            }
          }
        });
        // console.log('Purcahse group - offerProducts',offerProducts)
        if (!purchaseGroupProductsFound) {
          //if no purchase group products found then proceed further
          //21June2019
          // offersToApply.splice(index, 1)
          // this.setState({offersToApply:offersToApply})
          return false;
        }
        // console.log('purchaseGroupProductsFound', purchaseGroupProductsFound)
        //check purchase group qty is met
        let purchaseGroupQty = 0;
        // let purcahseCriteriaMeet = false
        offerProducts.sort(function(a, b) {
          return b.RetailPrice - a.RetailPrice;
        });

        // console.log('offerProducts',offerProducts)
        let offerProductsProcessing = [];
        for (let s = 0; s < offerProducts.length; s++) {
          if (offerProducts[s].IncludedIn === 0) {
            purchaseGroupQty = Number(purchaseGroupQty) + 1;
            offerProducts[s].IncludedIn = 1;
            offerProductsProcessing.push(offerProducts[s]);
          }
          // console.log('purchaseGroupQty',purchaseGroupQty)
          if (offer.PurchaseQty <= purchaseGroupQty) {
            // console.log('Purchase qty criteria meet  - offerProductsProcessing',offerProductsProcessing )

            let index = -1;
            for (let k = 0; k < offerProductsProcessing.length; k++) {
              let offerPoduct = offerProductsProcessing[k];
              // console.log('offerPoduct.Index',offerPoduct)
              if (index !== offerPoduct.Index) {
                // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                index = offerPoduct.Index;
                // console.log('index', index)
                lineItems[index].DiscountPer = offer.DiscountPercentage; //percentage
                lineItems[index].DiscountAmount = 0;
                lineItems[index] = this.onLineItemChange(
                  0,
                  lineItems[index],
                  this.state.placeOfSupply,
                  offer.hasOwnProperty("Type") ? offer.Type : "Offer",
                  offer.key,
                  offer.Name,
                  false
                );
                await this.calculateBillTotal(
                  lineItems,
                  this.state.receivedPaymentAmount
                );
                offerApplied = true;
              }
            }
            offerProductsProcessing = [];
            purchaseGroupQty = 0;
          }
        }
      }
    }
    // 21June2019
    // offersApplied.push(offer)
    // offersToApply.splice(index, 1)
    // this.setState({offersApplied:offersApplied,
    //     offersToApply:offersToApply,
    //     showDiscountOptions:false})
    console.log("computeSelectedOfferCoupon", this.state.lineItems);
    return true;
  }

  onLineItemChange(
    index,
    lineItem,
    placeOfSupply,
    discountType,
    discountID,
    discountInfo,
    transactionLevelDiscount,
    transactionType
  ) {
    let subTotal = this.roundUpTo2(
      Number(lineItem.RetailPrice) * Number(lineItem.Qty)
    );
    subTotal = this.roundUpTo2(
      subTotal + (lineItem.ModifierAmount ? lineItem.ModifierAmount : 0)
    );
    let discountAmt = 0;
    let discountPer = 0;
    let allowDiscount = true;
    if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
      allowDiscount = lineItem.Product.AllowDiscount;
    }
    if (allowDiscount) {
      if (
        Number(lineItem.DiscountAmount) > 0 &&
        Number(lineItem.DiscountPer) === 0
      ) {
        //calculate discount per based on discount amount
        //&& Number(lineItem.DiscountPer) === 0
        discountPer = Number(
          (
            (Number(lineItem.DiscountAmount) * 100) /
            Number(lineItem.SubTotal)
          ).toFixed(3)
        );
        discountAmt = this.roundUpTo2(lineItem.DiscountAmount);
      } else {
        discountPer = Number(lineItem.DiscountPer);
        discountAmt = this.roundUpTo2(
          (Number(subTotal) * Number(lineItem.DiscountPer)) / 100
        );
      }
      // console.log('discountPer', discountPer)
      if (discountInfo) {
        if (discountInfo.trim() !== "") {
          // if(!lineItem.hasOwnProperty('Discounts'))
          // {
          //     lineItem.Discounts = []
          // }
          // console.log("discountInfo", discountInfo);
          lineItem.Discounts = [];
          lineItem.Discounts.push({
            DiscountType: discountType,
            DiscountID: discountID,
            DiscountInfo: discountInfo,
            DiscountAmount: discountAmt,
            DiscountPercentage: discountPer,
            TransactionLevelDiscount: transactionLevelDiscount,
          });
        }
      } else if (discountAmt === 0) {
        lineItem.Discounts = [];
      }
    } else {
      lineItem.Discounts = [];
    }

    let subTotalWithDiscount = this.roundUpTo2(
      Number(subTotal) - Number(discountAmt)
    );
    let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
    let priceBasedTax = lineItem.PriceBasedTax;
    let taxInclusive = lineItem.TaxInclusive;
    let taxableAmount = 0;
    let totalTax = 0;
    let amount = 0;
    let taxComponentAmount = [];
    let retailPriceWithoutTax = 0; //TBD

    let transactionTypeForCalculation = transactionType
      ? transactionType
      : this.state.transactionType;
    if (transactionTypeForCalculation) {
      if (transactionTypeForCalculation.hasOwnProperty("CalculateTaxChecked")) {
        if (!transactionTypeForCalculation.CalculateTaxChecked) {
          chargeTaxOnProduct = false;
        } else if (transactionType) {
          chargeTaxOnProduct = true;
        }
      }
    }

    if (chargeTaxOnProduct) {
      if (priceBasedTax) {
        let unitPriceAfterDiscount = this.roundUpTo2(
          Number(subTotalWithDiscount) / Number(lineItem.Qty)
        );
        //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
        //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
        lineItem.Product.TaxPriceRange.map((t) => {
          if (
            Number(unitPriceAfterDiscount) >= Number(t.FromPrice) &&
            Number(unitPriceAfterDiscount) <= Number(t.ToPrice)
          ) {
            lineItem.TaxID = t.TaxID;
            lineItem.TaxGroup = t.TaxGroup;
            return;
          }
        });
      }
      let taxfound = false;
      let customerFromSameState = true; //TBD IMP IMP

      if (placeOfSupply !== "" && this.props.user.store.State !== "") {
        if (
          placeOfSupply.toLowerCase() !==
          this.props.user.store.State.toLowerCase()
        ) {
          customerFromSameState = false;
        }
      }
      // console.log('placeOfSupply',placeOfSupply)
      // console.log('customerFromSameState', customerFromSameState)
      this.props.config.ProductTaxes.filter(
        (t) => t.key === lineItem.TaxID.trim()
      ).map((taxGroup, index) => {
        taxfound = true;
        let taxAmount = 0;
        let taxComponentTemp = [];
        taxGroup.Taxes.map((t) => {
          if (
            customerFromSameState &&
            (t.TaxType === "CGST" || t.TaxType === "SGST")
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxPerAmt: t.TaxPerAmt ? t.TaxPerAmt : "",
              TaxAmount: 0,
            });
          } else if (!customerFromSameState && t.TaxType === "IGST") {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxPerAmt: t.TaxPerAmt ? t.TaxPerAmt : "",
              TaxAmount: 0,
            });
          } else if (
            t.TaxType !== "CGST" &&
            t.TaxType !== "IGST" &&
            t.TaxType !== "SGST"
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxPerAmt: t.TaxPerAmt ? t.TaxPerAmt : "",
              TaxAmount: 0,
            });
          }
        });

        if (taxInclusive) {
          //tax inclusive
          let fixedTaxAmt = 0;
          let totalTaxRate = 0;
          let perTaxAmt = 0;

          taxComponentTemp.map((t) => {
            if (t.TaxPerAmt && t.TaxPerAmt === "amt") {
              let tmpFixedTaxAmt = this.roundUpTo2(
                Number(t.TaxPercentage) * Number(lineItem.Qty)
              );
              fixedTaxAmt = Number(fixedTaxAmt) + Number(tmpFixedTaxAmt);
            } else {
              totalTaxRate = this.roundUpTo2(
                Number(t.TaxPercentage) + Number(totalTaxRate)
              );
            }
          });
          let subTotalWithDiscountWithoutFixedTax =
            Number(subTotalWithDiscount) - fixedTaxAmt;
          perTaxAmt = this.roundUpTo2(
            (Number(subTotalWithDiscountWithoutFixedTax) *
              Number(totalTaxRate)) /
              (100 + Number(totalTaxRate))
          );
          totalTax = Number(perTaxAmt) + Number(fixedTaxAmt);
          taxableAmount = this.roundUpTo2(
            Number(subTotalWithDiscount) - Number(totalTax)
          );
          amount = this.roundUpTo2(subTotalWithDiscount);
          taxComponentTemp.map((t) => {
            if (t.TaxPerAmt && t.TaxPerAmt === "amt") {
              taxAmount = this.roundUpTo2(
                Number(t.TaxPercentage) * Number(lineItem.Qty)
              );
            } else {
              taxAmount = this.roundUpTo2(
                (Number(perTaxAmt) *
                  ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /
                  100
              );
            }
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
              TaxPerAmt: t.TaxPerAmt ? t.TaxPerAmt : "",
            });
          });
        } else {
          taxableAmount = subTotalWithDiscount;
          taxComponentTemp.map((t) => {
            if (t.TaxPerAmt && t.TaxPerAmt === "amt") {
              taxAmount = this.roundUpTo2(
                Number(t.TaxPercentage) * Number(lineItem.Qty)
              );
            } else {
              taxAmount = this.roundUpTo2(
                (Number(taxableAmount) * Number(t.TaxPercentage)) / 100
              );
            }
            totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount));
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
              TaxPerAmt: t.TaxPerAmt ? t.TaxPerAmt : "",
            });
          });
          //console.log('totalTax',totalTax)
          amount = this.roundUpTo2(taxableAmount + totalTax);
        }
      });
      //tax is not found then
      if (!taxfound) {
        //alert("Tax not found for selected product. Please check item ")
        taxableAmount = 0;
        taxComponentAmount = [];
        totalTax = 0;
        amount = subTotalWithDiscount;
      }
    } else {
      taxableAmount = 0;
      taxComponentAmount = [];
      totalTax = 0;
      amount = subTotalWithDiscount;
    }

    // lineItem.Qty = qty
    // lineItem.RetailPrice = price
    lineItem.SubTotal = this.roundUpTo2(subTotal);
    lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
    lineItem.TaxInclusive = taxInclusive;
    lineItem.DiscountPer = this.roundUpTo2(discountPer);
    lineItem.DiscountAmount = this.roundUpTo2(discountAmt);
    lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount);
    lineItem.TaxableAmount = this.roundUpTo2(taxableAmount);
    lineItem.TaxComponentAmount = taxComponentAmount;
    lineItem.TotalTax = this.roundUpTo2(totalTax);
    lineItem.Amount = this.roundUpTo2(amount);
    return lineItem;
  }
  //line Item selected events -- END

  async onParkSaleClicked() {
    if (this.state.lineItems.length === 0) {
      this.setState({
        showError: true,
        noLineItemExistError: "No line item",
      });
      return;
    }
    let sale = {
      TransactionType: this.state.transactionType,
      Customer: this.state.selectedCustomer,
      RegistrationID: this.props.user.user.RegistrationID,
      CashRegister: this.props.user.cashRegister,
      Store: this.props.user.store,
      LineItems: this.state.lineItems,
      BillQty: this.state.billQty,
      BillSubTotal: this.state.billSubTotal,
      BillDiscount: this.state.billDiscount,
      BillTaxAmount: this.state.billTaxAmount,
      BillAmount: this.state.billAmount,
      BillBalanceAmount: 0,
      BillPaidAmount: 0,
      BillChangeDue: 0,
      ParentTransactionNo: this.state.parentTransactionNo,
      ParentTransactionKey: this.state.parentTransactionKey,
      ParentTransactionModule: this.state.parentTransactionModule,
      Employee: this.state.transactionLevelEmployeeSelected,
      Notes: this.state.notes,
      TaxSummaryList: this.state.taxSummaryList,
      BillingAddress: this.state.selectedCustomerAddress,
      PetDetails: this.state.selectedCustomerPet,
      PlaceOfSupply: this.state.placeOfSupply,
      ReceivedPaymentAmount: this.state.receivedPaymentAmount,
      ReceivedPaymentTransactions: this.state.receivedPaymentTransactions,
      IsConvert: this.state.isConvert,
      ConvertedFromTransactionBalanceAmount: this.state
        .convertedFromTransactionBalanceAmount,
      ApplicableMembership: this.state.applicableMembership,
      ApplyMembershipDiscount: this.state.applyMembershipDiscount,
    };

    await this.props.saveDraftTransaction(sale, "Sale", "");
    //get parked sale count
    // let parkedSales = []
    // parkedSales = this.props.sales.DraftSales
    // parkedSales.push(sale)
    // this.props.parkSales(parkedSales)
    await this.getParkedSales();
    this.onDiscardClicked();
  }

  async getParkedSales() {
    let draftsTemp = await this.props.getDraftTransactions("Sale");
    if (this.props.user.cashRegister) {
      let drafts = draftsTemp.filter(
        (f) =>
          f.Transaction.CashRegister.key === this.props.user.cashRegister.key &&
          f.Transaction.TransactionType.TransactionType ===
            this.state.transactionType.TransactionType
      );
      this.setState({ drafts: drafts });
    }
  }

  async showParkedSales() {
    let draftsTemp = await this.props.getDraftTransactions("Sale");
    let drafts = draftsTemp.filter(
      (f) => f.Transaction.CashRegister.key === this.props.user.cashRegister.key
    );
    // console.log('showParkedSales drafts', drafts)
    this.setState({ showParkedSalesModal: true, drafts: drafts });
  }

  async onRetriveParkedSales(draftSale, key) {
    //remove selected parked entry from redux store
    console.log("draftSale", draftSale);
    this.setState({
      type: actionTypes.SALE_NEW,
      transactionType: draftSale.TransactionType,
      selectedCustomer: draftSale.Customer,
      lineItems: draftSale.LineItems,
      billQty: draftSale.BillQty,
      billSubTotal: draftSale.BillSubTotal,
      billDiscount: draftSale.BillDiscount,
      billTaxAmount: draftSale.BillTaxAmount,
      billAmount: draftSale.BillAmount,
      parentTransactionNo: draftSale.ParentTransactionNo,
      parentTransactionKey: draftSale.ParentTransactionKey,
      notes: draftSale.Notes,
      taxSummaryList: draftSale.TaxSummaryList,
      selectedCustomerAddress: draftSale.BillingAddress,
      selectedCustomerPet: draftSale.hasOwnProperty("PetDetails")
        ? draftSale.PetDetails
        : null,
      transactionLevelEmployeeSelected: draftSale.Employee,
      showParkedSalesModal: false,
      receivedPaymentAmount: draftSale.hasOwnProperty("ReceivedPaymentAmount")
        ? draftSale.ReceivedPaymentAmount
        : 0,
      receivedPaymentTransactions: draftSale.hasOwnProperty(
        "ReceivedPaymentTransactions"
      )
        ? draftSale.ReceivedPaymentTransactions
        : [],
      isConvert: draftSale.hasOwnProperty("IsConvert")
        ? draftSale.IsConvert
        : false,
      convertedFromTransactionBalanceAmount: draftSale.hasOwnProperty(
        "ConvertedFromTransactionBalanceAmount"
      )
        ? draftSale.ConvertedFromTransactionBalanceAmount
        : 0,
      applicableMembership: draftSale.ApplicableMembership,
      applyMembershipDiscount: draftSale.hasOwnProperty(
        "ApplyMembershipDiscount"
      )
        ? draftSale.ApplyMembershipDiscount
        : false,
      deliveryCustomer: draftSale.hasOwnProperty("DeliveryCustomer")
        ? draftSale.DeliveryCustomer
        : null,
      deliveryCustomerAddress: draftSale.hasOwnProperty(
        "DeliveryCustomerAddress"
      )
        ? draftSale.DeliveryCustomerAddress
        : "",
      deliveryDate: draftSale.hasOwnProperty("DeliveryDate")
        ? draftSale.DeliveryDate
        : null,
      deliveryTime: draftSale.hasOwnProperty("DeliveryTime")
        ? draftSale.DeliveryTime
        : "",
      deliveryNotes: draftSale.hasOwnProperty("DeliveryNotes")
        ? draftSale.DeliveryNotes
        : "",
      placeOfSupply: draftSale.hasOwnProperty("PlaceOfSupply")
        ? draftSale.PlaceOfSupply
        : "",
      roundOff: draftSale.hasOwnProperty("RoundOff") ? draftSale.RoundOff : 0,
      customField1: draftSale.hasOwnProperty("CustomField1")
        ? draftSale.CustomField1
        : "",
      customField2: draftSale.hasOwnProperty("CustomField2")
        ? draftSale.CustomField2
        : "",
      customField3: draftSale.hasOwnProperty("CustomField3")
        ? draftSale.CustomField3
        : "",
      customField4: draftSale.hasOwnProperty("CustomField4")
        ? draftSale.CustomField4
        : "",
      customField5: draftSale.hasOwnProperty("CustomField5")
        ? draftSale.CustomField5
        : "",
      customField6: draftSale.hasOwnProperty("CustomField6")
        ? draftSale.CustomField6
        : "",
      customField7: draftSale.hasOwnProperty("CustomField7")
        ? draftSale.CustomField7
        : "",
      customField8: draftSale.hasOwnProperty("CustomField8")
        ? draftSale.CustomField8
        : "",
      customField9: draftSale.hasOwnProperty("CustomField9")
        ? draftSale.CustomField9
        : "",
      customField10: draftSale.hasOwnProperty("CustomField10")
        ? draftSale.CustomField10
        : "",
      customField11: draftSale.hasOwnProperty("CustomField10")
        ? draftSale.CustomField11
        : "",
      customField12: draftSale.hasOwnProperty("CustomField12")
        ? draftSale.CustomField12
        : "",
      customField13: draftSale.hasOwnProperty("CustomField13")
        ? draftSale.CustomField13
        : "",
      customField14: draftSale.hasOwnProperty("CustomField14")
        ? draftSale.CustomField14
        : "",
      customField15: draftSale.hasOwnProperty("CustomField15")
        ? draftSale.CustomField15
        : "",
      customField16: draftSale.hasOwnProperty("CustomField16")
        ? draftSale.CustomField16
        : "",
      customField17: draftSale.hasOwnProperty("CustomField17")
        ? draftSale.CustomField17
        : "",
      customField18: draftSale.hasOwnProperty("CustomField18")
        ? draftSale.CustomField18
        : "",
      customField19: draftSale.hasOwnProperty("CustomField19")
        ? draftSale.CustomField19
        : "",
      customField20: draftSale.hasOwnProperty("CustomField20")
        ? draftSale.CustomField20
        : "",
    });
    await this.props.deleteDraftTransaction(key);
    // let draftSales = this.props.sales.DraftSales
    // draftSales.splice(index, 1)
    // this.props.parkSales(draftSales)
    await this.getParkedSales();
  }

  onNotesOKClicked() {
    this.setState({ showNotesModal: false, notes: this.state.tempNotes });
  }

  onLineItemUOMChange(uom) {
    let lineItem = this.state.selectedLineItem;
    if (lineItem.Product.SalesUOM !== uom) {
      ///we need to change retail price
      let retailPrice = lineItem.Product.RetailPrice;
      let retailPriceAsPerPrimaryUOM = 0;
      let primaryUOM = lineItem.Product.UOM; //kg
      let salesUOM = lineItem.Product.SalesUOM;
      let salesUOMConversion = 1;
      if (salesUOM !== primaryUOM) {
        salesUOMConversion = lineItem.Product.SecondaryUOMs.filter(
          (f) => f.SecondaryUOMValue === salesUOM
        )[0].Conversion;
      }
      retailPriceAsPerPrimaryUOM = retailPrice / Number(salesUOMConversion);
      let selectedUOMConversion = 1;
      if (uom !== primaryUOM) {
        selectedUOMConversion = lineItem.Product.SecondaryUOMs.filter(
          (f) => f.SecondaryUOMValue === uom
        )[0].Conversion;
      }
      let newRetailPrice = retailPriceAsPerPrimaryUOM * selectedUOMConversion;
      lineItem.RetailPrice = newRetailPrice;
    } else {
      lineItem.RetailPrice = lineItem.Product.RetailPrice;
    }
    lineItem.UOM = uom;
    this.setState({ selectedLineItem: lineItem });
    this.onLineItemPriceChange(lineItem.RetailPrice);
  }

  async onPaymentClick() {
    let customerError = "";
    let lineItemExceedError = "";
    let employeeMandatoryAtTransactionError = "";
    let employeeMandatoryAtLineItemError = "";
    let noLineItemExistError = "";
    let storeError = "";
    let fromGSTINError = "";
    let toGSTINError = "";
    let fromGSTIN = "";
    let toGSTIN = "";
    let batchIDMissingError = "";
    if (this.state.transactionType.CustomerMandatoryChecked) {
      if (!this.state.selectedCustomer) {
        customerError = "Please select customer";
      }
    }
    if (this.state.transactionType.EmployeeMandatoryChecked) {
      if (
        this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel.toLowerCase() ===
        "transaction"
      ) {
        if (!this.state.transactionLevelEmployeeSelected) {
          employeeMandatoryAtTransactionError = "Please select employee";
        }
      } else {
        lineItems = [];
        this.state.lineItems.map((lineItem) => {
          if (lineItem.Employee.key === "") {
            employeeMandatoryAtLineItemError =
              "Employee not selected at line item level";
          }
        });
      }
    }
    if (!this.state.selectedCustomer) {
      //check if any trnasction is of type wallet recharge
      if (
        this.state.lineItems.filter(
          (lineItem) => lineItem.Product.ProductTypeID === 4
        ).length > 0
      ) {
        customerError = "Please select customer";
      }
      //check if ransaction is of type package
      if (
        this.state.lineItems.filter(
          (lineItem) => lineItem.Product.ProductTypeID === 5
        ).length > 0
      ) {
        customerError = "Please select customer";
      }
      if (
        this.state.lineItems.filter(
          (lineItem) =>
            lineItem.Product.hasOwnProperty("MembershipID") &&
            lineItem.Product.MembershipID.trim() !== ""
        ).length > 0
      ) {
        customerError = "Please select customer";
      }
    }
    if (this.state.lineItems.length === 0) {
      noLineItemExistError = "No line item";
    }
    // console.log('this.state.eWayBill',this.state.eWayBill)
    if (this.state.eWayBill) {
      // console.log('Ewaybill called')
      if (this.state.eWayBill.GenerateEWayBill) {
        //get store and customer gstin
        let customer = this.state.deliveryCustomer
          ? this.state.deliveryCustomer
          : this.state.selectedCustomer;
        this.props.config.CustomFields.map((m) => {
          if (m.ModuleName === "Store") {
            if (m.CustomField1Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField1")
                ? this.props.user.store.CustomField1
                : "";
            }
            if (m.CustomField2Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField2")
                ? this.props.user.store.CustomField2
                : "";
            }
            if (m.CustomField3Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField3")
                ? this.props.user.store.CustomField3
                : "";
            }
            if (m.CustomField4Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField4")
                ? this.props.user.store.CustomField4
                : "";
            }
            if (m.CustomField5Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField5")
                ? this.props.user.store.CustomField5
                : "";
            }
            if (m.CustomField6Name.trim().toLowerCase() === "gstin") {
              fromGSTIN = this.props.user.store.hasOwnProperty("CustomField6")
                ? this.props.user.store.CustomField6
                : "";
            }
          }
          if (m.ModuleName === "Customer" && customer) {
            if (m.CustomField1Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField1")
                ? customer.CustomField1
                : "";
            }
            if (m.CustomField2Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField2")
                ? customer.CustomField2
                : "";
            }
            if (m.CustomField3Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField3")
                ? customer.CustomField3
                : "";
            }
            if (m.CustomField4Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField4")
                ? customer.CustomField4
                : "";
            }
            if (m.CustomField5Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField5")
                ? customer.CustomField5
                : "";
            }
            if (m.CustomField6Name.trim().toLowerCase() === "gstin") {
              toGSTIN = customer.hasOwnProperty("CustomField6")
                ? customer.CustomField6
                : "";
            }
          }
        });
        if (fromGSTIN === "") {
          fromGSTINError = "Store GSTIN missing";
        }
        if (toGSTIN === "") {
          toGSTINError = "Customer GSTIN missing";
        }

        if (!this.state.selectedCustomer && !this.state.deliveryCustomer) {
          customerError = "Please select customer";
        } else if (this.state.deliveryCustomer) {
          if (!this.state.deliveryCustomerAddress) {
            customerError = "Customer delivery address missing";
          } else {
            if (
              !this.state.deliveryCustomerAddress.hasOwnProperty("StateCode")
            ) {
              customerError = "State code missing in customer delivery address";
            } else if (
              this.state.deliveryCustomerAddress.hasOwnProperty("StateCode") &&
              this.state.deliveryCustomerAddress.StateCode.trim() === ""
            ) {
              customerError = "State code missing in customer delivery address";
            }
          }
        } else if (this.state.selectedCustomer) {
          if (!this.state.selectedCustomerAddress) {
            customerError = "Customer address missing";
          } else {
            if (
              !this.state.selectedCustomerAddress.hasOwnProperty("StateCode")
            ) {
              customerError = "State code missing in customer address";
            } else if (
              this.state.selectedCustomerAddress.hasOwnProperty("StateCode") &&
              this.state.selectedCustomerAddress.StateCode.trim() === ""
            ) {
              customerError = "State code missing in customer address";
            }
          }
        }
      }
      //store checking
      if (!this.props.user.store.hasOwnProperty("StateCode")) {
        storeError = "Store should have state code";
      }
      if (
        this.props.user.store.hasOwnProperty("StateCode") &&
        this.props.user.store.StateCode.trim() === ""
      ) {
        storeError = "Store should have state code";
      }
    }

    if (
      this.props.registration.registration.key === "sgoJAOfvUrFfuQ0ICBm2" &&
      this.state.lineItems.length > 99
    ) {
      this.setState({
        showError: true,
        lineItemExceedError: "LineItems cannot be more than 100.",
      });
      return;
    }
    //check for excise qty validtion
    if (
      this.props.registration.registration &&
      this.props.registration.registration.EnableRE === true
    ) {
      let countryLiquorCount = 0;
      let wineCount = 0;
      let IMFLCount = 0;
      let importedForeignLiquorCount = 0;
      let beerCount = 0;

      for (let i = 0; i < this.state.lineItems.length; i++) {
        let product = this.state.lineItems[i].Product;
        if (
          product.hasOwnProperty("RE_Group") &&
          product.RE_Group !== "" &&
          product.hasOwnProperty("RE_QTY_IN_BTL") &&
          product.RE_QTY_IN_BTL !== ""
        ) {
          if (product.RE_Group.toLowerCase() === "country liquor") {
            countryLiquorCount =
              Number(countryLiquorCount) + Number(this.state.lineItems[i].Qty);
          } else if (product.RE_Group.toLowerCase() === "beer") {
            beerCount = Number(beerCount) + Number(this.state.lineItems[i].Qty);
          } else if (product.RE_Group.toLowerCase() === "imfl") {
            IMFLCount = Number(IMFLCount) + Number(this.state.lineItems[i].Qty);
          } else if (product.RE_Group.toLowerCase() === "wine") {
            wineCount = Number(wineCount) + Number(this.state.lineItems[i].Qty);
          } else if (
            product.RE_Group.toLowerCase() === "imported foreign liquor"
          ) {
            importedForeignLiquorCount =
              Number(importedForeignLiquorCount) +
              Number(this.state.lineItems[i].Qty);
          }
        }
      }
      //             1. 1.	Country Liquor : 3 Bulk Liters
      // 2. 2.	IMFL : 9 Bulk Liters
      // 3. 3.	Imported Foreign Liquor : 9 Bulk Liters - category bio/
      // 4. 4.	Beer : 15 Bulk Liters
      // 5. 5.	Wine : 12 Bulk Liters
      if (countryLiquorCount > 3) {
        alert("Country Liquor can not be more than 3 liters per invoice");
        return;
      }
      if (IMFLCount > 9) {
        alert("IMFL can not be more than 9 liters per invoice");
        return;
      }
      if (importedForeignLiquorCount > 9) {
        alert("Foreign Liquor can not be more than 9 liters per invoice");
        return;
      }
      if (beerCount > 15) {
        alert("Beer can not be more than 15 liters per invoice");
        return;
      }
      if (wineCount > 15) {
        alert("Wine can not be more than 15 liters per invoice");
        return;
      }
    }

    //check if batch id is missing
    this.state.lineItems.map((lineItem) => {
      let showBatchsForSelection = false;
      if (
        lineItem.Product.hasOwnProperty("InventoryType") &&
        lineItem.Product.InventoryType === "Batch Tracking"
      ) {
        if (!lineItem.hasOwnProperty("BatchID")) {
          batchIDMissingError = "Please select batch";
        } else if (
          lineItem.hasOwnProperty("BatchID") &&
          lineItem.BatchID === ""
        ) {
          batchIDMissingError = "Please select batch";
        }
      }
    });

    //check if line
    if (
      customerError !== "" ||
      employeeMandatoryAtTransactionError !== "" ||
      employeeMandatoryAtLineItemError !== "" ||
      noLineItemExistError !== "" ||
      storeError !== "" ||
      fromGSTINError !== "" ||
      toGSTINError !== "" ||
      lineItemExceedError !== "" ||
      batchIDMissingError !== ""
    ) {
      this.setState({
        showError: true,
        fromGSTINError: fromGSTINError,
        toGSTINError: toGSTINError,
        customerError: customerError,
        storeError: storeError,
        employeeMandatoryAtTransactionError: employeeMandatoryAtTransactionError,
        employeeMandatoryAtLineItemError: employeeMandatoryAtLineItemError,
        noLineItemExistError: noLineItemExistError,
        lineItemExceedError: lineItemExceedError,
        batchIDMissingError: batchIDMissingError,
      });
      return;
    }
    //now check for negative billing qty
    let lineItems = this.state.lineItems;
    let employee = {};
    let inStockQtyNegativeErrors = [];
    for (let h = 0; h < lineItems.length; h++) {
      let allow = await this.checkIfBillingAllowed(
        lineItems[h].Product,
        lineItems[h],
        false
      );
      if (!allow) {
        inStockQtyNegativeErrors.push({
          Error: lineItems[h].Product.Name,
        });
      }
      if (lineItems[h].Product.InventoryType === "Batch Tracking") {
        if (lineItems[h].BatchID === "") {
        }
      }
    }
    if (inStockQtyNegativeErrors.length > 0) {
      this.setState({
        showError: true,
        inStockQtyNegativeErrors: inStockQtyNegativeErrors,
        inStockQtyNegativeError:
          "Following product(s) can not be selected for billing as in stock quantity is less than zero.",
      });
      return;
    } else {
      this.setState({
        showError: false,
        inStockQtyNegativeErrors: inStockQtyNegativeErrors,
        inStockQtyNegativeError: "",
      });
    }

    //console.log('this.state.transactionLevelEmployeeSelected', this.state.transactionLevelEmployeeSelected)
    if (this.state.transactionLevelEmployeeSelected) {
      if (this.state.transactionLevelEmployeeSelected.hasOwnProperty("key")) {
        employee = {
          key: this.state.transactionLevelEmployeeSelected.key,
          EmployeeName:
            this.state.transactionLevelEmployeeSelected.EmployeeFirstName +
            " " +
            this.state.transactionLevelEmployeeSelected.EmployeeLastName,
          EmployeeFirstName: this.state.transactionLevelEmployeeSelected
            .EmployeeFirstName,
          EmployeeLastName: this.state.transactionLevelEmployeeSelected
            .EmployeeLastName,
          EmployeeCode: this.state.transactionLevelEmployeeSelected
            .EmployeeCode,
        };
        lineItems.map((l) => {
          l.Employee = employee;
        });
      }
    }
    let billingAddress = this.state.selectedCustomerAddress;
    let petDetails = this.state.selectedCustomerPet;

    let sale = {
      SalesChannel: this.state.selectedSalesChannel,
      SalesChannelName: this.state.selectedSalesChannelName,
      TransactionType: this.state.transactionType,
      Customer: this.state.selectedCustomer,
      RegistrationID: this.props.user.user.RegistrationID,
      CashRegister: this.props.user.cashRegister,
      Store: this.props.user.store,
      LineItems: lineItems,
      BillQty: this.state.billQty,
      BillSubTotal: this.state.billSubTotal,
      BillDiscount: this.state.billDiscount,
      BillTaxAmount: this.state.billTaxAmount,
      BillAmount: this.state.billAmount,
      RoundOff: this.state.roundOff,
      BillBalanceAmount: 0,
      BillPaidAmount: 0,
      BillChangeDue: 0,
      Payments: null,
      ParentTransactionNo: this.state.parentTransactionNo,
      ParentTransactionKey: this.state.parentTransactionKey,
      ParentTransactionModule: this.state.parentTransactionModule,
      Employee: employee,
      Notes: this.state.notes,
      TaxSummaryList: this.state.taxSummaryList,
      PlaceOfSupply: this.state.placeOfSupply,
      BillingAddress: billingAddress,
      DeliveryCustomer: this.state.deliveryCustomer,
      DeliveryCustomerAddress: this.state.deliveryCustomerAddress,
      DeliveryDate: this.state.deliveryDate,
      DeliveryTime: this.state.deliveryTime,
      DeliveryNotes: this.state.deliveryNotes,
      BackEndUpdate: false,
      ReceivedPaymentAmount: this.state.receivedPaymentAmount,
      ReceivedPaymentTransactions: this.state.receivedPaymentTransactions,
      IsConvert: this.state.isConvert,
      ConvertedFromTransactionBalanceAmount: this.state
        .convertedFromTransactionBalanceAmount,
      CustomField1: this.state.customField1,
      CustomField2: this.state.customField2,
      CustomField3: this.state.customField3,
      CustomField4: this.state.customField4,
      CustomField5: this.state.customField5,
      CustomField6: this.state.customField6,
      CustomField7: this.state.customField7,
      CustomField8: this.state.customField8,
      CustomField9: this.state.customField9,
      CustomField10: this.state.customField10,
      CustomField11: this.state.customField11,
      CustomField12: this.state.customField12,
      CustomField13: this.state.customField13,
      CustomField14: this.state.customField14,
      CustomField15: this.state.customField15,
      CustomField16: this.state.customField16,
      CustomField17: this.state.customField17,
      CustomField18: this.state.customField18,
      CustomField19: this.state.customField19,
      CustomField20: this.state.customField20,
      EWayBill: this.state.eWayBill,
      Table: this.state.table,
      TableOrder: this.state.tableOrder,
      PetDetails: petDetails,
    };
    if (sale.EWayBill) {
      sale.EWayBill.FromGSTIN = fromGSTIN;
      sale.EWayBill.ToGSTIN = toGSTIN;
    }
    // if (this.props.registration.registration.KOT) {
    sale.KOTType = this.state.KOTType;
    // }
    if (this.state.enableTCS) {
      sale.TCSAmount = this.state.TCSAmount;
      sale.TCSAmountAppliedOn = this.state.TCSAmountAppliedOn;
      sale.TCSPercentage = this.state.TCSPercentage;
    }
    if (this.state.type === actionTypes.SALE_NEW) {
      let transactionNo = "";
      this.state.transactionType.NumberingFormat.map((parameters) => {
        let para = "";
        if (parameters.Option === "text") {
          para = parameters.Value;
        } else if (parameters.Option == "storecode") {
          para = this.props.user.store.StoreCode;
        } else if (parameters.Option == "locationcode") {
          para = this.props.user.cashRegister.LocationCode;
        } else if (parameters.Option == "cashregistercode") {
          para = this.props.user.cashRegister.CashRegisterCode;
        } else if (parameters.Option == "calendardmonthyear") {
          let d = new Date();
          let a = d.getFullYear() + "";
          para = d.getMonth() + 1 + a.substring(2, 4);
          if (para.length < 3) {
            para = "0" + para;
          }
        } else if (parameters.Option === "fiscalyear") {
          let d = new Date();
          para = d.getFullYear() + "";
        } else if (parameters.Option === "calendarmonth") {
          let d = new Date();
          let a = d.getMonth() + 1 + "";
          if (a.length < 2) {
            para = "0" + a;
          } else {
            para = a;
          }
        } else if (parameters.Option === "calendaryear") {
          let d = new Date();
          para = d.getFullYear() + "";
        }
        transactionNo = transactionNo + para + parameters.Separator;
      });
      sale.TransactionNo = transactionNo;
      if (this.state.deliveryCustomer) {
        sale.DeliveryStatus = "Pending";
      }
    } else {
      sale.TransactionNo = this.state.editTransactionNo;
      sale.TransactionDate = this.state.editTransactionDate;
      sale.Store = this.state.editStore;
    }
    console.log("sales.js - sale", sale);
    //console.log('this.state.key' ,this.state.key)
    // this.props.callEventHook("onPaymentClick", sale)
    this.setState({ sale: sale, showPayment: true, showInvoice: false });
    // showInvoice: false
  }

  onDeliveryClicked() {
    let deliveryCustomer = null;
    let deliveryCustomerAddress = null;
    if (!this.state.deliveryCustomer && this.state.selectedCustomer) {
      deliveryCustomer = this.state.selectedCustomer;
      if (this.state.selectedCustomerAddress) {
        deliveryCustomerAddress = this.state.selectedCustomerAddress;
      }
    } else {
      deliveryCustomer = this.state.deliveryCustomer;
      deliveryCustomerAddress = this.state.deliveryCustomerAddress;
    }
    this.setState({
      showDelivery: true,
      deliveryCustomer: deliveryCustomer,
      deliveryCustomerAddress: deliveryCustomerAddress,
      tmpDeliveryCustomer: deliveryCustomer,
    });
  }

  onSave() {
    // console.log("onSave", this.state.module.toLowerCase());
    if (this.state.tableOrder) {
      this.props.history.push("/home/tableorder");
    } else if (this.state.module.toLowerCase() === "deliverylist") {
      this.props.history.push({
        pathname: "/home/sales/deliverylist/",
      });
    } else {
      this.props.history.push({
        pathname: "/home/sales/sales/",
        state: {
          transactionType: this.state.transactionType,
          type: actionTypes.SALE_NEW,
        },
      });
    }
    return;
  }

  onDiscountClicked() {
    if (this.state.lineItems.length === 0) {
      this.setState({
        showError: true,
        noLineItemExistError: "No line item",
      });
      return;
    }
    this.setState({ showDiscountModal: true });
  }

  shortcutProductSearch(event) {
    event.preventDefault();
    if (!this.state.toggleSearch) {
      this.refs.refProductSearch.getInstance().focus(); //13feb
    } else {
      this.setState({ toggleSearch: false });
    }
  }

  shortcutProductScan(event) {
    event.preventDefault();
    if (this.state.toggleSearch) {
      this.refs.refProductScan.focus(); //13feb
    } else {
      this.setState({ toggleSearch: true });
    }
  }

  shortcutSearchCustomer(event) {
    event.preventDefault();
    this.refs.refSearchCustomer.refs.refSearchCustomerInput
      .getInstance()
      .focus(); //13feb
  }

  shortcutNewCustomer(event) {
    event.preventDefault();
    this.setState({ showAddCustomer: true, showInvoice: false });
  }

  shortcutPay(event) {
    event.preventDefault();
    this.onPaymentClick();
  }

  shortcutPaymentOption1() {
    this.onPaymentClick();
  }

  shortcutPaymentOption2() {
    this.onPaymentClick();
  }

  shortcutPaymentOption3() {
    this.onPaymentClick();
  }

  shortcutDiscount() {
    this.onDiscountClicked();
  }

  shortcutHold() {
    this.onParkSaleClicked();
  }

  // async onEnteringCashRegisterOpeningBalance(openingBalance) {
  //     let cashRegisterClosing = {
  //         CashRegisterID: this.state.selectedCashRegister.key,
  //         CashRegisterCode: this.state.selectedCashRegister.CashRegisterCode,
  //         CashRegisterName: this.state.selectedCashRegister.CashRegisterName,
  //         StoreID: this.props.user.store.key,
  //         StoreCode: this.props.user.store.StoreCode,
  //         StoreName: this.props.user.store.StoreName,
  //         RegistrationID: this.props.user.user.RegistrationID,
  //         OpeningBalance: openingBalance,
  //         OpeningTime: new Date(),
  //         OpeningByUserID: this.props.user.user.UID,
  //         OpeningByUserName: this.props.user.user.Name,
  //         Status: "Open",
  //     };
  //     let res = await this.props.openCashRegister(cashRegisterClosing);
  //     // console.log('cashRegisterClosingobj', cashRegisterClosingobj)
  //     let cashRegisterClosingobj = await this.props.getCashRegisterOpeningDetails(
  //         res.CashRegisterID
  //     );
  //     // console.log('cashRegisterClosing1', cashRegisterClosing1)

  //     if (!cashRegisterClosingobj) {
  //         alert(
  //             "System is experiencing problem opning cash register. Pls try again or contact support"
  //         );
  //         return;
  //     }
  //     this.props.setCashRegister(this.state.selectedCashRegister);
  //     this.props.setCashRegisterClosing(cashRegisterClosingobj);
  //     this.setState({
  //         showCashRegisterOpening: false,
  //         showCashRegisterSelection: false,
  //         cashRegisterClosing: cashRegisterClosingobj,
  //     });
  // }

  // async onCashRegisterSelection(selectedCashRegister) {
  //     //check if cash regiter closing enabled
  //     let cashRegisterClosingEnbaled = false;
  //     if (this.props.user.store.hasOwnProperty("CashRegisterClosing")) {
  //         cashRegisterClosingEnbaled = this.props.user.store.CashRegisterClosing;
  //     }
  //     if (cashRegisterClosingEnbaled) {
  //         //check if cash register is already opened, if not then ask for opening balance
  //         let cashRegisterClosing = await this.props.getCashRegisterOpeningDetails(selectedCashRegister.key);
  //         //    console.log('cashRegisterClosing',cashRegisterClosing)
  //         if (cashRegisterClosing) {
  //             //open cash register found
  //             this.props.setCashRegisterClosing(cashRegisterClosing);
  //             this.props.setCashRegister(selectedCashRegister);
  //             this.setState({
  //                 showCashRegisterOpening: false,
  //                 showCashRegisterSelection: false,
  //                 cashRegisterClosing: cashRegisterClosing,
  //             });
  //         } else {
  //             //show option to enter opening balance
  //             this.setState({
  //                 selectedCashRegister: selectedCashRegister,
  //                 showCashRegisterOpening: true,
  //                 showCashRegisterSelection: false,
  //                 cashRegisterClosing: null,
  //             });
  //         }
  //     } else {
  //         this.props.setCashRegister(selectedCashRegister);
  //         this.setState({
  //             showCashRegisterOpening: false,
  //             showCashRegisterSelection: false,
  //         });
  //     }
  // }

  async onApplyRemoveMembershipDiscount(
    applyMembershipDiscount,
    applicableMembership
  ) {
    // console.log('applicableMembership',applicableMembership)
    let overallMembershipDiscount = false;
    if (applicableMembership.hasOwnProperty("DiscountApplied")) {
      overallMembershipDiscount = applicableMembership.DiscountApplied;
    }
    let lineItems = [];
    this.state.lineItems.map((l) => {
      let lineItem = l;
      // console.log('onApplyRemoveMembershipDiscount - lineItem',lineItem)
      this.applyRemoveMembershipDiscountAtLineItem(
        lineItem,
        applyMembershipDiscount,
        overallMembershipDiscount,
        applicableMembership
      );
      lineItems.push(lineItem);
    });

    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    if (applyMembershipDiscount) {
      this.setState({
        lineItems: lineItems,
        applyMembershipDiscount: applyMembershipDiscount,
        showDiscountMembership: true,
        showDiscountTransaction: false,
        showDiscountOffers: false,
        showDiscountCoupon: false,
        discountOptionSelected: "membership",
      });
    } else {
      this.setState({
        lineItems: lineItems,
        applyMembershipDiscount: applyMembershipDiscount,
        showDiscountMembership: true,
        showDiscountTransaction: true,
        showDiscountOffers: true,
        showDiscountCoupon: true,
        discountOptionSelected: "transaction",
      });
      await this.applyOffer(); //pawan
    }
  }

  applyRemoveMembershipDiscountAtLineItem(
    lineItem,
    applyMembershipDiscount,
    overallMembershipDiscount,
    applicableMembership
  ) {
    let processLineItem = true;
    //pawan
    if (overallMembershipDiscount && processLineItem) {
      lineItem.DiscountPer = Number(
        applyMembershipDiscount ? applicableMembership.Discount : 0
      );
      lineItem.DiscountAmount = 0;
      if (applyMembershipDiscount) {
        lineItem = this.onLineItemChange(
          -2,
          lineItem,
          this.state.placeOfSupply,
          "Membership",
          applicableMembership.key,
          applicableMembership.Name,
          true
        );
      } else {
        lineItem.Discounts = [];
        lineItem = this.onLineItemChange(
          -2,
          lineItem,
          this.state.placeOfSupply,
          "",
          "",
          "",
          true
        );
      }
    } else if (processLineItem) {
      if (applyMembershipDiscount) {
        applicableMembership.Products.filter(
          (p) => p.ProductID === lineItem.Product.key
        ).map((e) => {
          // console.log('e', e)
          let discountPercentage = e.hasOwnProperty("DiscountPercentage")
            ? Number(e.DiscountPercentage)
            : 0;
          let discountAmount = e.hasOwnProperty("DiscountAmount")
            ? Number(e.DiscountAmount)
            : 0;
          if (discountPercentage > 0) {
            // console.log('discountPercentage', discountPercentage)
            lineItem.DiscountPer = discountPercentage;
            lineItem.DiscountAmount = 0;
          } else if (discountAmount > 0) {
            // console.log('discountAmount', discountAmount)
            lineItem.DiscountPer = Number(
              (
                (Number(discountAmount) * 100) /
                Number(lineItem.RetailPrice)
              ).toFixed(3)
            );
            lineItem.DiscountAmount = 0;
          } else {
            // console.log('e.RetailPrice', e.RetailPrice)
            lineItem.RetailPrice = Number(e.RetailPrice);
          }
        });
        // console.log('lineItem',lineItem)
        lineItem = this.onLineItemChange(
          -2,
          lineItem,
          this.state.placeOfSupply,
          "Membership",
          applicableMembership.key,
          applicableMembership.Name,
          true
        );
      } else {
        lineItem.RetailPrice = lineItem.Product.RetailPrice;
        lineItem.DiscountPer = 0;
        lineItem.DiscountAmount = 0;
        lineItem.Discounts = [];
        lineItem = this.onLineItemChange(
          -2,
          lineItem,
          this.state.placeOfSupply,
          "",
          "",
          "",
          true
        );
      }
    }
  }

  async onProductBarCodeSearch(evt) {
    if (evt.key === "Enter") {
      await this.productBarCodeSearch(this.state.queryText);
      this.setState({ queryText: "" });
    }
  }

  async productBarCodeSearch(queryText) {
    if (queryText.trim() === "") {
      return true;
    }
    var productList = this.props.config.Products.filter(
      (e) =>
        (e.SKU && e.SKU.toLowerCase() === queryText.toLowerCase()) ||
        (e.BarCode && e.BarCode.toLowerCase() === queryText.toLowerCase())
    );

    let scaleWeight = 0;

    if (productList.length > 1) {
      // console.log("more tha one product for selection");
      this.setState({
        showProductSelection: true,
        showProductSelectionList: productList,
      });
      return;
    }
    if (
      productList.length === 0 &&
      this.props.registration.registration.hasOwnProperty(
        "EnableBarcodeWeightingScale"
      ) &&
      this.props.registration.registration.EnableBarcodeWeightingScale
    ) {
      var barCode = queryText.substr(
        0,
        queryText.length -
          this.props.registration.registration.NoOfCharacterForWeight
      );
      var res1 = queryText.substr(
        0,
        queryText.length -
          this.props.registration.registration.NoOfCharacterForWeight
      ).length;
      scaleWeight = queryText.substr(
        res1,
        this.props.registration.registration.NoOfCharacterForWeight
      );

      scaleWeight = Number(scaleWeight) / 1000;
      // alert(scaleWeight)
      productList = this.props.config.Products.filter(
        (e) =>
          e.SKU.toLowerCase() === barCode.toLowerCase() ||
          e.BarCode.toLowerCase() === barCode.toLowerCase()
      );
    }

    let e = productList[0];
    if (e) {
      if (e.hasOwnProperty("AccessibleToSelectedStores")) {
        if (e.AccessibleToSelectedStores) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );
          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.MRP = storePrice[0].MRP;
            e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
              ? storePrice[0].DiscountAmount
              : 0;
            e.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
          }
        }
      }
      if (e.hasOwnProperty("StoreSpecificPricing")) {
        if (e.StoreSpecificPricing) {
          let storePrice = e.StoreSpecificPrices.filter(
            (f) => f.StoreID === this.props.user.store.key
          );

          if (storePrice.length > 0) {
            e.RetailPrice = storePrice[0].RetailPrice;
            e.SupplyPrice = storePrice[0].SupplyPrice;
            e.Markup = storePrice[0].Markup;
            e.DiscountAmount = storePrice[0].hasOwnProperty("DiscountAmount")
              ? storePrice[0].DiscountAmount
              : 0;
            e.DiscountPercentage = storePrice[0].hasOwnProperty(
              "DiscountPercentage"
            )
              ? storePrice[0].DiscountPercentage
              : 0;
            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
              "WalletTopUpSameAsRetailPrice"
            )
              ? storePrice[0].WalletTopUpSameAsRetailPrice
              : false;
            e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
              "WalletTopUpAmount"
            )
              ? storePrice[0].WalletTopUpAmount
              : 0;
            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
              "EditWalletTopUpAmount"
            )
              ? storePrice[0].EditWalletTopUpAmount
              : false;
            e.MRP = storePrice[0].MRP;
          }
        }
      }
      // this.onProductSelected(e)
      this.onProductSelected(e, scaleWeight);
    } else {
      this.props
        .getInwardUniqueList(
          0,
          100,
          this.props.user.store.key,
          "",
          this.state.queryText
        )
        .then((result) => {
          if (result.searchResult && result.searchResult.length > 0) {
            let productID = result.searchResult[0].ProductID;
            let uniqueList = [];
            //check if thi product is already added
            if (
              this.state.lineItems.filter((f) => f.Product.key === productID)
                .length > 0
            ) {
              let l = this.state.lineItems.filter(
                (f) => f.Product.key === productID
              )[0];
              if (
                l.hasOwnProperty("UniqueNoList") &&
                l.UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1 === result.searchResult[0].UniqueNo1 &&
                    f.UniqueNo2 === result.searchResult[0].UniqueNo2 &&
                    f.UniqueNo3 === result.searchResult[0].UniqueNo3 &&
                    f.UniqueNo3 === result.searchResult[0].UniqueNo4
                ).length > 0
              ) {
                alert("Unique no already added to transaction");
                return;
              } else {
                //
                uniqueList = l.UniqueNoList;
              }
            }
            var productList = this.props.config.Products.filter(
              (e) => e.key === productID
            );

            var e = productList[0];

            if (e.hasOwnProperty("AccessibleToSelectedStores")) {
              if (e.AccessibleToSelectedStores) {
                let storePrice = e.StoreSpecificPrices.filter(
                  (f) => f.StoreID === this.props.user.store.key
                );
                if (storePrice.length > 0) {
                  e.RetailPrice = storePrice[0].RetailPrice;
                  e.SupplyPrice = storePrice[0].SupplyPrice;
                  e.Markup = storePrice[0].Markup;
                  e.MRP = storePrice[0].MRP;
                  e.DiscountAmount = storePrice[0].hasOwnProperty(
                    "DiscountAmount"
                  )
                    ? storePrice[0].DiscountAmount
                    : 0;
                  e.DiscountPercentage = storePrice[0].hasOwnProperty(
                    "DiscountPercentage"
                  )
                    ? storePrice[0].DiscountPercentage
                    : 0;
                  e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                    "WalletTopUpSameAsRetailPrice"
                  )
                    ? storePrice[0].WalletTopUpSameAsRetailPrice
                    : false;
                  e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "WalletTopUpAmount"
                  )
                    ? storePrice[0].WalletTopUpAmount
                    : 0;
                  e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "EditWalletTopUpAmount"
                  )
                    ? storePrice[0].EditWalletTopUpAmount
                    : false;
                }
              }
            }
            if (e.hasOwnProperty("StoreSpecificPricing")) {
              if (e.StoreSpecificPricing) {
                let storePrice = e.StoreSpecificPrices.filter(
                  (f) => f.StoreID === this.props.user.store.key
                );

                if (storePrice.length > 0) {
                  e.RetailPrice = storePrice[0].RetailPrice;
                  e.SupplyPrice = storePrice[0].SupplyPrice;
                  e.Markup = storePrice[0].Markup;
                  e.DiscountAmount = storePrice[0].hasOwnProperty(
                    "DiscountAmount"
                  )
                    ? storePrice[0].DiscountAmount
                    : 0;
                  e.DiscountPercentage = storePrice[0].hasOwnProperty(
                    "DiscountPercentage"
                  )
                    ? storePrice[0].DiscountPercentage
                    : 0;
                  e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty(
                    "WalletTopUpSameAsRetailPrice"
                  )
                    ? storePrice[0].WalletTopUpSameAsRetailPrice
                    : false;
                  e.WalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "WalletTopUpAmount"
                  )
                    ? storePrice[0].WalletTopUpAmount
                    : 0;
                  e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty(
                    "EditWalletTopUpAmount"
                  )
                    ? storePrice[0].EditWalletTopUpAmount
                    : false;
                  e.MRP = storePrice[0].MRP;
                }
              }
            }
            uniqueList.push(result.searchResult[0]);
            // this.productSelected(e, uniqueList)
            this.productSelected(e, uniqueList, null, scaleWeight);
          } else {
            this.setState({
              showAddProduct: true,
              showInvoice: false,
              scanedCode: queryText,
            });
          }
        });
    }
  }

  onShowDiscountOptions() {
    let showDiscountMembership = false;
    let showDiscountTransaction = false;
    let showDiscountOffers = false;
    let showDiscountCoupon = false;
    let discountOptionSelected = "";
    if (
      this.state.selectedCustomer &&
      this.state.selectedCustomer.Membership &&
      this.state.selectedCustomer.Membership.hasOwnProperty("MembershipID")
    ) {
      showDiscountMembership = true;
      discountOptionSelected = "membership";
    }
    if (
      !this.state.applyMembershipDiscount &&
      this.state.offersApplied.length === 0
    ) {
      showDiscountTransaction = true;
      showDiscountCoupon = true;
      discountOptionSelected =
        discountOptionSelected.trim() === ""
          ? "transaction"
          : discountOptionSelected;
    }
    if (this.state.offers.length > 0 && !this.state.applyMembershipDiscount) {
      showDiscountOffers = true;
      discountOptionSelected =
        discountOptionSelected.trim() === "" ? "offer" : discountOptionSelected;
    }
    let offersPopupTabSelected = "applied";
    if (this.state.offersApplied.length > 0) {
      offersPopupTabSelected = "applied";
    } else if (this.state.offersToApply.length > 0) {
      offersPopupTabSelected = "applicable";
    } else if (this.state.offers.length > 0) {
      offersPopupTabSelected = "all";
    }
    this.setState({
      showDiscountOptions: true,
      discountOptionSelected: discountOptionSelected,
      offersPopupTabSelected: offersPopupTabSelected,
      showDiscountMembership: showDiscountMembership,
      showDiscountTransaction: showDiscountTransaction,
      showDiscountOffers: showDiscountOffers,
      showDiscountCoupon: showDiscountCoupon,
    });
  }

  async onTransactionTypeSelected(selectedTransactionType) {
    //compute
    let lineItems = [];
    this.state.lineItems.map((l) => {
      let lineItem = this.onLineItemChange(
        -1,
        l,
        this.state.placeOfSupply,
        "",
        "",
        "",
        "",
        selectedTransactionType
      );
      lineItems.push(lineItem);
    });
    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount);
    // console.log("selectedTransactionType", selectedTransactionType);
    this.setState({
      lineItems: lineItems,
      transactionType: selectedTransactionType,
      showTransactionTypes: false,
    });
    // console.log()
    // this.onLineItemChangesDone()

    // if(this.state.transactionType.TransactionType.toLowerCase() !== selectedTransactionType.TransactionType.toLowerCase())
    // {

    // await this.calculateBillTotal(lineItems)
    // this.setState({transactionType:selectedTransactionType,
    //     lineItems:lineItems,
    //     showTransactionTypes:false})
    // }
  }

  onEWayBillClicked() {
    let tmpGenerateEWayBill = "";
    let tmpEWayBillTransporterName = "";
    let tmpEWayBillModeOfTransportCode = "";
    let tmpEWayBillModeOfTransportName = "";
    let tmpEWayBillVehicleNo = "";
    let tmpEWayBillVehicleTypeCode = "";
    let tmpEWayBillVehicleTypeName = "";
    let tmpEWayBillDistanceOfTransport = "";

    if (this.state.eWayBill) {
      tmpGenerateEWayBill = this.state.eWayBill.GenerateEWayBill;
      tmpEWayBillTransporterName = this.state.eWayBill.TransporterName;
      tmpEWayBillModeOfTransportCode = this.state.eWayBill.ModeOfTransportCode;
      tmpEWayBillModeOfTransportName = this.state.eWayBill.ModeOfTransportName;
      tmpEWayBillVehicleNo = this.state.eWayBill.VehicleNo;
      tmpEWayBillVehicleTypeCode = this.state.eWayBill.VehicleTypeCode;
      tmpEWayBillVehicleTypeName = this.state.eWayBill.VehicleTypeName;
      tmpEWayBillDistanceOfTransport = this.state.eWayBill.DistanceOfTransport;
    }
    this.setState({
      showEWayBill: true,
      tmpGenerateEWayBill: tmpGenerateEWayBill,
      tmpEWayBillTransporterName: tmpEWayBillTransporterName,
      tmpEWayBillModeOfTransportCode: tmpEWayBillModeOfTransportCode,
      tmpEWayBillModeOfTransportName: tmpEWayBillModeOfTransportName,
      tmpEWayBillVehicleNo: tmpEWayBillVehicleNo,
      tmpEWayBillVehicleTypeCode: tmpEWayBillVehicleTypeCode,
      tmpEWayBillVehicleTypeName: tmpEWayBillVehicleTypeName,
      tmpEWayBillDistanceOfTransport: tmpEWayBillDistanceOfTransport,
    });
  }

  showAllStoreInStockQty(lineItem) {
    if (this.props.registration.registration.ShowOtherStoreInStockAtBilling) {
      // if(lineItem.Product && lineItem.Product.)
      // console.log('showAllStoreInStockQty')
      this.setState({
        showAllStoreInStockQty: true,
        selectedProductForWhichAllStoreInStockQtyToBeShown: lineItem.Product,
      });
    }
  }

  onEWayBillCancelClicked() {
    this.setState({ showEWayBill: false });
  }

  onEWayBillOKClicked() {
    let eWayBill = {
      GenerateEWayBill: this.state.tmpGenerateEWayBill,
      TransporterName: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillTransporterName
        : "",
      ModeOfTransportCode: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillModeOfTransportCode
        : "",
      ModeOfTransportName: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillModeOfTransportName
        : "",
      VehicleNo: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillVehicleNo
        : "",
      VehicleTypeCode: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillVehicleTypeCode
        : "",
      VehicleTypeName: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillVehicleTypeName
        : "",
      DistanceOfTransport: this.state.tmpGenerateEWayBill
        ? this.state.tmpEWayBillDistanceOfTransport
        : "",
    };
    this.setState({
      showEWayBill: false,
      eWayBill: eWayBill,
    });
    if (!this.state.tmpGenerateEWayBill) {
      this.setState({
        tmpEWayBillTransporterName: "",
        tmpEWayBillModeOfTransportCode: "",
        tmpEWayBillModeOfTransportName: "",
        tmpEWayBillVehicleNo: "",
        tmpEWayBillDistanceOfTransport: "",
        tmpEWayBillVehicleTypeCode: "",
        tmpEWayBillVehicleTypeName: "",
      });
    }
  }

  renderMobileHeaderOptions() {
    return (
      <React.Fragment>
        {(this.state.mobileOptionSelected === 2 ||
          this.state.mobileOptionSelected === 3 ||
          this.state.mobileLineItemSelected) && (
          <div className="input-group sales-mobile-header">
            <button
              type="button"
              onClick={this.handleScan}
              className="sales-mobile-header-buttons"
            >
              <i className="fa fa-barcode"></i>
              <div>scan</div>
            </button>
            <button
              type="button"
              onClick={() => {
                this.onProuctTypeAheadSearch("");
                this.setState({
                  mobileOptionSelected: 1,
                  title: "Product Search",
                  mobileShowInvoice: false,
                  categorySelected: false,
                  mobileLineItemSelected: false,
                  mobile: true,
                });
              }}
              className={
                this.state.mobileOptionSelected == 1
                  ? "sales-mobile-header-buttons-selected"
                  : "sales-mobile-header-buttons"
              }
            >
              <i className="fa fa-search"></i>
              <div>search</div>
            </button>
            <button
              type="button"
              onClick={() => {
                this.setState({
                  mobileOptionSelected: 2,
                  categorySelected: false,
                  mobileLineItemSelected: false,
                  mobile: true,
                });
              }}
              className={
                this.state.mobileOptionSelected == 2
                  ? "sales-mobile-header-buttons-selected"
                  : "sales-mobile-header-buttons"
              }
            >
              <i className="fa fa-th-large"></i>
              <div>categories</div>
            </button>
            <button
              type="button"
              onClick={() =>
                this.setState({
                  mobileOptionSelected: 3,
                  categorySelected: false,
                  mobileLineItemSelected: false,
                  mobile: true,
                })
              }
              className={
                this.state.mobileOptionSelected == 3
                  ? "sales-mobile-header-buttons-selected"
                  : "sales-mobile-header-buttons"
              }
            >
              <i className="fa fa-heart"></i>
              <div>favourite</div>
            </button>
            <button
              type="button"
              onClick={() =>
                this.setState({
                  mobileShowCustomer: true,
                  mobileLineItemSelected: false,
                  title: "Customer selection",
                  mobileOptionSelected: 4,
                })
              }
              className={
                this.state.selectedCustomer
                  ? "sales-mobile-header-buttons sales-mobile-header-buttons-customer-selected"
                  : "sales-mobile-header-buttons"
              }
            >
              <i className="fa fa-user"></i>
              <div>customer</div>
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderMobileCategories() {
    return (
      <div className="sales-mobile-list">
        <div>
          <div className="product-selection-container">
            {this.state.SaleProductCategories.map((productCategory, index) => (
              <div
                key={index}
                className="product-selection-btn"
                style={{
                  backgroundColor:
                    productCategory.Color === ""
                      ? "white"
                      : productCategory.Color,
                }}
                onClick={() =>
                  this.setState({
                    mobile: true,
                    categorySelected: true,
                    selectedCategoryID: productCategory.key,
                    selectedCategory: productCategory.Name,
                    selectedSubCategoryID: "",
                    selectedSubCategory: "",
                  })
                }
              >
                {!productCategory.Image && (
                  <div className="product-select-btn-text-full-product-name1">
                    {productCategory.Name}
                  </div>
                )}
                {productCategory.Image && (
                  <React.Fragment>
                    <div className="image-div">
                      <img
                        className="photo"
                        // style={{ width: "99px" }}
                        src={productCategory.Image.Preview}
                      />
                    </div>
                    <div className="product-select-btn-text-name1">
                      {productCategory.Name}
                    </div>
                  </React.Fragment>
                )}
              </div>
            ))}
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }

  renderMobileSubCategoriesAndProducts() {
    return (
      <div className="sales-mobile-list">
        <div>
          {/* className="col-xs-12" */}
          <div className="product-selection-container">
            {this.state.mobileOptionSelected === 2 &&
              this.state.categorySelected && (
                <React.Fragment>
                  <div
                    key={"back"}
                    className="product-selection-btn"
                    style={
                      {
                        // width: "29%",
                        // backgroundColor: "white",
                        // borderRadius: "10px",
                        // textAlign: "center",
                        // verticalAlign: "middle",
                      }
                    }
                    onClick={() => {
                      if (this.state.selectedSubCategoryID === "") {
                        this.setState({
                          mobile: true,
                          selectedCategoryID: "",
                          selectedCategory: "",
                          selectedSubCategoryID: "",
                          selectedSubCategory: "",
                          categorySelected: false,
                        });
                      } else {
                        this.setState({
                          mobile: true,
                          selectedSubCategoryID: "",
                          selectedSubCategory: "",
                          categorySelected: true,
                        });
                      }
                    }}
                  >
                    <i
                      className="fa fa-arrow-left fa-2"
                      aria-hidden="true"
                      // style={{ marginTop: "33px" }}
                    ></i>
                  </div>
                  {this.state.selectedSubCategoryID === "" && (
                    <React.Fragment>
                      {" "}
                      {this.state.SaleProductSubCategories.filter(
                        (e) => e.ParentID === this.state.selectedCategoryID
                      ).map((productSubCategory, index) => (
                        <div
                          key={index}
                          className="product-selection-btn"
                          style={{
                            // width: "29%",
                            backgroundColor:
                              productSubCategory.Color === ""
                                ? "white"
                                : "white",
                            // borderRadius:
                            //     "10px",
                          }}
                          onClick={() =>
                            this.setState({
                              mobile: true,
                              selectedSubCategoryID: productSubCategory.key,
                              selectedSubCategory: productSubCategory.Name,
                            })
                          }
                        >
                          {!productSubCategory.Image && (
                            <div>
                              <div className="product-select-btn-text-full-product-name1">
                                {productSubCategory.Name}
                              </div>
                            </div>
                          )}
                          {productSubCategory.Image && (
                            <div>
                              <div className="row">
                                <img
                                  className="photo"
                                  style={{
                                    width: "99px",
                                  }}
                                  src={productSubCategory.Image.Preview}
                                />
                              </div>
                              <div className="row product-select-btn-text-name1">
                                {productSubCategory.Name}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            {this.state.Products.filter(
              (e) =>
                e.ProductTypeID !== 2 &&
                e.CategoryID === this.state.selectedCategoryID &&
                e.SubCategoryID === this.state.selectedSubCategoryID
            ).map((product, index) => (
              <div
                key={index}
                className="product-selection-btn"
                style={
                  {
                    // width: "30%",
                    // backgroundColor: "white",
                    // borderRadius: "10px",
                  }
                }
                onClick={() => this.onProductSelected(product)}
              >
                {product.Images.length === 0 && (
                  <React.Fragment>
                    <div className="product-select-btn-text-full-product">
                      {product.Name}
                    </div>
                    <div className="product-select-btn-text-full-price">
                      {this.props.user.store.Currency}
                      {product.RetailPrice}/-
                    </div>
                  </React.Fragment>
                )}
                {product.Images.length !== 0 && (
                  <React.Fragment>
                    <div className="image-div">
                      <img
                        className="photo"
                        // style={{ width: "99px" }}
                        src={product.Images[0].ImagePath}
                        alt={product.Images[0].ImageName}
                      />
                    </div>
                    <div className="product-select-btn-img-text-full-product">
                      {product.Name}
                    </div>
                    <div className="product-select-btn-text-full-price">
                      {this.props.user.store.Currency}
                      {product.RetailPrice}/-
                    </div>
                  </React.Fragment>
                )}
              </div>
            ))}
          </div>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }

  renderMobileFavItems() {
    return (
      <div className="sales-mobile-list">
        <div className="product-selection-container">
          {this.state.Products.filter(
            (e) => e.hasOwnProperty("Favourite") && e.Favourite
          ).map((product, index) => (
            <div
              key={index}
              className="product-selection-btn"
              onClick={() => this.onProductSelected(product)}
            >
              {product.Images.length === 0 && (
                <React.Fragment>
                  <div className="product-select-btn-text-full-product">
                    {product.Name}
                  </div>
                  <div className="product-select-btn-text-full-price">
                    {this.props.user.store.Currency}
                    {product.RetailPrice}/-
                  </div>
                </React.Fragment>
              )}
              {product.Images.length !== 0 && (
                <React.Fragment>
                  <div className="image-div">
                    <img
                      className="photo"
                      // style={{ width: "99px" }}
                      src={product.Images[0].ImagePath}
                      alt={product.Images[0].ImageName}
                    />
                  </div>
                  <div className="product-select-btn-img-text-full-product">
                    {product.Name}
                  </div>
                  <div className="product-select-btn-text-full-price">
                    {" "}
                    {this.props.user.store.Currency}
                    {product.RetailPrice}/-
                  </div>
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderQtyControl(lineItem, index) {
    return (
      <div
        className="sales-qty-control"
        // style={{display:"flex"}}
      >
        {((lineItem.Product.ProductTypeID === 1 &&
          lineItem.Product.hasOwnProperty("InventoryType") &&
          lineItem.Product.InventoryType !== "Unique No") ||
          lineItem.Product.ProductTypeID !== 1 ||
          (lineItem.Product.ProductTypeID === 1 &&
            !lineItem.Product.hasOwnProperty("InventoryType"))) && (
          <React.Fragment>
            {/* <div className=""> */}
            <button
              onClick={(evt) => {
                let qty = lineItem.Qty;
                qty = qty - 1;
                if (qty <= 0) {
                  this.onRemoveLineItem(index);
                } else {
                  this.setState({
                    selectedLineItem: lineItem,
                  });
                  this.onLineItemQtyChange(qty, lineItem);
                  this.calculateBillTotal(
                    this.state.lineItems,
                    this.state.receivedPaymentAmount
                  );
                  //15-jun-22
                  this.applyOffer();
                  this.applySelectedCoupon(this.state.coupon);
                }
              }}
              type="button"
              className="btn button-border button-left-rounded small"
              // style=""
            >
              <Icon type="minus" />
            </button>
            {/* </div> */}
            <input
              type="number"
              className="sales-qty"
              onChange={(evt) => {
                this.setState({ selectedLineItem: lineItem });
                this.onLineItemQtyChange(evt.target.value, lineItem);
                this.calculateBillTotal(
                  this.state.lineItems,
                  this.state.receivedPaymentAmount
                );
                //15-jun-22
                this.applyOffer();
                this.applySelectedCoupon(this.state.coupon);
              }}
              value={lineItem.Qty}
            />
            {/* <div className=""> */}
            <button
              onClick={(evt) => {
                let qty = lineItem.Qty;
                qty = qty + 1;
                this.setState({ selectedLineItem: lineItem });
                this.onLineItemQtyChange(qty, lineItem);
                this.calculateBillTotal(
                  this.state.lineItems,
                  this.state.receivedPaymentAmount
                );
                //15-jun-22
                this.applyOffer();
                this.applySelectedCoupon(this.state.coupon);
              }}
              type="button"
              className="btn button-border button-right-rounded small"
            >
              <Icon type="add" />
            </button>
            {/* </div> */}
            {/* <ImageButton type="add" size="small" onClick={(evt) => {
                                    let qty = lineItem.Qty;
                                    qty = qty + 1;
                                    this.setState({ selectedLineItem: lineItem });
                                    this.onLineItemQtyChange(qty, lineItem);
                                    this.calculateBillTotal(
                                        this.state.lineItems,
                                        this.state.receivedPaymentAmount
                                    );
                                }}/> */}
          </React.Fragment>
        )}
        {lineItem.Product.ProductTypeID === 1 &&
          lineItem.Product.hasOwnProperty("InventoryType") &&
          lineItem.Product.InventoryType === "Unique No" && (
            <div>{lineItem.Qty}</div>
          )}
        {/* {lineItem.Employee.key !== "" && <span className="product-description">
                <i className="fa fa-user" />  {lineItem.Employee.EmployeeFirstName} {lineItem.Employee.EmployeeLastName}
            </span>}
            {(this.state.transactionType.EmployeeMandatoryChecked
                && this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel === "lineitem"
                && lineItem.Employee.key === "") && <span style={{ color: "red" }}>Please select employee</span>}
            {lineItem.Notes.trim() !== "" && <span className="product-description">{lineItem.Notes}</span>} */}
      </div>
    );
  }

  renderLineItemBatchSelection(lineItem, index) {
    // console.log("renderLineItemBatchSelection");
    let showBatchsForSelection = false;
    if (
      lineItem.Product.hasOwnProperty("InventoryType") &&
      lineItem.Product.InventoryType === "Batch Tracking"
    ) {
      if (!lineItem.hasOwnProperty("BatchID")) {
        showBatchsForSelection = true;
      } else if (
        lineItem.hasOwnProperty("BatchID") &&
        lineItem.BatchID === ""
      ) {
        showBatchsForSelection = true;
      }
    }
    // console.log("showBatchsForSelection",showBatchsForSelection)
    if (showBatchsForSelection) {
      return (
        <React.Fragment>
          <span style={{ color: "red" }}>Please select batch</span>
          <button
            type="submit"
            style={{ marginLeft: "5px" }}
            className="btn btn-md btn-default btn-flat"
            onClick={() =>
              this.setState({
                showBatchsForSelection: true,
                selectedLineItem: lineItem,
                selectedLineItemIndex: index,
                selectedProduct: lineItem.Product,
                showAllBatches: lineItem.Qty < 0 ? true : false,
              })
            }
          >
            Select Batch
          </button>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  renderMobileLineItems() {
    return (
      <React.Fragment>
        <div className="sales-mobile-list-white">
          {this.state.transactionType && (
            <div className="row">
              {this.state.transactionType
                .EmployeeManadatoryTransactionORLineItemLevel ===
                "transaction" && (
                <div className="col-12">
                  {!this.state.transactionLevelEmployeeSelected && (
                    <div
                      className={
                        this.state.employeeMandatoryAtTransactionError
                          ? "form-group has-error"
                          : "form-group "
                      }
                      style={{ paddingTop: "0px" }}
                    >
                      <Typeahead
                        allowNew={false}
                        multiple={false}
                        options={this.state.employeeList}
                        labelKey="EmployeeFirstName"
                        placeholder="Select employee"
                        filterBy={[
                          "EmployeeCode",
                          "EmployeeFirstName",
                          "EmployeeLastName",
                        ]}
                        bsSize={"large"}
                        dropup={false}
                        highlightOnlyResult={true}
                        selected={this.state.transactionLevelEmployeeSelected}
                        onChange={(selected) => {
                          this.setState({
                            transactionLevelEmployeeSelected: selected[0],
                          });
                        }}
                        renderMenuItemChildren={(option, props) => (
                          <div>
                            <div>
                              <b>
                                {option.EmployeeFirstName}{" "}
                                {option.EmployeeLastName}
                              </b>
                            </div>
                            <div>{option.EmployeeCode}</div>
                            <div>{option.PhoneNo}</div>
                          </div>
                        )}
                      />
                    </div>
                  )}
                  {this.state.transactionLevelEmployeeSelected && (
                    <div className="input-group">
                      <button
                        type="button"
                        className="btn btn-default btn-block btn-flat btn-lg"
                      >
                        {
                          this.state.transactionLevelEmployeeSelected
                            .EmployeeFirstName
                        }{" "}
                        {
                          this.state.transactionLevelEmployeeSelected
                            .EmployeeLastName
                        }{" "}
                        {this.state.transactionLevelEmployeeSelected
                          .EmployeeCode !== ""
                          ? " (" +
                            this.state.transactionLevelEmployeeSelected
                              .EmployeeCode +
                            ")"
                          : ""}
                      </button>
                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default btn-lg btn-flat"
                          onClick={() =>
                            this.setState({
                              transactionLevelEmployeeSelected: null,
                            })
                          }
                        >
                          <i className="fa fa-remove"></i>{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div>
            <ul className="product-list-box">
              {this.state.lineItems.map((lineItem, index) => (
                <li key={index} className="item">
                  <div
                    className="item-img"
                    onClick={() => this.onLineItemSelected(lineItem, index)}
                  >
                    <Avatar
                      text={lineItem.Product.Name}
                      image={
                        lineItem.Product.Images.length > 0
                          ? lineItem.Product.Images[0].ImagePath
                          : ""
                      }
                    />
                  </div>
                  <div className="item-info">
                    <div
                      className="product-info"
                      onClick={() => this.onLineItemSelected(lineItem, index)}
                    >
                      <a className="product-title" style={{ color: "black" }}>
                        {lineItem.Product.SKU} - {lineItem.Product.Name}
                      </a>
                    </div>
                    <div className="product-quantity-selection-div">
                      <button
                        onClick={(evt) => {
                          let qty = lineItem.Qty;
                          qty = qty - 1;
                          if (qty <= 0) {
                            this.onRemoveLineItem(index);
                          } else {
                            this.setState({ selectedLineItem: lineItem });
                            this.onLineItemQtyChange(qty, lineItem);
                            this.calculateBillTotal(
                              this.state.lineItems,
                              this.state.receivedPaymentAmount
                            );
                          }
                        }}
                        className="product-quantity-selection-btn"
                        type="button"
                      >
                        -
                      </button>
                      <input
                        className="inputDiv-product-quantity"
                        type="number"
                        onChange={(evt) => {
                          this.setState({
                            selectedLineItem: lineItem,
                          });
                          this.onLineItemQtyChange(evt.target.value, lineItem);
                          this.calculateBillTotal(
                            this.state.lineItems,
                            this.state.receivedPaymentAmount
                          );
                        }}
                        value={lineItem.Qty}
                      />
                      <button
                        onClick={(evt) => {
                          let qty = lineItem.Qty;
                          qty = qty + 1;
                          this.setState({ selectedLineItem: lineItem });
                          this.onLineItemQtyChange(qty, lineItem);
                          this.calculateBillTotal(
                            this.state.lineItems,
                            this.state.receivedPaymentAmount
                          );
                        }}
                        className="product-quantity-selection-btn"
                        type="button"
                      >
                        +
                      </button>
                    </div>
                    {this.state.transactionType.EmployeeMandatoryChecked &&
                      this.state.transactionType
                        .EmployeeManadatoryTransactionORLineItemLevel ===
                        "lineitem" &&
                      lineItem.Employee.key === "" && (
                        <span style={{ color: "red" }}>
                          Please select employee
                        </span>
                      )}
                    {this.renderLineItemBatchSelection(lineItem, index)}
                    {lineItem.ActualInStockQty && (
                      <span className="product-description">
                        In Stock Qty:{lineItem.ActualInStockQty}{" "}
                      </span>
                    )}
                    {lineItem.Employee.key !== "" && (
                      <span className="product-description">
                        <i className="fa fa-user" />{" "}
                        {lineItem.Employee.EmployeeFirstName}{" "}
                        {lineItem.Employee.EmployeeLastName}
                      </span>
                    )}
                    {lineItem.Notes.trim() !== "" && (
                      <span className="product-description">
                        {lineItem.Notes}
                      </span>
                    )}
                    <div
                      className="product-description"
                      style={{ marginLeft: "0px", color: "#999" }}
                    >
                      {lineItem.Product.BatchTrackingBatchNoRequired &&
                        lineItem.BatchNo && (
                          <React.Fragment>
                            Batch No: {lineItem.BatchNo}
                          </React.Fragment>
                        )}
                      {lineItem.Product.BatchTrackingMRPRequired &&
                        lineItem.BatchMRP && (
                          <React.Fragment>
                            {" "}
                            MRP: {lineItem.BatchMRP}
                          </React.Fragment>
                        )}
                      {lineItem.Product.BatchTrackingMfgDateRequired &&
                        lineItem.BatchMfgDate && (
                          <React.Fragment>
                            {" "}
                            Mfg: {lineItem.BatchMfgDate}
                          </React.Fragment>
                        )}
                      {lineItem.Product.BatchTrackingExpDateRequired &&
                        lineItem.BatchExpDate && (
                          <React.Fragment>
                            {" "}
                            Exp: {lineItem.BatchExpDate}
                          </React.Fragment>
                        )}
                      {/* {lineItem.BatchID !=="" && <React.Fragment>
                                                    {" "}In Stock Qty:{" "}{lineItem.InStockQty}
                                                </React.Fragment>} */}
                    </div>
                    {lineItem.Product.hasOwnProperty("InventoryType") &&
                      lineItem.Product.InventoryType.toLowerCase() ===
                        "unique no" && (
                        <button
                          type="submit"
                          style={{ marginLeft: "5px" }}
                          className="btn btn-md btn-default btn-flat"
                          onClick={() =>
                            this.setState({
                              showUniqueNoInput: true,
                              selectedLineItem: lineItem,
                              selectedLineItemIndex: index,
                              selectedProduct: lineItem.Product,
                            })
                          }
                        >
                          {lineItem.Product.UniqueLabel.trim() !== ""
                            ? lineItem.Product.UniqueLabel
                            : "Unique No"}
                        </button>
                      )}
                    {lineItem.ModifierApplicable && (
                      <Button
                        type="secondary"
                        text="Modifier(s)"
                        onClick={() => {
                          this.getApplicableModifierGroups(
                            lineItem.Product.key
                          );
                          this.setState({
                            showModifiers: true,
                            selectedLineItemIndex: index,
                            selectedModifiers: lineItem.Modifiers
                              ? lineItem.Modifiers
                              : [],
                            modifierQty: lineItem.ModifierQty
                              ? lineItem.ModifierQty
                              : 0,
                            modifierAmount: lineItem.ModifierAmount
                              ? lineItem.ModifierAmount
                              : 0,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="item-price-and-Remove-item">
                    <div className="pull-right" style={{ height: "100%" }}>
                      {this.props.config.CountryConfig.CurrencySymbol}{" "}
                      {lineItem.Amount}
                    </div>
                    <br />
                    <div>
                      <a
                        onClick={() => this.onRemoveLineItem(index)}
                        style={{ color: "black" }}
                      >
                        <i className="fa fa-lg fa-trash-o pull-right"></i>
                      </a>
                    </div>
                  </div>
                  {/* </div> */}
                </li>
              ))}
              <li>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobileSearchCatalog() {
    return (
      <React.Fragment>
        <div className="container">
          <ListSearch
            value={this.state.productSearchText}
            onChange={(productSearchText) => {
              this.setState({
                productSearchText: productSearchText,
              });
              // if(productSearchText.length<3)
              // {
              //     this.setState({productsTypeAheadOptions:[]})
              // }
              // else
              // {
              this.handleProductSearchThrottle(productSearchText);
              // }
            }}
            searchPlaceHolder="Search by SKU, Bar Code, Name"
          />

          {this.state.loading && <Spinner />}

          {!this.state.loading && (
            <div className="list">
              {this.state.productsTypeAheadOptions.map((product) => (
                <div
                  className="list-row seperator"
                  onClick={() => {
                    this.onProductSelected(product);
                  }}
                >
                  <Avatar text={product.Name} />

                  <div>
                    <div className="row-title">{product.Name}</div>
                    <div className="row-sub-text">{product.SKU}</div>
                    <div className="row-sub-text">
                      {this.props.config.CountryConfig.CurrencySymbol}{" "}
                      {product.RetailPrice}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderMobileFooterOptions() {
    return (
      <div
        className="sales-mobile-footer"
        style={{
          height: this.state.mobileShowMoreOptions
            ? this.state.mobileLineItemSelected
              ? "255px"
              : "165px"
            : this.state.mobileLineItemSelected
            ? "150px"
            : "60px",
        }}
      >
        {this.state.mobileLineItemSelected && (
          <React.Fragment>
            <div style={{ padding: "5px 15px 5px 15px" }}>
              Sub Total
              <span className="pull-right">
                {this.props.config.CountryConfig.CurrencySymbol}{" "}
                {this.state.billSubTotal}
              </span>
            </div>
            <div style={{ padding: "5px 15px 5px 15px" }}>
              Discount
              <span className="pull-right">
                {this.props.config.CountryConfig.CurrencySymbol}{" "}
                {this.state.billDiscount}
              </span>
            </div>
            <div style={{ padding: "5px 15px 5px 15px" }}>
              Taxes
              <span className="pull-right">
                {this.props.config.CountryConfig.CurrencySymbol}{" "}
                {this.state.billTaxAmount}
              </span>
            </div>
          </React.Fragment>
        )}
        <div className="input-group">
          <span className="input-group-btn">
            <button
              type="button"
              onClick={() => {
                this.setState({
                  mobileShowMoreOptions: !this.state.mobileShowMoreOptions,
                });
              }}
              className="btn btn-default btn-flat btn-lg"
              style={{
                backgroundColor: "white",
                width: "15%",
                border: "none",
              }}
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>
            <button
              type="button"
              className="btn btn-default btn-flat btn-lg"
              onClick={() => {
                this.setState({
                  mobileLineItemSelected: true,
                  mobileOptionSelected: -1,
                  mobileShowCustomer: false,
                });
              }}
              style={{
                backgroundColor: "white",
                width: "35%",
                border: "none",
              }}
            >
              <small>{this.state.billQty} items</small>
            </button>
            <button
              type="button"
              style={{
                width: "50%",
                borderRadius: "50px",
                border: "none",
              }}
              className={
                this.state.type === actionTypes.SALE_NEW
                  ? this.state.transactionType.TransactionType.toLowerCase() !==
                    "sales return"
                    ? "btn btn-lg btn-success"
                    : "btn btn-lg btn-warning"
                  : this.state.type === actionTypes.SALE_EDIT
                  ? "btn btn-lg btn-info "
                  : "btn btn-lg btn-warning"
              }
              onClick={() => {
                if (this.state.lineItems.length > 0) {
                  this.setState({ mobile: true });
                  this.onPaymentClick();
                }
              }}
            >
              <div className="pull-left">
                <b>
                  {this.state.transactionType.PaymentModeChecked
                    ? " Pay"
                    : " Save"}
                </b>
              </div>
              <div className="pull-right">
                <b>
                  {" "}
                  {this.props.config.CountryConfig.CurrencySymbol}{" "}
                  {this.state.billAmount}
                </b>
                {/* {this.props.user.store.Currency} */}
              </div>
            </button>
          </span>
        </div>
        {this.state.mobileShowMoreOptions && (
          <div className="input-group" style={{ width: "100%" }}>
            {this.state.key.trim() === "" && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    mobileShowMoreOptions: false,
                    mobileOptionSelected: 2,
                    mobileLineItemSelected: false,
                    selectedCategory: "",
                    selectedCategoryID: "",
                  });
                  this.onParkSaleClicked();
                }}
              >
                <i className="fa fa-clock-o"></i>
                <div style={{ fontSize: "10px" }}>park sale</div>
              </button>
            )}
            {this.state.key.trim() === "" && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                onClick={() =>
                  this.setState({
                    showParkedSalesModal: true,
                    mobileShowMoreOptions: false,
                  })
                }
              >
                {this.state.transactionType && (
                  <span className="badge bg-green">
                    {this.state.drafts.length}
                  </span>
                )}
                {/* <i className="fa fa-mail-forward"></i> */}
                <div style={{ fontSize: "10px" }}>retrieve sale</div>
              </button>
            )}
            {this.state.key.trim() === "" && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    mobileShowMoreOptions: false,
                  });
                  this.onDiscardClicked();
                }}
              >
                <i className="fa fa-trash-o"></i>
                <div style={{ fontSize: "10px" }}>discard sale</div>
              </button>
            )}
            {this.state.transactionType.DiscountChecked && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    mobileShowMoreOptions: false,
                  });
                  this.onShowDiscountOptions();
                }}
              >
                <i className="fa">%</i>
                <div style={{ fontSize: "10px" }}>discount</div>
              </button>
            )}
            <button
              type="button"
              className="btn btn-default btn-flat btn-lg"
              style={{
                backgroundColor: "white",
                width: "20%",
                border: "0px",
              }}
              disabled={this.state.lineItems.length > 0 ? false : true}
              onClick={() => {
                this.setState({
                  showNotesModal: true,
                  mobileShowMoreOptions: false,
                });
              }}
            >
              {/* {this.state.notes !== "" && <span className="badge bg-green">Notes</span>} */}
              <i className="fa fa-sticky-note-o"></i>
              <div style={{ fontSize: "10px" }}>notes</div>
            </button>
            {this.props.config.CustomFields.filter(
              (f) => f.ModuleName.toLowerCase() === "sales"
            ).length > 0 && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    showAdditionalInfo: true,
                    additionalInfoMode: "edit",
                    mobileShowMoreOptions: false,
                  });
                }}
              >
                <i className="fa fa-list"></i>
                <div style={{ fontSize: "10px" }}>add info</div>
              </button>
            )}
            {this.props.registration.registration.Delivery && (
              <button
                type="button"
                className="btn btn-default btn-flat btn-lg"
                style={{
                  backgroundColor: "white",
                  width: "20%",
                  border: "0px",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    mobileShowMoreOptions: false,
                  });
                  this.onDeliveryClicked();
                }}
              >
                <i className="fa fa-truck"></i>
                <div style={{ fontSize: "10px" }}>delivery</div>
              </button>
            )}
            <button
              type="button"
              className="btn btn-default btn-flat btn-lg"
              style={{
                backgroundColor: "white",
                width: "20%",
                border: "0px",
              }}
              onClick={() =>
                this.setState({
                  showAddProduct: true,
                  showInvoice: false,
                  mobileShowInvoice: false,
                  mobile: true,
                  mobileShowMoreOptions: false,
                  scanedCode: "",
                })
              }
            >
              <i className="fa fa-plus"></i>
              <div style={{ fontSize: "10px" }}>new product</div>
            </button>
            {this.state.transactionType &&
              this.state.transactionType.TransactionType.toLowerCase() ===
                "sales return" && (
                <button
                  type="button"
                  className="btn btn-default btn-flat btn-lg"
                  style={{
                    backgroundColor: "white",
                    width: "20%",
                    border: "0px",
                  }}
                >
                  <Switch
                    checked={this.state.negativeQty}
                    onChange={() => {
                      this.setState({
                        negativeQty: !this.state.negativeQty,
                      });
                    }}
                  />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "-5px",
                    }}
                  >
                    negative qty
                  </div>
                </button>
              )}
          </div>
        )}
      </div>
    );
  }

  onHeaderBackClick() {
    if (this.state.mobileOptionSelected === 4) {
      this.setState({
        title: this.state.transactionType.TransactionType,
        mobileOptionSelected: 2,
        mobileShowCustomer: false,
      });
    } else if (this.state.mobileOptionSelected === 1) {
      this.setState({
        title: this.state.transactionType.TransactionType,
        mobileOptionSelected: 2,
        mobileShowCustomer: false,
      });
    } else {
      this.props.history.push("/home/sales/saleslist");
      // this.props.onClose()
    }
  }

  renderNotesControl() {
    return (
      <React.Fragment>
        {this.state.showNotesModal && (
          <Window
            header={"Notes"}
            // height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showNotesModal: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "ok") {
                this.onNotesOKClicked();
              } else {
                this.setState({ showNotesModal: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="textarea"
              onChange={(value) => this.setState({ tempNotes: value })}
              value={this.state.tempNotes}
            />
          </Window>
        )}
      </React.Fragment>
    );
  }

  // style={{height:this.returnBillingHeight()}}
  returnDesktop1ScrollHeight() {
    // let screenHeight = window.screenHeight;
    let screenHeight = window.innerHeight;
    let height = Number(screenHeight) - (132 + 100);
    return height + "px";
  }

  renderDesktopOption1() {
    return (
      <div className="col-md-12">
        <div className="col-md-2" style={{ padding: "5px" }}>
          <div
            className="item-list-scroll scroll2"
            style={{ height: this.state.desktop1ScrollHeight }}
          >
            {this.state.favouriteExists && (
              <div
                key="fav"
                onClick={() =>
                  this.setState({
                    favouriteSelected: true,
                    selectedCategoryID: "",
                    selectedCategory: "",
                    selectedSubCategoryID: "",
                    selectedSubCategory: "",
                    mobileOptionSelected: 3,
                  })
                }
                className={
                  this.state.favouriteSelected
                    ? "button-category button-category-selected"
                    : "button-category"
                }
              >
                <div className="button-sub-text-text">Favourite</div>
              </div>
            )}
            {this.state.SaleProductCategories.map((productCategory, index) => (
              <React.Fragment key={index}>
                {!productCategory.Image && (
                  <div
                    onClick={() =>
                      this.setState({
                        favouriteSelected: false,
                        selectedCategoryID: productCategory.key,
                        selectedCategory: productCategory.Name,
                        selectedSubCategoryID: "",
                        selectedSubCategory: "",
                      })
                    }
                    className={
                      this.state.selectedCategoryID === productCategory.key
                        ? "button-category button-category-selected"
                        : "button-category"
                    }
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty(
                          "background-color",
                          productCategory.Color,
                          "important"
                        );
                      }
                    }}
                  >
                    <div className="button-category-sub-text">
                      {productCategory.Name}
                    </div>
                  </div>
                )}
                {productCategory.Image && (
                  <div
                    key={index}
                    onClick={() =>
                      this.setState({
                        selectedCategoryID: productCategory.key,
                        selectedCategory: productCategory.Name,
                        selectedSubCategoryID: "",
                        selectedSubCategory: "",
                      })
                    }
                    className={
                      this.state.selectedCategoryID === productCategory.key
                        ? "button-category button-category-selected"
                        : "button-category"
                    }
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty(
                          "background-color",
                          productCategory.Color,
                          "important"
                        );
                      }
                    }}
                  >
                    <div className="button-product-category-image">
                      <img
                        style={{ width: "100%" }}
                        src={productCategory.Image.Preview}
                      />
                    </div>
                    <div className="button-category-sub-img">
                      {productCategory.Name}{" "}
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="col-md-2" style={{ padding: "5px" }}>
          <div
            className="item-list-scroll scroll2"
            style={{ height: this.returnDesktop1ScrollHeight() }}
          >
            {this.state.SaleProductSubCategories.filter(
              (e) => e.ParentID === this.state.selectedCategoryID
            ).map((productSubCategory, index) => (
              <React.Fragment>
                {!productSubCategory.Image && (
                  <div
                    key={index}
                    onClick={() =>
                      this.setState({
                        selectedSubCategoryID: productSubCategory.key,
                        selectedSubCategory: productSubCategory.Name,
                      })
                    }
                    className={
                      this.state.selectedSubCategoryID ===
                      productSubCategory.key
                        ? "button-category button-category-selected"
                        : "button-category"
                    }
                    // style={this.state.selectedSubCategoryID === productSubCategory.key ? {} : { backgroundColor: productSubCategory.Color + " !important"}}
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty(
                          "background-color",
                          productSubCategory.Color,
                          "important"
                        );
                      }
                    }}
                  >
                    <div className="button-category-sub-text">
                      {productSubCategory.Name}
                    </div>
                  </div>
                )}
                {productSubCategory.Image && (
                  <div
                    key={index}
                    onClick={() =>
                      this.setState({
                        selectedSubCategoryID: productSubCategory.key,
                        selectedSubCategory: productSubCategory.Name,
                      })
                    }
                    className={
                      this.state.selectedSubCategoryID ===
                      productSubCategory.key
                        ? "button-category button-category-selected"
                        : "button-category"
                    }
                    ref={(el) => {
                      if (el) {
                        el.style.setProperty(
                          "background-color",
                          productSubCategory.Color,
                          "important"
                        );
                      }
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={productSubCategory.Image.Preview}
                    />
                    <div className="button-category-sub-img">
                      {productSubCategory.Name}
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="col-md-8" style={{ padding: "5px" }}>
          {this.state.favouriteSelected && (
            <div
              className="item-list-scroll scroll2 product-container"
              style={{ height: this.returnDesktop1ScrollHeight() }}
            >
              {/* {this.props.config.Products.filter( */}
              {this.state.Products.filter(
                (e) => e.hasOwnProperty("Favourite") && e.Favourite
              ).map((product, index) => (
                <React.Fragment>
                  {product.Images.length === 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product"
                    >
                      <div className="button-sub-text-text">{product.Name}</div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                  {product.Images.length !== 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product"
                    >
                      <div className="button-product-image">
                        <img
                          // style={{ width: "100%" }}
                          src={product.Images[0].ImagePath}
                        />
                      </div>
                      <div className="button-sub-text-img">
                        {/* {product.Name.substring(0, 14)}<br/>{this.props.user.store.Currency} {product.RetailPrice}/- */}
                        {product.Name}
                        {/* <br/>{this.props.user.store.Currency} {product.RetailPrice}/- */}
                      </div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
          {!this.state.favouriteSelected && (
            <div
              className="item-list-scroll scroll2 product-container"
              style={{ height: this.returnDesktop1ScrollHeight() }}
            >
              {/* {this.props.config.Products.filter((e) => { */}
              {this.state.Products.filter((e) => {
                let show = true;
                if (e.hasOwnProperty("ParentProductID")) {
                  show = false;
                }
                if (
                  e.hasOwnProperty("SearchMode") &&
                  e.SearchMode.toLowerCase() === "scanonly"
                ) {
                  // console.log(e.SearchMode)
                  show = false;
                }
                if (
                  show &&
                  e.ProductTypeID !== 2 &&
                  e.CategoryID === this.state.selectedCategoryID &&
                  e.SubCategoryID === this.state.selectedSubCategoryID
                ) {
                  return true;
                } else {
                  return false;
                }
              }).map((product, index) => (
                <React.Fragment>
                  {product.Images.length === 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product"
                    >
                      <div className="button-sub-text-text">{product.Name}</div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                  {product.Images.length !== 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product"
                    >
                      <div className="button-product-image">
                        <img src={product.Images[0].ImagePath} />
                      </div>
                      <div className="button-sub-text-img">{product.Name}</div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDesktopOption2() {
    return (
      <div className="col-md-12">
        {this.state.selectedCategoryID === "" &&
          this.state.selectedSubCategoryID === "" &&
          !this.state.favouriteSelected && (
            <div className="col-md-12" style={{ padding: "5px" }}>
              <div className="item-list-scroll2 scroll2">
                {this.state.favouriteExists && (
                  <div
                    key="fav"
                    onClick={() =>
                      this.setState({
                        favouriteSelected: true,
                        selectedCategoryID: "",
                        selectedCategory: "",
                        selectedSubCategoryID: "",
                        selectedSubCategory: "",
                        mobileOptionSelected: 3,
                      })
                    }
                    className={
                      this.state.favouriteSelected
                        ? "button-category2 button-selected"
                        : "button-category2"
                    }
                  >
                    <div className="button-sub-text-text">Favourite</div>
                  </div>
                )}
                {this.state.SaleProductCategories.map(
                  (productCategory, index) => (
                    <React.Fragment>
                      {!productCategory.Image && (
                        <div
                          key={index}
                          onClick={() =>
                            this.setState({
                              favouriteSelected: false,
                              selectedCategoryID: productCategory.key,
                              selectedCategory: productCategory.Name,
                              selectedSubCategoryID: "",
                              selectedSubCategory: "",
                            })
                          }
                          className={
                            this.state.selectedCategoryID ===
                            productCategory.key
                              ? "button-category2 button-selected"
                              : "button-category2"
                          }
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "background-color",
                                productCategory.Color,
                                "important"
                              );
                            }
                          }}
                        >
                          <div className="button-category-sub-text">
                            {productCategory.Name}
                          </div>
                        </div>
                      )}
                      {productCategory.Image && (
                        <div
                          key={index}
                          onClick={() =>
                            this.setState({
                              selectedCategoryID: productCategory.key,
                              selectedCategory: productCategory.Name,
                              selectedSubCategoryID: "",
                              selectedSubCategory: "",
                            })
                          }
                          className={
                            this.state.selectedCategoryID ===
                            productCategory.key
                              ? "button-category2 button-selected"
                              : "button-category2"
                          }
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "background-color",
                                productCategory.Color,
                                "important"
                              );
                            }
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                            }}
                            src={productCategory.Image.Preview}
                          />
                          <div className="button-category-sub-img">
                            {productCategory.Name}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          )}
        {this.state.selectedCategoryID !== "" &&
          !this.state.favouriteSelected &&
          this.state.selectedSubCategoryID === "" && (
            <div className="col-md-12" style={{ padding: "5px" }}>
              <div className="item-list-scroll2 scroll2">
                {this.renderBackButton()}
                {this.state.SaleProductSubCategories.filter(
                  (e) => e.ParentID === this.state.selectedCategoryID
                ).map((productSubCategory, index) => (
                  <React.Fragment>
                    {!productSubCategory.Image && (
                      <div
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedSubCategoryID: productSubCategory.key,
                            selectedSubCategory: productSubCategory.Name,
                          })
                        }
                        className={
                          this.state.selectedSubCategoryID ===
                          productSubCategory.key
                            ? "button-category2 button-selected"
                            : "button-category2"
                        }
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "background-color",
                              productSubCategory.Color,
                              "important"
                            );
                          }
                        }}
                      >
                        <div className="button-category-sub-text">
                          {productSubCategory.Name}
                        </div>
                      </div>
                    )}
                    {productSubCategory.Image && (
                      <div
                        key={index}
                        onClick={() =>
                          this.setState({
                            selectedSubCategoryID: productSubCategory.key,
                            selectedSubCategory: productSubCategory.Name,
                          })
                        }
                        className={
                          this.state.selectedSubCategoryID ===
                          productSubCategory.key
                            ? "button-category2 button-selected"
                            : "button-category2"
                        }
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "background-color",
                              productSubCategory.Color,
                              "important"
                            );
                          }
                        }}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={productSubCategory.Image.Preview}
                        />
                        <div className="button-category-sub-img">
                          {productSubCategory.Name}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}

                {this.renderProducts()}
              </div>
            </div>
          )}
        {this.state.selectedCategoryID !== "" &&
          !this.state.favouriteSelected &&
          this.state.selectedSubCategoryID !== "" && (
            <div className="col-md-12" style={{ padding: "5px" }}>
              <div className="item-list-scroll2 scroll2">
                {this.renderBackButton()}
                {this.renderProducts()}
              </div>
            </div>
          )}
        {this.state.favouriteSelected && (
          <div className="col-md-12" style={{ padding: "5px" }}>
            <div className="item-list-scroll2 scroll2 ">
              {this.renderBackButton()}
              {this.state.Products.filter(
                (e) => e.hasOwnProperty("Favourite") && e.Favourite
              ).map((product, index) => (
                <React.Fragment>
                  {product.Images.length === 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product2"
                    >
                      <div className="button-sub-text-text">{product.Name}</div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                  {product.Images.length !== 0 && (
                    <div
                      key={index}
                      onClick={() => this.onProductSelected(product)}
                      className="button-product2"
                    >
                      <div className="button-product-image">
                        <img src={product.Images[0].ImagePath} />
                      </div>
                      <div className="button-sub-text-img">{product.Name}</div>
                      <div className="button-sub-text-price">
                        {this.props.user.store.Currency} {product.RetailPrice}/-
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderDesktopOption3() {
    return (
      <div className="col-md-12">
        <div
          className="item-list-scroll scroll2 sales-screenoption3-lineitem-container"
          style={{ height: this.state.desktop1ScrollHeight }}
        >
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Product</th>
                <th>Qty</th>
                <th>MRP</th>
                <th>Retail Price</th>
                <th>Sub Total</th>
                <th>Dis</th>
                <th>Tax</th>
                <th>Amount</th>
                <th style={{ width: "46px" }}></th>
              </tr>
            </thead>
            <tbody>
              {this.state.lineItems.map((lineItem, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td onClick={() => this.onLineItemSelected(lineItem, index)}>
                    <div className="sales-selected-line-item-avatar">
                      <Avatar
                        text={lineItem.Product.Name}
                        image={
                          lineItem.Product.Images &&
                          lineItem.Product.Images.length > 0
                            ? lineItem.Product.Images[0].ImagePath
                            : null
                        }
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={() => this.onLineItemSelected(lineItem, index)}
                    >
                      {lineItem.Product.Name}
                    </div>
                    <div
                      onClick={() => this.onLineItemSelected(lineItem, index)}
                    >
                      <div>{lineItem.Product.SKU}</div>
                      <div>{lineItem.Product.BarCode}</div>
                    </div>
                    <div>{this.renderInStockQty(lineItem)}</div>
                    {lineItem.Employee.key !== "" && (
                      <div>
                        <i className="fa fa-user" />{" "}
                        {lineItem.Employee.EmployeeFirstName}{" "}
                        {lineItem.Employee.EmployeeLastName}
                      </div>
                    )}
                    {lineItem.Notes.trim() !== "" && (
                      <div>
                        <i className="fa fa-sticky-note" /> {lineItem.Notes}
                      </div>
                    )}
                    {lineItem.KOTNo && <div>KOT: {lineItem.KOTNo}</div>}
                    {lineItem.Product.BatchTrackingBatchNoRequired &&
                      lineItem.BatchNo && (
                        <React.Fragment>
                          Batch No: {lineItem.BatchNo}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingMRPRequired &&
                      lineItem.BatchMRP && (
                        <React.Fragment>
                          {" "}
                          MRP: {lineItem.BatchMRP}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingMfgDateRequired &&
                      lineItem.BatchMfgDate && (
                        <React.Fragment>
                          {" "}
                          Mfg: {lineItem.BatchMfgDate}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingExpDateRequired &&
                      lineItem.BatchExpDate && (
                        <React.Fragment>
                          {" "}
                          Exp: {lineItem.BatchExpDate}
                        </React.Fragment>
                      )}
                    {lineItem.ModifierApplicable && (
                      <Button
                        type="secondary"
                        text="Modifier(s)"
                        onClick={() => {
                          this.getApplicableModifierGroups(
                            lineItem.Product.key
                          );
                          this.setState({
                            showModifiers: true,
                            selectedLineItemIndex: index,
                            selectedModifiers: lineItem.Modifiers
                              ? lineItem.Modifiers
                              : [],
                            modifierQty: lineItem.ModifierQty
                              ? lineItem.ModifierQty
                              : 0,
                            modifierAmount: lineItem.ModifierAmount
                              ? lineItem.ModifierAmount
                              : 0,
                          });
                        }}
                      />
                    )}
                    {this.state.transactionType.EmployeeMandatoryChecked &&
                      this.state.transactionType
                        .EmployeeManadatoryTransactionORLineItemLevel ===
                        "lineitem" &&
                      lineItem.Employee.key === "" && (
                        <div style={{ color: "red" }}>
                          Please select employee
                        </div>
                      )}

                    {!this.props.registration.registration
                      .EnablePosBillingForPincode && (
                      <div className="product-description">
                        {lineItem.Product.hasOwnProperty("CustomField1") &&
                          lineItem.Product.CustomField1.toString().trim() !==
                            "" &&
                          this.state.productCustomField1DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              {this.state.productCustomField1DisplayName} :{" "}
                              {lineItem.Product.CustomField1}
                            </React.Fragment>
                          )}
                        {lineItem.Product.hasOwnProperty("CustomField2") &&
                          lineItem.Product.CustomField2.toString().trim() !==
                            "" &&
                          this.state.productCustomField2DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              {" "}
                              , {
                                this.state.productCustomField2DisplayName
                              } : {lineItem.Product.CustomField2}
                            </React.Fragment>
                          )}
                        {lineItem.Product.hasOwnProperty("CustomField3") &&
                          lineItem.Product.CustomField3.toString().trim() !==
                            "" &&
                          this.state.productCustomField3DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              , {this.state.productCustomField3DisplayName}:{" "}
                              {lineItem.Product.CustomField3}{" "}
                            </React.Fragment>
                          )}
                        {lineItem.Product.hasOwnProperty("CustomField4") &&
                          lineItem.Product.CustomField4.toString().trim() !==
                            "" &&
                          this.state.productCustomField4DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              , {this.state.productCustomField4DisplayName} :{" "}
                              {lineItem.Product.CustomField4}{" "}
                            </React.Fragment>
                          )}
                        {lineItem.Product.hasOwnProperty("CustomField5") &&
                          lineItem.Product.CustomField5.toString().trim() !==
                            "" &&
                          this.state.productCustomField5DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              , {this.state.productCustomField5DisplayName}:{" "}
                              {lineItem.Product.CustomField5}
                            </React.Fragment>
                          )}
                        {lineItem.Product.hasOwnProperty("CustomField6") &&
                          lineItem.Product.CustomField6.toString().trim() !==
                            "" &&
                          this.state.productCustomField6DisplayName.trim() !==
                            "" && (
                            <React.Fragment>
                              , {this.state.productCustomField6DisplayName}:{" "}
                              {lineItem.Product.CustomField6}
                            </React.Fragment>
                          )}
                      </div>
                    )}
                  </td>
                  <td>{this.renderQtyControl(lineItem, index)}</td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.Product.MRP}
                  </td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.RetailPrice}
                  </td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.SubTotal}
                  </td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.DiscountAmount}
                  </td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.TotalTax}
                  </td>
                  <td className="align-right">
                    {this.props.user.store.Currency}
                    {lineItem.Amount}
                  </td>
                  <td>
                    <ImageButton
                      type="delete"
                      onClick={() => this.onRemoveLineItem(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderTotals() {
    // billQty: billQty,
    // billSubTotal: billSubTotal,
    // billAmount: billAmount,
    // billTaxAmount: billTaxAmount,
    // billDiscount: billDiscount,
    // taxSummaryList: taxSummaryList,
    // roundOff: roundOff,
    return (
      <React.Fragment>
        <div className="viewsales-section-header">Total:</div>
        <div className="viewsales-table-container">
          <table className="table">
            <tr>
              <td style={{ width: "50%" }}>Line item(s):</td>
              <td className="text-align-right">
                {this.state.lineItems.length}
              </td>
            </tr>
            <tr>
              <td style={{ width: "50%" }}>Qty:</td>
              <td className="text-align-right">{this.state.billQty}</td>
            </tr>
            <tr>
              <td>Subtotal:</td>
              <td className="text-align-right">{this.state.billSubTotal}</td>
            </tr>
            <tr>
              <td>Discount</td>
              <td className="text-align-right">{this.state.billDiscount}</td>
            </tr>
            <tr>
              <td>Tax:</td>
              <td className="text-align-right">{this.state.billTaxAmount}</td>
            </tr>
            <tr>
              <td>Total:</td>
              <td className="text-align-right">{this.state.billAmount}</td>
            </tr>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderBackButton() {
    return (
      <div
        onClick={() => {
          if (this.state.selectedSubCategoryID !== "") {
            this.setState({
              selectedSubCategoryID: "",
              selectedSubCategory: "",
            });
          } else {
            this.setState({
              selectedCategoryID: "",
              selectedCategory: "",
              favouriteSelected: false,
            });
          }
        }}
        className={
          this.state.desktopOption === 2 ? "button-product2" : "button-product"
        }
      >
        <div className="button-sub-text-text">Back</div>
      </div>
    );
  }

  renderProducts() {
    return (
      <React.Fragment>
        {this.state.Products.filter((e) => {
          let show = true;
          if (e.hasOwnProperty("ParentProductID")) {
            show = false;
          }
          if (
            e.hasOwnProperty("SearchMode") &&
            e.SearchMode.toLowerCase() === "scanonly"
          ) {
            // console.log(e.SearchMode)
            show = false;
          }
          if (
            show &&
            e.ProductTypeID !== 2 &&
            e.CategoryID === this.state.selectedCategoryID &&
            e.SubCategoryID === this.state.selectedSubCategoryID
          ) {
            return true;
          } else {
            return false;
          }
        }).map((product, index) => (
          <React.Fragment>
            {product.Images.length === 0 && (
              <div
                key={index}
                onClick={() => this.onProductSelected(product)}
                className={
                  this.state.desktopOption === 2
                    ? "button-product2"
                    : "button-product"
                }
              >
                {/* <img  style={{ width: "100%" }} src={product.Images[0].ImagePath} /> */}
                <div className="button-sub-text-text">
                  {product.Name}
                  {/* <br/>{this.props.user.store.Currency} {product.RetailPrice}/- */}
                </div>
                <div className="button-sub-text-price">
                  {this.props.user.store.Currency} {product.RetailPrice}/-
                </div>
              </div>
            )}
            {product.Images.length !== 0 && (
              <div
                key={index}
                onClick={() => this.onProductSelected(product)}
                className={
                  this.state.desktopOption === 2
                    ? "button-product2"
                    : "button-product"
                }
              >
                <img
                  style={{
                    width: this.state.desktopOption === 2 ? "50%" : "100%",
                  }}
                  src={product.Images[0].ImagePath}
                />

                <div className="button-sub-text-img">{product.Name}</div>
                <div className="button-sub-text-price">
                  {this.props.user.store.Currency} {product.RetailPrice}/-
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  renderAdditionalCharges() {
    return (
      <React.Fragment>
        {this.state.showAdditionalCharges && (
          <Window
            header={"Additonal Charges"}
            // height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showAdditionalCharges: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "ok") {
                this.setState({
                  showAdditionalCharges: false,
                  TCSAmount: this.state.tmpTCSAmount,
                  TCSPercentage: this.state.TCSPercentage,
                  TCSAmountAppliedOn: this.state.tmpTCSAmountAppliedOn,
                  billAmount:
                    Number(this.state.billAmount) +
                    Number(this.state.tmpTCSAmount),
                });
              } else {
                this.setState({ showAdditionalCharges: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <Input
              type="number"
              label="Amount"
              value={this.state.tmpTCSAmountAppliedOn}
              onChange={(value) =>
                this.setState({
                  tmpTCSAmountAppliedOn: value,
                  tmpTCSAmount: this.roundUpTo2(
                    (value * this.state.TCSPercentage) / 100
                  ),
                })
              }
            />
            <Input
              type="number"
              label="TCS %"
              value={this.state.TCSPercentage}
              onChange={(value) =>
                this.setState({
                  TCSPercentage: value,
                  tmpTCSAmount: this.roundUpTo2(
                    (value * this.state.tmpTCSAmountAppliedOn) / 100
                  ),
                })
              }
            />
            <Input
              type="number"
              label="TCS Amount"
              value={this.state.tmpTCSAmount}
              disabled={true}
            />
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderPayButton() {
    let css = "pay-btn";
    if (this.state.type === actionTypes.SALE_NEW) {
      if (
        this.state.transactionType.TransactionType.toLowerCase() !==
        "sales return"
      ) {
        css = "pay-btn";
      } else {
        css = "pay-btn sales-return";
      }
    } else {
      css = "pay-btn sales-edit";
    }

    return (
      <React.Fragment>
        {this.state.tableOrder &&
          this.state.KOTType.toLowerCase() === "dine in" && (
            <div>
              <button
                type="button"
                className="btn btn-flat btn-app button-KOT"
                style={{ width: "20%" }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => this.onPrintKOT()}
              >
                (F10) KOT
              </button>

              <button
                type="button"
                className="btn btn-flat btn-app buttton-printbill"
                style={{ width: "20%" }}
                onClick={() => this.onPrintBill()}
                disabled={this.state.lineItems.length > 0 ? false : true}
              >
                (F11)PrintBill
              </button>

              <button
                type="button"
                style={{
                  marginBottom: "10px",
                  marginLeft: "10px",
                  width: "50%",
                }}
                disabled={this.state.lineItems.length > 0 ? false : true}
                className="btn btn-lg btn-flat btn-success pay-btn"
                onClick={() => {
                  this.setState({ printKOT: false });
                  this.onPaymentClick();
                }}
              >
                <div className="pull-left">
                  <b>
                    <font style={{ fontSize: "12px" }}>(F12)</font>{" "}
                    {this.state.transactionType.PaymentModeChecked
                      ? " Pay"
                      : " Save"}
                  </b>
                  <small>
                    {" "}
                    Items: {this.state.lineItems.length} , Quantity:{" "}
                    {this.state.billQty}
                  </small>{" "}
                </div>
                <div className="pull-right">
                  <b>
                    {this.props.user.store.Currency} {this.state.billAmount}
                  </b>
                </div>
              </button>
            </div>
          )}

        {this.state.tableOrder &&
          this.state.KOTType.toLowerCase() !== "dine in" && (
            <button
              type="button"
              disabled={this.state.lineItems.length > 0 ? false : true}
              className={
                this.state.type === actionTypes.SALE_NEW
                  ? this.state.transactionType.TransactionType.toLowerCase() !==
                    "sales return"
                    ? "btn btn-lg btn-flat btn-success btn-block pay-btn"
                    : "btn btn-lg btn-flat btn-warning btn-block pay-btn"
                  : this.state.type === actionTypes.SALE_EDIT
                  ? "btn btn-lg btn-info btn-block pay-btn"
                  : "btn btn-lg btn-warning btn-block pay-btn"
              }
              onClick={() => this.onPaymentClick()}
            >
              <div className="pull-left">
                <b>
                  <font style={{ fontSize: "12px" }}>(F12)</font>{" "}
                  {this.state.transactionType.PaymentModeChecked
                    ? " Pay"
                    : " Save"}
                </b>
                <small>{this.state.billQty} items</small>{" "}
              </div>
              <div className="pull-right">
                <b>
                  {this.props.user.store.Currency} {this.state.billAmount}
                </b>
              </div>
            </button>
          )}

        {!this.state.tableOrder && (
          <button
            type="button"
            disabled={this.state.lineItems.length > 0 ? false : true}
            className={css}
            onClick={() => this.onPaymentClick()}
          >
            <div className="pull-left">
              <b>
                <font style={{ fontSize: "12px" }}>(F12)</font>{" "}
                {this.state.transactionType.PaymentModeChecked
                  ? " Pay"
                  : " Save"}
              </b>
              {/* <small>{this.state.billQty} items</small>{" "} */}
              <small style={{ fontSize: "12px" }}>
                {" "}
                (Items: {this.state.lineItems.length} , Quantity:{" "}
                {this.state.billQty})
              </small>{" "}
            </div>
            <div className="pull-right">
              <b>
                {this.props.user.store.Currency} {this.state.billAmount}
              </b>
            </div>
          </button>
        )}
      </React.Fragment>
    );
  }

  renderFooterButtons() {
    return (
      <React.Fragment>
        <div className="sales-footer-option-left">
          <div className="sales-footer-btn">
            <ImageButton
              type="list"
              label="Sales History"
              onClick={() => {
                this.props.history.push({
                  pathname: "/home/sales/saleslist",
                  state: { type: "list" },
                });
              }}
            />
            <div className="sales-footer-btn-label">Sales History</div>
          </div>
          {this.state.key.trim() === "" && (
            <div className="sales-footer-btn">
              <ImageButton
                type="park"
                label="Park Sale"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.onParkSaleClicked();
                }}
              />
              <div className="sales-footer-btn-label">Park Sale</div>
            </div>
          )}
          {this.state.key.trim() === "" && (
            <div className="sales-footer-btn">
              <ImageButton
                type="retrieve"
                label="Retrieve Sale"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.showParkedSales();
                }}
              />
              <div className="sales-footer-btn-label">Retrieve Sale</div>
            </div>
          )}
          {/* {this.state.transactionType && (<span className="badge bg-green">{this.state.drafts.length}</span>)} */}
          {this.state.key.trim() === "" && (
            <div className="sales-footer-btn">
              <ImageButton
                type="delete"
                label="Discard Sale"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.onDiscardClicked();
                }}
              />
              <div className="sales-footer-btn-label">Discard Sale</div>
            </div>
          )}
        </div>

        <div className="sales-footer-option-right">
          {this.state.enableTCS && (
            <div className="sales-footer-btn">
              {" "}
              <ImageButton
                type="other"
                label="Addn Charges"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.onAddnChargesClick();
                }}
              />
              <div className="sales-footer-btn-label">Addn Charges</div>
            </div>
          )}
          {/* {this.props.user.store.hasOwnProperty("EWayBill") && this.props.user.store.EWayBill && (<ImageButton type="ewaybill" label="eWay Bill" disabled={this.state.lineItems.length >0 ? false: true}  onClick={() => {this.onEWayBillClicked()}}/>  )} */}
          {this.props.config.CustomFields.filter(
            (f) => f.ModuleName.toLowerCase() === "sales"
          ).length > 0 && (
            <div className="sales-footer-btn">
              <ImageButton
                type="info"
                label="Additional Info"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.setState({
                    showAdditionalInfo: true,
                    additionalInfoMode: "edit",
                  });
                }}
              />
              <div className="sales-footer-btn-label">Addn Info</div>
            </div>
          )}
          {this.props.registration.registration.Delivery && (
            <div className="sales-footer-btn">
              <ImageButton
                type="delivery"
                label="Delivery"
                disabled={this.state.lineItems.length > 0 ? false : true}
                onClick={() => {
                  this.onDeliveryClicked();
                }}
              />
              <div className="sales-footer-btn-label">Delivery</div>
            </div>
          )}
          {/* {this.state.notes !== "" && (<span className="badge bg-green">Notes</span>)} */}
          {/* {this.state.notes !== ""  ? "secondary" : "primary"} */}
          <div className="sales-footer-btn">
            <ImageButton
              type="notes"
              icon="notes"
              label="Notes"
              disabled={this.state.lineItems.length > 0 ? false : true}
              onClick={() => {
                this.setState({ showNotesModal: true });
              }}
            />
            <div className="sales-footer-btn-label">Notes</div>
          </div>
          <div className="sales-footer-btn">
            <ImageButton
              type="discount"
              label="Discount"
              disabled={this.state.lineItems.length > 0 ? false : true}
              onClick={() => {
                this.onShowDiscountOptions();
              }}
            />
            <div className="sales-footer-btn-label">Discount</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMenuItem = (option, props) => {
    return (
      <div className="sales-product-list-item">
        <div className="sales-product-list-item-maintext">
          <div className="left w85">{option.Name}</div>
          <div className="right w15">
            {this.props.user.store.Currency} {option.RetailPrice}
          </div>
        </div>
        <div>
          <div className="sales-product-list-item-subtext">{option.SKU}</div>
          {!this.props.registration.registration.EnablePosBillingForPincode && (
            <div className="sales-product-list-item-subtext">
              {option.hasOwnProperty("CustomField1") &&
                option.CustomField1.toString().trim() !== "" &&
                this.state.productCustomField1DisplayName.trim() !== "" && (
                  <React.Fragment>
                    {this.state.productCustomField1DisplayName}:{" "}
                    {option.CustomField1}
                  </React.Fragment>
                )}
              {option.hasOwnProperty("CustomField2") &&
                option.CustomField2.toString().trim() !== "" &&
                this.state.productCustomField2DisplayName.trim() !== "" && (
                  <React.Fragment>
                    , {this.state.productCustomField2DisplayName}:{" "}
                    {option.CustomField2}
                  </React.Fragment>
                )}
              {option.hasOwnProperty("CustomField3") &&
                option.CustomField3.toString().trim() !== "" &&
                this.state.productCustomField3DisplayName.trim() !== "" && (
                  <React.Fragment>
                    , {this.state.productCustomField3DisplayName}:{" "}
                    {option.CustomField3}
                  </React.Fragment>
                )}
              {option.hasOwnProperty("CustomField4") &&
                option.CustomField4.toString().trim() !== "" &&
                this.state.productCustomField4DisplayName.trim() !== "" && (
                  <React.Fragment>
                    , {this.state.productCustomField4DisplayName}:{" "}
                    {option.CustomField4}
                  </React.Fragment>
                )}
              {option.hasOwnProperty("CustomField5") &&
                option.CustomField5.toString().trim() !== "" &&
                this.state.productCustomField5DisplayName.trim() !== "" && (
                  <React.Fragment>
                    , {this.state.productCustomField5DisplayName}:{" "}
                    {option.CustomField5}
                  </React.Fragment>
                )}
              {option.hasOwnProperty("CustomField6") &&
                option.CustomField6.toString().trim() !== "" &&
                this.state.productCustomField6DisplayName.trim() !== "" && (
                  <React.Fragment>
                    , {this.state.productCustomField6DisplayName}:{" "}
                    {option.CustomField6}
                  </React.Fragment>
                )}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderProductSearch() {
    let showAddProductBtn = false;
    if (
      this.props.getVisibility("Products", "create") !== "none" ||
      this.props.registration.registration.EnablePosBillingForPincode
    ) {
      showAddProductBtn = true;
    }
    return (
      <React.Fragment>
        <div className="col-md-12 sales-productsearch-btns">
          <div className="input-group">
            <div className="input-group-btn">
              {this.state.tableOrder && (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn button-lg button-left-rounded"
                  >
                    {this.state.table && (
                      <span>
                        {this.state.KOTType}: {this.state.table.Table}
                      </span>
                    )}
                    {!this.state.table && <span>{this.state.KOTType}</span>}
                  </button>
                </React.Fragment>
              )}

              {!this.props.registration.registration.KOT &&
                !this.state.tableOrder && (
                  <React.Fragment>
                    {this.state.type === actionTypes.SALE_NEW && (
                      <button
                        type="button"
                        className="btn button-lg button-left-rounded button-border"
                        onClick={() => {
                          if (this.state.transactionTypes.length > 0) {
                            this.setState({ showTransactionTypes: true });
                          }
                        }}
                      >
                        New {this.state.transactionType.TransactionType}
                      </button>
                    )}

                    {this.state.type === actionTypes.SALE_EDIT && (
                      <button
                        type="button"
                        className="btn button-lg button-left-rounded button-border"
                      >
                        Edit {this.state.transactionType.TransactionType}
                      </button>
                    )}
                  </React.Fragment>
                )}

              {this.props.registration.registration.KOT &&
                !this.state.tableOrder && (
                  <React.Fragment>
                    <button
                      type="button"
                      onClick={() => this.setState({ KOTType: "Dine In" })}
                      className={
                        this.state.KOTType.toLowerCase() === "dine in"
                          ? "btn button-lg button-left-rounded button-selected button-border"
                          : "btn button-lg button-left-rounded button-border"
                      }
                    >
                      DINE IN
                    </button>
                    <button
                      type="button"
                      style={{ width: "120px" }}
                      onClick={() => this.setState({ KOTType: "Delivery" })}
                      className={
                        this.state.KOTType.toLowerCase() === "delivery"
                          ? "btn button-flat button-lg button-selected button-border"
                          : "btn button-lg button-flat button-border"
                      }
                    >
                      DELIVERY
                    </button>
                    <button
                      type="button"
                      style={{ width: "120px" }}
                      onClick={() => this.setState({ KOTType: "Pick Up" })}
                      className={
                        this.state.KOTType.toLowerCase() === "pick up"
                          ? "btn button-flat button-lg button-selected button-border"
                          : "button-lg btn button-flat button-border"
                      }
                    >
                      PICK UP
                    </button>
                  </React.Fragment>
                )}
              {this.state.salesChannels.length > 0 && (
                <button
                  type="button"
                  style={{ width: "120px" }}
                  onClick={() =>
                    this.setState({ showSalesChannelSelection: true })
                  }
                  className={"button-lg btn button-flat button-border"}
                >
                  {this.state.selectedSalesChannelName}
                </button>
              )}
            </div>

            {!this.props.registration.registration.OnlineBilling && (
              <>
                {!this.state.toggleSearch && (
                  <AsyncTypeahead
                    ref="refProductSearch"
                    id="my-prod-typeahead-id"
                    autoFocus={true}
                    allowNew={false}
                    isLoading={this.state.isProductTypeAheadLoading}
                    multiple={false}
                    options={this.state.productsTypeAheadOptions}
                    labelKey="Name"
                    minLength={3}
                    onSearch={this.onProuctTypeAheadSearch}
                    placeholder="(F1) Start typing to search products"
                    filterBy={["Name", "SKU", "BarCode"]}
                    bsSize={"large"}
                    dropup={false}
                    highlightOnlyResult={true}
                    selected={this.state.productsTypeAheadSelected}
                    onChange={(selected) => {
                      this.onProductSelected(selected[0]);
                      this.setState({ productsTypeAheadSelected: [] });
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <div className="sales-product-list-item">
                        <div className="sales-product-list-item-maintext">
                          <div className="left w85">{option.Name}</div>
                          <div className="right w15">
                            {this.props.user.store.Currency}{" "}
                            {option.RetailPrice}
                          </div>
                        </div>
                        <div>
                          <div className="sales-product-list-item-subtext">
                            {option.SKU}
                          </div>
                          {!this.props.registration.registration
                            .EnablePosBillingForPincode && (
                            <div className="sales-product-list-item-subtext">
                              {option.hasOwnProperty("CustomField1") &&
                                option.CustomField1.toString().trim() !== "" &&
                                this.state.productCustomField1DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    {this.state.productCustomField1DisplayName}:{" "}
                                    {option.CustomField1}
                                  </React.Fragment>
                                )}
                              {option.hasOwnProperty("CustomField2") &&
                                option.CustomField2.toString().trim() !== "" &&
                                this.state.productCustomField2DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    ,{" "}
                                    {this.state.productCustomField2DisplayName}:{" "}
                                    {option.CustomField2}
                                  </React.Fragment>
                                )}
                              {option.hasOwnProperty("CustomField3") &&
                                option.CustomField3.toString().trim() !== "" &&
                                this.state.productCustomField3DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    ,{" "}
                                    {this.state.productCustomField3DisplayName}:{" "}
                                    {option.CustomField3}
                                  </React.Fragment>
                                )}
                              {option.hasOwnProperty("CustomField4") &&
                                option.CustomField4.toString().trim() !== "" &&
                                this.state.productCustomField4DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    ,{" "}
                                    {this.state.productCustomField4DisplayName}:{" "}
                                    {option.CustomField4}
                                  </React.Fragment>
                                )}
                              {option.hasOwnProperty("CustomField5") &&
                                option.CustomField5.toString().trim() !== "" &&
                                this.state.productCustomField5DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    ,{" "}
                                    {this.state.productCustomField5DisplayName}:{" "}
                                    {option.CustomField5}
                                  </React.Fragment>
                                )}
                              {option.hasOwnProperty("CustomField6") &&
                                option.CustomField6.toString().trim() !== "" &&
                                this.state.productCustomField6DisplayName.trim() !==
                                  "" && (
                                  <React.Fragment>
                                    ,{" "}
                                    {this.state.productCustomField6DisplayName}:{" "}
                                    {option.CustomField6}
                                  </React.Fragment>
                                )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  />
                )}

                {this.state.toggleSearch && (
                  <input
                    type="text"
                    ref="refProductScan"
                    onChange={(evt) => {
                      this.setState({ queryText: evt.target.value });
                    }}
                    value={this.state.queryText}
                    onKeyPress={this.onProductBarCodeSearch}
                    autoFocus={true}
                    placeholder="(F2) Start scanning to search products"
                    className="form-control input-lg"
                  />
                )}
              </>
            )}

            {/* online billing */}
            {this.props.registration.registration.OnlineBilling && (
              <>
                {!this.state.toggleSearch && (
                  <AsyncTypeahead
                    ref="refProductSearch"
                    id="my-prod-typeahead-id"
                    autoFocus={true}
                    allowNew={false}
                    isLoading={this.state.isProductTypeAheadLoading}
                    multiple={false}
                    options={this.state.productsTypeAheadOptions}
                    labelKey="Name"
                    minLength={3}
                    onSearch={() => {}} // Dummy function to satisfy the component's requirement
                    // onInputChange={this.handleProductSearchOnlineThrottle} // Detect input changes
                    onInputChange={this.handleSearchOnlineInputChange} // Detect input changes
                    placeholder="(F1) Start typing to search products"
                    filterBy={() => true}
                    bsSize={"large"}
                    useCache={false} // Disable caching
                    dropup={false}
                    highlightOnlyResult={true}
                    selected={this.state.productsTypeAheadSelected}
                    onChange={(selected) => {
                      this.onProductSelected(selected[0]);
                      this.setState({ productsTypeAheadSelected: [] });
                    }}
                    renderMenuItemChildren={(option, props) => (
                      <div className="sales-product-list-item">
                        <div className="sales-product-list-item-maintext">
                          <div className="left w85">{option.Name}</div>
                          <div className="right w15">
                            {this.props.user.store.Currency}{" "}
                            {option.RetailPrice}
                          </div>
                        </div>
                        <div>
                          <div className="sales-product-list-item-subtext">
                            {option.SKU}
                          </div>
                          <div className="sales-product-list-item-subtext">
                            {option.BarCode}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                )}
                {this.state.toggleSearch && (
                  <input
                    type="text"
                    ref="refProductScan"
                    onChange={(evt) => {
                      this.setState({ queryText: evt.target.value });
                    }}
                    value={this.state.queryText}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.onProductScan(this.state.queryText);
                      }
                    }}
                    autoFocus={true}
                    placeholder="(F2) Start scanning to search products"
                    className="form-control input-lg"
                  />
                )}
                {/* <CustomAsyncTypeahead
                  ref="refProductSearch"
                  id="my-prod-typeahead-id"
                  autoFocus={true}
                  allowNew={false}
                  isLoading={this.state.isProductTypeAheadLoading}
                  multiple={false}
                  options={this.state.productsTypeAheadOptions}
                  labelKey="Name"
                  minLength={3}
                  onInputChange={(text, e) => {
                    console.log("onInputChange", text);
                    if (e.key === "Enter") {
                      console.log("onInputChange Enter", text);
                      this.onProductScan(text);
                      this.setState({
                        scannedInput: text,
                        productsTypeAheadOptions: [],
                      }); // Store the scanned input
                    } else {
                      console.log("onInputChange type", text);
                      this.handleProductSearchOnlineThrottle(text, e);
                      this.setState({ scannedInput: text }); // Store the scanned input
                    }
                  }}
                  onKeyDown={(e) => {
                    // if (e.key === "Enter") {
                    //   console.log("Enter");
                    //   if (this.state.scannedInput == "") {
                    //     console.log("Enter scannedInput=''");
                    //     this.setState({ productsTypeAheadOptions: [] });
                    //     return false;
                    //   }
                    //   // Trigger search when Enter is pressed (barcode scanner behavior)
                    //   this.setState({
                    //     isProductTypeAheadLoading: false,
                    //     productsTypeAheadOptions: [],
                    //     scannedInput: "",
                    //   });
                    //   this.onProductScan(this.state.scannedInput);
                    // }
                  }}
                  placeholder="(F1) Scan product or start typing to search products"
                  filterBy={() => true}
                  bsSize="large"
                  dropup={false}
                  highlightOnlyResult={true}
                  selected={this.state.productsTypeAheadSelected}
                  onChange={(selected) => {
                    console.log("onChange");
                    this.onProductSelected(selected[0]);
                    this.setState({ productsTypeAheadSelected: [] });
                    this.setState({ scannedInput: "" }); // Store the scanned input
                  }}
                  renderMenuItemChildren={this.renderMenuItem}
                /> */}
                {/* <CustomAsyncTypeahead
                  ref="refProductSearch"
                  id="my-prod-typeahead-id"
                  autoFocus={true}
                  allowNew={false}
                  isLoading={this.state.isProductTypeAheadLoading}
                  multiple={false}
                  options={this.state.productsTypeAheadOptions}
                  labelKey="Name"
                  minLength={3}
                  onInputChange={(text, e) => {
                    // Skip if this is from an Enter key (barcode scan)
                    if (e && e.key === "Enter") return;

                    console.log("Typing detected:", text);
                    this.handleProductSearchOnlineThrottle(text, e);
                    this.setState({ scannedInput: text });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      console.log("Barcode scan detected:", e.target.value);
                      e.preventDefault();

                      // Clear any pending search from typing
                      if (this.handleProductSearchOnlineThrottle.cancel) {
                        this.handleProductSearchOnlineThrottle.cancel();
                      }

                      // Process the barcode scan
                      this.onProductScan(e.target.value);
                      this.setState({
                        scannedInput: "",
                        productsTypeAheadOptions: [],
                      });
                    }
                  }}
                  placeholder="(F1) Scan product or start typing to search products"
                  filterBy={() => true}
                  bsSize="large"
                  dropup={false}
                  highlightOnlyResult={true}
                  selected={this.state.productsTypeAheadSelected}
                  onChange={(selected) => {
                    console.log("onChange");
                    this.onProductSelected(selected[0]);
                    this.setState({
                      productsTypeAheadSelected: [],
                      scannedInput: "",
                    });
                  }}
                  renderMenuItemChildren={this.renderMenuItem}
                /> */}
              </>
            )}

            {this.state.transactionType &&
              this.state.transactionType.TransactionType.toLowerCase() ===
                "sales return" && (
                <span
                  className="input-group-addon "
                  // style={{backgroundColor:"#f4f4f4",display:this.state.transactionType.TransactionType.toLowerCase() ==="sales return"? "": "none"}}
                  onClick={() =>
                    this.setState({ negativeQty: !this.state.negativeQty })
                  }
                >
                  <input
                    type="checkbox"
                    onChange={() =>
                      this.setState({ negativeQty: !this.state.negativeQty })
                    }
                    checked={this.state.negativeQty}
                  />
                  Negative Qty
                </span>
              )}
            <div className="input-group-btn">
              <button
                type="button"
                onClick={() =>
                  this.setState({ toggleSearch: !this.state.toggleSearch })
                }
                className={
                  showAddProductBtn
                    ? "btn button-flat button-lg button-border"
                    : "btn button-lg button-right-rounded button-border"
                }
              >
                <Icon type="toggle" />
              </button>
            </div>
            {showAddProductBtn && (
              <div className="input-group-btn">
                <button
                  type="button"
                  className="btn button-lg button-right-rounded button-border"
                  onClick={() =>
                    this.setState({
                      showAddProduct: true,
                      showInvoice: false,
                      scanedCode: "",
                    })
                  }
                >
                  <Icon type="addlg" />
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobile() {
    return (
      <React.Fragment>
        {!this.state.mobileShowCustomer && (
          <Page
            title={this.state.title}
            onClick={() => this.onHeaderBackClick()}
          >
            {(this.state.mobileLineItemSelected ||
              this.state.mobileOptionSelected === 1) && (
              <div className="header-new-background"></div>
            )}

            {this.props.user.cashRegister && (
              <div>
                {this.renderMobileHeaderOptions()}

                {!this.state.mobileLineItemSelected &&
                  this.state.mobileOptionSelected === 2 &&
                  !this.state.categorySelected &&
                  this.renderMobileCategories()}

                {!this.state.mobileLineItemSelected &&
                  this.state.categorySelected &&
                  this.state.mobileOptionSelected === 2 &&
                  this.renderMobileSubCategoriesAndProducts()}

                {!this.state.mobileLineItemSelected &&
                  this.state.mobileOptionSelected === 3 &&
                  this.renderMobileFavItems()}

                {!this.state.mobileLineItemSelected &&
                  this.state.mobileOptionSelected === 1 &&
                  this.renderMobileSearchCatalog()}

                {this.state.mobileLineItemSelected &&
                  this.renderMobileLineItems()}

                {(this.state.mobileOptionSelected === 2 ||
                  this.state.mobileOptionSelected === 3 ||
                  this.state.mobileLineItemSelected) &&
                  this.renderMobileFooterOptions()}
              </div>
            )}
          </Page>
        )}

        {this.state.mobileShowCustomer && (
          <MobileCustomer
            {...this.props}
            customer={this.state.selectedCustomer}
            customerAddress={this.state.selectedCustomerAddress}
            placeOfSupply={this.state.placeOfSupply}
            error={this.state.customerError}
            onCustomerChange={(
              customer,
              customerAddress,
              placeOfSupply,
              customerPet
            ) => {
              this.setState({
                selectedCustomer: customer,
                selectedPet: customerPet,
                selectedCustomerAddress: customerAddress,
                placeOfSupply: placeOfSupply,
                mobileOptionSelected: 2,
                title: this.state.transactionType.TransactionType,
                mobileShowInvoice: true,
                mobileShowCustomer: false,
              });
              this.onCustomerChange(customer);
            }}
            onCancel={() => {
              this.setState({
                mobileShowInvoice: true,
                title: this.state.transactionType.TransactionType,
                mobileShowCustomer: false,
                mobileOptionSelected: 2,
              });
            }}
          />
        )}
      </React.Fragment>
    );
  }

  renderInStockQty(lineItem) {
    let showInStockQty = false;
    if (
      lineItem.Product.ProductTypeID === 1 &&
      (this.state.negativeProductQtyNotAllowedForBilling ||
        this.props.registration.registration.EnablePosBillingForPincode)
    ) {
      showInStockQty = true;
    }
    if (
      !showInStockQty &&
      this.props.registration.registration.ShowOtherStoreInStockAtBilling
    ) {
      showInStockQty = true;
    }
    if (showInStockQty) {
      if (lineItem.InStockQty && lineItem.InStockQty > 0) {
        return (
          <div
            className="badge green"
            onClick={() => this.showAllStoreInStockQty(lineItem)}
          >
            In Stock Qty:&nbsp;{lineItem.InStockQty}
          </div>
        );
      } else {
        return (
          <div
            className="badge red"
            onClick={() => this.showAllStoreInStockQty(lineItem)}
          >
            In Stock Qty:&nbsp;{lineItem.InStockQty}
          </div>
        );
      }
    }
  }

  returnBillingHeight() {
    let screenHeight = window.screen.height; //window.innerHeight;
    let height = Number(screenHeight) - Number(76) - 90;
    return height + "px";
  }

  renderLineItems() {
    return (
      <React.Fragment>
        {this.state.transactionType && (
          <div
            ref="rightSection"
            style={{
              height: this.state.rightHeight,
              overflowY: "scroll",
              overflowX: "hidden",
            }}
            className="scroll2 sales-selected-line-items"
          >
            {this.state.lineItems.map((lineItem, index) => (
              <div
                className="sales-selected-line-item"
                // style={{ borderBottom:"1px solid #d2d6de",paddingBottom:"5px",marginBottom:"5px",marginRight:"5px"}}
              >
                <div
                  className="sales-selected-line-item-avatar"
                  onClick={() => this.onLineItemSelected(lineItem, index)}
                >
                  <Avatar
                    text={lineItem.Product.Name}
                    image={
                      lineItem.Product.Images &&
                      lineItem.Product.Images.length > 0
                        ? lineItem.Product.Images[0].ImagePath
                        : null
                    }
                  />
                </div>
                <div
                  className="sales-selected-line-item-content"
                  // style={{paddingRight:"0px" }}
                >
                  <div
                    className="sales-selected-line-item-content-productname"
                    // style={{ marginLeft:"0px",color:"#999"}}
                  >
                    <a onClick={() => this.onLineItemSelected(lineItem, index)}>
                      <b>
                        {lineItem.Product.Name} - {lineItem.Product.SKU}
                      </b>
                    </a>
                  </div>
                  {lineItem.Error && lineItem.Error !== "" && (
                    <div>{lineItem.Error}</div>
                  )}
                  <div className="sales-selected-line-item-content-qty">
                    <div className="sales-selected-line-item-content-qty1">
                      {this.renderQtyControl(lineItem, index)}
                    </div>
                    <div className="sales-selected-line-item-content-qty2">
                      {" "}
                      x &nbsp;{this.props.user.store.Currency}
                      {lineItem.RetailPrice}
                    </div>
                    <div className="sales-selected-line-item-content-qty3">
                      {" "}
                      = &nbsp;{this.props.user.store.Currency}
                      {lineItem.Amount}
                    </div>
                    <div className="sales-selected-line-item-content-qty4">
                      {/* <a onClick={() =>this.onRemoveLineItem(index)} className="sales-remove">
                                                <Icon type="delete"/>
                                            </a> */}
                      <ImageButton
                        type="delete"
                        onClick={() => this.onRemoveLineItem(index)}
                      />
                    </div>
                  </div>
                  <div>
                    MRP: {this.props.user.store.Currency}
                    {lineItem.Product.MRP} &nbsp;&nbsp;&nbsp;Dis:{" "}
                    {this.props.user.store.Currency}
                    {lineItem.DiscountAmount}&nbsp;&nbsp;&nbsp; Tax:{" "}
                    {this.props.user.store.Currency}
                    {lineItem.TotalTax}
                  </div>
                  <div>{this.renderInStockQty(lineItem)}</div>
                  {lineItem.hasOwnProperty("Discounts") && (
                    <React.Fragment>
                      {lineItem.Discounts.map((m, index) => (
                        <div key={index}>
                          {m.DiscountType + ":"} {m.DiscountInfo}{" "}
                          {m.DiscountAmount > 0
                            ? ", Discount: " +
                              this.props.user.store.Currency +
                              m.DiscountAmount
                            : ""}
                        </div>
                      ))}
                    </React.Fragment>
                  )}
                  {lineItem.Employee.key !== "" && (
                    <div>
                      <i className="fa fa-user" />{" "}
                      {lineItem.Employee.EmployeeFirstName}{" "}
                      {lineItem.Employee.EmployeeLastName}
                    </div>
                  )}
                  {this.state.transactionType.EmployeeMandatoryChecked &&
                    this.state.transactionType
                      .EmployeeManadatoryTransactionORLineItemLevel ===
                      "lineitem" &&
                    lineItem.Employee.key === "" && (
                      <div style={{ color: "red" }}>Please select employee</div>
                    )}
                  {lineItem.Notes.trim() !== "" && (
                    <div>
                      <i className="fa fa-sticky-note" /> {lineItem.Notes}
                    </div>
                  )}
                  {lineItem.KOTNo && <div>KOT: {lineItem.KOTNo}</div>}
                  {!this.props.registration.registration
                    .EnablePosBillingForPincode && (
                    <div
                      className="product-description"
                      // style={{ marginLeft: "0px",color:"#999"}}
                    >
                      {lineItem.Product.hasOwnProperty("CustomField1") &&
                        lineItem.Product.CustomField1.trim() !== "" &&
                        this.state.productCustomField1DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            {this.state.productCustomField1DisplayName} :{" "}
                            {lineItem.Product.CustomField1}
                          </React.Fragment>
                        )}
                      {lineItem.Product.hasOwnProperty("CustomField2") &&
                        lineItem.Product.CustomField2.trim() !== "" &&
                        this.state.productCustomField2DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            {" "}
                            , {this.state.productCustomField2DisplayName} :{" "}
                            {lineItem.Product.CustomField2}
                          </React.Fragment>
                        )}
                      {lineItem.Product.hasOwnProperty("CustomField3") &&
                        lineItem.Product.CustomField3.trim() !== "" &&
                        this.state.productCustomField3DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            , {this.state.productCustomField3DisplayName}:{" "}
                            {lineItem.Product.CustomField3}{" "}
                          </React.Fragment>
                        )}
                      {lineItem.Product.hasOwnProperty("CustomField4") &&
                        lineItem.Product.CustomField4.trim() !== "" &&
                        this.state.productCustomField4DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            , {this.state.productCustomField4DisplayName} :{" "}
                            {lineItem.Product.CustomField4}{" "}
                          </React.Fragment>
                        )}
                      {lineItem.Product.hasOwnProperty("CustomField5") &&
                        lineItem.Product.CustomField5.trim() !== "" &&
                        this.state.productCustomField5DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            , {this.state.productCustomField5DisplayName}:{" "}
                            {lineItem.Product.CustomField5}
                          </React.Fragment>
                        )}
                      {lineItem.Product.hasOwnProperty("CustomField6") &&
                        lineItem.Product.CustomField6.trim() !== "" &&
                        this.state.productCustomField6DisplayName.trim() !==
                          "" && (
                          <React.Fragment>
                            , {this.state.productCustomField6DisplayName}:{" "}
                            {lineItem.Product.CustomField6}
                          </React.Fragment>
                        )}
                    </div>
                  )}
                  {lineItem.Product.hasOwnProperty("InventoryType") &&
                    lineItem.Product.InventoryType.toLowerCase() ===
                      "unique no" && (
                      <Button
                        type="secondary"
                        text={
                          lineItem.Product.UniqueLabel.trim() !== ""
                            ? lineItem.Product.UniqueLabel
                            : "Unique No"
                        }
                        onClick={() =>
                          this.setState({
                            showUniqueNoInput: true,
                            selectedLineItem: lineItem,
                            selectedLineItemIndex: index,
                            selectedProduct: lineItem.Product,
                          })
                        }
                      />
                    )}
                  <div
                    className="product-description"
                    // style={{marginLeft:"0px", color: "#999",}}
                  >
                    {lineItem.Product.BatchTrackingBatchNoRequired &&
                      lineItem.BatchNo && (
                        <React.Fragment>
                          Batch No: {lineItem.BatchNo}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingMRPRequired &&
                      lineItem.BatchMRP && (
                        <React.Fragment>
                          {" "}
                          MRP: {lineItem.BatchMRP}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingMfgDateRequired &&
                      lineItem.BatchMfgDate && (
                        <React.Fragment>
                          {" "}
                          Mfg: {lineItem.BatchMfgDate}
                        </React.Fragment>
                      )}
                    {lineItem.Product.BatchTrackingExpDateRequired &&
                      lineItem.BatchExpDate && (
                        <React.Fragment>
                          {" "}
                          Exp: {lineItem.BatchExpDate}
                        </React.Fragment>
                      )}
                    {/* {lineItem.BatchID !=="" && <React.Fragment>{" "}In Stock Qty:{" "}{lineItem.InStockQty}</React.Fragment>} */}
                  </div>
                  {this.renderLineItemBatchSelection(lineItem, index)}
                  {lineItem.ModifierApplicable && (
                    <Button
                      type="secondary"
                      text="Modifier(s)"
                      onClick={() => {
                        this.getApplicableModifierGroups(lineItem.Product.key);
                        this.setState({
                          showModifiers: true,
                          selectedLineItemIndex: index,
                          selectedModifiers: lineItem.Modifiers
                            ? lineItem.Modifiers
                            : [],
                          modifierQty: lineItem.ModifierQty
                            ? lineItem.ModifierQty
                            : 0,
                          modifierAmount: lineItem.ModifierAmount
                            ? lineItem.ModifierAmount
                            : 0,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderDesktop() {
    const handlers = {
      productSearch: this.shortcutProductSearch,
      productScan: this.shortcutProductScan,
      newCustomer: this.shortcutNewCustomer,
      searchCustomer: this.shortcutSearchCustomer,
      pay: this.shortcutPay,
      printBill: this.onPrintBill,
      printKOT: this.onPrintKOT,
    };

    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        {this.props.user.cashRegister && !this.props.isMobileApp() && (
          <React.Fragment>
            <div className="sales-container" id="sales-container">
              <div style={{ display: "flex" }}>
                <div className="w65" style={{ marginRight: "10px" }}>
                  <div
                    className="sales-product-tab"
                    style={{ height: this.state.billingHeight }}
                  >
                    <div
                      className="row"
                      style={{ padding: "10px", paddingBottom: "0px" }}
                    >
                      {this.renderProductSearch()}
                    </div>
                    <div className="row">
                      {this.state.desktopOption == 1 &&
                        this.renderDesktopOption1()}
                      {this.state.desktopOption === 2 &&
                        this.renderDesktopOption2()}
                      {this.state.desktopOption === 3 &&
                        this.renderDesktopOption3()}
                    </div>
                  </div>
                </div>
                <div className="w35">
                  <div
                    className="sales-item-list"
                    style={{ height: this.state.billingHeight }}
                  >
                    <CustomerSearch
                      {...this.props}
                      ref="refSearchCustomer"
                      mobile={false}
                      showAddOption={true}
                      customer={this.state.selectedCustomer}
                      error={this.state.customerError}
                      onCustomerChange={this.onCustomerChange}
                      onAddCustomer={(newCustomer) => {
                        this.setState({
                          showAddCustomer: true,
                          showInvoice: false,
                          newCustomer: newCustomer,
                        });
                      }}
                      onCustomerEdit={() =>
                        this.props.config.ConnectionStatus.Status ===
                        "connected"
                          ? this.setState({
                              showAddCustomer: true,
                              showInvoice: false,
                            })
                          : false
                      }
                      onShowCustomerDashBoard={() => {
                        this.setState({
                          showCustomerDashBoard: true,
                          showInvoice: false,
                        });
                      }}
                    />
                    {this.state.selectedCustomer &&
                      this.state.selectedCustomer.Addresses &&
                      this.state.selectedCustomer.Addresses.length > 0 && (
                        <Input
                          type="typeaheadselect"
                          placeholder="Select address"
                          options={this.state.selectedCustomer.Addresses}
                          labelKey="Address1"
                          filterBy={[
                            "AddressType",
                            "Address1",
                            "Address2",
                            "City",
                            "State",
                            "PostalCode",
                            "Country",
                          ]}
                          columns={[
                            {
                              Column: "AddressType",
                              Filter: false,
                              SelectedText: true,
                              SearchText: true,
                              Main: true,
                            },
                            {
                              Column: "Address1",
                              Filter: true,
                              SearchText: true,
                              SelectedText: true,
                            },
                            {
                              Column: "Address2",
                              Filter: true,
                              SearchText: true,
                            },
                            { Column: "City", Filter: true, SearchText: true },
                            {
                              Column: "State",
                              Filter: false,
                              SearchText: true,
                            },
                            {
                              Column: "PostalCode",
                              Filter: false,
                              SearchText: true,
                            },
                            {
                              Column: "Country",
                              Filter: false,
                              SearchText: true,
                            },
                          ]}
                          value={this.state.selectedCustomerAddress}
                          onChange={(selected) => {
                            this.setState({
                              selectedCustomerAddress: selected[0],
                              placeOfSupply: selected[0].State,
                            });
                            this.onSelectedCustomerAddressChange(
                              selected[0].State
                            );
                            // this.setState({transactionLevelEmployeeSelected:selected[0]})
                          }}
                          onClick={(action) => {
                            if (action === "remove") {
                              this.setState({ selectedCustomerAddress: null });
                              this.onSelectedCustomerAddressChange("");
                            }
                          }}
                          // error={this.state.employeeMandatoryAtTransactionError}
                        />
                      )}
                    {this.state.selectedCustomer && (
                      <CustomerPets
                        {...this.props}
                        customer={this.state.selectedCustomer}
                        selectedPet={this.state.selectedCustomerPet}
                        onPetChange={(pet) => {
                          this.setState({ selectedCustomerPet: pet });
                        }}
                      />
                    )}
                    {this.state.transactionType &&
                      this.state.transactionType
                        .EmployeeManadatoryTransactionORLineItemLevel ===
                        "transaction" && (
                        <Input
                          type="typeaheadselect"
                          placeholder="Select employee"
                          options={this.state.employeeList}
                          labelKey="EmployeeName"
                          filterBy={[
                            "EmployeeName",
                            "EmployeeCode",
                            "EmployeeFirstName",
                            "EmployeeLastName",
                          ]}
                          columns={[
                            {
                              Column: "EmployeeName",
                              Filter: false,
                              SelectedText: true,
                              SearchText: true,
                              Main: true,
                            },
                            {
                              Column: "EmployeeCode",
                              Filter: true,
                              SearchText: true,
                              SelectedText: true,
                            },
                            { Column: "EmployeeFirstName", Filter: true },
                            { Column: "EmployeeLastName", Filter: true },
                            {
                              Column: "PhoneNo",
                              Filter: false,
                              SearchText: true,
                            },
                          ]}
                          value={this.state.transactionLevelEmployeeSelected}
                          onChange={(selected) => {
                            this.setState({
                              transactionLevelEmployeeSelected: selected[0],
                            });
                          }}
                          onClick={(action) => {
                            if (action === "remove") {
                              this.setState({
                                transactionLevelEmployeeSelected: null,
                              });
                            }
                          }}
                          error={this.state.employeeMandatoryAtTransactionError}
                        />
                      )}
                    {this.state.desktopOption === 3 && (
                      <div>{this.renderTotals()}</div>
                    )}
                    {this.state.desktopOption !== 3 && this.renderLineItems()}
                  </div>
                </div>
              </div>
            </div>
            <div className="sales-footer">
              {this.state.transactionType && (
                <div className="row">
                  <div className="col-md-8 sales-footer-options">
                    {this.renderFooterButtons()}
                  </div>
                  <div className="col-md-4" style={{ paddingLeft: "0px" }}>
                    {this.renderPayButton()}
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </HotKeys>
    );
  }

  renderParkedSales() {
    return (
      <React.Fragment>
        {this.state.showParkedSalesModal && (
          <Window
            header={"Retrieve Sales"}
            width={"600px"}
            height={"400px"}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showParkedSalesModal: false })}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}
          >
            {this.state.drafts &&
              this.state.drafts.length === 0 &&
              this.state.transactionType && (
                <div>No parked transaction(s) found</div>
              )}
            {this.state.drafts &&
              this.state.drafts.length > 0 &&
              this.state.transactionType && (
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th style={{ width: "60px" }}></th>
                      {this.props.isMobileApp() && <th>Parked Transaction</th>}
                      {!this.props.isMobileApp() && <th>Transaction Time</th>}
                      {!this.props.isMobileApp() && <th>Customer</th>}
                      {!this.props.isMobileApp() && <th>Qty</th>}
                      {!this.props.isMobileApp() && <th>Amount</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.drafts.map((draftSale, index) => (
                      <React.Fragment>
                        {draftSale.Transaction.TransactionType
                          .TransactionType ===
                          this.state.transactionType.TransactionType && (
                          <tr key={index}>
                            <td>
                              <button
                                type="button"
                                className="btn btn-flat btn-md btn-default "
                                onClick={() =>
                                  this.onRetriveParkedSales(
                                    draftSale.Transaction,
                                    draftSale.key
                                  )
                                }
                              >
                                <i className="fa fa-mail-forward"></i>
                              </button>
                            </td>
                            {this.props.isMobileApp() && (
                              <td>
                                <div>
                                  {draftSale.ActionOn &&
                                    new Date(draftSale.ActionOn.seconds * 1000)
                                      .toString()
                                      .substring(0, 24)}
                                </div>
                                <div>
                                  {draftSale.Transaction.Customer
                                    ? draftSale.Transaction.Customer.FirstName +
                                      " " +
                                      draftSale.Transaction.Customer.LastName
                                    : ""}
                                </div>
                                <div>Qty: {draftSale.Transaction.BillQty}</div>
                                <div>
                                  Amount: {draftSale.Transaction.BillAmount}
                                </div>
                              </td>
                            )}
                            {!this.props.isMobileApp() && (
                              <td>
                                {draftSale.ActionOn &&
                                  new Date(draftSale.ActionOn.seconds * 1000)
                                    .toString()
                                    .substring(0, 24)}
                              </td>
                            )}
                            {!this.props.isMobileApp() && (
                              <td>
                                {draftSale.Transaction.Customer
                                  ? draftSale.Transaction.Customer.FirstName +
                                    " " +
                                    draftSale.Transaction.Customer.LastName
                                  : ""}
                              </td>
                            )}
                            {!this.props.isMobileApp() && (
                              <td>{draftSale.Transaction.BillQty}</td>
                            )}
                            {!this.props.isMobileApp() && (
                              <td>{draftSale.Transaction.BillAmount}</td>
                            )}
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              )}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderLineItemModal() {
    return (
      <React.Fragment>
        {this.state.showLineItemModal && this.state.selectedLineItem && (
          <Window
            header={
              this.state.selectedLineItem.Product.SKU +
              "-" +
              this.state.selectedLineItem.Product.Name
            }
            width={"700px"}
            height={"400px"}
            modal={false}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showLineItemModal: false })}
            onButtonClick={(buttonType) => {
              // console.log('buttonType',buttonType)
              if (buttonType.toLowerCase() === "ok") {
                this.onLineItemChangesDone();
              } else {
                this.setState({ showLineItemModal: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            {this.state.selectedLineItem && (
              <div
              // className="box-body"
              // style={{
              //     height: this.props.isMobileApp()
              //         ? "70vh"
              //         : "",
              //     overflowY: this.props.isMobileApp()
              //         ? "scroll"
              //         : "hidden",
              //     overflowX: "hidden",
              //     padding: "0px",
              // }}
              >
                {// this.state.transactionType.TransactionType.toLowerCase() !==
                //   "stock update" &&
                this.props.registration.registration
                  .EnablePosBillingForPincode &&
                  this.state.selectedLineItem.Product.CustomField2 === "GC" &&
                  this.state.selectedLineItem.Product.CustomField4 === "" && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="gcissue-info">
                          If the product is incorrect, featuring a different
                          image, an MRP that varies, and an alternate packaging
                          quantity.
                          <br />
                          <a
                            onClick={() => {
                              this.setState({
                                selectedProductID: this.state.selectedLineItem
                                  .Product.key,
                                showAddProduct: true,
                                showInvoice: false,
                                showLineItemModal: false,
                              });
                            }}
                          >
                            Report issue
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row">
                  <div className="col-md-2">
                    <Input
                      type="number"
                      label="MRP"
                      value={this.state.selectedLineItem.Product.MRP}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginRight: "5px" }}>
                  {//     !this.props.registration.registration.hasOwnProperty(
                  //     "EnableBarcodeWeightingScale"
                  // ) &&
                  !this.props.isElectron() && (
                    <div
                      className="col-md-2 col-xs-6"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="form-group">
                        <label>Qty</label>
                        <input
                          type="number"
                          className="form-control"
                          autoFocus={true}
                          onChange={(evt) =>
                            this.onLineItemQtyChange(evt.target.value)
                          }
                          value={this.state.selectedLineItem.Qty}
                          onKeyPress={this.handleKeyPress}
                          disabled={
                            this.state.selectedLineItem.Product.hasOwnProperty(
                              "InventoryType"
                            ) &&
                            this.state.selectedLineItem.Product.InventoryType.toLowerCase() ===
                              "unique no"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>
                  )}
                  {this.props.registration.registration.hasOwnProperty(
                    "EnableBarcodeWeightingScale"
                  ) &&
                    this.props.registration.registration
                      .EnableBarcodeWeightingScale &&
                    this.props.isElectron() && (
                      <div
                        className="col-md-2 col-xs-6"
                        style={{ paddingRight: "0px" }}
                      >
                        <label>Qty</label>
                        <br />
                        <React.Fragment>
                          <input
                            type="number"
                            style={{
                              width: "65px",
                              border: "1px solid #ccc;",
                              height: "34px",
                              fontSize: "14px",
                              marginLeft: "2px",
                              marginRight: "2px",
                            }}
                            autoFocus={true}
                            onChange={(evt) =>
                              this.onLineItemQtyChange(evt.target.value)
                            }
                            onKeyPress={this.handleKeyPress}
                            disabled={
                              this.state.selectedLineItem.Product.hasOwnProperty(
                                "InventoryType"
                              ) &&
                              this.state.selectedLineItem.Product.InventoryType.toLowerCase() ===
                                "unique no"
                                ? true
                                : false
                            }
                            value={this.state.selectedLineItem.Qty}
                          />
                          <button
                            style={{
                              height: "33px",
                              fontSize: "14px",
                            }}
                            // fa-balance-scale
                            onClick={() => this.readQty()}
                            type="button"
                            className="btn-grp-last fa fa-balance-scale"
                          ></button>
                        </React.Fragment>{" "}
                      </div>
                    )}

                  {this.state.selectedLineItem.Product.hasOwnProperty(
                    "SecondaryUOMs"
                  ) &&
                    this.state.selectedLineItem.Product.SecondaryUOMs.length >
                      0 &&
                    this.state.selectedLineItem.Product.UOM &&
                    this.state.selectedLineItem.Product.UOM !== "" && (
                      <div
                        className="col-md-2 col-xs-6"
                        style={{ paddingRight: "0px" }}
                      >
                        <div className="form-group">
                          <label>UOM</label>
                          <select
                            className="form-control"
                            value={this.state.selectedLineItem.UOM}
                            onChange={(e) => {
                              let lineItem = this.state.selectedLineItem;
                              // lineItem.UOM = e.target.value
                              // this.setState({
                              //     selectedLineItem: lineItem
                              // })

                              if (
                                lineItem.Product.SalesUOM !== e.target.value
                              ) {
                                ///we need to change retail price
                                let retailPrice = lineItem.Product.RetailPrice;
                                let retailPriceAsPerPrimaryUOM = 0;
                                let primaryUOM = lineItem.Product.UOM; //kg
                                let salesUOM = lineItem.Product.SalesUOM;

                                // if(primaryUOM !==purchaseUOM)
                                // {
                                let salesUOMConversion = 1;
                                if (salesUOM !== primaryUOM) {
                                  salesUOMConversion = lineItem.Product.SecondaryUOMs.filter(
                                    (f) => f.SecondaryUOMValue === salesUOM
                                  )[0].Conversion;
                                }
                                // console.log(
                                //     "retailPrice",
                                //     retailPrice
                                // );
                                // console.log(
                                //     "salesUOMConversion",
                                //     salesUOMConversion
                                // );
                                retailPriceAsPerPrimaryUOM =
                                  retailPrice / Number(salesUOMConversion);
                                console.log(
                                  "retailPriceAsPerPrimaryUOM",
                                  retailPriceAsPerPrimaryUOM
                                );
                                let selectedUOMConversion = 1;
                                if (e.target.value !== primaryUOM) {
                                  selectedUOMConversion = lineItem.Product.SecondaryUOMs.filter(
                                    (f) =>
                                      f.SecondaryUOMValue === e.target.value
                                  )[0].Conversion;
                                }
                                console.log(
                                  "selectedUOMConversion",
                                  selectedUOMConversion
                                );
                                let newRetailPrice =
                                  retailPriceAsPerPrimaryUOM *
                                  selectedUOMConversion;
                                console.log("newRetailPrice", newRetailPrice);
                                lineItem.RetailPrice = newRetailPrice;
                                // }
                              } else {
                                lineItem.RetailPrice =
                                  lineItem.Product.RetailPrice;
                              }
                              lineItem.UOM = e.target.value;
                              this.setState({
                                selectedLineItem: lineItem,
                              });
                              this.onLineItemPriceChange(lineItem.RetailPrice);
                            }}
                          >
                            <option
                              value={this.state.selectedLineItem.Product.UOM}
                            >
                              {
                                this.state.uoms.filter(
                                  (f) =>
                                    f.value ===
                                    this.state.selectedLineItem.Product.UOM
                                )[0].text
                              }
                            </option>
                            {this.state.selectedLineItem.Product.SecondaryUOMs.map(
                              (t) => (
                                <option value={t.SecondaryUOMValue}>
                                  {t.SecondaryUOM}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    )}
                  <div
                    className={
                      this.state.selectedLineItem.Modifiers
                        ? "col-md-2 col-xs-6"
                        : "col-md-2 col-xs-6"
                    }
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="form-group">
                      <label>Price</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.onLineItemPriceChange(evt.target.value)
                        }
                        value={this.state.selectedLineItem.RetailPrice}
                        disabled={
                          !this.state.selectedLineItem.Product.AllowPriceEdit
                        }
                        onKeyPress={this.handleKeyPress}
                      />
                    </div>
                  </div>
                  {this.state.selectedLineItem.Modifiers && (
                    <div
                      className="col-md-2 col-xs-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="form-group">
                        <label>Modifier Amt</label>
                        <input
                          type="number"
                          className="form-control"
                          // onChange={(evt) => this.onLineItemPriceChange(evt.target.value)}
                          value={this.state.selectedLineItem.ModifierAmount}
                          disabled={true}
                        />
                      </div>
                    </div>
                  )}
                  {/* </div>
                <div className="row"> */}
                  <div
                    className="col-md-2 col-xs-6"
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="form-group">
                      <label>Disc %</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.onLineItemDiscountChange(evt.target.value)
                        }
                        value={this.state.selectedLineItem.DiscountPer}
                        disabled={
                          !this.state.selectedLineItem.Product.AllowDiscount ||
                          (this.state.selectedLineItem.hasOwnProperty(
                            "Discounts"
                          ) &&
                            this.state.selectedLineItem.Discounts.length > 0) ||
                          !this.state.transactionType.DiscountChecked
                        }
                        onKeyPress={this.handleKeyPress}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-2 col-xs-6"
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="form-group">
                      <label>Dis Amt</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.onLineItemDiscountAmountChange(evt.target.value)
                        }
                        value={this.state.selectedLineItem.DiscountAmount}
                        disabled={
                          !this.state.selectedLineItem.Product.AllowDiscount ||
                          (this.state.selectedLineItem.hasOwnProperty(
                            "Discounts"
                          ) &&
                            this.state.selectedLineItem.Discounts.length > 0) ||
                          !this.state.transactionType.DiscountChecked
                        }
                        onKeyPress={this.handleKeyPress}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.selectedLineItem.Modifiers
                        ? "col-md-3 col-xs-12"
                        : "col-md-3 col-xs-12"
                    }
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="form-group">
                      <label>Sub Total</label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control"
                          disabled={!this.state.enableLineItemSubTotalEdit}
                          value={
                            this.state.enableLineItemSubTotalEdit
                              ? this.state.subTotalWithDiscount
                              : this.state.selectedLineItem.SubTotalWithDiscount
                          }
                          onChange={(evt) => {
                            if (this.state.enableLineItemSubTotalEdit) {
                              this.setState({
                                subTotalWithDiscount: evt.target.value,
                              });
                            }
                          }}
                        />
                        {/* onChange={(evt)=>{this.onLineItemSubTotalChange(evt.target.value)}} */}

                        {this.props.user.store.hasOwnProperty(
                          "ChangeQtyOnPriceUpdate"
                        ) &&
                          this.props.user.store.ChangeQtyOnPriceUpdate &&
                          (this.state.selectedLineItem.Product.InventoryType ==
                            "FIFO" ||
                            this.state.selectedLineItem.Product
                              .ProductTypeID === 3) && (
                            <div className="input-group-btn">
                              <button
                                type="button"
                                className="btn btn-default btn-md"
                                onClick={() => {
                                  if (this.state.enableLineItemSubTotalEdit) {
                                    this.onLineItemSubTotalChange(
                                      this.state.subTotalWithDiscount
                                    );
                                  }
                                  this.setState({
                                    enableLineItemSubTotalEdit: !this.state
                                      .enableLineItemSubTotalEdit,
                                  });
                                }}
                              >
                                {this.state.enableLineItemSubTotalEdit
                                  ? "OK"
                                  : "Edit"}
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                {(this.state.selectedLineItem.Product.hasOwnProperty(
                  "EditWalletTopUpAmount"
                )
                  ? this.state.selectedLineItem.Product.EditWalletTopUpAmount
                  : false) && (
                  <div className="row">
                    <div
                      className="col-md-2 col-xs-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <label> Wallet top up amount</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(evt) =>
                          this.onLineItemWalletTopUpChange(evt.target.value)
                        }
                        value={
                          this.state.selectedLineItem.Product.WalletTopUpAmount
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="row" style={{ marginRight: "5px" }}>
                  <div
                    className="col-md-4 col-xs-12"
                    style={{ paddingRight: "0px" }}
                  >
                    {this.state.transactionType.CalculateTaxChecked && (
                      <div className="form-group">
                        <input
                          type="checkbox"
                          onChange={() => this.onChargeTaxOnProductChange()}
                          defaultChecked={
                            this.state.selectedLineItem.ChargeTaxOnProduct
                          }
                          disabled={
                            !this.state.selectedLineItem.Product.AllowTaxChange
                          }
                        />
                        <label>Charge taxes on this product</label>
                      </div>
                    )}
                  </div>
                  {this.state.selectedLineItem.ChargeTaxOnProduct && (
                    <div
                      className="col-md-4 col-xs-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="form-group">
                        <input
                          type="checkbox"
                          onChange={() => this.onTaxIncluxiveChange()}
                          defaultChecked={
                            this.state.selectedLineItem.TaxInclusive
                          }
                          disabled={
                            !this.state.selectedLineItem.Product.AllowTaxChange
                          }
                        />
                        <label>Tax inclusive</label>
                      </div>
                    </div>
                  )}
                </div>
                {this.state.selectedLineItem.ChargeTaxOnProduct && (
                  <div className="row" style={{ marginRight: "5px" }}>
                    <div
                      className="col-md-4 col-xs-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="form-group">
                        <label>HSN/SAC Code</label>
                        <input
                          type="text"
                          className="form-control"
                          onKeyPress={this.handleKeyPress}
                          onChange={(evt) => {
                            let lineItem = this.state.selectedLineItem;
                            lineItem.HSNSACCode = evt.target.value;
                            this.setState({
                              selectedLineItem: lineItem,
                            });
                          }}
                          value={this.state.selectedLineItem.HSNSACCode}
                          disabled={
                            !this.state.selectedLineItem.Product.AllowTaxChange
                          }
                          placeholder="HSN/SAC code"
                        />
                      </div>
                    </div>
                    <div
                      className="col-md-2 col-xs-12"
                      style={{ paddingRight: "0px" }}
                    >
                      <div className="form-group">
                        <label>Tax </label>
                        <select
                          className="form-control"
                          value={this.state.selectedLineItem.TaxID}
                          onChange={(evt) =>
                            this.onTaxGroupChange(
                              evt.target.value,
                              evt.target.options[evt.target.selectedIndex].text
                            )
                          }
                          disabled={
                            !this.state.selectedLineItem.Product.AllowTaxChange
                          }
                        >
                          <option value="">Select Tax</option>
                          {this.props.config.ProductTaxes.filter(
                            (f) => f.IsDeleted === false
                          ).map((t, index) => (
                            <option value={t.key}>{t.TaxGroup}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {this.state.selectedLineItem.TaxComponentAmount.map((t) => (
                      <div
                        className="col-md-2 col-xs-12"
                        style={{ paddingRight: "0px" }}
                      >
                        <Input
                          type="number"
                          label={
                            t.TaxName +
                            " " +
                            t.TaxPercentage +
                            (t.TaxPerAmt && t.TaxPerAmt === "amt" ? "" : "%")
                          }
                          value={t.TaxAmount}
                        />
                      </div>
                    ))}
                  </div>
                )}
                <div className="row" style={{ marginRight: "5px" }}>
                  <div
                    className="col-md-2 col-xs-12"
                    style={{ paddingRight: "0px" }}
                  >
                    <Input
                      type="number"
                      label="Total Amount"
                      value={this.state.selectedLineItem.Amount}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginRight: "5px" }}>
                  <div
                    className="col-md-6 col-xs-12"
                    style={{ paddingRight: "0px" }}
                  >
                    <Input
                      type="select"
                      label="Employee"
                      onChange={(value) => {
                        let selectedLineItem = this.state.selectedLineItem;
                        this.state.employeeList
                          .filter((e) => e.key === value)
                          .map((f) => {
                            f.EmployeeName =
                              f.EmployeeFirstName + " " + f.EmployeeLastName;
                            selectedLineItem.Employee = f;
                            this.setState({
                              selectedLineItem: selectedLineItem,
                              selectedItemEmployeeSelectionError: "",
                            });
                            return;
                          });
                      }}
                      value={this.state.selectedLineItem.Employee.key}
                      error={this.state.selectedItemEmployeeSelectionError}
                    >
                      <option value="">Select Employee</option>
                      {this.state.employeeList.map((e, index) => (
                        <option key={index} value={e.key}>
                          {e.EmployeeFirstName + " " + e.EmployeeLastName}
                        </option>
                      ))}
                    </Input>
                  </div>
                  <div
                    className="col-md-6 col-xs-12"
                    style={{ paddingRight: "0px" }}
                  >
                    <Input
                      type="text"
                      label="Notes"
                      value={this.state.selectedLineItem.Notes}
                      onChange={(value) => this.onLineItemNotesChange(value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderError() {
    return (
      <React.Fragment>
        {this.state.showError && (
          <Window
            header={"Error"}
            // height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showError: false })}
            onButtonClick={(buttonType) => {
              this.setState({ showError: false });
            }}
            buttons={[{ Type: "primary", Text: "OK", Align: "right" }]}
          >
            <ul>
              {this.state.inStockQtyNegativeError !== "" && (
                <li>{this.state.inStockQtyNegativeError}</li>
              )}
              {this.state.lineItemExceedError !== "" && (
                <li>{this.state.lineItemExceedError}</li>
              )}
              {this.state.storeError !== "" && <li>{this.state.storeError}</li>}
              {this.state.customerError !== "" && (
                <li>{this.state.customerError}</li>
              )}
              {this.state.fromGSTINError !== "" && (
                <li>{this.state.fromGSTINError}</li>
              )}
              {this.state.toGSTINError !== "" && (
                <li>{this.state.toGSTINError}</li>
              )}
              {this.state.employeeMandatoryAtLineItemError !== "" && (
                <li>{this.state.employeeMandatoryAtLineItemError}</li>
              )}
              {this.state.employeeMandatoryAtTransactionError !== "" && (
                <li>{this.state.employeeMandatoryAtTransactionError}</li>
              )}
              {this.state.noLineItemExistError !== "" && (
                <li>{this.state.noLineItemExistError}</li>
              )}
              {this.state.inStockQtyNegativeErrors.map((m) => (
                <li>{m.Error}</li>
              ))}
              {this.state.batchIDMissingError !== "" && (
                <li>{this.state.batchIDMissingError}</li>
              )}
            </ul>
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderDelivery() {
    return (
      <React.Fragment>
        {this.state.showDelivery && (
          <Delivery
            {...this.props}
            mobile={this.props.isMobileApp()}
            deliveryCustomer={this.state.deliveryCustomer}
            deliveryCustomerAddress={this.state.deliveryCustomerAddress}
            deliveryDate={this.state.deliveryDate}
            deliveryTime={this.state.deliveryTime}
            deliveryNotes={this.state.deliveryNotes}
            onAddCustomer={(newCustomer) => {
              this.setState({
                showAddCustomer: true,
                showInvoice: false,
                fromDelivery: true,
                showDelivery: false,
                newCustomer: newCustomer,
              });
            }}
            onCustomerEdit={(deliveryCustomer) => {
              this.setState({
                tmpDeliveryCustomer: deliveryCustomer,
                showAddCustomer: true,
                showInvoice: false,
                fromDelivery: true,
                showDelivery: false,
              });
            }}
            onShowCustomerDashBoard={(deliveryCustomer) => {
              this.setState({
                tmpDeliveryCustomer: deliveryCustomer,
                showCustomerDashBoard: true,
                showInvoice: false,
                fromDelivery: true,
                showDelivery: false,
              });
            }}
            onClose={() =>
              this.setState({
                showDelivery: false,
                fromDelivery: false,
              })
            }
            onSave={(
              deliveryCustomer,
              deliveryCustomerAddress,
              deliveryDate,
              deliveryTime,
              deliveryNotes,
              placeOfSupply
            ) => {
              this.setState({
                deliveryCustomer: deliveryCustomer,
                deliveryCustomerAddress: deliveryCustomerAddress,
                deliveryDate: deliveryDate,
                deliveryTime: deliveryTime,
                deliveryNotes: deliveryNotes,
                placeOfSupply: placeOfSupply,
                showDelivery: false,
                fromDelivery: false,
              });
            }}
          />
        )}
      </React.Fragment>
    );
  }

  renderOffersApplied() {
    let lineItems = this.state.lineItems;
    let offersApplied = [];
    lineItems.map((l) => {
      if (l.Discounts && l.Discounts.length > 0) {
        if (
          offersApplied.filter((o) => o.key === l.Discounts[0].DiscountID)
            .length === 0
        ) {
          //
          if (
            this.state.offers.filter((o) => o.key === l.Discounts[0].DiscountID)
              .length > 0
          ) {
            offersApplied.push(
              this.state.offers.filter(
                (o) => o.key === l.Discounts[0].DiscountID
              )[0]
            );
          }
        }
      }
    });
    return (
      <div>
        {offersApplied.map((m, index) => (
          <div className="input-group">
            <button
              key={index}
              style={{ height: "64px" }}
              type="submit"
              className="btn btn-lg btn-block btn-default btn-flat"
            >
              {m.Name} <br></br>
              <div style={{ fontSize: "14px" }}>{m.Description}</div>
            </button>
            <div className="input-group-btn">
              <button
                style={{ height: "64px" }}
                type="button"
                onClick={() => this.removeSelectedOffer(m)}
                className="btn btn-default btn-lg btn-flat"
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  }
  renderDiscountOptions() {
    return (
      <React.Fragment>
        {this.state.showDiscountOptions && (
          <Window
            header={"Discount"}
            width={"600px"}
            modal={false}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showDiscountOptions: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "ok") {
                if (this.state.discountOptionSelected === "transaction") {
                  this.onOverAllDiscount(
                    this.state.overallDiscountPercentage,
                    this.state.overallDiscountAmount,
                    this.state.overallDiscountClearExistingDiscount,
                    "",
                    "",
                    ""
                  );
                }
              } else {
                this.setState({ showDiscountOptions: false });
              }
            }}
            buttons={[
              { Type: "secondary", Text: "Cancel", Align: "left" },
              { Type: "primary", Text: "OK", Align: "right" },
            ]}
          >
            <div className="box-body">
              <div className="nav-tabs-custom" style={{ cursor: "move" }}>
                <ul className="nav nav-tabs  ui-sortable-handle">
                  {this.state.showDiscountMembership && (
                    <li
                      className={
                        this.state.discountOptionSelected === "membership"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        onClick={() =>
                          this.setState({
                            discountOptionSelected: "membership",
                          })
                        }
                        data-toggle="tab"
                      >
                        Membership
                      </a>
                    </li>
                  )}
                  {this.state.showDiscountTransaction &&
                    this.state.transactionType.DiscountChecked && (
                      <li
                        className={
                          this.state.discountOptionSelected === "transaction"
                            ? "active"
                            : ""
                        }
                      >
                        <a
                          onClick={() =>
                            this.setState({
                              discountOptionSelected: "transaction",
                            })
                          }
                          data-toggle="tab"
                        >
                          Transaction
                        </a>
                      </li>
                    )}
                  {this.state.showDiscountOffers && (
                    <li
                      className={
                        this.state.discountOptionSelected === "offer"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        onClick={() =>
                          this.setState({ discountOptionSelected: "offer" })
                        }
                        data-toggle="tab"
                      >
                        Offer
                      </a>
                    </li>
                  )}
                  {this.state.showDiscountCoupon && (
                    <li
                      className={
                        this.state.discountOptionSelected === "coupon"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        onClick={() =>
                          this.setState({ discountOptionSelected: "coupon" })
                        }
                        data-toggle="tab"
                      >
                        Coupon
                      </a>
                    </li>
                  )}
                </ul>
                <div className="tab-content no-padding">
                  {this.state.discountOptionSelected === "membership" &&
                    this.state.selectedCustomer &&
                    this.state.selectedCustomer.Membership &&
                    this.state.selectedCustomer.Membership.hasOwnProperty(
                      "MembershipID"
                    ) && (
                      <div className="box-body" style={{ height: "300px" }}>
                        <div style={{ padding: "10px 0px 10px 0px" }}>
                          Apply membership discount
                        </div>
                        <label>
                          <Switch
                            onChange={() =>
                              this.onApplyRemoveMembershipDiscount(
                                !this.state.applyMembershipDiscount,
                                this.state.applicableMembership
                              )
                            }
                            checked={this.state.applyMembershipDiscount}
                          />
                        </label>
                      </div>
                    )}
                  {this.state.discountOptionSelected === "coupon" && (
                    <React.Fragment>
                      {" "}
                      <div className="box-body" style={{ height: "310px" }}>
                        {!this.state.coupon && (
                          <div className="row">
                            <div className="col-md-8 col-xs-8">
                              <div
                                className={
                                  this.state.couponCodeError !== ""
                                    ? "form-group has-error"
                                    : "form-group "
                                }
                              >
                                <Input
                                  type="text"
                                  onChange={(value) =>
                                    this.setState({ couponCode: value })
                                  }
                                  value={this.state.couponCode}
                                  placeholder="Please enter coupon code"
                                />
                                {this.state.couponCodeError !== "" && (
                                  <span className="help-block">
                                    {this.state.couponCodeError}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 col-xs-4">
                              <Button
                                text="Apply"
                                type="primary"
                                onClick={() => this.applyCoupon()}
                              />
                            </div>
                          </div>
                        )}
                        {this.state.couponErrorMsg.trim() !== "" && (
                          <div className="col-md-12" style={{ padding: "0px" }}>
                            <div className="alert alert-danger alert-dismissible">
                              <h4>
                                <i className="icon fa fa-ban"></i> Error!
                              </h4>
                              {this.state.couponErrorMsg}
                            </div>
                          </div>
                        )}
                        {this.state.coupon &&
                          this.state.couponErrorMsg.trim() === "" && (
                            <div className="input-group">
                              <button
                                style={{ height: "64px" }}
                                type="submit"
                                className="btn btn-lg btn-block btn-default btn-flat"
                              >
                                {this.state.coupon.Name} <br></br>
                                <div style={{ fontSize: "14px" }}>
                                  {this.state.coupon.Description}
                                </div>
                              </button>
                              <div className="input-group-btn">
                                <button
                                  style={{ height: "64px" }}
                                  type="button"
                                  onClick={() =>
                                    this.removeSelectedCoupon(
                                      this.state.coupon.key
                                    )
                                  }
                                  className="btn btn-default btn-lg btn-flat"
                                >
                                  <i className="fa fa-remove"></i>
                                </button>
                              </div>
                            </div>
                          )}
                        {this.state.coupon && (
                          <div className="col-md-12">
                            <div>{this.state.coupon.Name}</div>
                            {this.state.coupon.Description !== "" && (
                              <div>{this.state.coupon.Description}</div>
                            )}
                            <div>
                              Validity From: {this.state.coupon.ValidityFrom}{" "}
                              Validity To : {this.state.coupon.ValidityTo}
                            </div>
                            {this.state.coupon.SpecificDay === "Yes" && (
                              <div>
                                {"Specific Days: " +
                                  this.state.coupon.SpecificDays}
                              </div>
                            )}
                            {this.state.coupon.SpecificTime === "Yes" && (
                              <div>
                                Specific Time :{" "}
                                {this.state.coupon.OfferStartTime.HH +
                                  ":" +
                                  this.state.coupon.OfferStartTime.MM +
                                  " " +
                                  this.state.coupon.OfferStartTime.AMPM}{" "}
                                -{" "}
                                {this.state.coupon.OfferEndTime.HH +
                                  ":" +
                                  this.state.coupon.OfferEndTime.MM +
                                  " " +
                                  this.state.coupon.OfferEndTime.AMPM}
                              </div>
                            )}
                            <div>
                              Offer Type : {this.state.coupon.OfferType}
                            </div>
                            {this.state.coupon.PurchaseGroup !== "" && (
                              <div>
                                Purchase Group :
                                {this.state.coupon.PurchaseGroup}
                              </div>
                            )}
                            {this.state.coupon.PurchaseQty !== 0 && (
                              <div>
                                Purchase Qty : {this.state.coupon.PurchaseQty}
                              </div>
                            )}
                            {this.state.coupon.FreeGroup !== "" && (
                              <div>
                                {" "}
                                Free Group : {this.state.coupon.FreeGroup}
                              </div>
                            )}
                            {this.state.coupon.FreeQty !== 0 && (
                              <div>Free Qty : {this.state.coupon.FreeQty}</div>
                            )}
                            {this.state.coupon.InvoiceAmount !== 0 && (
                              <div>
                                Invoice Amount :{" "}
                                {this.state.coupon.InvoiceAmount}
                              </div>
                            )}
                            {this.state.coupon.Discount !== "" && (
                              <div>
                                Discount Type : {this.state.coupon.Discount}
                              </div>
                            )}
                            {this.state.coupon.DiscountValue !== 0 && (
                              <div>
                                {" "}
                                Discount Value :{" "}
                                {this.state.coupon.DiscountValue}
                              </div>
                            )}
                            {this.state.coupon.OfferGroup !== "" && (
                              <div>
                                {" "}
                                Offer Group : {this.state.coupon.OfferGroup}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.discountOptionSelected === "transaction" &&
                    this.state.transactionType &&
                    this.state.transactionType.DiscountChecked && (
                      <div className="box-body" style={{ height: "250px" }}>
                        <Input
                          type="number"
                          label="Discount Percentage"
                          onChange={(value) =>
                            this.setState({ overallDiscountPercentage: value })
                          }
                          onEnter={this.handleKeyPress}
                          value={this.state.overallDiscountPercentage}
                        />
                        <Input
                          type="number"
                          label="Discount Amount"
                          onChange={(value) =>
                            this.setState({ overallDiscountAmount: value })
                          }
                          onEnter={this.handleKeyPress}
                          value={this.state.overallDiscountAmount}
                        />
                        <Input
                          type="checkbox"
                          label="Clear existing discount"
                          value={
                            this.state.overallDiscountClearExistingDiscount
                          }
                          onChange={(value) =>
                            this.setState({
                              overallDiscountClearExistingDiscount: value,
                            })
                          }
                        />
                      </div>
                    )}
                  {this.state.discountOptionSelected === "offer" && (
                    <div className="box-body" style={{ paddingBottom: "0px" }}>
                      <div
                        className="nav-tabs-custom"
                        style={{ cursor: "move" }}
                      >
                        <ul className="nav nav-tabs  ui-sortable-handle">
                          <li
                            className={
                              this.state.offersPopupTabSelected === "applied"
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              onClick={() =>
                                this.setState({
                                  offersPopupTabSelected: "applied",
                                })
                              }
                              data-toggle="tab"
                            >
                              Applied
                            </a>
                          </li>
                          <li
                            className={
                              this.state.offersPopupTabSelected === "applicable"
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              onClick={() =>
                                this.setState({
                                  offersPopupTabSelected: "applicable",
                                })
                              }
                              data-toggle="tab"
                            >
                              Applicable
                            </a>
                          </li>
                          <li
                            className={
                              this.state.offersPopupTabSelected === "all"
                                ? "active"
                                : ""
                            }
                          >
                            <a
                              onClick={() =>
                                this.setState({ offersPopupTabSelected: "all" })
                              }
                              data-toggle="tab"
                            >
                              {" "}
                              All
                            </a>
                          </li>
                        </ul>
                        <div
                          className="scroll2"
                          style={{
                            height: "230px",
                            padding: "10px",
                            paddingBottom: "0px",
                            overflowY: "scroll",
                          }}
                        >
                          {this.state.offersPopupTabSelected === "applied" &&
                            this.renderOffersApplied()}
                          {this.state.offersPopupTabSelected ===
                            "applicable" && (
                            <div>
                              {this.state.offersToApply.map((m, index) => (
                                <button
                                  key={index}
                                  type="submit"
                                  className="btn btn-lg btn-block btn-default btn-flat"
                                  onClick={() => this.applySelectedOffer(index)}
                                >
                                  {m.Name} <br></br>{" "}
                                  <div style={{ fontSize: "14px" }}>
                                    {m.Description}
                                  </div>
                                </button>
                              ))}
                            </div>
                          )}
                          {this.state.offersPopupTabSelected === "all" && (
                            <div>
                              {this.state.offers.map((m, index) => (
                                <button
                                  key={index}
                                  type="submit"
                                  className="btn btn-lg btn-block btn-default btn-flat"
                                >
                                  {m.Name} <br></br>
                                  <div style={{ fontSize: "14px" }}>
                                    {m.Description}
                                  </div>
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderEWayBill() {
    return (
      <React.Fragment>
        {this.state.showEWayBill && (
          <Window
            header={"e Way Bill"}
            height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showEWayBill: false })}
            onButtonClick={(buttonType) => {
              if (buttonType.toLowerCase() === "yes") {
                this.onEWayBillOKClicked();
              } else {
                this.onEWayBillCancelClicked();
              }
            }}
            buttons={[
              { Type: "secondary", Text: "No", Align: "left" },
              { Type: "primary", Text: "Yes", Align: "right" },
            ]}
          >
            <Input
              label="Generate e way bill"
              type="checkbox"
              checked={this.state.tmpGenerateEWayBill}
              onChange={(value) =>
                this.setState({ tmpGenerateEWayBill: value })
              }
            />
            <Input
              label="Transporter Name"
              type="text"
              onChange={(value) =>
                this.setState({ tmpEWayBillTransporterName: value })
              }
              value={this.state.tmpEWayBillTransporterName}
            />
            <Input
              label="Mode Of Transport"
              type="select"
              onChange={(value) =>
                this.setState({ tmpEWayBillModeOfTransportCode: value })
              }
              value={this.state.tmpEWayBillModeOfTransportCode}
            >
              <option value={0}>Select</option>
              {this.state.modeOfTransportList.map((m) => (
                <option value={m.Code}>{m.Name}</option>
              ))}
            </Input>
            <Input
              label="Vehicle No."
              type="text"
              onChange={(value) =>
                this.setState({ tmpEWayBillVehicleNo: value })
              }
              value={this.state.tmpEWayBillVehicleNo}
            />
            <Input
              label="Vehicle Type"
              type="select"
              onChange={(value) =>
                this.setState({ tmpEWayBillVehicleTypeCode: value })
              }
              value={this.state.tmpEWayBillVehicleTypeCode}
            >
              <option value={0}>Select</option>
              {this.state.vehicleTypelist.map((m) => (
                <option value={m.Code}>{m.Name}</option>
              ))}
            </Input>
            <Input
              label="Distance Of Transport"
              type="number"
              onChange={(value) =>
                this.setState({ tmpEWayBillDistanceOfTransport: value })
              }
              value={this.state.tmpEWayBillDistanceOfTransport}
            />
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderModifierSelection() {
    return (
      <React.Fragment>
        {/* {this.state.showModifiers && <Window 
                header={"Modifier (s)"}  
                width={"600px"}
                // height={"200px"}
                // modal={true}
                // processing={this.state.deleting}
                onClose={()=>this.setState({showModifiers:false})}
                // onButtonClick={(buttonType)=>{}}
                buttons={[]}> */}
        {this.state.showModifiers && (
          <SalesModifiers
            {...this.props}
            mobile={this.props.isMobileApp()}
            selectedLineItem={this.state.selectedLineItem}
            selectedModifiers={this.state.selectedModifiers}
            modifierQty={this.state.modifierQty}
            modifierAmount={this.state.modifierAmount}
            applicableModifierGroups={this.state.applicableModifierGroups}
            applicableModifiers={this.state.applicableModifiers}
            selectedModifierGroupID={this.state.selectedModifierGroupID}
            onModifierGroupSelected={(modifierGroupID) =>
              this.setState({ selectedModifierGroupID: modifierGroupID })
            }
            onModifierAdded={(modifier) => {
              let selectedModifiers = this.state.selectedModifiers;
              let modifierQty = Number(this.state.modifierQty);
              let modifierAmount = Number(this.state.modifierAmount);
              let selectedModifier = {
                key: modifier.key,
                ModifierName: modifier.ModifierName,
                ModifierPrice: modifier.ModifierPrice,
                ModifierGroup: modifier.ModifierGroup,
                ModifierGroupID: modifier.ModifierGroupID,
              };
              selectedModifiers.unshift(selectedModifier);
              modifierQty = modifierQty + 1;
              modifierAmount =
                modifierAmount + Number(selectedModifier.ModifierPrice);
              this.setState({
                selectedModifiers: selectedModifiers,
                modifierQty: modifierQty,
                modifierAmount: modifierAmount,
              });
            }}
            onModifierRemoved={(index, amount) => {
              let selectedModifiers = [];
              let modifierQty = Number(this.state.modifierQty);
              let modifierAmount = Number(this.state.modifierAmount);
              this.state.selectedModifiers.map((m, i) => {
                if (index !== i) {
                  selectedModifiers.push(m);
                }
              });
              modifierQty = modifierQty - 1;
              modifierAmount = modifierAmount - Number(amount);
              this.setState({
                selectedModifiers: selectedModifiers,
                modifierQty: modifierQty,
                modifierAmount: modifierAmount,
              });
            }}
            onModifierSelectionCompletion={() => {
              this.onModifierSelectionCompletion();
            }}
            onClose={() => this.onModifierSelectionClose()}
          />
        )}
        {/* </Window>} */}
      </React.Fragment>
    );
  }

  renderCustomFields() {
    return (
      <React.Fragment>
        {/* {this.state.showAdditionalInfo && <Window 
            header={"Additional Info"}  
            width={"600px"}
            // height={"200px"}
            // modal={true}
            // processing={this.state.deleting}
            onClose={()=>this.setState({showAdditionalInfo:false})}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}> */}
        {this.state.showAdditionalInfo && (
          <CustomFieldEntry
            {...this.props}
            mobile={this.props.isMobileApp()}
            onClose={() => this.setState({ showAdditionalInfo: false })}
            customField1DisplayName={this.state.customField1Name}
            customField2DisplayName={this.state.customField2Name}
            customField3DisplayName={this.state.customField3Name}
            customField4DisplayName={this.state.customField4Name}
            customField5DisplayName={this.state.customField5Name}
            customField6DisplayName={this.state.customField6Name}
            customField7DisplayName={this.state.customField7Name}
            customField8DisplayName={this.state.customField8Name}
            customField9DisplayName={this.state.customField9Name}
            customField10DisplayName={this.state.customField10Name}
            customField11DisplayName={this.state.customField11Name}
            customField12DisplayName={this.state.customField12Name}
            customField13DisplayName={this.state.customField13Name}
            customField14DisplayName={this.state.customField14Name}
            customField15DisplayName={this.state.customField15Name}
            customField16DisplayName={this.state.customField16Name}
            customField17DisplayName={this.state.customField17Name}
            customField18DisplayName={this.state.customField18Name}
            customField19DisplayName={this.state.customField19Name}
            customField20DisplayName={this.state.customField20Name}
            customField1={this.state.customField1}
            customField2={this.state.customField2}
            customField3={this.state.customField3}
            customField4={this.state.customField4}
            customField5={this.state.customField5}
            customField6={this.state.customField6}
            customField7={this.state.customField7}
            customField8={this.state.customField8}
            customField9={this.state.customField9}
            customField10={this.state.customField10}
            customField11={this.state.customField11}
            customField12={this.state.customField12}
            customField13={this.state.customField13}
            customField14={this.state.customField14}
            customField15={this.state.customField15}
            customField16={this.state.customField16}
            customField17={this.state.customField17}
            customField18={this.state.customField18}
            customField19={this.state.customField19}
            customField20={this.state.customField20}
            onOK={(
              customField1,
              customField2,
              customField3,
              customField4,
              customField5,
              customField6,
              customField7,
              customField8,
              customField9,
              customField10,
              customField11,
              customField12,
              customField13,
              customField14,
              customField15,
              customField16,
              customField17,
              customField18,
              customField19,
              customField20
            ) => {
              this.setState({
                showAdditionalInfo: false,
                customField1: customField1,
                customField2: customField2,
                customField3: customField3,
                customField4: customField4,
                customField5: customField5,
                customField6: customField6,
                customField7: customField7,
                customField8: customField8,
                customField9: customField9,
                customField10: customField10,
                customField11: customField11,
                customField12: customField12,
                customField13: customField13,
                customField14: customField14,
                customField15: customField15,
                customField16: customField16,
                customField17: customField17,
                customField18: customField18,
                customField19: customField19,
                customField20: customField20,
              });
            }}
          />
        )}
        {/* </Window>} */}
      </React.Fragment>
    );
  }

  renderUniqueNoInput() {
    return (
      <React.Fragment>
        {/* {this.state.showUniqueNoInput && <Window 
            header= {this.state.selectedProduct.hasOwnProperty("UniqueLabel") &&
                this.state.selectedProduct.UniqueLabel.trim() !== "" 
                ? this.state.selectedProduct.UniqueLabel.trim(): "Unique No"} 
            width={"600px"}
            height={"200px"}
            // modal={true}
            // processing={this.state.deleting}
            onClose={()=>this.setState({showUniqueNoInput:false})}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}> */}
        {this.state.showUniqueNoInput && (
          <UniqueNoInput
            {...this.props}
            mobile={this.props.isMobileApp()}
            transactionID={this.state.key}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            mode={"remove"}
            onOK={(uniqueNoList) => {
              this.productSelected(this.state.selectedProduct, uniqueNoList);
              this.setState({ showUniqueNoInput: false });
            }}
            onClose={() => this.setState({ showUniqueNoInput: false })}
          />
        )}
        {/* </Window>} */}
      </React.Fragment>
    );
  }

  renderUniqueNoSelection() {
    return (
      <React.Fragment>
        {/* {this.state.showUniqueNoSelection && <Window 
            header={this.state.selectedProduct.Name}  
            width={"600px"}
            // height={"200px"}
            // modal={true}
            // processing={this.state.deleting}
            onClose={()=>this.setState({showUniqueNoSelection:false, selectedLineItem: null,})}
            // onButtonClick={(buttonType)=>{}} 
            buttons={[]}> */}
        {this.state.showUniqueNoSelection && (
          <UniqueNoSelection
            {...this.props}
            mobile={this.props.isMobileApp()}
            product={this.state.selectedProduct}
            lineItem={this.state.selectedLineItem}
            lineItems={this.state.lineItems}
            negativeQty={this.state.negativeQty}
            salesReturn={
              this.props.location.state.transactionType &&
              this.props.location.state.transactionType.TransactionType.toLowerCase() ===
                "sales return"
                ? true
                : false
            }
            onOK={(uniqueNoList) => {
              this.productSelected(this.state.selectedProduct, uniqueNoList);
              this.setState({
                showUniqueNoSelection: false,
                selectedLineItem: null,
              });
            }}
            onClose={() =>
              this.setState({
                showUniqueNoSelection: false,
                selectedLineItem: null,
              })
            }
          />
        )}
        {/* </Window>} */}
      </React.Fragment>
    );
  }

  renderBatchSelection() {
    return (
      <React.Fragment>
        {/* {this.state.showBatchsForSelection && <Window 
            header={"Batch Selection"}  
            width={"600px"}
            // modal={true}
            // processing={this.state.deleting}
            onClose={()=>this.setState({showBatchsForSelection:false, selectedLineItem: null,selectedProduct:null})}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}> */}
        {this.state.showBatchsForSelection && (
          <BatchSelection
            {...this.props}
            mobile={this.props.isMobileApp()}
            product={this.state.selectedProduct}
            showAllBatches={this.state.showAllBatches}
            storeLocationID={
              this.props.user.cashRegister &&
              this.props.user.cashRegister.StoreLocationID
            }
            lineItems={this.state.lineItems}
            onOK={async (batches) => {
              for (let i = 0; i < batches.length; i++) {
                let batch = batches[i];
                // console.log('batch',batch)
                if (this.state.selectedLineItem) {
                  let lineItems = this.state.lineItems;
                  lineItems[this.state.selectedLineItemIndex].BatchID =
                    batch.key;
                  this.setState({ lineItems: lineItems });
                } else {
                  await this.productSelected(
                    this.state.selectedProduct,
                    [],
                    batch
                  );
                }
              }
              this.setState({
                showBatchsForSelection: false,
                selectedLineItem: null,
                selectedProduct: null,
                showAllBatches: false,
              });
            }}
            onClose={() =>
              this.setState({
                showBatchsForSelection: false,
                showAllBatches: false,
                selectedLineItem: null,
                selectedProduct: null,
              })
            }
            onShowAllStoreInStockQty={(product) => {
              console.log("onShowAllStoreInStockQty");
              this.setState({
                showBatchsForSelection: false,
                showAllBatches: false,
                showAllStoreInStockQty: true,
                selectedProductForWhichAllStoreInStockQtyToBeShown: product,
              });
            }}
          />
        )}
        {/* // </Window>} */}
      </React.Fragment>
    );
  }

  renderTransactionTypeSelected() {
    return (
      <React.Fragment>
        {this.state.showTransactionTypes && (
          <Window
            header={"Change Sales Transaction"}
            height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={() => this.setState({ showTransactionTypes: false })}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}
          >
            {this.state.transactionTypes.map((m, index) => (
              <Button
                type="secondary"
                text={m.TransactionType}
                key={index}
                onClick={() => {
                  this.onTransactionTypeSelected(m);
                }}
              />
            ))}
          </Window>
        )}
      </React.Fragment>
    );
  }

  renderProductVariants() {
    return (
      <React.Fragment>
        {/* {this.state.showVariants && <Window 
            header={""}  
            height={"200px"}
            modal={true}
            // processing={this.state.deleting}
            onClose={()=>this.setState({showVariants:false})}
            // onButtonClick={(buttonType)=>{}}
            buttons={[]}> */}
        {this.state.showVariants && (
          <ProductVariants
            {...this.props}
            mobile={this.props.isMobileApp()}
            selectedVariantProduct={this.state.selectedVariantProduct}
            onOK={async (product) => {
              await this.productSelected(product, []);
              this.setState({
                showVariants: false,
                selectedLineItem: null,
                selectedVariantProduct: null,
              });
            }}
            onClose={() => this.setState({ showVariants: false })}
          />
        )}
        {/* </Window>}     */}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSalesChannelSelectionWindow()}
        {this.state.showInvoice && (
          <React.Fragment>
            {this.props.isMobileApp() &&
              this.state.transactionType &&
              this.renderBilling()}

            {!this.props.isMobileApp() && this.renderDesktop()}

            {this.props.config.PrintConfigs && (
              <div style={{ display: "none" }}>
                {this.props.config.PrintConfigs.map((m, index) => (
                  <React.Fragment key={index}>
                    {m.HeaderImage && m.HeaderImage.Preview.trim() !== "" && (
                      <img src={m.HeaderImage.Preview}></img>
                    )}
                    {m.FooterImage && m.FooterImage.Preview.trim() !== "" && (
                      <img src={m.FooterImage.Preview}></img>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </React.Fragment>
        )}

        {this.renderAdditionalCharges()}

        {this.renderNotesControl()}

        {this.renderParkedSales()}

        {this.renderLineItemModal()}

        {this.renderError()}

        {this.renderDelivery()}

        {this.renderDiscountOptions()}

        {this.renderEWayBill()}

        {this.renderModifierSelection()}

        {this.renderCustomFields()}

        {this.renderUniqueNoInput()}

        {this.renderUniqueNoSelection()}

        {this.renderBatchSelection()}

        {this.renderTransactionTypeSelected()}

        {this.renderProductVariants()}

        {/* {this.state.showProductSelection && (
          <ProductSelection
            products={this.state.showProductSelectionList}
            onProductSelection={(product) => {
              this.productSelected(product);
              this.setState({ showProductSelection: false });
            }}
            onClose={() => this.setState({ showProductSelection: false })}
          />
        )} */}

        {this.state.showProductSelection && (
          <ProductSelection
            products={this.state.showProductSelectionList}
            onProductSelection={(product) => {
              this.productSelected(product);
              this.setState({ showProductSelection: false });
              this.setState({ productsTypeAheadSelected: [] });
              this.setState({ scannedInput: "" });
              // if (this.refs.refProductSearch)
              //   this.refs.refProductSearch.getInstance().focus(); //13feb
            }}
            onClose={() => {
              this.setState({
                showProductSelection: false,
                productsTypeAheadSelected: [],
                scannedInput: "",
              });
              // if (this.refs.refProductSearch)
              //   this.refs.refProductSearch.getInstance().focus(); //13feb
            }}
          />
        )}

        {this.state.showPayment && (
          <React.Fragment>
            <Payment
              {...this.props}
              mobile={this.props.isMobileApp()}
              transactionID={this.state.key}
              onCancel={() => {
                this.setState({
                  showPayment: false,
                  showInvoice: true,
                });
              }}
              onSave={() => {
                this.onSave();
              }}
              sale={this.state.sale}
              tableOrder={this.state.tableOrder}
              printKOT={this.state.printKOT}
              printBill={this.state.printBill}
              payments={this.state.payments}
            />
          </React.Fragment>
        )}

        {this.state.showAddCustomer && (
          <Customer
            {...this.props}
            calledFromInvoice={true}
            selectedCustomer={
              this.state.fromDelivery
                ? this.state.tmpDeliveryCustomer
                : this.state.selectedCustomer
            }
            newCustomer={this.state.newCustomer}
            onSave={(customer) => {
              // console.log('customer-after',customer)
              if (!this.state.fromDelivery) {
                this.onCustomerSelected(customer);
              } else {
                this.setState({ deliveryCustomer: customer });
              }
              this.setState({
                showAddCustomer: false,
                showInvoice: true,
                showDelivery: this.state.fromDelivery,
              });
            }}
            onClose={() =>
              this.setState({
                showAddCustomer: false,
                showInvoice: true,
                showDelivery: this.state.fromDelivery,
              })
            }
          />
        )}

        {this.state.showCustomerDashBoard && (
          <CustomerDashBoard
            {...this.props}
            calledFromInvoice={true}
            cutomerID={
              this.state.fromDelivery
                ? this.state.tmpDeliveryCustomer.key
                : this.state.selectedCustomer.key
            }
            onClose={() =>
              this.setState({
                showCustomerDashBoard: false,
                showInvoice: true,
                showDelivery: this.state.fromDelivery,
              })
            }
          />
        )}
        {this.state.showAllStoreInStockQty && (
          <AllStoreInStockQty
            {...this.props}
            product={
              this.state.selectedProductForWhichAllStoreInStockQtyToBeShown
            }
            onClose={() => this.setState({ showAllStoreInStockQty: false })}
          />
        )}

        {this.state.showAddProduct &&
          !this.props.registration.registration.EnablePosBillingForPincode && (
            <Product
              {...this.props}
              calledFromInvoice={true}
              mobile={this.props.isMobileApp()}
              searchBarcode={this.state.scanedCode}
              onSave={(product) => {
                this.setState({
                  showAddProduct: false,
                  showInvoice: true,
                  mobileShowInvoice: true,
                });
                this.onProductSelected(product);
                //update redux with new product
              }}
              onClose={() =>
                this.setState({
                  showAddProduct: false,
                  showInvoice: true,
                  mobileShowInvoice: true,
                })
              }
            />
          )}

        {this.state.showAddProduct &&
          this.props.registration.registration.EnablePosBillingForPincode && (
            <ProductPincode
              {...this.props}
              selectedProductID={this.state.selectedProductID}
              calledFromInvoice={true}
              mobile={this.props.isMobileApp()}
              searchBarcode={this.state.scanedCode}
              onSave={(product) => {
                // this.setState({
                //   showAddProduct: false,
                //   showInvoice: true,
                //   mobileShowInvoice: true,
                // });
                // this.onProductSelected(product);
                if (
                  this.state.selectedLineItemIndex >= 0 &&
                  this.state.selectedProductID !== ""
                ) {
                  let lineItems = this.state.lineItems;
                  lineItems[this.state.selectedLineItemIndex].Product = product;
                  this.setState({
                    lineItems: lineItems,
                    showLineItemModal: false,
                    // selectedProduct: product,
                  });
                } else {
                  this.onProductSelected(product);
                }
                this.setState({
                  showAddProduct: false,
                  showInvoice: true,
                  mobileShowInvoice: true,
                  selectedProductID: "",
                });
              }}
              onClose={() =>
                this.setState({
                  showAddProduct: false,
                  showInvoice: true,
                  mobileShowInvoice: true,
                })
              }
            />
          )}
      </React.Fragment>
    );
  }
}
export default withRouter(Sales);
