import React, { useEffect, useState, useRef } from "react";
import Icon from "./Icon";



const Autocompletesearch = ({
  label,
  value,
  onChange,
  onSelect,
  placeholder,
  Addbuttonclick,
  Addbutntext,
  showsearch,
  searchbtnclick,
  errormessage,
  data = [],
  defaultDataCount = 5,
  filterKey = "text",
  renderSuggestion,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const debouncedValue = useDebounce(inputValue, 500);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [forceShowAll, setForceShowAll] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const wrapperRef = useRef(null);
  const suggestionsRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
        setForceShowAll(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (!showSuggestions) {
      setIsTyping(false);
    }
  }, [showSuggestions]);

  const searchValue = debouncedValue.toLowerCase();
  const filteredData = (!isTyping && showSuggestions)
    ? data.slice(0, defaultDataCount)
    : debouncedValue
      ? data.filter((item) => {
        if (Array.isArray(filterKey)) {
          return filterKey.some((key) =>
            String(item[key] || "").toLowerCase().includes(searchValue))
        }
        return String(item[filterKey] || "").toLowerCase().includes(searchValue);
      })
      : data.slice(0, defaultDataCount);

  const getDefaultDisplayValue = (item) => {
    if (Array.isArray(filterKey)) {
      return item[filterKey[0]] || "";
    }
    return item[filterKey] || "";
  };

  const isItemSelected = (item) => {
    const displayValue = getDefaultDisplayValue(item);
    return displayValue === inputValue;
  };

  const handleItemClick = (item) => {
    const displayValue = getDefaultDisplayValue(item);
    setInputValue(displayValue);
    onChange&&onChange(displayValue);
    if (onSelect) {
      onSelect(item);
    }
    setShowSuggestions(false);
    setForceShowAll(false);
  };

  const handleFocus = () => {
    setShowSuggestions(true);
    setSelectedIndex(-1);
    setForceShowAll(true);
  };

  const handleToggleSuggestions = () => {
    const willShow = !showSuggestions;
    setShowSuggestions(willShow);
    setForceShowAll(willShow);
    setSelectedIndex(-1);
    setIsTyping(false);
  };

  const defaultRenderSuggestion = (item, index) => {
    return (
      <div
        style={{
          padding: '8px 12px',
          cursor: 'pointer',
          backgroundColor: selectedIndex === index ? '#f5f5f5' : 
                         isItemSelected(item) ? '#e8e8e8' : 'transparent',
          color: selectedIndex === index ? '#262626' : '#333',
          ':hover': {
            backgroundColor: '#f5f5f5'
          }
        }}
        onClick={() => handleItemClick(item)}
        onMouseEnter={() => setSelectedIndex(index)}
        ref={(el) => (itemRefs.current[index] = el)}
      >
        <p style={{ fontWeight: 'bold', margin: 0 }}>{getDefaultDisplayValue(item)}</p>
      </div>
    );
  };

  const scrollIntoView = (index) => {
    if (itemRefs.current[index] && suggestionsRef.current) {
      const item = itemRefs.current[index];
      const container = suggestionsRef.current;
      const itemTop = item.offsetTop;
      const itemBottom = itemTop + item.offsetHeight;
      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.offsetHeight;

      if (itemTop < containerTop) {
        container.scrollTop = itemTop;
      } else if (itemBottom > containerBottom) {
        container.scrollTop = itemBottom - container.offsetHeight;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions || filteredData.length === 0) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      const nextIndex = selectedIndex + 1;
      const newIndex = nextIndex < filteredData.length ? nextIndex : 0;
      setSelectedIndex(newIndex);
      scrollIntoView(newIndex);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const prevIndex = selectedIndex - 1;
      const newIndex = prevIndex >= 0 ? prevIndex : filteredData.length - 1;
      setSelectedIndex(newIndex);
      scrollIntoView(newIndex);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (selectedIndex >= 0 && selectedIndex < filteredData.length) {
        handleItemClick(filteredData[selectedIndex]);
      }
    } else if (e.key === "Escape") {
      setShowSuggestions(false);
      setForceShowAll(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    onChange&&onChange(e.target.value)
    setSelectedIndex(-1);
    setForceShowAll(false);
    setIsTyping(true);
    if (!showSuggestions) {
      setShowSuggestions(true);
    }
  };
  
  return (
    <div style={{ position: 'relative' }} ref={wrapperRef}>
      {/* Label */}
      {label && (
        <label style={{ 
          display: 'block', 
          marginBottom: '5px',
          color: errormessage ? '#dc3545' : '#212529'
        }}>
          {label}
        </label>
      )}

      {/* Input Container */}
      <div style={{ 
        display: 'flex',
        width: '100%',
        border: errormessage ? '1px solid #dc3545' : '1px solid #ced4da',
        overflow:"hidden",
        borderRadius: '32px'
      }}>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          style={{
            flex: 1,
            padding: '6px 12px',
            border: 'none',
            outline: 'none',
            fontSize: '14px',
            lineHeight: '1.5',
            borderRadius: '4px 0 0 4px'
          }}
          onKeyDown={handleKeyDown}
        />
        
        {inputValue && (
          <button
            style={{
              // padding: '6px 12px',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              borderLeft: '1px solid #ced4da'
            }}
            type="button"
            onClick={() => {
              setInputValue("");
              onChange&&onChange("");
              setForceShowAll(true);
              setIsTyping(false);
            }}
          >
            <i className="glyphicon glyphicon-remove"></i>
          </button>
        )}
        
        <button
          style={{
            // padding: '6px 12px',
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            borderLeft: '1px solid #ced4da'
          }}
          type="button"
          onClick={handleToggleSuggestions}
        >
          <i className={`glyphicon glyphicon-chevron-${showSuggestions ? "up" : "down"}`}></i>
          {/* <Icon type={"downarrow"}/> */}
        </button>

        {showsearch && (
          <button
            style={{
              // padding: '6px 12px',
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              borderLeft: '1px solid #ced4da'
            }}
            type="button"
            onClick={searchbtnclick}
          >
            <i className="glyphicon glyphicon-search"></i>
          </button>
        )}
      </div>

      {errormessage && (
        <div style={{
          width: '100%',
          marginTop: '0.25rem',
          fontSize: '0.875em',
          color: '#dc3545'
        }}>
          {errormessage}
        </div>
      )}

      {/* Suggestions Dropdown */}
      {showSuggestions && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            bottom: 'auto',
            left: 0,
            zIndex: 1000,
            display: showSuggestions ? 'block' : 'none',
            float: 'left',
            minWidth: '160px',
            maxHeight: '300px',
            overflowY: 'auto',
            padding: '5px 0',
            margin: '2px 0 0',
            fontSize: '14px',
            textAlign: 'left',
            listStyle: 'none',
            backgroundColor: '#fff',
            backgroundClip: 'padding-box',
            border: '1px solid #ccc',
            border: '1px solid rgba(0, 0, 0, 0.15)',
            borderRadius: '4px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
            WebkitBoxShadow: '0 6px 12px rgba(0, 0, 0, 0.175)',
          }}
          ref={suggestionsRef}
          role="listbox"
        >
          {filteredData.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: selectedIndex === index ? '#f5f5f5' : 
                                  isItemSelected(item) ? '#e8e8e8' : 'transparent',
                  ':hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
                onClick={() => handleItemClick(item)}
                onMouseEnter={() => setSelectedIndex(index)}
                ref={(el) => (itemRefs.current[index] = el)}
                role="option"
                aria-selected={isItemSelected(item)}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {renderSuggestion
                    ? renderSuggestion(item, index)
                    : defaultRenderSuggestion(item, index)}
                </div>
                {isItemSelected(item) && (
                  <i className="bi bi-check" style={{ color: '#0d6efd' }}></i>
                )}
              </div>
            );
          })}
          {filteredData.length === 0 && (
            <div style={{ padding: '8px 12px', color: '#6c757d' }}>
              No results found
            </div>
          )}
        </div>
      )}

      {Addbutntext && showSuggestions && (
        <div style={{ 
          borderTop: '1px solid rgba(0, 0, 0, 0.15)',
          padding: '12px',
          backgroundColor: '#f8f9fa'
        }}>
          <button
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              color: '#0d6efd',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
            onClick={Addbuttonclick}
          >
            <i className="bi bi-plus"></i> {Addbutntext}
          </button>
        </div>
      )}
    </div>
  );
};
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default Autocompletesearch;